import { exists } from "@les-ormes/lib";
import moment from "moment";
import React from "react";
import { IBasketActivity } from "../../generated/dataInterfaces";
import { useAppContext } from "../Context/AppContext";
import useBasket from "../Context/BasketContext/useBasket";
import useCustomerMemberships from "../Context/CustomerMembershipsContext";

interface IMembershipPickerProps {
    basketActivity: IBasketActivity;
    quantityIndex: number;
}

const MembershipPicker = ({
    basketActivity,
    quantityIndex
}: IMembershipPickerProps) => {
    const { applicableCustomerMemberships, appliedCustomerMembershipIds } =
        basketActivity;

    const selectedCustomerMembershipId =
        appliedCustomerMembershipIds[quantityIndex];

    const { applyMembership, customerMemberships, isApplyMembershipLoading } =
        useCustomerMemberships();
    const { basketId } = useBasket();
    const { customerId } = useAppContext();

    if (!customerId || !basketId) {
        return null;
    }

    const isCustomerMembershipAmongApplicable =
        applicableCustomerMemberships?.some(
            (m) => m.id === selectedCustomerMembershipId
        ) ?? false;

    const selectedCustomerMembership = customerMemberships?.find(
        (m) => m.id === selectedCustomerMembershipId
    );

    const defaultValue = !selectedCustomerMembershipId
        ? ""
        : selectedCustomerMembershipId;

    return (
        <select
            data-testid="membership-select-input"
            disabled={
                (!selectedCustomerMembershipId &&
                    applicableCustomerMemberships?.length === 0) ||
                isApplyMembershipLoading
            }
            onChange={async (event) => {
                await applyMembership({
                    variables: {
                        value: {
                            quantityIndex,
                            basketActivityId: basketActivity.id,
                            basketId,
                            customerMembershipId: event.target.value,
                            customerId,
                            shouldRemoveCustomerMembership:
                                event.target.value === "" ? true : false
                        }
                    }
                });
            }}
            value={defaultValue}
        >
            {applicableCustomerMemberships
                ? [
                      applicableCustomerMemberships.length > 0 ? (
                          <option key={0} value="">
                              Please select a membership
                          </option>
                      ) : !selectedCustomerMembership ? (
                          <option key={0} value="">
                              No applicable memberships
                          </option>
                      ) : null
                  ].concat(
                      applicableCustomerMemberships
                          .map((applicableCustomerMembership) => (
                              <option
                                  key={applicableCustomerMembership.id}
                                  value={applicableCustomerMembership.id}
                              >
                                  {optionallyAddDetailsToName(
                                      applicableCustomerMembership.name,
                                      applicableCustomerMembership.expiresAt,
                                      applicableCustomerMembership.customer &&
                                          applicableCustomerMembership.customer
                                              .id !== customerId
                                          ? applicableCustomerMembership
                                                .customer.name
                                          : ""
                                  )}
                              </option>
                          ))
                          .concat(
                              [
                                  !isCustomerMembershipAmongApplicable &&
                                  selectedCustomerMembership ? (
                                      <option
                                          key={selectedCustomerMembershipId}
                                          value={selectedCustomerMembershipId}
                                      >
                                          {optionallyAddDetailsToName(
                                              selectedCustomerMembership.name,
                                              selectedCustomerMembership.expiresAt,
                                              selectedCustomerMembership.customer &&
                                                  selectedCustomerMembership
                                                      .customer.id !==
                                                      customerId
                                                  ? selectedCustomerMembership
                                                        .customer.name
                                                  : ""
                                          )}
                                      </option>
                                  ) : null,
                                  selectedCustomerMembershipId ? (
                                      <option key={1} value="">
                                          Remove membership
                                      </option>
                                  ) : null
                              ].filter(exists)
                          )
                  )
                : null}
        </select>
    );
};

const optionallyAddDetailsToName = (
    customerMembershipName: string,
    expiresAt: string | Date,
    sharedByCustomerName: string
): string => {
    let optionalAddition = `${customerMembershipName} ${
        moment
            .utc()
            .isBetween(
                moment.utc(expiresAt).subtract(5, "days"),
                moment.utc(expiresAt)
            )
            ? "(expiring soon)"
            : ""
    }`;

    optionalAddition = `${optionalAddition} ${
        sharedByCustomerName ? ` // linked from ${sharedByCustomerName}` : ""
    }`;
    return optionalAddition;
};

export default MembershipPicker;
