import { gql } from "@apollo/client";

const COMPLETE_TRANSACTION_FROM_BASKET = gql`
    mutation completeTransactionFromBasket(
        $value: CompleteTransactionFromBasketInput
    ) {
        completeTransactionFromBasket(input: $value) {
            isSuccessful
            transactionId
            errorMessage
            bookings {
                id
                activityStartsAt
                activityInstance {
                    activity {
                        name
                        description
                    }
                }
                bookingExtras {
                    name
                    description
                }
                reference
                transactions {
                    id
                    isComplete
                    providerIdentifier
                    completedAt
                }
            }
            customerMemberships {
                id
                name
                completedAt
                expiresAt
            }
        }
    }
`;

export default COMPLETE_TRANSACTION_FROM_BASKET;
