import React from "react";
import { Redirect } from "react-router-dom";
import { useAppContext } from "../../Context/AppContext";
import { useAuthenticated } from "../../Context/AuthContext";

const Logout = () => {
    const { setToken, entryRoute } = useAppContext();
    const { setIsAuthenticated } = useAuthenticated();

    setIsAuthenticated(false);
    setToken(null);

    return <Redirect to={entryRoute} />;
};

export default Logout;
