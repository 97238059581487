import { useQuery } from "@apollo/client";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CHECK_IN_INFO from "../../data/CheckInInfo";
import { ICheckInInfo } from "../../generated/dataInterfaces";
import SelfCheckInButton from "../SelfCheckInButton";
import Html from "../Util/Html";
import NotificationBar from "../Util/NotificationBar";
import PageHeader from "../Util/PageHeader";

const CheckIn = () => {
    const { bookingReference }: { bookingReference?: string } = useParams();
    const [isCheckedIn, setIsCheckedIn] = useState(false);

    const { data, loading } = useQuery<{ checkInInfo: ICheckInInfo }>(
        CHECK_IN_INFO,
        {
            variables: {
                bookingReference
            },
            skip: !bookingReference
        }
    );

    useEffect(() => {
        if (data?.checkInInfo?.isAlreadyCheckedIn && !isCheckedIn) {
            setIsCheckedIn(true);
        }
    }, [data, isCheckedIn, loading]);

    if (!data || loading) {
        return null;
    }

    const { checkInInfo } = data;
    const {
        isCheckInAvailable,
        bookingReference: confirmedBookingReference,
        postCheckInInstruction,
        preCheckInInstruction,
        activityStartsAt,
        activityName,
        resourceName
    } = checkInInfo;

    if (!confirmedBookingReference) {
        return null;
    }

    return (
        <div className="fw">
            <PageHeader title="Self check-in" />
            <div className="pw">
                <header>
                    <h2>Booking reference: {confirmedBookingReference}</h2>
                </header>

                {!isCheckInAvailable && !isCheckedIn ? (
                    <p className="lead mb-3">Check-in not available</p>
                ) : null}

                {isCheckInAvailable && !isCheckedIn && preCheckInInstruction ? (
                    <>
                        <p className="lead mb-3">Check-in Instructions</p>

                        <div className="content fw mb-5">
                            <Html content={preCheckInInstruction} />
                        </div>

                        <SelfCheckInButton
                            bookingReference={confirmedBookingReference}
                            setIsCheckedIn={setIsCheckedIn}
                        />
                    </>
                ) : null}

                {isCheckInAvailable && isCheckedIn ? (
                    <>
                        <div
                            className="content fw"
                            data-testid="checked-in-text"
                        >
                            <p className="lead mb-3">
                                <strong>You are now checked in!</strong>
                            </p>
                        </div>

                        <div className="m-activity-box half-container a-box ">
                            <div>
                                <header>
                                    <p className="lead">
                                        {activityName} - {resourceName}
                                    </p>
                                </header>
                                <div className="content">
                                    <label>
                                        <span>When: </span>
                                        {moment(activityStartsAt)
                                            .utc()
                                            .format("h:mm a")}
                                    </label>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        {postCheckInInstruction ? (
                            <>
                                <header>
                                    <p className="lead">
                                        What should I do now?
                                    </p>
                                </header>

                                <div className="content fw">
                                    <Html content={postCheckInInstruction} />
                                </div>
                            </>
                        ) : null}
                    </>
                ) : null}

                <NotificationBar duration={7500} />
            </div>
        </div>
    );
};

export default CheckIn;
