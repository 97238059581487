import React from "react";
import {
    ICustomerMembership,
    IMembership
} from "../../generated/dataInterfaces";
import { useAppContext } from "../Context/AppContext";
import useBasket from "../Context/BasketContext/useBasket";
import Html from "../Util/Html";

const Membership = ({ membership, customerMembership }: IMembershipProp) => {
    const { organisationId } = useAppContext();
    const { addItemToBasket, basketId, checkIsCustomerMembershipInBasket } =
        useBasket();

    if (!organisationId) {
        return null;
    }

    return membership ? (
        <div className="m-membership fw">
            <div className="a-box v-box-secondary p-2 fw">
                <header className="">
                    <h4>Renew: {customerMembership.name}</h4>
                </header>

                <div className="m-activity-ticket-selector fw renewal">
                    <div className="fw">
                        <div className="content">
                            <div className="left">
                                <Html content={membership?.description ?? ""} />
                            </div>
                            <div className="right">
                                <span className="lead">
                                    £{membership.price}
                                </span>
                            </div>
                        </div>
                        <footer className="v-box-secondary">
                            <button
                                disabled={checkIsCustomerMembershipInBasket(
                                    customerMembership.id
                                )}
                                className="m-btn pull-right"
                                onClick={async () => {
                                    await addItemToBasket({
                                        variables: {
                                            value: {
                                                organisationId,
                                                basketId,
                                                membership: {
                                                    membershipId: membership.id,
                                                    quantity: 1,
                                                    customerMembershipId:
                                                        customerMembership.id,
                                                    isRenewal: true
                                                }
                                            }
                                        }
                                    });
                                }}
                            >
                                Add to Basket
                            </button>
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

interface IMembershipProp {
    membership: IMembership;
    customerMembership: ICustomerMembership;
}

export default Membership;
