import { gql } from "@apollo/client";

const GET_TRANSACTION = gql`
    query getTransaction($transactionId: ID!) {
        getTransaction(id: $transactionId) {
            id
            amount
            bookings {
                activityInstance {
                    activity {
                        id
                        name
                        checkoutCompleteText
                        defaultSlotLengthMinutes
                        resource {
                            name
                        }
                    }
                }
                activityPrice
                activityQuantity
                activityStartsAt
                reference
                packageReference
                bookingNames
                bookingExtras {
                    extraId
                    pricePerItem
                    discountPerItem
                    name
                    quantity
                    description
                }
                selfCheckInOpensAt
                selfCheckInClosesAt
            }
            memberships {
                id
                name
                expiresAt
                completedAt
                hasBeenActivated
                canPhotoBeChanged
                membership {
                    id
                    name
                }
                amountPaid
            }
        }
    }
`;

export default GET_TRANSACTION;
