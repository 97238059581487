import moment from "moment";
import React from "react";
import { ICustomerMembershipSharing } from "../../../../../generated/dataInterfaces";
import useCustomerMemberships from "../../../../Context/CustomerMembershipsContext";
import { useNotification } from "../../../../Context/NotificationContext";
import Button, {
    BUTTON_COLOR,
    BUTTON_ICONS,
    BUTTON_POSITION,
    BUTTON_SIZE
} from "../../../../Util/Button";
import { NOTIFICATION_BAR_MESSAGE } from "../../../../Util/NotificationBar";

const SharedWith = ({ sharings, onRemove }: ISharedWithProps) => {
    const {
        setNotificationColor,
        setNotificationMessage,
        setNotificationSubMessage,
        setShowNotification
    } = useNotification();
    const { removeMembershipShareMutation } = useCustomerMemberships();

    const removeShareClick = async (id: string) => {
        // remove it;
        if (
            confirm(
                "Are you sure? The other member will no longer be able to use this membership, unless you decide to share it with them again"
            )
        ) {
            const result = await removeMembershipShareMutation({
                variables: {
                    customerMembershipShareId: id
                }
            });

            if (result) {
                setNotificationColor(NOTIFICATION_BAR_MESSAGE.SUCCESS);
                setNotificationMessage("Membership link removed.");
                setNotificationSubMessage(
                    "The other person will no longer be able to use this membership"
                );
                console.log("remove id", id);
                onRemove();
            } else {
                setNotificationColor(NOTIFICATION_BAR_MESSAGE.FAILURE);
                setNotificationMessage("Your membership link was not removed.");
            }

            setShowNotification(true);
        }
    };

    return sharings ? (
        <div className="mt-2 mb-2 fw">
            {sharings.map((sharing) => {
                return (
                    <div key={sharing.id} className="m-activity-booking fw">
                        <header
                            className={`a-box ${
                                sharing.isConfirmed
                                    ? "bg-color-greyscale-1"
                                    : "bg-color-greyscale-2 color-ui-warning"
                            }`}
                        >
                            <h4>
                                {sharing.sharedWithCustomer.name}
                                <Button
                                    icon={BUTTON_ICONS.DELETE}
                                    size={BUTTON_SIZE.SMALL}
                                    color={BUTTON_COLOR.WARNING}
                                    position={BUTTON_POSITION.RIGHT}
                                    handleClick={() =>
                                        removeShareClick(sharing.id)
                                    }
                                    title="Remove"
                                />
                            </h4>
                            <label
                                className={`${
                                    sharing.isConfirmed
                                        ? "color-ui-success"
                                        : "color-ui-warning"
                                }`}
                            >
                                {sharing.isConfirmed
                                    ? `Confirmed on ${moment(
                                          sharing.confirmedAt
                                      ).format("dddd, MMMM Do YYYY")}`
                                    : "Not yet confirmed"}{" "}
                                <span style={{ display: "none" }}>
                                    {" "}
                                    {sharing.id}
                                </span>
                            </label>
                            <div className="clearfix"></div>
                        </header>
                    </div>
                );
            })}
        </div>
    ) : null;
};

export default SharedWith;

interface ISharedWithProps {
    sharings: ICustomerMembershipSharing[];
    onRemove: () => void;
}
