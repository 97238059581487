import moment from "moment";
import React from "react";
import { IBasketActivity } from "../../generated/dataInterfaces";
import MembershipPicker from "./MembershipPicker";

interface IActivityMembershipPickerProps {
    basketActivity: IBasketActivity | null;
}

const Activity = ({ basketActivity }: IActivityMembershipPickerProps) => {
    if (!basketActivity) {
        return null;
    }
    return (
        <div className="m-apply-membership a-box v-box-secondary section fw">
            <h3>
                {basketActivity.quantity} x {basketActivity.description}
            </h3>
            <h4 className="v-light">
                {
                    // ❗UTC needed for activityStartsAt
                    moment(basketActivity.startsAt)
                        .utc()
                        .format("dddd, MMMM Do YYYY, HH:mm")
                }
            </h4>
            {Array.from(Array(basketActivity.quantity).keys()).map(
                (quantityIndex) => (
                    <div key={`${basketActivity.id}${quantityIndex}`}>
                        <label>{`Ticket ${quantityIndex + 1}`}</label>
                        <MembershipPicker
                            key={quantityIndex}
                            quantityIndex={quantityIndex}
                            basketActivity={basketActivity}
                        />
                    </div>
                )
            )}
        </div>
    );
};
export default Activity;
