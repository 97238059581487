import { useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";
import { RESOURCES } from "../../data/Activity/ActivityList";
import { IMembershipGroup, IResource } from "../../generated/dataInterfaces";
import ResourceList from "../ActivityBooking/ResourceList";
//// import StepTracking from "../Analytics/StepTracking";
import Basket from "../Basket/Basket";
import { useAppContext } from "../Context/AppContext";
import { IMembership } from "../MembershipPurchase/interfaces";
import MembershipLink from "../MembershipPurchase/MembershipLink";
import BreadCrumbs from "../Util/Breadcrumbs";
import NotificationBar from "../Util/NotificationBar";
import PageBillboard from "../Util/PageBillboard";
import PageHeader from "../Util/PageHeader";

const ActivitiesSelection = () => {
    const { setPageTitle, organisationId, setEntryRoute, setEntryLabel } =
        useAppContext();
    const {
        resourceStub,
        resourceGroupStub,
        facilityStub,
        membershipId
    }: {
        resourceStub?: string;
        resourceGroupStub?: string;
        facilityStub?: string;
        membershipId?: string;
    } = useParams();

    setEntryLabel("Choose your options");

    if (resourceStub) {
        setEntryRoute(`/resource/${resourceStub}`);
    } else if (facilityStub) {
        setEntryRoute(`/facility/${facilityStub}`);
    } else if (resourceGroupStub) {
        setEntryRoute(`/group/${resourceGroupStub}`);
    } else if (membershipId) {
        setEntryRoute(`/membership-activities/${membershipId}`);
    } else {
        setEntryRoute(`/`);
    }

    const { data } = useQuery<{
        resourcesByOrg: IResource[];
        membershipGroupByResourceGroupStub: IMembershipGroup;
        membershipsByOrg: IMembership[];
    }>(RESOURCES, {
        variables: {
            orgId: organisationId,
            resourceStub,
            facilityStub,
            resourceGroupStub,
            membershipId
        },
        skip: !organisationId
    });

    const activitiesByResource = data ? data.resourcesByOrg : null;
    const membershipGroup = data
        ? data.membershipGroupByResourceGroupStub
        : null;
    const memberships = data ? data.membershipsByOrg : null;

    setPageTitle("Book now");

    return (
        <>
            <PageHeader />
            <PageBillboard />
            <div className="fw">
                <div className="pw">
                    <BreadCrumbs step={0} />
                    <div className="clearfix" />
                    <div className="content fw">
                        <div className="two-thirds-container pt-3">
                            <ResourceList
                                resources={activitiesByResource ?? []}
                            />
                            {memberships && memberships.length > 0 ? (
                                <MembershipLink
                                    membershipGroup={membershipGroup}
                                />
                            ) : null}
                        </div>
                        <aside
                            id="basket-scroll"
                            className="sidebar third-container right"
                        >
                            <Basket
                                isCheckout={false}
                                shouldShowMembershipWarning={true}
                            />
                        </aside>
                    </div>
                    <footer />
                </div>
            </div>
            <NotificationBar duration={7500} />
            {/* <StepTracking stepNumber="1" stepName="Choose your options" /> */}
        </>
    );
};

export default ActivitiesSelection;
