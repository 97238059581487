import { gql } from "@apollo/client";

const UPDATE_MEMBERSHIP = gql`
    mutation updateMembership($value: UpdateMembershipInput!) {
        updateMembership(data: $value) {
            id
            name
            canPhotoBeChanged
            photo {
                base64
            }
        }
    }
`;

export default UPDATE_MEMBERSHIP;
