import decodeJwt from "jwt-decode";
import { useAppContext } from "../Context/AppContext";

const isLoggedIn = (): boolean => {
    const { token } = useAppContext();
    let notLoggedIn = false;
    if (token) {
        const decodedToken: {
            credentialId: string;
            organisationId: string;
            customerId: string;
        } = decodeJwt(token);

        if (
            decodedToken &&
            decodedToken.credentialId &&
            decodedToken.organisationId &&
            decodedToken.customerId
        ) {
        } else {
            notLoggedIn = true;
        }
    } else {
        notLoggedIn = true;
    }

    return !notLoggedIn;
};

export default isLoggedIn;
