import { gql } from "@apollo/client";

const BRAINTREE_CLIENT_TOKEN_QUERY = gql`
    query braintreeClientToken {
        braintreeClientToken {
            token
        }
    }
`;

export default BRAINTREE_CLIENT_TOKEN_QUERY;
