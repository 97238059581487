import * as React from "react";
import { render } from "react-dom";
import "normalize.css";
import App from "./components/App";
import { AppContextProvider } from "./components/Context/AppContext";
import { GoogleAnalyticsProvider } from "react-ga4-gtag";

render(
    <AppContextProvider>
        <GoogleAnalyticsProvider measurementId="G-L1MDMH6JRF">
            <App />
        </GoogleAnalyticsProvider>
    </AppContextProvider>,
    document.getElementById("root") as HTMLElement
);
