import React from "react";

const AuthenticatedUser = () => {
    return (
        <>
            <p>Already authenticated</p>
        </>
    );
};

export default AuthenticatedUser;
