import { gql } from "@apollo/client";
import queryFields from "./queryFields";

const ADD_TO_BASKET = gql`
    mutation addToBasket($value: AddToBasketInput!) {
        addToBasket(input: $value) {
            ${queryFields}
        }
    }
`;

export default ADD_TO_BASKET;
