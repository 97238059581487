import moment from "moment";
import React from "react";
import { IBooking } from "../../generated/dataInterfaces";
import Html from "../Util/Html";
import CheckoutCompleteTracking from "../Analytics/CheckoutCompleteTracking";

interface IPropsBookingConfirmation {
    data: IBooking;
}

const BookingConfirmation = ({ data }: IPropsBookingConfirmation) => {
    // ❗UTC needed for activityStartsAt
    const dateNice: moment.Moment = moment(data.activityStartsAt).utc();
    return (
        <div className="m-booking-confirmation a-box third-container">
            <h3 className="color-brand">
                {data.activityQuantity} x {data.activityInstance.activity.name}
                <br />({
                    data.activityInstance.activity.defaultSlotLengthMinutes
                }{" "}
                minutes)
            </h3>

            <p className="lead">
                <strong>
                    {dateNice.format("dddd, MMMM Do YYYY")} at{" "}
                    {dateNice.format("h.mm a")}
                </strong>
            </p>
            {data?.bookingExtras?.map((extra, i) => {
                return (
                    <p key={i}>
                        {extra.quantity} x {extra.description}
                    </p>
                );
            })}

            {data.bookingNames && data.bookingNames.length > 0 ? (
                <p className="booking-ref">
                    Name(s)/Details:{" "}
                    <span data-testid="booking-names">
                        {data.bookingNames.join(", ")}
                    </span>
                </p>
            ) : null}

            <p className="booking-ref">
                Booking reference: <span>{data.reference}</span>
            </p>

            <div className="booking-activity-desc">
                <Html
                    content={
                        data?.activityInstance?.activity
                            ?.checkoutCompleteText ?? ""
                    }
                />
            </div>
            <div className="clearfix" />
        </div>
    );
};
export default BookingConfirmation;
