import React, { createContext, useMemo, useState, useContext } from "react";

const FormContext = createContext<IFormContext | null>(null);

function FormContextProvider({ children }: { children: React.ReactNode }) {
    const [check, setCheck] = useState(false);
    const [ready, setReady] = useState(false);

    const values = useMemo(() => {
        return {
            check,
            setCheck,
            ready,
            setReady
        };
    }, [ready, check]);

    return (
        <FormContext.Provider value={values}>{children}</FormContext.Provider>
    );
}

interface IFormContext {
    check: boolean;
    setCheck: (value: boolean) => void;
    ready: boolean;
    setReady: (value: boolean) => void;
}

function useForm() {
    const context = useContext(FormContext);
    if (!context) {
        throw new Error("useForm must be used within a FormContextProvider");
    }
    const { check, setCheck, ready, setReady } = context;
    return { check, setCheck, ready, setReady };
}

export { FormContextProvider, useForm };
