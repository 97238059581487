import React, { useRef } from "react";
import { Link, Redirect } from "react-router-dom";
//import StepTracking from "../../Analytics/StepTracking";
import Basket from "../../Basket/Basket";
import { useAppContext, useAppRedirect } from "../../Context/AppContext";
import { useAuthenticated, useLogin } from "../../Context/AuthContext";
import useBasket from "../../Context/BasketContext/useBasket";
import { useNotification } from "../../Context/NotificationContext";
import BreadCrumbs from "../../Util/Breadcrumbs";
import NotificationBar, {
    NOTIFICATION_BAR_MESSAGE
} from "../../Util/NotificationBar";
import PageHeader from "../../Util/PageHeader";

const LoginPage = () => {
    const {
        setShowNotification,
        setNotificationColor,
        setNotificationMessage,
        setNotificationSubMessage
    } = useNotification();
    const emailInput = useRef<HTMLInputElement | null>(null);
    const passwordInput = useRef<HTMLInputElement | null>(null);
    const { redirectTo } = useAppRedirect();
    const { loginCustomer } = useLogin();
    const { isAuthenticated } = useAuthenticated();
    const { basketData } = useBasket();
    const { entryRoute } = useAppContext();

    const handleKeyPress = async (
        event: React.KeyboardEvent<HTMLInputElement>
    ): Promise<void> => {
        if (event.key === "Enter") {
            // eslint-disable-next-line @typescript-eslint/unbound-method
            await handleLoginClick({ preventDefault: event.preventDefault });
        }
    };

    const handleLoginClick = async (
        event: Pick<React.MouseEvent<HTMLInputElement>, "preventDefault">
    ) => {
        event.preventDefault();
        if (emailInput?.current?.value && passwordInput?.current?.value) {
            // reset the warnings
            emailInput.current.classList.remove("v-input-warning");
            passwordInput.current.classList.remove("v-input-warning");
            const isLoggedIn = await loginCustomer(
                emailInput.current.value,
                passwordInput.current.value
            );

            if (!isLoggedIn) {
                setNotificationColor(NOTIFICATION_BAR_MESSAGE.FAILURE);
                setNotificationMessage(
                    "Log in was not successful, please try again"
                );
                setNotificationSubMessage("");
                setShowNotification(true);
            }
        } else {
            // no value in email input
            if (!emailInput?.current?.value) {
                emailInput?.current?.classList?.add("v-input-warning");
            }
            // no value in password input
            if (!passwordInput?.current?.value) {
                passwordInput?.current?.classList?.add("v-input-warning");
            }

            setNotificationColor(NOTIFICATION_BAR_MESSAGE.FAILURE);
            setNotificationMessage(
                "Please fill in both email address and password"
            );
            setNotificationSubMessage("");
            setShowNotification(true);
        }
    };

    const basketHasItems =
        basketData && basketData.items && basketData.items.length > 0;

    console.log("redirectTo on login", redirectTo);

    return (
        <>
            {isAuthenticated ? (
                <Redirect
                    to={
                        !redirectTo ||
                        (redirectTo && redirectTo === "/check-out")
                            ? entryRoute
                            : redirectTo
                    }
                />
            ) : (
                <div className="fw">
                    <PageHeader title="Login" />
                    <div className="pw">
                        <BreadCrumbs step={basketHasItems ? 1 : -1} />
                        <div className="clearfix" />
                        <div className="content fw">
                            <div className="two-thirds-container">
                                <form className="m-login a-box v-box-secondary">
                                    <header>
                                        <h3>Log in</h3>
                                    </header>
                                    <div className="clearfix" />
                                    <div className="login-form">
                                        <div className="fields">
                                            <div className="m-form-field">
                                                <label>
                                                    <strong>
                                                        Email Address
                                                    </strong>
                                                </label>
                                                <input
                                                    ref={emailInput}
                                                    type="text"
                                                    placeholder="Please enter your email address"
                                                />
                                                <div className="clearfix" />
                                            </div>
                                            <div className="m-form-field">
                                                <label>
                                                    <strong>Password</strong>
                                                </label>
                                                <input
                                                    placeholder="Please enter your password"
                                                    ref={passwordInput}
                                                    type="password"
                                                    onKeyPress={handleKeyPress}
                                                />
                                                <div className="clearfix" />
                                            </div>
                                        </div>
                                        <Link to="/request-password-reset">
                                            <label className="color-text-tertiary">
                                                Forgotten your password?
                                            </label>
                                        </Link>
                                        <div className="clearfix" />
                                    </div>

                                    <div className="continue fw">
                                        <div className="m-auto clearfix">
                                            <button
                                                type="submit"
                                                className="m-btn v-bg-secondary"
                                                onClick={handleLoginClick}
                                            >
                                                Log in &amp; Continue
                                            </button>
                                            <div className="clearfix" />
                                            <label>
                                                Don&apos;t have an account?{" "}
                                                <Link
                                                    to="/register"
                                                    className="color-text-tertiary"
                                                >
                                                    Sign up here
                                                </Link>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="clearfix" />
                                </form>
                            </div>
                            <aside
                                id="basket-scroll"
                                className="sidebar third-container right"
                            >
                                <Basket
                                    isCheckout={true}
                                    shouldShowMembershipWarning={false}
                                />
                            </aside>
                        </div>
                    </div>
                    <NotificationBar duration={7500} />
                    {/* <StepTracking stepNumber="2" stepName="Login" /> */}
                </div>
            )}
        </>
    );
};

export default LoginPage;
