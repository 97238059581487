import React from "react";
import { IBasketItem } from "../../generated/dataInterfaces";
import Activity from "./Activity";

interface IActivitiesProps {
    basketItems: IBasketItem[];
}

const Activities = ({ basketItems }: IActivitiesProps) => {
    return basketItems ? (
        <>
            {basketItems.map((basketItem) => (
                <Activity
                    key={basketItem.id}
                    basketActivity={basketItem?.basketActivity ?? null}
                />
            ))}
        </>
    ) : null;
};
export default Activities;
