import { gql } from "@apollo/client";
import queryFields from "./../Basket/queryFields";

const APPLY_COUPON = gql`
    mutation applyCoupon($value: ApplyCouponInput!) {
        applyCoupon(input: $value) {
            ${queryFields}
        }
    }
`;

export default APPLY_COUPON;
