import { useMutation, useQuery } from "@apollo/client";
import DropIn from "braintree-web-drop-in-react";
import React, { useEffect, useState } from "react";
import { Redirect, Link } from "react-router-dom";
import BRAINTREE_CLIENT_TOKEN_QUERY from "../../../data/Braintree/BraintreeClientToken";
import COMPLETE_TRANSACTION_FROM_BASKET from "../../../data/Braintree/CompleteTransactionFromBasket";
import {
    IBraintreeClientToken,
    ICompleteTransactionFromBasketResponse
} from "../../../generated/dataInterfaces";
import useBasket from "../../Context/BasketContext/useBasket";
import { useNotification } from "../../Context/NotificationContext";
import { useTransaction } from "../../Context/TransactionContext";
import CheckBox from "../../Util/CheckBox";
import { NOTIFICATION_BAR_MESSAGE } from "../../Util/NotificationBar";
import { Dropin } from "braintree-web-drop-in";

const Payment = () => {
    const { basketId, basketData } = useBasket();
    const {
        setNotificationColor,
        setNotificationMessage,
        setNotificationSubMessage,
        setShowNotification
    } = useNotification();
    const { transactionData, setTransactionData } = useTransaction();
    const { basketItemIdBookingNamesPairs } = useBasket();
    const [braintreeInstance, setBraintreeInstance] =
        useState<Dropin | null>(null);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isPaymentInProgress, setIsPaymentInProgress] = useState(false);
    const [isTermsChecked, setIsTermsChecked] = useState(false);

    const isZeroPayment = basketData?.total === 0 ? true : false;

    useEffect(() => {
        if (isTermsChecked && (braintreeInstance || isZeroPayment)) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [isTermsChecked, braintreeInstance, isZeroPayment]);

    // braintree client token
    const { data } = useQuery<{
        braintreeClientToken: IBraintreeClientToken;
    }>(BRAINTREE_CLIENT_TOKEN_QUERY, {
        skip: isZeroPayment
    });

    const braintreeClientToken =
        data && data.braintreeClientToken
            ? data.braintreeClientToken.token
            : null;

    // complete booking
    const [createTransaction] = useMutation<{
        completeTransactionFromBasket: ICompleteTransactionFromBasketResponse;
    }>(COMPLETE_TRANSACTION_FROM_BASKET);

    const handlePayClick = async () => {
        setIsDisabled(true);
        setIsPaymentInProgress(true);
        // await braintreeInstance?.updateConfiguration(
        //     "applePay",
        //     "amount",
        //     total.toFixed(2)
        // );
        let braintreeNonce: string | null = null;
        if (!isZeroPayment) {
            // REQUESTPAYMENTMETHOD
            const { nonce } = (await braintreeInstance?.requestPaymentMethod({
                threeDSecure: {
                    amount: (basketData?.total ?? 0).toFixed(2),
                    collectDeviceData: true
                    // challengeRequested: true
                }
            })) ?? {
                nonce: null
            };
            braintreeNonce = nonce;
        }
        const { data, errors } = await createTransaction({
            variables: {
                value: {
                    basketId,
                    braintreeNonce,
                    basketItemIdBookingNamesPairsInput:
                        basketItemIdBookingNamesPairs
                }
            }
        });

        // transaction should be complete
        if (data && data.completeTransactionFromBasket) {
            // extract the transactionId from the mutation
            const response = data.completeTransactionFromBasket;

            if (response.isSuccessful) {
                setTransactionData(response);
            } else {
                setNotificationColor(NOTIFICATION_BAR_MESSAGE.WARNING);
                setNotificationMessage(
                    "There was an issue with your payment. Please try again"
                );

                setNotificationSubMessage(response.errorMessage ?? "");
                setShowNotification(true);
                setIsDisabled(false);
                setIsPaymentInProgress(false);
            }
        }

        if (errors && errors.length > 0) {
            // transaction failed
            const errorMessage = errors[0].message;
            setNotificationColor(NOTIFICATION_BAR_MESSAGE.WARNING);
            setNotificationMessage(
                "There was an issue with your payment. Please try again"
            );
            setNotificationSubMessage(errorMessage);
            setShowNotification(true);
            setIsDisabled(false);
            setIsPaymentInProgress(false);
        }
    };

    // redirect if successful
    if (
        transactionData &&
        transactionData.isSuccessful &&
        transactionData.transactionId
    ) {
        return (
            <Redirect
                to={`/check-out/success/${transactionData.transactionId}`}
            />
        );
    }

    // show loader if payment required and there's a payment to be had
    if (!braintreeClientToken && !isZeroPayment) {
        return <div className="m-loading">Preparing for checkout...</div>;
    }

    return (
        <>
            {isZeroPayment ? null : (
                <div className="mt-5">
                    <p>
                        We’ve made some changes for Strong Customer
                        Authentication, so you may be required to complete
                        3D-Secure.
                    </p>
                    <p>
                        If you're paying by card, please ensure your chosen card
                        has a green tick alongside. If not, please click or tap
                        on the card below before you press "Complete Payment"
                    </p>

                    <div className="clearfix" />
                </div>
            )}

            {isZeroPayment ? null : (
                <div className="m-payment-dropin test">
                    <DropIn
                        preselectVaultedPaymentMethod={false}
                        options={{
                            threeDSecure: true,
                            dataCollector: true,
                            authorization: braintreeClientToken as string,
                            translations: {
                                payingWith: "Paying with...",
                                chooseAnotherWayToPay: "Change payment method",
                                chooseAWayToPay: "Select payment method"
                            },
                            preselectVaultedPaymentMethod: true,

                            vaultManager: true,
                            card: {
                                cardholderName: {
                                    required: true
                                },
                                vault: {
                                    allowVaultCardOverride: true
                                }
                            },
                            paymentOptionPriority: [
                                "applePay",
                                "card",
                                "googlePay"
                            ],
                            applePay: {
                                displayName: "Pay with ApplePay",
                                paymentRequest: {
                                    total: {
                                        label: "Les Ormes",
                                        amount: (
                                            basketData?.total ?? 0
                                        ).toFixed(2)
                                    },
                                    // We recommend collecting billing address information, at minimum
                                    // billing postal code, and passing that billing postal code with all
                                    // Apple Pay transactions as a best practice.
                                    requiredBillingContactFields: [
                                        "postalAddress"
                                    ],
                                    countryCode: "GB",
                                    currencyCode: "GBP",
                                    supportedNetworks: [
                                        "visa",
                                        "masterCard",
                                        "amex",
                                        "jcb"
                                    ],
                                    merchantCapabilities: [
                                        "supports3DS",
                                        "supportsCredit"
                                    ]
                                }
                            },
                            googlePay: {
                                googlePayVersion: "2",
                                merchantId: "709268366",
                                transactionInfo: {
                                    totalPriceStatus: "FINAL",
                                    totalPrice: (
                                        basketData?.total ?? 0
                                    ).toFixed(2),
                                    currencyCode: "GBP"
                                }
                            }
                        }}
                        onInstance={(instance) => {
                            setBraintreeInstance(instance);
                        }}
                    />
                </div>
            )}

            <div className="mt-5">
                <CheckBox
                    checked={[isTermsChecked, setIsTermsChecked]}
                    dataTestId="terms-checkbox"
                    link={{
                        preLinkString: "I have read and accept the ",
                        label: "terms and conditions",
                        to: "https://www.lesormesjersey.co.uk/activities/terms/"
                    }}
                />
            </div>
            <div className="m-auto clearfix mt-5">
                <button
                    onClick={handlePayClick}
                    className="m-btn fw"
                    disabled={isDisabled}
                    data-testid="pay-button"
                    title={
                        isDisabled
                            ? "Please accept the terms and conditions"
                            : "Click or tap to pay now"
                    }
                >
                    {isZeroPayment
                        ? isPaymentInProgress
                            ? `Completing booking`
                            : `Complete booking`
                        : isPaymentInProgress
                        ? `Payment in progress`
                        : `Pay Total: £${(basketData?.total ?? 0).toFixed(2)}`}
                </button>
            </div>
        </>
    );
};

export default Payment;
