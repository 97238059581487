import { gql } from "@apollo/client";

const REGISTER_CUSTOMER_MUTATION = gql`
    mutation createCustomerWithCredential(
        $value: CreateCustomerWithCredentialInput!
    ) {
        createCustomerWithCredential(input: $value) {
            name
            isDisabled
            postcode
            credentials {
                id
                login
                firstName
                lastName
                password {
                    hash
                }
            }
        }
    }
`;

export default REGISTER_CUSTOMER_MUTATION;
