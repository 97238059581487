import { useQuery } from "@apollo/client";
import DropIn from "braintree-web-drop-in-react";
import React, { useState } from "react";
import BRAINTREE_CLIENT_TOKEN_QUERY from "../../data/Braintree/BraintreeClientToken";

const ManageCards = ({ customerId }: IManageCardsProps) => {
    const [braintreeClientToken, setBraintreeClientToken] =
        useState<string | null>(null);
    const [noPaymentMethods, setNoPaymentMethods] = useState(false);

    const { data } = useQuery(BRAINTREE_CLIENT_TOKEN_QUERY, {
        variables: {
            customerId
        },
        skip: !!customerId && !!braintreeClientToken
    });

    if (data && data["braintreeClientToken"] && !braintreeClientToken) {
        setBraintreeClientToken(data["braintreeClientToken"].token);
    }

    if (noPaymentMethods) {
        return (
            <div className="m-card-manager">
                <h5>
                    You have no saved payment cards available. You can choose to
                    save payment cards on check out.
                </h5>
            </div>
        );
    }

    return braintreeClientToken ? (
        <div className="m-card-manager">
            <DropIn
                options={{
                    authorization: braintreeClientToken,
                    translations: {
                        edit: "Delete a payment card?",
                        editPaymentMethods: "Select a card to delete",
                        payingWith: "",
                        chooseAnotherWayToPay: "Add a new card",
                        chooseAWayToPay: "Enter card details"
                    },
                    vaultManager: true,
                    preselectVaultedPaymentMethod: false
                }}
                onInstance={(instance) => {
                    if (instance._model._paymentMethods.length === 0) {
                        setNoPaymentMethods(true);
                    }
                }}
            />
        </div>
    ) : (
        <h5>Fetching cards from Braintree</h5>
    );
};

export interface IManageCardsProps {
    customerId: string;
}

export default ManageCards;
