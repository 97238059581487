import { gql } from "@apollo/client";

const REQUEST_MEMBERSHIP_SHARE_MUTATION = gql`
    mutation requestMembershipShare(
        $customerMembershipId: ID!
        $emailAddress: String!
    ) {
        requestMembershipShare(
            input: {
                customerMembershipId: $customerMembershipId
                emailAddress: $emailAddress
            }
        ) {
            id
            membershipOwner {
                id
                name
            }
            sharedCustomerMembership {
                id
                name
                membership {
                    id
                    name
                }
            }
            sharedWithCustomer {
                id
                name
            }
            sharedToEmail
            isConfirmed
            confirmedAt
            createdAt
            updatedAt
        }
    }
`;

export default REQUEST_MEMBERSHIP_SHARE_MUTATION;
