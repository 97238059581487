import React, { useEffect, useRef } from "react";
import { useAccountContext } from "../Context/AccountContext";
import { useForm } from "../Context/FormContext";
import Button, {
    BUTTON_COLOR,
    BUTTON_ICONS,
    BUTTON_POSITION,
    BUTTON_SIZE
} from "../Util/Button";
import InputField, { InputFieldType } from "../Util/InputField";

const AddCredentialForm = () => {
    const { setCheck, ready, setReady } = useForm();
    const { setIsAddCredentialShowing, addNewCredentialToCustomer } =
        useAccountContext();
    const firstNameRef = useRef<HTMLInputElement | null>(null);
    const lastNameRef = useRef<HTMLInputElement | null>(null);
    const emailRef = useRef<HTMLInputElement | null>(null);

    const handleRegisterClick = () => {
        setCheck(true);
        setReady(false);
    };

    useEffect(() => {
        void (async () => {
            // check that the fields are all filled in
            if (ready) {
                await addNewCredentialToCustomer({
                    variables: {
                        newCredentialEmail: emailRef?.current?.value ?? "",
                        newCredentialFirstName:
                            firstNameRef?.current?.value ?? "",
                        newCredentialLastName: lastNameRef?.current?.value ?? ""
                    }
                });

                setIsAddCredentialShowing((x) => !x);
            }
        })();
    }, [ready, addNewCredentialToCustomer, setIsAddCredentialShowing]);

    return (
        <div className="main a-box v-box-secondary m-toggle-box">
            <header>
                <h4>New users details</h4>
            </header>
            <div className="section fw">
                <InputField
                    label="First Name"
                    placeholder="New user's first name"
                    type={InputFieldType.TEXT}
                    reference={firstNameRef}
                    required={true}
                />
                <InputField
                    label="Last Name"
                    placeholder="New user's last name"
                    type={InputFieldType.TEXT}
                    reference={lastNameRef}
                    required={true}
                />
                <InputField
                    label="Email"
                    placeholder="New user's email address"
                    type={InputFieldType.TEXT}
                    reference={emailRef}
                    required={true}
                />

                <footer>
                    <Button
                        title="Save and email"
                        color={BUTTON_COLOR.SUCCESS}
                        size={BUTTON_SIZE.THIRD}
                        position={BUTTON_POSITION.RIGHT}
                        handleClick={handleRegisterClick}
                        icon={BUTTON_ICONS.SUBMIT}
                    />
                </footer>
            </div>
            <div className="clearfix" />
        </div>
    );
};

export default AddCredentialForm;
