const membershipQuery = `applicableCustomerMemberships {
    id
    name
    expiresAt
    customer{
        id
        name
    }
}`;

const query = (membershipQuery?: string) => {
    return `
    id
    couponIds
    updatedAt
    expiresAt
    items {
        id
        basketActivity {
            id
            activityInstanceId
            startsAt
            description
            quantity
            pricePerItem
            discountPerItem
            activityInstance{
                activity {
                    ticketDescription
                    keyInformation
                    quantityPerBooking
                    bookingNameInfo
                }
            }
            ${membershipQuery}
            appliedCustomerMembershipIds
            areBookingNamesRequired
            bookingNameInfo
            membershipDiscount
        }
        basketExtras {
            id
            description
            pricePerItem
            discountPerItem
            quantity
        }
        basketMembership {
            id
            description
            quantity
            pricePerItem
            discountPerItem
            membershipId
            customerMembershipId
            isRenewal
        }
    }
    couponIds
    coupons {
       id
       name
       couponCode
    }
    total
    `;
};

const fullQuery = query(membershipQuery);
const partialQuery = query("");

export default fullQuery;
export { fullQuery, partialQuery };
