import React from "react";
import { useAppContext } from "../Context/AppContext";
import SiteHeader from "./SiteHeader";
import PageBreadCrumbs from "./PageBreadcrumbs";

const PageHeader = ({ title, isAccountPage }: IPageHeaderProps) => {
    const { pageTitle } = useAppContext();

    const pageHeaderTitle = title ? title : pageTitle;

    return (
        <>
            <SiteHeader isAccountPage={isAccountPage} />
            <div className="o-page-header fw">
                <div className="pw">
                    <PageBreadCrumbs step={title} />
                    <h1
                        className="color-brand two-thirds-container"
                        data-testid="page-title-element"
                    >
                        {pageHeaderTitle}
                    </h1>
                    {/* <div className="third-container account-login float-right">
                        <div
                            className={`float-right ${
                                isAccountPage ? "" : "half-container"
                            }`}
                        >
                            {isAccountPage ? (
                                <Button
                                    title="View all activities"
                                    icon={BUTTON_ICONS.ACTIVITIES}
                                    color={BUTTON_COLOR.SECONDARY}
                                    link="/"
                                />
                            ) : (
                                <Button
                                    title="My Account"
                                    icon={BUTTON_ICONS.ACCOUNT}
                                    color={BUTTON_COLOR.WARNING}
                                    link="/account/profile"
                                />
                            )}
                        </div>
                    </div> */}
                    <div className="clearfix" />
                </div>
            </div>
        </>
    );
};

export default PageHeader;

export interface IPageHeaderProps {
    title?: string;
    isAccountPage?: boolean;
}
