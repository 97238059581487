import { useQuery } from "@apollo/client";
import { exists } from "@les-ormes/lib";
import { produce } from "immer";
import { flatten, map } from "lodash";
import React, { useEffect, useState } from "react";
import GET_MEMBERSHIP_GROUPS from "../../data/Membership/MembershipGroupList";
import { IBasket, IBasketMembership } from "../../generated/dataInterfaces";
import { useAppContext } from "../Context/AppContext";
import useBasket from "../Context/BasketContext/useBasket";
import { IMembershipGroup } from "./interfaces";
import MembershipGroup from "./MembershipGroup";

interface IMembershipGroupListProps {
    membershipGroupStub?: string;
}

const MembershipGroupList = ({
    membershipGroupStub
}: IMembershipGroupListProps) => {
    const { organisationId } = useAppContext();
    const { basketData } = useBasket();

    const [membershipGroups, setMembershipGroups] = useState<
        IMembershipGroup[]
    >([]);

    const { loading, error, data } = useQuery<IMembershipGroupData>(
        GET_MEMBERSHIP_GROUPS,
        {
            variables: {
                orgId: organisationId,
                membershipGroupStub
            },
            skip: !organisationId || !membershipGroupStub
        }
    );

    useEffect(() => {
        if (basketData) {
            updateMembershipGroupsWithBasketData(
                basketData,
                membershipGroups,
                setMembershipGroups
            );
        }
    }, [basketData, membershipGroups]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        console.log(`Error! ${error.message}`);
        return null;
    }

    if (!membershipGroupStub) {
        return null;
    }

    if (data && data.membershipGroupsByOrg && membershipGroups.length === 0) {
        const membershipGroups = data.membershipGroupsByOrg;
        if (basketData) {
            updateMembershipGroupsWithBasketData(
                basketData,
                membershipGroups,
                setMembershipGroups
            );
        } else {
            setMembershipGroups(membershipGroups);
        }
    }

    return membershipGroups && membershipGroups.length > 0 ? (
        <>
            {membershipGroups.map((membershipGroup) => (
                <MembershipGroup
                    key={membershipGroup.id}
                    membershipGroup={membershipGroup}
                />
            ))}
        </>
    ) : (
        <p>Memberships coming soon</p>
    );
};

interface IMembershipGroupData {
    membershipGroupsByOrg: IMembershipGroup[];
}

function updateMembershipGroupsWithBasketData(
    basketData: IBasket,
    membershipGroups: IMembershipGroup[],
    setMembershipGroups: (
        value: React.SetStateAction<IMembershipGroup[]>
    ) => void
) {
    if (basketData.items) {
        const { items } = basketData;
        const basketMembershipItems = items
            .filter((item) => item.basketMembership)
            .map((item) => item?.basketMembership)
            .filter(exists);

        const hydratedMembershipGroups =
            hydrateMembershipGroupsWithMembershipQuantities(
                membershipGroups,
                basketMembershipItems
            );

        setMembershipGroups(hydratedMembershipGroups);
    }
}

function hydrateMembershipGroupsWithMembershipQuantities(
    membershipGroups: IMembershipGroup[],
    basketMemberships: IBasketMembership[]
): IMembershipGroup[] {
    if (basketMemberships.length === 0) {
        console.log("basket memberhips 0");
        return setMembershipQuantitiesToZero(membershipGroups);
    }
    return produce(membershipGroups, (membershipGroupsDraft) => {
        basketMemberships.forEach((basketMembership) => {
            const memberships = flatten(
                map(
                    membershipGroupsDraft,
                    (membershipGroup) => membershipGroup.memberships
                )
            );
            const matchingMembership = memberships.find(
                (membership) => membership?.id === basketMembership.membershipId
            );

            if (matchingMembership) {
                matchingMembership.quantity = basketMembership.quantity;
            }
        });
    });
}
function setMembershipQuantitiesToZero(
    membershipGroups: IMembershipGroup[]
): IMembershipGroup[] {
    return produce(membershipGroups, (membershipGroupsDraft) => {
        if (membershipGroupsDraft) {
            membershipGroupsDraft.forEach((membershipGroupDraft) => {
                membershipGroupDraft?.memberships?.forEach(
                    (membershipDraft) => {
                        membershipDraft.quantity = 0;
                    }
                );
            });
        }
        return membershipGroupsDraft;
    });
}

export default MembershipGroupList;
