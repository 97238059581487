import { useQuery } from "@apollo/client";
import React from "react";
import { GET_RESOURCE_ACTIVITIES } from "../../data/Activity/ActivityList";
import { IResource } from "../../generated/dataInterfaces";
import Html from "../Util/Html";
import ActivityBooking from "./ActivityBooking";
import { ActivityContextProvider } from "./ActivityContext";

const Resource = ({ resource }: IResourceProp) => {
    const { data } = useQuery<{ resource: IResource }>(
        GET_RESOURCE_ACTIVITIES,
        {
            variables: {
                resourceId: resource && resource.id
            },
            skip: !resource,
            fetchPolicy: "cache-and-network"
        }
    );

    if (!data || !resource) {
        return null;
    }

    console.log("resource.activities", resource.activities);

    const sortedActivities = resource.activities.slice();

    // Sort the activities by field ordering
    sortedActivities.sort((a, b) => a.ordering - b.ordering);

    console.log(sortedActivities);

    return (
        <div className="m-resource-list">
            {resource ? (
                <>
                    <h3>{resource.name}</h3>
                    <section>
                        <Html content={resource.description} />
                    </section>
                </>
            ) : null}
            {sortedActivities.map((activity) => (
                <ActivityContextProvider activity={activity} key={activity.id}>
                    <ActivityBooking
                        dates={
                            data && data.resource && data.resource.activities
                                ? (data.resource.activities.find(
                                      (a) => a.id === activity.id
                                  )?.dates as string[])
                                : null
                        }
                    />
                </ActivityContextProvider>
            ))}
            <div className="clearfix" />
        </div>
    );
};

interface IResourceProp {
    resource: IResource;
}

export default Resource;
