import React from "react";

const ScrollToTop = ({ display }: IScrollToTop) => {
    const handleClick = () => {
        window.scrollTo(0, 0);
    };

    return (
        <div
            className={`m-scroll-to-top ${display ? "active" : ""}`}
            onClick={handleClick}
        >
            <i className="fal fa-chevron-right" />
        </div>
    );
};

export default ScrollToTop;

export interface IScrollToTop {
    display: boolean;
}
