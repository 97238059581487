import React, { useEffect, useState } from "react";
import { IBookableExtra } from "../../generated/dataInterfaces";
import QuantitySelector from "../Util/QuantitySelector";
import { useActivityContext } from "./ActivityContext";

const ExtraSelector = ({ data }: IExtraSelector) => {
    const { setExtraQuantity, ticketQuantity } = useActivityContext();
    const [quantity, setQuantity] = useState(0);

    const handleChange = (value: number): void => {
        setQuantity(value);
    };

    useEffect(() => {
        setExtraQuantity({
            extraId: data.extraId,
            quantity
        });
    }, [quantity, setExtraQuantity, data]);

    useEffect(() => {
        setQuantity(0);
        setExtraQuantity({
            extraId: data.extraId,
            quantity: 0
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="fw m-extra-item">
            <div className="content">
                <p className="">
                    {ticketQuantity > 0 ? `${quantity} x ${data.name} ` : ""}
                    {ticketQuantity > 0 ? (
                        <span className="price-ea">
                            £{data.price.toFixed(2)}
                        </span>
                    ) : (
                        ""
                    )}
                    <br />
                    <strong>
                        {ticketQuantity > 0
                            ? `£${(data.price * quantity).toFixed(2)}`
                            : ""}
                    </strong>
                </p>
            </div>
            {ticketQuantity > 0 ? (
                <QuantitySelector
                    min={0}
                    max={99}
                    quantity={quantity}
                    onChange={handleChange}
                    dataTestIdPrefix="extra"
                />
            ) : (
                ""
            )}
        </div>
    );
};

interface IExtraSelector {
    data: IBookableExtra;
}

export default ExtraSelector;
