import {
    ApolloClient,
    createHttpLink,
    DefaultOptions,
    from
} from "@apollo/client";
import { InMemoryCache, NormalizedCacheObject } from "@apollo/client/cache";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

function generateApolloClient(
    token: string | null
): ApolloClient<NormalizedCacheObject> {
    const httpLink = createHttpLink({
        uri: process.env.GRAPHQL_ENDPOINT
    });
    const authLink = setContext((_request, { headers }) => {
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : ""
            }
        };
    });
    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            graphQLErrors.forEach(({ message, locations, path }) => {
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                );

                if (message.toLowerCase().includes("not authorised")) {
                    localStorage.clear();
                    window.location.reload();
                }
            });
        }

        if (networkError) console.log(`[Network error]: ${networkError}`);
    });
    const defaultApolloOptions: DefaultOptions = {
        watchQuery: {
            fetchPolicy: "cache-and-network",
            errorPolicy: "ignore"
        },
        query: {
            fetchPolicy: "network-only",
            errorPolicy: "all"
        },
        mutate: {
            errorPolicy: "all"
        }
    };
    const client = new ApolloClient({
        link: from([errorLink, authLink.concat(httpLink)]),
        cache: new InMemoryCache(),
        defaultOptions: defaultApolloOptions
    });
    return client;
}

export default generateApolloClient;
