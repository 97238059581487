import moment from "moment";
import React from "react";
import { ICustomerMembership } from "../../generated/dataInterfaces";

const SummaryCustomerMembershipCard = ({
    customerMembership,
    useMembershipName
}: ISummaryCustomerMembershipCardProps) => {
    return (
        <div className="m-membership-card">
            <div className="content">
                <div className="left">
                    <header>
                        <h3>
                            {useMembershipName
                                ? customerMembership.name
                                : customerMembership.customer
                                ? customerMembership.customer.name
                                : "?"}
                        </h3>

                        {customerMembership.hasBeenActivated ? (
                            <h6>{customerMembership.membership.name}</h6>
                        ) : null}
                    </header>

                    <label>
                        STARTS:{" "}
                        <strong>
                            {moment(customerMembership.completedAt)
                                .utc()
                                .format("dddd, MMMM Do YYYY")}
                        </strong>
                        &nbsp;&nbsp;&nbsp; ENDS:{" "}
                        <strong>
                            {moment(customerMembership.expiresAt)
                                .utc()
                                .format("dddd, MMMM Do YYYY")}
                        </strong>
                    </label>
                </div>
                {customerMembership.photo &&
                customerMembership.hasBeenActivated ? (
                    <div className="right">
                        <div className="image">
                            <img
                                alt={customerMembership.name}
                                src={customerMembership?.photo?.base64 ?? ""}
                            />
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

interface ISummaryCustomerMembershipCardProps {
    customerMembership: ICustomerMembership;
    useMembershipName?: boolean;
}

export default SummaryCustomerMembershipCard;
