import React from "react";
import { IMembershipGroup } from "../../generated/dataInterfaces";
import Membership from "./Membership";

const MembershipGroup = ({ membershipGroup }: IMembershipGroupProp) => {
    return membershipGroup ? (
        <div className="mb-3">
            <h3>{membershipGroup.name}</h3>
            {membershipGroup?.memberships?.map((membership) => (
                <Membership key={membership.id} membership={membership} />
            ))}
            <div className="clearfix" />
        </div>
    ) : null;
};

interface IMembershipGroupProp {
    membershipGroup: IMembershipGroup;
}

export default MembershipGroup;
