import { gql } from "@apollo/client";
import queryField from "./queryField";

const UPDATE_MY_CREDENTIAL_MUTATION = gql`
    mutation updateMyCredential(
        $firstName: String!
        $lastName: String!
        $mobileNumber: String!
        $password: String
        $doesReceiveEmails: Boolean
        $doesReceiveSms: Boolean
    ) {
        updateMyCredential(
            input: {
                firstName: $firstName
                lastName: $lastName
                mobileNumber: $mobileNumber
                password: $password
                doesReceiveEmails: $doesReceiveEmails
                doesReceiveSms: $doesReceiveSms
            }
        ) {
           ${queryField}
        }
    }
`;

export default UPDATE_MY_CREDENTIAL_MUTATION;
