const GenerateBasketFooterLink = (
    areThereBasketActivities: boolean,
    areBookingNamesRequired: boolean,
    hasCustomerMemberships: boolean,
    path: string
) => {
    switch (true) {
        case areThereBasketActivities &&
            hasCustomerMemberships &&
            path !== "/apply-memberships":
            return "/apply-memberships";
        case areBookingNamesRequired && path !== "/booking-details":
            return "/booking-details";
        default:
            return "/check-out";
    }
};

export default GenerateBasketFooterLink;
