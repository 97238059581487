import { useContext } from "react";
import { BasketContext } from "./index";

function useCoupon() {
    const context = useContext(BasketContext);
    if (!context) {
        throw new Error(
            "applyCoupon must be used within a BasketContextProvider"
        );
    }
    const { applyCouponCode, couponsApplied, isCouponApplied } = context;
    return { applyCouponCode, couponsApplied, isCouponApplied };
}

export default useCoupon;
