import { gql } from "@apollo/client";
import queryFields from "./queryFields";

const REMOVE_COUPON_FROM_BASKET_MUTATION = gql`
    mutation removeCoupon($basketId: ID!, $couponId: ID!) {
        removeCoupon(input: {
            basketId: $basketId, 
            couponId: $couponId 
        }) {
            ${queryFields}
        }
    }
`;

export default REMOVE_COUPON_FROM_BASKET_MUTATION;
