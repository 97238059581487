import { concat, flatten } from "lodash";
import { useEffect } from "react";
import useBasket from "../Context/BasketContext/useBasket";
import { IBasket } from "../../generated/dataInterfaces";
import { useGoogleAnalytics } from "react-ga4-gtag";

const CheckoutTracking = () => {
    const { basketData } = useBasket();

    const gtag = useGoogleAnalytics();

    basketData?.items?.map((item) => {
        item.basketActivity;
    });

    useEffect(() => {
        if (gtag !== null) {
            let index = 0;
            const products = basketData?.items
                ?.filter((item) => item.basketActivity !== null)
                .map((item) => {
                    index++;
                    const b = item?.basketActivity;
                    return {
                        item_category: "Activities", // b.activityInstance.activity.resource.name,
                        item_id: b?.activityInstanceId,
                        item_name: b?.description,
                        price: b?.pricePerItem,
                        quantity: b?.quantity,
                        discount: b?.discountPerItem,
                        index
                    } as IProduct;
                });

            const extraProducts = flatten(
                basketData?.items
                    ?.filter((item) => item.basketExtras !== null)
                    .map((item) =>
                        item?.basketExtras?.map((b) => {
                            index++;
                            return {
                                item_category: "Activities", // b.activityInstance.activity.resource.name,
                                item_id: b?.id,
                                item_name: "Extra: " + b?.description,
                                price: b?.pricePerItem,
                                quantity: b?.quantity,
                                discount: b?.discountPerItem,
                                index
                            } as IProduct;
                        })
                    )
            );

            const membershipProducts = basketData?.items
                ?.filter((item) => item.basketMembership !== null)
                .map((item) => {
                    index++;
                    const b = item?.basketMembership;
                    return {
                        item_category: "Activities", //"Memberships",
                        item_id: b?.membershipId,
                        item_name: "Membership: " + b?.description,
                        price: b?.pricePerItem,
                        quantity: b?.quantity,
                        index
                    } as IProduct;
                });

            const allProducts = concat(
                products,
                extraProducts,
                membershipProducts
            );

            console.log("allProducts", allProducts);

            gtag("event", "begin_checkout", {
                currency: "GBP",
                value: basketData?.total, //total value of items in the cart
                items: allProducts
                // [
                //     {
                //         item_category: "Activities",
                //         item_id: "SKU_12345", //The ID of the each item in cart
                //         item_name: "Trampoline Park", //The name of each item in cart
                //         coupon: "SUMMER_FUN", //if applicable (optional)
                //         discount: 2.22, //if applicable (optional)
                //         index: 0, //Position of the item in this array (optional)
                //         price: 9.99, //The price of each item in cart (optional)
                //         quantity: 1 //Quantity of each item in cart (optional)
                //     }
                // ]
            });
        }
    }, [gtag]);

    return null;
};
interface IProduct {
    item_category: string;
    item_id: string;
    item_name: string;
    coupon?: string;
    discount?: number;
    index?: number;
    price: number;
    quantity: number;
}

interface IProps {
    basketData: IBasket;
}

export default CheckoutTracking;
