import React from "react";
import { Link } from "react-router-dom";

const Button = ({
    handleClick,
    title,
    icon = BUTTON_ICONS.NONE,
    link,
    size,
    style,
    position = BUTTON_POSITION.LEFT,
    color = BUTTON_COLOR.PRIMARY
}: IButtonProps) => {
    const i = icon !== BUTTON_ICONS.NONE ? `v-btn-icon ${icon}` : `${icon}`;
    const c = color ? color : "";
    const s = size ? size : "";
    const p = position ? position : "";

    return (
        <>
            {link ? (
                <Link
                    style={style}
                    className={`m-btn ${i} ${c} ${s} ${p}`}
                    to={link}
                >
                    {title}
                </Link>
            ) : (
                <button
                    style={style}
                    className={`m-btn ${i} ${c} ${s} ${p}`}
                    onClick={handleClick}
                >
                    {title}
                </button>
            )}
        </>
    );
};

export default Button;

export interface IButtonProps {
    handleClick?: () => void;
    title?: string;
    link?: string;
    icon?: BUTTON_ICONS;
    color?: BUTTON_COLOR;
    size?: BUTTON_SIZE;
    position?: BUTTON_POSITION;
    style?: any;
}

export enum BUTTON_ICONS {
    ACCOUNT = "account",
    NONE = "no-icon",
    SUBMIT = "submit",
    ACTIVITIES = "list",
    DELETE = "delete"
}

export enum BUTTON_COLOR {
    PRIMARY = "primary",
    SUCCESS = "success",
    WARNING = "warning",
    HIGHLIGHT = "highlight",
    TERTIARY = "v-btn-tertiary",
    SECONDARY = "v-btn-secondary"
}

export enum BUTTON_SIZE {
    THIRD = "third-container",
    SMALL = "small"
}

export enum BUTTON_POSITION {
    RIGHT = "right",
    LEFT = "left"
}
