import { gql } from "@apollo/client";

const SHARED_CUSTOMER_MEMBERSHIPS = gql`
    query sharedCustomerMemberships($customerMembershipId: ID!) {
        sharedCustomerMemberships(
            input: { customerMembershipId: $customerMembershipId }
        ) {
            membership {
                id
                name
                description
            }
            customerMembership {
                id
                name
                completedAt
                expiresAt
                hasBeenActivated
                photo {
                    base64
                }
                membership {
                    id
                    name
                }
            }
            customerMembershipSharings {
                id
                membershipOwner {
                    id
                    name
                }
                sharedCustomerMembership {
                    id
                    completedAt
                    expiresAt
                    hasBeenActivated
                    photo {
                        base64
                    }
                    membership {
                        id
                        name
                    }
                }
                sharedWithCustomer {
                    id
                    name
                }
                sharedToEmail
                isConfirmed
                confirmedAt
                createdAt
                updatedAt
            }
        }
    }
`;

export default SHARED_CUSTOMER_MEMBERSHIPS;
