import moment from "moment";
import React from "react";
import { useActivityContext } from "./ActivityContext";

interface IDateSelectorProps {
    dates: string[];
}

/**
 *  @name           DateSelector
 *  @description    If the dateContext has no reference to ta date in it, then the date selector will grab the next
 *                  21 days, create a dropdown and its options
 *  @props          null
 */
const DateSelector = ({ dates }: IDateSelectorProps) => {
    const { setSelectedDate } = useActivityContext();

    const handleChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ): void => {
        const selectedDate = event.target.value;
        if (selectedDate !== null) {
            setSelectedDate(selectedDate);
        }
    };

    return (
        <div className="m-activity-date-selector section fw">
            <label>
                <strong>Select a date</strong>
            </label>
            <div className="select-wrap">
                {dates ? (
                    dates.length > 0 ? (
                        <select
                            data-testid="activity-select-date"
                            defaultValue={"default"}
                            onChange={handleChange}
                        >
                            <option value="default">
                                - Please select a date -
                            </option>
                            {dates.map((date) => {
                                const cleanDate = moment(date);

                                return (
                                    <option
                                        value={cleanDate.format("YYYY-MM-DD")}
                                        key={date}
                                    >
                                        {cleanDate.format("dddd, MMMM Do YYYY")}
                                    </option>
                                );
                            })}
                        </select>
                    ) : (
                        <select defaultValue={"default"} disabled>
                            <option value="default">
                                - Sorry, no dates available -
                            </option>
                        </select>
                    )
                ) : (
                    <select defaultValue={"default"} disabled>
                        <option value="default">- Loading dates -</option>
                    </select>
                )}
            </div>
        </div>
    );
};

export default DateSelector;
