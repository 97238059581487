import { exists } from "@les-ormes/lib";
import React from "react";
import { useAppContext } from "../../Context/AppContext";
import { useAuthenticated } from "../../Context/AuthContext";
import useBasket from "../../Context/BasketContext/useBasket";
import useCustomerMemberships from "../../Context/CustomerMembershipsContext";
import Step from "./Step";

const BreadCrumbs = ({ step }: IBreadCrumbs) => {
    const { entryRoute, entryLabel } = useAppContext();
    const { isAuthenticated } = useAuthenticated();
    const {
        areBookingNamesRequired,
        areThereBasketActivities,
        areThereBasketItems
    } = useBasket();
    const { hasCustomerMemberships } = useCustomerMemberships();

    const title = entryLabel; // firstStepTitle || "Choose your activities";

    const titleArray: ILinkBuilder[] = (
        isAuthenticated
            ? [
                  {
                      title,
                      link: entryRoute
                  },
                  areThereBasketActivities && hasCustomerMemberships
                      ? {
                            title: "Memberships",
                            link: "/apply-memberships"
                        }
                      : null,
                  areBookingNamesRequired
                      ? { title: "Booking details", link: "/booking-details" }
                      : null,
                  {
                      title: "Payment",
                      link: areThereBasketItems ? "/check-out" : null
                  }
              ]
            : [
                  {
                      title,
                      link: entryRoute
                  },
                  {
                      title: "Log in or register",
                      link: isAuthenticated ? null : "/log-in"
                  },
                  {
                      title: "Payment",
                      link: areThereBasketItems ? "/check-out" : null
                  }
              ]
    ).filter(exists);

    return (
        <>
            {titleArray &&
            titleArray.length &&
            titleArray.some((title: ILinkBuilder) => title && step) ? (
                <div className="m-breadcrumbs fw block">
                    <div className="m-auto clearfix">
                        {titleArray.map((title, i) =>
                            title && step ? (
                                <Step
                                    key={i}
                                    last={
                                        i === titleArray.length - 1
                                            ? true
                                            : false
                                    }
                                    active={i < step}
                                    current={i === step}
                                    title={title.title}
                                    link={title.link}
                                />
                            ) : null
                        )}
                        <div className="clearfix" />
                    </div>
                </div>
            ) : null}
        </>
    );
};

export interface IBreadCrumbs {
    step?: number;
}

interface ILinkBuilder {
    title: string;
    link: string | null;
}

export default BreadCrumbs;
