import { gql } from "@apollo/client";

const RESET_PASSWORD_MUTATION = gql`
    mutation resetPassword(
        $securityStamp: String!
        $newPassword: String!
        $organisationId: ID!
    ) {
        resetPassword(
            input: {
                securityStamp: $securityStamp
                newPassword: $newPassword
                organisationId: $organisationId
            }
        ) {
            email
            securityStamp
        }
    }
`;

export default RESET_PASSWORD_MUTATION;
