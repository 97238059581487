import React, { useEffect } from "react";
import { useNotification } from "../Context/NotificationContext";

const NotificationBar = ({ duration }: INotificationBar) => {
    const {
        showNotification,
        setShowNotification,
        notificationColor,
        notificationMessage,
        notificationSubMessage,
        notificationType
    } = useNotification();

    useEffect(() => {
        if (showNotification === true) {
            // // show for 5 seconds and fade off
            setTimeout(
                () => {
                    setShowNotification(false);
                },
                duration ? duration : 5000
            );
        }
    }, [showNotification, setShowNotification, duration]);

    const handleClick = () => {
        setShowNotification(false);
    };

    return (
        <div
            onClick={handleClick}
            className={`fw m-notification-bar ${notificationColor} ${
                showNotification ? "display" : ""
            } `}
        >
            <div className="pw">
                <i className={`far ${icon(notificationType)}`} />
                <label>
                    <strong>{notificationMessage} </strong>
                </label>
                <label>{notificationSubMessage}</label>

                <a className="float-right">
                    <i className="fal fa-times" onClick={handleClick} />
                </a>
            </div>
        </div>
    );
};

const icon = (messageType: NOTIFICATION_BAR_MESSAGE) => {
    switch (messageType) {
        case NOTIFICATION_BAR_MESSAGE.SUCCESS:
            return "fa-check";
        case NOTIFICATION_BAR_MESSAGE.WARNING:
            return "fa-exclamation-circle";
        case NOTIFICATION_BAR_MESSAGE.FAILURE:
            return "fa-exclamation-triangle";
    }
};

export default NotificationBar;

export interface INotificationBar {
    duration?: number;
    color?: NOTIFICATION_BAR_MESSAGE;
}

export enum NOTIFICATION_BAR_MESSAGE {
    SUCCESS,
    WARNING,
    FAILURE
}
