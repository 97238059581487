import React from "react";
import { Link, Redirect } from "react-router-dom";
import Activities from "../ApplyMemberships/Activities";
import Basket from "../Basket/Basket";
import { useAppContext } from "../Context/AppContext";
import useBasket from "../Context/BasketContext/useBasket";
import BreadCrumbs from "../Util/Breadcrumbs";
import NotificationBar from "../Util/NotificationBar";
import PageHeader from "../Util/PageHeader";

const ApplyMemberships = () => {
    const { basketData } = useBasket();
    const basketItems = basketData?.items?.filter((i) =>
        Boolean(i.basketActivity)
    );

    const { entryRoute } = useAppContext();

    // if (!basketItems || basketItems.length === 0) {
    //     return <Redirect to={entryRoute} />;
    // }

    return (
        <>
            <PageHeader />
            <div className="fw">
                <div className="pw">
                    <BreadCrumbs step={1} />
                    <div className="clearfix" />
                    <div className="content fw">
                        <div className="two-thirds-container">
                            <h2>Memberships</h2>
                            <p>
                                Please select a membership for below activities.
                                To purchase additional memberships visit{" "}
                                <Link to="/memberships">
                                    the memberships page.
                                </Link>
                            </p>
                            <Activities basketItems={basketItems} />
                        </div>
                        <aside
                            id="basket-scroll"
                            className="sidebar third-container right"
                        >
                            <Basket
                                isCheckout={false}
                                shouldShowMembershipWarning={false}
                            />
                        </aside>
                    </div>
                    <footer />
                </div>
            </div>
            <NotificationBar duration={7500} />
        </>
    );
};

export default ApplyMemberships;
