import moment from "moment";
import React from "react";
import { ICredential } from "../../generated/dataInterfaces";
import { useAccountContext } from "../Context/AccountContext";
import Button, { BUTTON_COLOR, BUTTON_SIZE } from "../Util/Button";
import InputField, { InputFieldType } from "../Util/InputField";

const CredentialForm = ({ data }: ICredentialForm) => {
    const { credential, toggleCredential } = useAccountContext();

    if (!data) {
        return null;
    }

    async function handleDisableClick() {
        await toggleCredential({
            variables: {
                targetCredentialId: data.id
            }
        });
    }

    return (
        <>
            <InputField
                disabled={true}
                label="First Name"
                type={InputFieldType.TEXT}
                placeholder="First Name"
                value={data.firstName}
            />

            <InputField
                disabled={true}
                label="Last Name"
                type={InputFieldType.TEXT}
                placeholder="Last Name"
                value={data.lastName}
            />

            <InputField
                disabled={true}
                label="Email"
                type={InputFieldType.EMAIL}
                placeholder="Email"
                value={data.email}
            />

            <InputField
                disabled={true}
                label="Mobile Number"
                type={InputFieldType.TEXT}
                placeholder="Mobile Number"
                value={data.mobileNumber}
            />
            <InputField
                disabled={true}
                label="Registration date"
                type={InputFieldType.TEXT}
                placeholder="added to customer account"
                value={moment(data.createdAt).utc().format("DD-MM-YYYY")}
            />

            {credential?.isPrimaryCredential && !data.isPrimaryCredential ? (
                <footer>
                    {data.isDisabled ? (
                        <Button
                            color={BUTTON_COLOR.SUCCESS}
                            size={BUTTON_SIZE.THIRD}
                            title="Enable user"
                            handleClick={handleDisableClick}
                        />
                    ) : (
                        <Button
                            color={BUTTON_COLOR.WARNING}
                            size={BUTTON_SIZE.THIRD}
                            title="Disable user"
                            handleClick={handleDisableClick}
                        />
                    )}
                </footer>
            ) : null}
        </>
    );
};

export default CredentialForm;

interface ICredentialForm {
    data: ICredential;
}
