import { gql } from "@apollo/client";
import basketFields from "../Basket/queryFields";

const APPLY_MEMBERSHIP = gql`
    mutation applyMembership($value: ApplyMembershipInput!) {
        applyMembership(data: $value) {
            ${basketFields}
        }
    }
`;

export default APPLY_MEMBERSHIP;
