import React from "react";
import Button, { BUTTON_COLOR } from "../Button";

const UpdateField = ({
    isHidden,
    handleSaveClick,
    handleCancelClick,
    lockedMode,
    editTitle = "edit details",
    saveTitle = "save details"
}: IUpdateField) => {
    return (
        <>
            {!isHidden ? (
                <div className="m-save-fields two-thirds-container">
                    <div className="half-container left">
                        <Button
                            handleClick={handleSaveClick}
                            title={lockedMode ? editTitle : saveTitle}
                            color={
                                lockedMode
                                    ? BUTTON_COLOR.PRIMARY
                                    : BUTTON_COLOR.SUCCESS
                            }
                        />
                    </div>
                    {!lockedMode ? (
                        <div className="half-container right">
                            <Button
                                handleClick={handleCancelClick}
                                title="Cancel"
                                color={BUTTON_COLOR.SECONDARY}
                            />
                        </div>
                    ) : null}
                </div>
            ) : null}
        </>
    );
};

export default UpdateField;

export interface IUpdateField {
    isHidden: boolean;
    handleSaveClick: () => void;
    handleCancelClick: () => void;
    lockedMode?: boolean;
    editTitle?: string;
    saveTitle?: string;
}
