import React, { useEffect } from "react";

const RedirectToMain = () => {
    useEffect(() => {
        window.location.href = "https://www.lesormesjersey.co.uk/things-to-do/all-activities/";
    }, []);

    return null;
};

export default RedirectToMain;
