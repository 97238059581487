import { MutationFunctionOptions, useMutation, useQuery } from "@apollo/client";
import { ExecutionResult } from "graphql";
import moment from "moment";
import React, { createContext, useContext } from "react";
import APPLY_MEMBERSHIP from "../../data/CustomerMembership/ApplyMembership";
import MY_CUSTOMER_MEMBERSHIPS from "../../data/CustomerMembership/MyCustomerMemerships";
import ACCEPT_MEMBERSHIP_SHARE_MUTATION from "../../data/CustomerMembershipSharing/AcceptMembershipShare";
import REMOVE_MEMBERSHIP_SHARE_MUTATION from "../../data/CustomerMembershipSharing/RemoveMembershipShare";
import {
    IApplyMembershipInput,
    IBasket,
    ICustomerMembership,
    ICustomerMembershipSharing
} from "../../generated/dataInterfaces";
import { useAppContext } from "./AppContext";

export interface ICustomerMembershipsContext {
    customerMemberships: ICustomerMembership[] | null;
    hasCustomerMemberships: boolean;
    applyMembership: (
        options?: MutationFunctionOptions<
            {
                applyMembership: IBasket;
            },
            {
                value: IApplyMembershipInput;
            }
        >
    ) => Promise<
        ExecutionResult<{
            applyMembership: IBasket;
        }>
    >;
    removeMembershipShareMutation: (
        options?: MutationFunctionOptions<
            {
                removeMembershipShare: boolean;
            },
            {
                customerMembershipShareId: string;
            }
        >
    ) => Promise<
        ExecutionResult<{
            removeMembershipShare: boolean;
        }>
    >;
    acceptMembershipShareMutation: (
        options?: MutationFunctionOptions<
            {
                acceptMembershipShare: ICustomerMembershipSharing;
            },
            {
                customerMembershipShareId: string;
            }
        >
    ) => Promise<
        ExecutionResult<{
            acceptMembershipShare: ICustomerMembershipSharing;
        }>
    >;
    isApplyMembershipLoading: boolean;
    renewableCustomerMemberships: ICustomerMembership[] | null;
    hasRenewableCustomerMemberships: boolean | null;
}

export const CustomerMembershipsContext =
    createContext<ICustomerMembershipsContext | null>(null);

export const CustomerMembershipsContextProvider = ({
    children
}: {
    children: React.ReactNode;
}) => {
    const { customerId } = useAppContext();

    const { data } = useQuery<
        { myCustomerMemberships: ICustomerMembership[] },
        { customerId: string }
    >(MY_CUSTOMER_MEMBERSHIPS, {
        skip: !customerId
    });

    const renewableCustomerMemberships =
        data?.myCustomerMemberships?.filter((m) => {
            return (
                m.hasBeenActivated &&
                m.expiresAt &&
                m.renewalAvailableAt &&
                moment.utc().isAfter(moment(m.renewalAvailableAt).utc())
            );
        }) ?? null;

    const hasRenewableCustomerMemberships =
        renewableCustomerMemberships && renewableCustomerMemberships.length > 0;

    const customerMemberships =
        data?.myCustomerMemberships?.filter(
            (m) =>
                (m.hasBeenActivated && !m.expiresAt) ||
                moment().utc().isBefore(m.expiresAt)
        ) ?? null;

    const hasCustomerMemberships = Boolean(
        customerMemberships && customerMemberships.length > 0
    );

    const [applyMembership, { loading: isApplyMembershipLoading }] =
        useMutation<
            { applyMembership: IBasket },
            { value: IApplyMembershipInput }
        >(APPLY_MEMBERSHIP);

    const [removeMembershipShareMutation] = useMutation<
        { removeMembershipShare: boolean },
        { customerMembershipShareId: string }
    >(REMOVE_MEMBERSHIP_SHARE_MUTATION);

    const [acceptMembershipShareMutation] = useMutation<
        { acceptMembershipShare: ICustomerMembershipSharing },
        { customerMembershipShareId: string }
    >(ACCEPT_MEMBERSHIP_SHARE_MUTATION);

    const contextValue: ICustomerMembershipsContext = {
        customerMemberships,
        hasCustomerMemberships,
        renewableCustomerMemberships,
        hasRenewableCustomerMemberships,
        applyMembership,
        removeMembershipShareMutation,
        acceptMembershipShareMutation,
        isApplyMembershipLoading
    };
    return (
        <CustomerMembershipsContext.Provider value={contextValue}>
            {children}
        </CustomerMembershipsContext.Provider>
    );
};

const useCustomerMemberships = () => {
    const context = useContext(CustomerMembershipsContext);
    if (!context) {
        throw new Error(
            "useAccountContext must be used within a CustomerMembershipContextProvider"
        );
    }

    return {
        ...context
    };
};

export default useCustomerMemberships;
