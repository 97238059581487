import React, { useEffect, useState } from "react";
//import StepTracking from "../../Analytics/StepTracking";
import Basket from "../../Basket/Basket";
import Discount from "../../Checkout/Discount";
import Payment from "../../Checkout/Payment";
import useBasket from "../../Context/BasketContext/useBasket";
import BreadCrumbs from "../../Util/Breadcrumbs";
import NotificationBar from "../../Util/NotificationBar";
import PageHeader from "../../Util/PageHeader";
import { Link } from "react-router-dom";
import CheckoutTracking from "../../Analytics/CheckoutTracking";

const CheckoutPage = () => {
    const { basketId, basketData, setBasketId } = useBasket();
    const [random, setRandom] = useState(0);

    if (
        !basketId ||
        (basketData && (!basketData.items || basketData.items.length === 0))
    ) {
        // return <Redirect to={`/`} />;
    }

    // if (!basketData) {
    //     return null;
    // }

    useEffect(() => {
        setRandom(Math.floor(Math.random() * 1000000) + 1);
        console.log("refreshing key", random);
    }, [basketData]);

    useEffect(() => {
        // if a ?bid is passed, set the basket ID!
        const urlParams = new URLSearchParams(window.location.search);
        const bid = urlParams.get("bid");
        if (bid) {
            setBasketId(bid);
        }
    }, []);

    return (
        <div className="fw">
            <PageHeader title="Payment" />
            <div className="pw">
                <BreadCrumbs step={3} />
                <div className="clearfix" />
                <div className="content fw">
                    <div className="two-thirds-container">
                        <div className="m-checkin a-box v-box-secondary">
                            <Discount />
                            <Payment key={random} />
                            <footer />
                            <div className="clearfix" />
                        </div>
                    </div>
                    <aside
                        id="basket-scroll"
                        className="sidebar third-container right"
                    >
                        <Basket
                            isCheckout={true}
                            shouldShowMembershipWarning={false}
                        />

                        <p>
                            Need to apply your membership?
                            <br />
                            <Link to="/apply-memberships">Click here</Link>
                        </p>
                    </aside>
                </div>
            </div>
            <NotificationBar duration={7500} />
            {/* <StepTracking stepNumber="3" stepName="Payment" /> */}

            <CheckoutTracking />
        </div>
    );
};

export default CheckoutPage;
