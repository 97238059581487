import React, { useEffect, useState } from "react";
import { useAppContext } from "../Context/AppContext";
import useBasket from "../Context/BasketContext/useBasket";
import Html from "../Util/Html";
import QuantitySelector from "../Util/QuantitySelector";
import { IMembership } from "./interfaces";

const Membership = ({ membership }: IMembershipProp) => {
    const { organisationId } = useAppContext();
    const { addItemToBasket, basketId } = useBasket();
    const [quantity, setQuantity] = useState(membership.quantity || 0);

    useEffect(() => {
        if (
            membership &&
            (membership?.quantity ?? 0) >= 0 &&
            membership.quantity !== quantity
        ) {
            setQuantity(membership?.quantity ?? 0);
        }
    }, [membership, quantity]);

    if (!organisationId) {
        return null;
    }

    return membership ? (
        <div className="m-membership fw">
            <div className="a-box v-box-secondary p-2 fw">
                <header className="">
                    <h4>{membership.name}</h4>
                </header>

                <div className="m-activity-ticket-selector fw ">
                    <div className="fw main">
                        <div className="content">
                            <div className="a-p fw">
                                <div className="pull-left">
                                    <Html
                                        content={membership?.description ?? ""}
                                    />
                                </div>
                                <span className="lead pull-right">
                                    £{membership.price}
                                </span>
                            </div>
                        </div>
                        <QuantitySelector
                            min={0}
                            max={10}
                            quantity={quantity}
                            dataTestIdPrefix="membership"
                            onChange={async (value) => {
                                setQuantity(value);
                                await addItemToBasket({
                                    variables: {
                                        value: {
                                            organisationId,
                                            basketId,
                                            membership: {
                                                membershipId: membership.id,
                                                quantity: value
                                            }
                                        }
                                    }
                                });
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

interface IMembershipProp {
    membership: IMembership;
}

export default Membership;
