import React from "react";
import { IBasketItem } from "../../generated/dataInterfaces";
import BasketActivityItem from "./BasketActivityItem";
import BasketMembershipItem from "./BasketMembershipItem";

const BasketItem = ({
    basketItem,
    isItemRemovable,
    shouldShowMembershipWarning
}: IBasketItemProps) => {
    if (basketItem.basketActivity) {
        const { basketActivity } = basketItem;

        return (
            <BasketActivityItem
                key={basketItem.id}
                id={basketItem.id}
                date={basketActivity.startsAt}
                activityTitle={basketActivity.description}
                ticketDescription={
                    basketActivity?.activityInstance?.activity
                        ?.ticketDescription ?? ""
                }
                quantity={basketActivity.quantity}
                numberOfAppliedCustomerMemberships={
                    basketActivity.appliedCustomerMembershipIds
                        ? basketActivity.appliedCustomerMembershipIds.filter(
                              (m) => m !== ""
                          ).length
                        : 0
                }
                currency={"£"}
                price={basketActivity.quantity * basketActivity.pricePerItem}
                extras={basketItem?.basketExtras ?? null}
                discount={basketItem.basketActivity.discountPerItem}
                membershipDiscount={
                    basketItem.basketActivity.membershipDiscount
                }
                applicableCustomerMemberships={
                    basketActivity?.applicableCustomerMemberships ?? null
                }
                isItemRemovable={isItemRemovable}
                shouldShowMembershipWarning={shouldShowMembershipWarning}
            />
        );
    } else if (basketItem.basketMembership) {
        const { basketMembership } = basketItem;
        return (
            <BasketMembershipItem
                key={basketItem.id}
                id={basketItem.id}
                membershipName={basketMembership.description}
                isRenewal={basketMembership.isRenewal ?? false}
                quantity={basketMembership.quantity}
                currency={"£"}
                price={
                    basketMembership.quantity * basketMembership.pricePerItem
                }
                isItemRemovable={isItemRemovable}
            />
        );
    }

    return null;
};

export default BasketItem;

interface IBasketItemProps {
    basketItem: IBasketItem;
    isItemRemovable: boolean;
    shouldShowMembershipWarning: boolean;
}
