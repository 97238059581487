import { gql } from "@apollo/client";

const ACTIVATE_MEMBERSHIP = gql`
    mutation activateMembership($value: ActivateMembershipInput!) {
        activateMembership(data: $value) {
            id
            hasBeenActivated
            name
            photo {
                base64
            }
        }
    }
`;

export default ACTIVATE_MEMBERSHIP;
