import { useMutation, useQuery } from "@apollo/client";
import { exists } from "@les-ormes/lib";
import { xor } from "lodash";
import React, { createContext, useEffect, useMemo, useState } from "react";
import ADD_TO_BASKET from "../../../data/Basket/AddToBasket";
import GET_BASKET from "../../../data/Basket/GetBasket";
import RENEW_BASKET from "../../../data/Basket/RenewBasket";
import REMOVE_COUPON_FROM_BASKET_MUTATION from "../../../data/Basket/RemoveCouponFromBasket";
import REMOVE_FROM_BASKET from "../../../data/Basket/RemoveFromBasket";
import APPLY_COUPON from "../../../data/Coupon/ApplyCoupon";
import {
    IAddToBasketInput,
    IApplyCouponInput,
    IBasket,
    IBasketItem,
    IRemoveFromBasketInput
} from "../../../generated/dataInterfaces";
import { NOTIFICATION_BAR_MESSAGE } from "../../Util/NotificationBar";
import { useAppContext } from "../AppContext";
import { useAuthenticated } from "../AuthContext";
import { useNotification } from "../NotificationContext";
import { useTransaction } from "../TransactionContext";
import {
    IBasketContext,
    IBasketData,
    IBasketItemIdBookingNamesPair
} from "./interfaces";

export const BasketContext = createContext<IBasketContext | null>(null);

const BasketContextProvider = ({ children }: { children: React.ReactNode }) => {
    const { basketId, setBasketId } = useAppContext();
    const { setTransactionData } = useTransaction();
    const {
        setNotificationColor,
        setNotificationMessage,
        setNotificationSubMessage,
        setShowNotification
    } = useNotification();
    const { setIsAuthenticated } = useAuthenticated();
    const [basketItemIdBookingNamesPairs, setBasketItemIdBookingNamesPairs] =
        useState<IBasketItemIdBookingNamesPair[]>([]);
    const [isCouponApplied, setIsCouponApplied] = useState(false);

    const { data } = useQuery<IBasketData>(GET_BASKET, {
        variables: {
            basketId
        },
        skip: !basketId
    });

    const basketData = data && data.basketById ? data.basketById : null;

    const [applyCouponCode] = useMutation<
        { applyCoupon: IBasket },
        { value: IApplyCouponInput }
    >(APPLY_COUPON, {
        onCompleted: (data) => {
            const applied = !!(data && data.applyCoupon);
            setIsCouponApplied(applied);
            console.log("apply_coupon", data.applyCoupon);
        },
        onError: (error) => {
            console.log(error);
            setIsAuthenticated(false);
        }
    });

    const [addItemToBasket] = useMutation<
        { addToBasket: IBasket },
        { value: IAddToBasketInput }
    >(ADD_TO_BASKET, {
        onCompleted: ({ addToBasket }) => {
            setBasketId(addToBasket.id);
        },
        onError: (error) => {
            setNotificationColor(NOTIFICATION_BAR_MESSAGE.WARNING);
            setNotificationMessage("We're sorry, something went wrong.");
            setNotificationSubMessage(
                " Please try adding the item(s) to your basket again."
            );
            setShowNotification(true);
            console.error(error.message);
        }
    });

    const [removeItemFromBasket] = useMutation<
        { removeFromBasket: IBasket },
        { value: IRemoveFromBasketInput }
    >(REMOVE_FROM_BASKET, {
        onCompleted: ({ removeFromBasket }) => {
            setBasketId(removeFromBasket.id);
        },
        onError: (error) => {
            setNotificationColor(NOTIFICATION_BAR_MESSAGE.WARNING);
            setNotificationMessage("We're sorry, something went wrong.");
            setNotificationSubMessage(
                " Please try adding the item(s) to your basket again."
            );
            setShowNotification(true);
            console.error(error.message);
        }
    });

    const [removeCouponFromBasket] = useMutation<
        { removeCoupon: IBasket },
        { couponId: string; basketId: string }
    >(REMOVE_COUPON_FROM_BASKET_MUTATION, {
        onCompleted: ({ removeCoupon }) => {
            setBasketId(removeCoupon.id);
        },
        onError: (error) => {
            setNotificationColor(NOTIFICATION_BAR_MESSAGE.WARNING);
            setNotificationMessage("We're sorry, something went wrong.");
            setNotificationSubMessage(
                " Please try adding the item(s) to your basket again."
            );
            setShowNotification(true);
            console.error(error.message);
        }
    });

    const [renewBasket] = useMutation<
        { renewBasket: string },
        { basketId: string }
    >(RENEW_BASKET, {
        onCompleted: ({ renewBasket }) => {},
        onError: (error) => {
            setNotificationColor(NOTIFICATION_BAR_MESSAGE.WARNING);
            setNotificationMessage("We're sorry, something went wrong.");
            setNotificationSubMessage(
                " Please try adding the item(s) to your basket again."
            );
            setShowNotification(true);
            console.error(error.message);
        }
    });

    const couponsApplied =
        data && data.basketById ? data.basketById.couponIds : [];

    const basketItemActivities = useMemo<IBasketItem[]>(
        () => basketData?.items?.filter((i) => i.basketActivity) ?? [],
        [basketData]
    );

    const areThereBasketActivities =
        basketItemActivities.length > 0 ? true : false;

    const basketMemberships =
        basketData?.items
            ?.filter((i) => i.basketMembership)
            ?.map((i) => i.basketMembership) ?? [];

    const areThereBasketMemberships =
        basketData && basketMemberships && basketMemberships.length > 0
            ? true
            : false;

    const areThereBasketItems =
        basketData?.items?.length ?? 0 > 0 ? true : false;

    const itemActivitiesRequiringBookingName = basketItemActivities.filter(
        (i) => i?.basketActivity?.areBookingNamesRequired ?? false
    );
    const areBookingNamesRequired =
        itemActivitiesRequiringBookingName.length > 0 ? true : false;

    useEffect(
        function initBasketItemIdBookingNamesPairs() {
            if (
                itemActivitiesRequiringBookingName &&
                itemActivitiesRequiringBookingName.length > 0 &&
                xor(
                    basketItemIdBookingNamesPairs.map(
                        (p) => p.basketActivityId
                    ),
                    itemActivitiesRequiringBookingName.map(
                        (i) => i?.basketActivity?.id
                    )
                ).length !== 0 // To check there are new basket activities added
            ) {
                setBasketItemIdBookingNamesPairs(
                    itemActivitiesRequiringBookingName
                        .map((i) => i.basketActivity)
                        .filter(exists)
                        .map<IBasketItemIdBookingNamesPair>(
                            (basketActivity) => {
                                return {
                                    basketActivityId: basketActivity.id,
                                    bookingNames: Array(
                                        basketActivity.quantity *
                                            basketActivity.activityInstance
                                                .activity.quantityPerBooking
                                    ).fill("")
                                };
                            }
                        )
                );
            }
        },
        [
            basketData,
            basketItemActivities,
            basketItemIdBookingNamesPairs,
            itemActivitiesRequiringBookingName
        ]
    );

    const areBookingNamesValid =
        basketItemIdBookingNamesPairs.length > 0
            ? basketItemIdBookingNamesPairs.every((pair) =>
                  pair.bookingNames.every((name) => name !== "")
              )
            : false;

    const checkIsCustomerMembershipInBasket = (
        customerMembershipId: string
    ): boolean => {
        if (areThereBasketMemberships) {
            return basketMemberships.some(
                (bm) => bm?.customerMembershipId === customerMembershipId
            );
        }
        return false;
    };

    const destroyBasket = () => {
        setBasketId(null);
        setTransactionData(null);
    };

    const values = useMemo<IBasketContext>(
        () => ({
            basketId,
            setBasketId,
            applyCouponCode,
            couponsApplied,
            isCouponApplied,
            basketData,
            addItemToBasket,
            removeItemFromBasket,
            renewBasket,
            removeCouponFromBasket,
            checkIsCustomerMembershipInBasket,
            destroyBasket,
            areThereBasketActivities,
            areThereBasketMemberships,
            areThereBasketItems,
            itemActivitiesRequiringBookingName,
            areBookingNamesRequired,
            areBookingNamesValid,
            basketItemIdBookingNamesPairs,
            setBasketItemIdBookingNamesPairs
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [basketData, basketId, couponsApplied, basketItemIdBookingNamesPairs]
    );

    return (
        <BasketContext.Provider value={values}>
            {children}
        </BasketContext.Provider>
    );
};

export { BasketContextProvider };
