import React, { useEffect, useState } from "react";
import { Link, match } from "react-router-dom";
import { ICustomerMembershipSharing } from "../../../../generated/dataInterfaces";
import useCustomerMemberships from "../../../Context/CustomerMembershipsContext";
import { useNotification } from "../../../Context/NotificationContext";
import SummaryCustomerMembershipCard from "../../../CustomerMembership/SummaryCustomerMembershipCard";
import { NOTIFICATION_BAR_MESSAGE } from "../../../Util/NotificationBar";

const AcceptSharedMembership = ({ match }: IAcceptSharedMembershipProps) => {
    const {
        setNotificationColor,
        setNotificationMessage,
        setNotificationSubMessage,
        setShowNotification
    } = useNotification();

    const [accepted, setAccepted] = useState(false);
    const [customerMembershipShare, setCustomerMembershipShare] =
        useState<ICustomerMembershipSharing | null>(null);
    const customerMembershipShareId = match.params["customerMembershipShareId"];
    const { acceptMembershipShareMutation } = useCustomerMemberships();

    useEffect(() => {
        void acceptMembershipShareMutation({
            variables: {
                customerMembershipShareId
            }
        }).then((result) => {
            if (result.data && result.data.acceptMembershipShare) {
                setNotificationColor(NOTIFICATION_BAR_MESSAGE.SUCCESS);
                setNotificationMessage(
                    "The membership share has been accepted."
                );
                setNotificationSubMessage(
                    "You will now see this in your list of memberships, on checkout."
                );

                setAccepted(true);
                setCustomerMembershipShare(result.data.acceptMembershipShare);
            } else {
                setNotificationColor(NOTIFICATION_BAR_MESSAGE.FAILURE);
                setNotificationMessage(
                    "You cannot accept this membership share."
                );
                setNotificationSubMessage(
                    "Perhaps it was meant for someone else?"
                );
            }

            setShowNotification(true);
        });
    });

    useEffect(() => {
        const accept = async (id: string) => {
            await acceptMembershipShareMutation({
                variables: {
                    customerMembershipShareId: id
                }
            });
        };
        void accept(customerMembershipShareId);
    }, [customerMembershipShareId, acceptMembershipShareMutation]);

    return (
        <div className="o-membership-container">
            <header>
                <h3>Accept a linked membership</h3>
            </header>
            {accepted ? (
                <div className="m-membership fw mt-5">
                    <p>
                        The following membership is now linked to you, and will
                        be availble in your membership list when you check out.
                    </p>

                    <div className="mt-4 pt-4">
                        {customerMembershipShare &&
                            customerMembershipShare.sharedCustomerMembership && (
                                <SummaryCustomerMembershipCard
                                    customerMembership={
                                        customerMembershipShare.sharedCustomerMembership
                                    }
                                />
                            )}
                    </div>
                    <p className="mt-4">
                        You can link your memberships to your friends and family
                        by going to{" "}
                        <Link to="/account/memberships">Memberships</Link> and
                        selecting <strong>Manage linked memberships</strong>
                    </p>
                </div>
            ) : (
                <div className="m-membership fw mt-5">
                    <p>
                        Only the person with whom this membership has been
                        shared can accept it.
                    </p>
                    <p>From what we know, you are not that person.</p>
                </div>
            )}
        </div>
    );
};

export default AcceptSharedMembership;

interface IAcceptSharedMembershipProps {
    match: match;
}
