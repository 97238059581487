import React from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";

interface IPhotoUploadProps {
    base64ImageData: string;
    setBase64ImageData: React.Dispatch<any>;
}

const PhotoUpload = ({
    base64ImageData,
    setBase64ImageData
}: IPhotoUploadProps) => {
    const resizeImg = (
        img: HTMLImageElement,
        maxWidth: number,
        maxHeight: number
    ) => {
        // create an off-screen canvas
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const ratio = Math.min(
            1,
            maxWidth / img.naturalWidth,
            maxHeight / img.naturalHeight
        );

        // set its dimension to target size
        canvas.width = img.naturalWidth * ratio;
        canvas.height = img.naturalHeight * ratio;

        // draw source image into the off-screen canvas:
        ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);

        // encode image to data-uri with base64 version of compressed image
        return canvas.toDataURL();
    };

    const onDrop: DropzoneOptions["onDrop"] = (
        acceptedFiles,
        rejectedFiles
    ) => {
        const reader = new FileReader();
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
            const { result } = reader;

            const img = new Image();
            img.onload = () => {
                const resized = resizeImg(img, 300, 300);
                setBase64ImageData(resized);
            };
            img.src = result?.toString() ?? "";
        };
        acceptedFiles.forEach((file) => reader.readAsDataURL(file));
        rejectedFiles.forEach((fileRejection) => {
            console.log(`Rejected file: ${fileRejection.file.name}`);
        });
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: "image/*",
        onDrop
    });

    return (
        <>
            <div>
                <div
                    data-testid="photo-upload-input"
                    className={
                        "m-photo-upload " + (base64ImageData ? "" : "fh")
                    }
                    {...getRootProps()}
                >
                    <input {...getInputProps()} />

                    {base64ImageData ? (
                        <div>
                            <img
                                style={{ width: "100%" }}
                                src={base64ImageData}
                            />
                        </div>
                    ) : isDragActive ? (
                        <p>Drop the image here</p>
                    ) : (
                        <p>
                            Drag &apos;n&apos; drop an image, or click to select
                            one
                        </p>
                    )}
                </div>
            </div>
        </>
    );
};

export default PhotoUpload;
