import produce from "immer";
import moment from "moment";
import React, { createContext, useContext, useEffect, useState } from "react";
import {
    IActivity,
    IAddToBasketInput,
    IAvailableTime,
    IExtraBasketInput
} from "../../generated/dataInterfaces";
import { useAppContext } from "../Context/AppContext";
import useBasket from "../Context/BasketContext/useBasket";

interface IActivityContextProviderProps {
    activity: IActivity;
    children: any;
}

const ActivityContext = createContext<IActivityContext | null>(null);

export const ActivityContextProvider = ({
    activity,
    children
}: IActivityContextProviderProps) => {
    const { organisationId } = useAppContext();

    const { basketId } = useBasket();

    const [toggle, setToggle] = useState(false);
    const [activityName, setActivityName] = useState<string | null>(null);
    const [activityData, setActivityData] =
        useState<IActivity | null>(activity);
    const [activityTimeData, setActivityTimeData] =
        useState<IAvailableTime[] | null>(null);
    const [selectedDate, setSelectedDate] = useState<string | null>(null);
    const [hasDateChanged, setHasDateChanged] = useState(false);
    const [selectedActivityInstanceId, setSelectedActivityInstanceId] =
        useState<string | null>(null);
    const [selectedActivityInstance, setSelectedActivityInstance] =
        useState<IAvailableTime | null>(null);
    const [ticketQuantity, setTicketQuantity] = useState(1);
    const [extraQuantity, setExtraQuantity] = useState<IExtraBasketInput[]>([]);
    const setExtraQuantityExtended = (extraValue: IExtraBasketInput) => {
        const nextState = produce(extraQuantity, (draftState) => {
            for (let i = 0; i < draftState.length; i++) {
                const item = draftState[i];
                if (extraValue.extraId === item.extraId) {
                    // if so replace
                    item.quantity = extraValue.quantity;
                    return;
                }
            }
            draftState.push(extraValue);
        });
        setExtraQuantity(nextState);
    };
    const [availability, setAvailability] = useState<number | null>(null);

    const [activityDataSet, setActivityDataSet] =
        useState<IAddToBasketInput | null>(null);

    const setSelectedDateExtended = (date: string | null): void => {
        setSelectedDate(moment(date).format("YYYY-MM-DD"));
        setHasDateChanged(true);
        setSelectedActivityInstance(null);
        setSelectedActivityInstanceId(null);
    };

    const setActivityTimeDataExtended = (data: any) => {
        // only change if needed
        if (activityTimeData !== data) {
            setActivityTimeData(data);
        }
        setHasDateChanged(false);
    };

    function bookActivity(): IAddToBasketInput | null {
        if (organisationId && selectedActivityInstanceId) {
            const model: IAddToBasketInput = {
                basketId: basketId ? basketId : null,
                organisationId,
                activity: {
                    activityInstanceId: selectedActivityInstanceId,
                    quantity: ticketQuantity
                },
                extras: extraQuantity
            };
            return model;
        }
        return null;
    }

    useEffect(() => {
        if (activityTimeData) {
            const match = activityTimeData.find((value) => {
                return (
                    selectedActivityInstanceId ===
                    value.activityDetails.activityInstanceId
                );
            });

            if (match) {
                setSelectedActivityInstance(match);
                setAvailability(match.activityDetails.quantityAvailable);
                setExtraQuantity([]);
            }
        }
    }, [selectedActivityInstanceId, activityTimeData]);

    const activityContext: IActivityContext = {
        toggle,
        setToggle,
        activityName,
        setActivityName,
        activityData,
        setActivityData,
        activityTimeData,
        setActivityTimeData: setActivityTimeDataExtended,
        selectedDate,
        setSelectedDate: setSelectedDateExtended,
        selectedActivityInstanceId,
        setSelectedActivityInstanceId,
        selectedActivityInstance,
        setSelectedActivityInstance,
        ticketQuantity,
        setTicketQuantity,
        extraQuantity,
        setExtraQuantity: setExtraQuantityExtended,
        hasDateChanged,
        availability,
        setAvailability,
        activityDataSet,
        setActivityDataSet,
        setHasDateChanged,
        bookActivity
    };

    return (
        <ActivityContext.Provider value={activityContext}>
            {children}
        </ActivityContext.Provider>
    );
};

export const useActivityContext = () => {
    const context = useContext<IActivityContext | null>(ActivityContext);
    if (!context) {
        throw new Error(
            "useActivityContext must be used within a ActivityContextProvider"
        );
    }
    return context;
};

export interface IActivityContext {
    toggle: boolean;
    setToggle: (value: boolean) => void;
    activityName: string | null;
    setActivityName: (value: string | null) => void;
    activityData: IActivity | null;
    setActivityData: (value: IActivity | null) => void;
    activityTimeData: IAvailableTime[] | null;
    setActivityTimeData: (value: IAvailableTime[] | null) => void;
    selectedDate: string | null;
    setSelectedDate: (value: string | null) => void;
    selectedActivityInstanceId: string | null;
    setSelectedActivityInstanceId: (value: string | null) => void;
    selectedActivityInstance: IAvailableTime | null;
    setSelectedActivityInstance: (value: IAvailableTime | null) => void;
    ticketQuantity: number;
    setTicketQuantity: (value: number) => void;
    extraQuantity: IExtraBasketInput[];
    setExtraQuantity: (value: IExtraBasketInput) => void;
    hasDateChanged: boolean;
    availability: number | null;
    setAvailability: (value: number | null) => void;
    activityDataSet: IAddToBasketInput | null;
    setActivityDataSet: (value: IAddToBasketInput | null) => void;
    setHasDateChanged: (value: boolean) => void;
    bookActivity: () => IAddToBasketInput | null;
}
