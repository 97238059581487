import React from "react";
import { useAccountContext } from "../../Context/AccountContext";
import { useAppContext } from "../../Context/AppContext";
import { FormContextProvider } from "../../Context/FormContext";
import AddCredentialForm from "../../CredentialForm/AddCredentialForm";
import EditCredentialForm from "../../CredentialForm/EditCredentialForm";
import Button, {
    BUTTON_COLOR,
    BUTTON_POSITION,
    BUTTON_SIZE
} from "../../Util/Button";
import ToggleBox from "../../Util/ToggleBox";

const Credentials = () => {
    const { setPageTitle } = useAppContext();
    const {
        credential,
        customerCredentials,
        isAddCredentialShowing,
        setIsAddCredentialShowing
    } = useAccountContext();

    setPageTitle("Logins");

    function handleAddCredential() {
        setIsAddCredentialShowing(true);
    }

    if (!credential) {
        return null;
    }

    return (
        <>
            <p>
                Your user details are shown below. To update your details, click
                or tap <strong>Update My Details</strong> below.
                <br />
                <br />
            </p>
            <FormContextProvider>
                <EditCredentialForm />
            </FormContextProvider>

            {credential.isPrimaryCredential ? (
                <>
                    <div className="content">
                        <h4>Other users</h4>
                        <h5>
                            You can add additional users to your account. They
                            will have access to any saved payment cards, and
                            bookings will appear on your account.
                        </h5>
                        <h5>
                            When you add a new user, the person will receive an
                            email asking them to set their password.
                        </h5>
                        {customerCredentials ? (
                            customerCredentials.map((customerCredential, i) => {
                                if (customerCredential.id !== credential.id) {
                                    return (
                                        <ToggleBox key={i} data={credential} />
                                    );
                                }
                                return null;
                            })
                        ) : (
                            <p>
                                You have no other users in your customer account
                            </p>
                        )}
                    </div>

                    <footer>
                        {!isAddCredentialShowing ? (
                            <Button
                                color={BUTTON_COLOR.PRIMARY}
                                title="Add a new user"
                                handleClick={handleAddCredential}
                                size={BUTTON_SIZE.THIRD}
                                position={BUTTON_POSITION.LEFT}
                            />
                        ) : (
                            <FormContextProvider>
                                <AddCredentialForm />
                            </FormContextProvider>
                        )}
                    </footer>
                </>
            ) : null}
        </>
    );
};

export default Credentials;
