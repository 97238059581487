import React from "react";
import { AccountContextProvider } from "../../Context/AccountContext";
import NotificationBar from "../../Util/NotificationBar";
import PageHeader from "../../Util/PageHeader";
import PrivateRoute from "../../Util/PrivateRoute";
import AccountSidebar from "./AccountSidebar";
import Activities from "./Activities/Index";
import Credentials from "./Credentials";
import Dashboard from "./Dashboard";
import Logout from "./Logout";
import Memberships from "./Memberships";
import Profile from "./Profile";
import ActivateMembership from "./Memberships/ActivateMembership";
import EditMembership from "./Memberships/EditMembership";
import ShareMembership from "./Memberships/SharedMembership";
import AcceptSharedMembership from "./Memberships/AcceptSharedMembership";
import { useRouteMatch } from "react-router-dom";

const Account = () => {
    const { path } = useRouteMatch();

    return (
        <AccountContextProvider>
            <div className="fw">
                <PageHeader isAccountPage={true} title={`My Account`} />
                <div className="pw">
                    <div className="clearfix" />
                    <div className="content fw">
                        <AccountSidebar />
                        <div className="two-thirds-container right">
                            <PrivateRoute
                                exact
                                path={`${path}/dashboard`}
                                component={Dashboard}
                            />
                            <PrivateRoute
                                exact
                                path={`${path}/profile`}
                                component={Profile}
                            />
                            <PrivateRoute
                                exact
                                path={`${path}/credentials`}
                                component={Credentials}
                            />
                            <PrivateRoute
                                exact
                                path={`${path}/activities`}
                                component={Activities}
                            />
                            <PrivateRoute
                                exact
                                path={`${path}/memberships/activate/:membershipId`}
                                component={ActivateMembership}
                            />
                            <PrivateRoute
                                exact
                                path={`${path}/memberships/edit/:membershipId`}
                                component={EditMembership}
                            />
                            <PrivateRoute
                                exact
                                path={`${path}/memberships/share/:customerMembershipId`}
                                component={ShareMembership}
                            />
                            <PrivateRoute
                                exact
                                path={`${path}/memberships/acceptshare/:customerMembershipShareId`}
                                component={AcceptSharedMembership}
                            />
                            <PrivateRoute
                                exact
                                path={`${path}/memberships`}
                                component={Memberships}
                            />

                            <PrivateRoute
                                exact
                                path={`${path}/logout`}
                                component={Logout}
                            />
                        </div>
                    </div>
                </div>
                <NotificationBar duration={5000} />
            </div>
        </AccountContextProvider>
    );
};

export default Account;
