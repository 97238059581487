import { useContext } from "react";
import { BasketContext } from "./index";
import { IBasketContext } from "./interfaces";

function useBasket() {
    const context = useContext(BasketContext);
    if (!context) {
        throw new Error(
            "useBasket must be used within a BasketContextProvider"
        );
    }
    const {
        basketId,
        setBasketId,
        basketData,
        couponsApplied,
        removeCouponFromBasket,
        checkIsCustomerMembershipInBasket,
        addItemToBasket,
        removeItemFromBasket,
        destroyBasket,
        renewBasket,
        areThereBasketActivities,
        areThereBasketMemberships,
        areThereBasketItems,
        areBookingNamesRequired,
        areBookingNamesValid,
        itemActivitiesRequiringBookingName,
        basketItemIdBookingNamesPairs,
        setBasketItemIdBookingNamesPairs
    }: IBasketContext = context;
    return {
        basketId,
        setBasketId,
        basketData,
        itemActivitiesRequiringBookingName,
        couponsApplied,
        removeCouponFromBasket,
        checkIsCustomerMembershipInBasket,
        addItemToBasket,
        removeItemFromBasket,
        destroyBasket,
        renewBasket,
        areThereBasketActivities,
        areThereBasketMemberships,
        areThereBasketItems,
        areBookingNamesRequired,
        areBookingNamesValid,
        basketItemIdBookingNamesPairs,
        setBasketItemIdBookingNamesPairs
    };
}

export default useBasket;
