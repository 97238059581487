import { gql } from "@apollo/client";
import queryFields from "./queryField";

const MY_CREDENTIAL_QUERY = gql`
    query myCredential {
        myCredential {
            ${queryFields}
        }
    }
`;

export default MY_CREDENTIAL_QUERY;
