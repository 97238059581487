import React from "react";
import { Redirect } from "react-router-dom";
import Basket from "../../Basket/Basket";
import { useAppContext } from "../../Context/AppContext";
import { useAuthenticated } from "../../Context/AuthContext";
import useBasket from "../../Context/BasketContext/useBasket";
import BreadCrumbs from "../../Util/Breadcrumbs";
import NotificationBar from "../../Util/NotificationBar";
import PageHeader from "../../Util/PageHeader";
import ActivityBookingNames from "./ActivityBookingNames";

const BookingDetails = () => {
    const {
        areBookingNamesRequired,
        itemActivitiesRequiringBookingName,
        basketItemIdBookingNamesPairs,
        setBasketItemIdBookingNamesPairs
    } = useBasket();
    const { isAuthenticated } = useAuthenticated();

    const { entryRoute } = useAppContext();

    if (!areBookingNamesRequired) {
        return <Redirect to={entryRoute} />;
    }

    if (!isAuthenticated) {
        return <Redirect to={"/log-in"} />;
    }

    return (
        <>
            <PageHeader />
            <div className="fw">
                <div className="pw">
                    <BreadCrumbs step={2} />
                    <div className="clearfix" />
                    <div className="content fw">
                        <div className="two-thirds-container">
                            <h2>Booking Details</h2>
                            {itemActivitiesRequiringBookingName.length > 0
                                ? itemActivitiesRequiringBookingName.map(
                                      (itemActivity, index) => {
                                          return (
                                              <ActivityBookingNames
                                                  key={index}
                                                  basketItemActivity={
                                                      itemActivity
                                                  }
                                                  basketItemIdBookingNamesPairs={
                                                      basketItemIdBookingNamesPairs
                                                  }
                                                  setBasketItemIdBookingNamesPairs={
                                                      setBasketItemIdBookingNamesPairs
                                                  }
                                              />
                                          );
                                      }
                                  )
                                : null}
                        </div>
                        <aside
                            id="basket-scroll"
                            className="sidebar third-container right"
                        >
                            <Basket
                                isCheckout={false}
                                shouldShowMembershipWarning={false}
                            />
                        </aside>
                    </div>
                    <footer />
                </div>
            </div>
            <NotificationBar duration={7500} />
        </>
    );
};
export default BookingDetails;
