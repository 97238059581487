import _ from "lodash";
import moment from "moment";
import React from "react";
import { IBooking } from "../../../generated/dataInterfaces";
import Html from "../../Util/Html";

interface IPropsPackageBookingConfirmation {
    bookings: IBooking[];
}

const PackageBookingConfirmation = ({
    bookings
}: IPropsPackageBookingConfirmation) => {
    const orderedBookings = _.orderBy(bookings, ["activityStartsAt"], ["asc"]);
    const firstBooking = orderedBookings[0];

    return (
        <div className="m-booking-confirmation a-box third-container">
            <h3 className="color-brand">
                {firstBooking.activityQuantity} x{" "}
                {firstBooking.activityInstance.activity.name}
            </h3>

            <p className="lead">
                <strong>You have booked for the following dates/times</strong>
            </p>
            <ul className="date-list">
                {orderedBookings.map((booking, i) => {
                    const dateNice: moment.Moment = moment(
                        booking.activityStartsAt
                    ).utc();
                    return (
                        <li key={i}>
                            {dateNice.format("dddd, MMMM Do YYYY")} at{" "}
                            {dateNice.format("h.mm a")}
                        </li>
                    );
                })}
            </ul>

            {firstBooking?.bookingExtras?.map((extra, i) => {
                return (
                    <p key={i}>
                        {extra.quantity} x {extra.description}
                    </p>
                );
            })}

            {firstBooking.bookingNames &&
            firstBooking.bookingNames.length > 0 ? (
                <p className="booking-ref">
                    Name(s)/Details:{" "}
                    <span data-testid="booking-names">
                        {firstBooking.bookingNames.join(", ")}
                    </span>
                </p>
            ) : null}

            <p className="booking-ref">
                Booking reference: <span>{firstBooking.packageReference}</span>
            </p>

            <div className="booking-activity-desc">
                <Html
                    content={
                        firstBooking?.activityInstance?.activity
                            ?.checkoutCompleteText ?? ""
                    }
                />
            </div>
            <div className="clearfix" />
        </div>
    );
};

export default PackageBookingConfirmation;
