import { useMutation } from "@apollo/client";
import React from "react";
import CHECK_IN_MUTATION from "../data/CheckInMutation";

interface ISelfCheckInButtonProps {
    bookingReference: string;
    setIsCheckedIn: (value: boolean) => void;
}

const SelfCheckInButton = ({
    bookingReference,
    setIsCheckedIn
}: ISelfCheckInButtonProps) => {
    const [checkIn] = useMutation(CHECK_IN_MUTATION);

    const checkInClickHandler = async () => {
        const response = await checkIn({
            variables: {
                input: {
                    bookingReference
                }
            }
        });
        if (response.data.selfCheckIn) {
            setIsCheckedIn(response.data.selfCheckIn.isSuccessful);
        }
    };

    return (
        <button
            className="m-btn"
            data-testid="checkin-button"
            onClick={checkInClickHandler}
        >
            Check in now
        </button>
    );
};

export default SelfCheckInButton;
