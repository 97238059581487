import { useMutation } from "@apollo/client";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import MY_CREDENTIAL_QUERY from "../../../../data/Account/MyCredentialQuery";
import ACTIVATE_MEMBERSHIP from "../../../../data/CustomerMembership/ActivateMembership";
import MY_CUSTOMER_MEMBERSHIPS from "../../../../data/CustomerMembership/MyCustomerMemerships";
import {
    IActivateMembershipInput,
    ICustomerMembership
} from "../../../../generated/dataInterfaces";
import { useAccountContext } from "../../../Context/AccountContext";
import { useNotification } from "../../../Context/NotificationContext";
import { InputFieldType } from "../../../Util/InputField";
import { NOTIFICATION_BAR_MESSAGE } from "../../../Util/NotificationBar";
import PhotoUpload from "./PhotoUpload";

const ActivateMembership = () => {
    const { customer } = useAccountContext();
    const {
        setNotificationColor,
        setNotificationMessage,
        setNotificationSubMessage,
        setShowNotification
    } = useNotification();

    const [activateMembership] = useMutation<
        { activateMembership: ICustomerMembership },
        { value: IActivateMembershipInput }
    >(ACTIVATE_MEMBERSHIP, {
        onCompleted({ activateMembership }) {
            if (activateMembership.hasBeenActivated) {
                setNotificationColor(NOTIFICATION_BAR_MESSAGE.SUCCESS);
                setNotificationMessage("Membership activated!");
                setNotificationSubMessage("");
                setShowNotification(true);
            }
        },
        onError(error) {
            setNotificationColor(NOTIFICATION_BAR_MESSAGE.FAILURE);
            setNotificationMessage("Your membership wasn't activated.");
            setNotificationSubMessage("");
            setShowNotification(true);
            console.log(error);
        },
        refetchQueries: [
            {
                query: MY_CREDENTIAL_QUERY
            },
            { query: MY_CUSTOMER_MEMBERSHIPS }
        ]
    });

    const [membershipName, setMembershipName] = useState("");
    const [base64ImageData, setBase64ImageData] = useState(null);
    const { membershipId }: { membershipId?: string } = useParams();

    const currentMembership = useMemo<ICustomerMembership | null>(() => {
        if (customer && customer.memberships && membershipId) {
            const membership: ICustomerMembership | null =
                customer.memberships.find((m) => m.id === membershipId) ?? null;
            return membership;
        }
        return null;
    }, [customer, membershipId]);

    if (!currentMembership) {
        return null;
    }

    if (currentMembership.hasBeenActivated) {
        return <Redirect to="/account/memberships" />;
    }

    const randomNames = [
        "Johnny",
        "Jackie",
        "Jane",
        "Jessica",
        "Jennifer",
        "Jamie",
        "Jessie"
    ];
    const randomName =
        randomNames[Math.floor(Math.random() * randomNames.length)];

    return (
        <div>
            <h3>Activate your membership</h3>
            <div className="clearfix"></div>

            <div className="content">
                <div className="m-form-field">
                    <label>
                        <strong>Membership type</strong>
                    </label>
                    <p className="pb-3">
                        {currentMembership.membership.name}
                        <label>
                            STARTS:{" "}
                            <strong>
                                {moment(currentMembership.completedAt)
                                    .utc()
                                    .format("dddd, MMMM Do YYYY")}
                            </strong>
                            &nbsp;&nbsp;&nbsp; ENDS:{" "}
                            <strong>
                                {moment(currentMembership.expiresAt)
                                    .utc()
                                    .format("dddd, MMMM Do YYYY")}
                            </strong>
                        </label>
                    </p>
                </div>
            </div>

            <div className="content">
                <div className="m-form-field">
                    <label>
                        <strong>Membership name</strong>
                    </label>
                    <input
                        data-testid="membership-name-input"
                        type={InputFieldType.TEXT}
                        placeholder={`e.g. ${randomName}'s ${currentMembership.membership.name}`}
                        value={membershipName}
                        disabled={false}
                        onChange={(e) => {
                            setMembershipName(e.target.value);
                        }}
                    />
                </div>
            </div>
            <div className="clearfix"></div>
            <div className="content">
                <div className="m-form-field">
                    <label>
                        <strong>Photo of member</strong>
                    </label>
                    <div className="clearfix"></div>
                    <PhotoUpload
                        base64ImageData={base64ImageData ?? ""}
                        setBase64ImageData={setBase64ImageData}
                    />
                </div>
            </div>

            <footer className="m-edit-form">
                <div className="m-save-fields ">
                    <div className="half-container left">
                        {base64ImageData ? (
                            <p className="pb-5">
                                Click the image to replace with a new one. Once
                                you&apos;ve activated your membership, you
                                won&apos;t be able to change the picture until
                                you renew your membership.
                            </p>
                        ) : null}

                        <button
                            className="m-btn fw no-icon primary two-thirds-container left"
                            disabled={!base64ImageData}
                            data-testid="activate-membership-button"
                            onClick={async () => {
                                if (
                                    customer &&
                                    membershipName &&
                                    membershipId
                                ) {
                                    await activateMembership({
                                        variables: {
                                            value: {
                                                customerId: customer.id,
                                                customerMembershipId:
                                                    membershipId,
                                                photoInput: {
                                                    base64DataImage:
                                                        base64ImageData ?? ""
                                                },
                                                customerMembershipName:
                                                    membershipName
                                                        ? membershipName
                                                        : `${customer.name}'s ${currentMembership.membership.name}`
                                            }
                                        }
                                    });
                                }
                            }}
                        >
                            Activate your membership
                        </button>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default ActivateMembership;
