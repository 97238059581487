import React, { useEffect, useState } from "react";

const RadioButton = ({
    id,
    value,
    disabled,
    label,
    selectedButtonId,
    setSelectedButtonId,
    dataTestId
}: IRadioButtonProps) => {
    const [active, setActive] = useState(false);

    const handleClick = () => {
        setSelectedButtonId(id);
    };

    useEffect(() => {
        //  check if id is the same as selected, else set active to false
        selectedButtonId === id ? setActive(true) : setActive(false);
    }, [selectedButtonId, id]);

    return (
        <button
            className={`m-radio-button a-box v-box-secondary ${
                active ? "active" : ""
            }`}
            id={id}
            value={value}
            disabled={disabled}
            onClick={handleClick}
            data-testid={dataTestId ?? ""}
        >
            <span>
                {label}
                {disabled ? <small>SOLD OUT</small> : null}
            </span>
        </button>
    );
};
interface IRadioButtonProps {
    id: string;
    value: string;
    label: string;
    disabled?: boolean;
    selectedButtonId: string | null;
    setSelectedButtonId: (id: string) => void;
    dataTestId?: string;
}

export default RadioButton;
