import React from "react";
// import { useAppContext } from "../Context/AppContext";

const SiteFooter = () => {
    const lesOrmesUrl = "https://www.lesormesjersey.co.uk/";

    // const { footerHtml } = useAppContext();

    return (
        // <>
        //     {footerHtml && footerHtml !== "" ?
        //         <div dangerouslySetInnerHTML={footerHtml}></div> : null
        //     }
        // </>

        <footer className="o-footer">
            <div className="pw">
                <div className="full-content-container mb-xl">
                    <div className="half-container">
                        <div className="full-content-container">
                            <div className="half-container">
                                <figure className="fw full-content-container">
                                    <img
                                        className="fw logo"
                                        src="../../images/logo-white.svg"
                                        alt="logo"
                                    />
                                </figure>
                                <div className="fw full-content-container ">
                                    <div className="two-thirds-container">
                                        <ul className="social full-content-container justify-center">
                                            <li className="col-4 facebook">
                                                <a
                                                    href="https://www.facebook.com/lesormesjersey"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <i
                                                        className="fab fa-facebook-f"
                                                        aria-hidden="true"
                                                    ></i>
                                                </a>
                                            </li>
                                            <li className="col-4 instagram">
                                                <a
                                                    href="https://www.instagram.com/lesormes_jersey/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <i
                                                        className="fab fa-instagram"
                                                        aria-hidden="true"
                                                    ></i>
                                                </a>
                                            </li>
                                            <li className="col-4 youtube">
                                                <a
                                                    href="https://www.youtube.com/user/LESORMES1"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <i
                                                        className="fab fa-youtube"
                                                        aria-hidden="true"
                                                    ></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="half-container">
                        <div className="fw full-content-container">
                            <div className="col-3">
                                <h4>Les Ormes</h4>
                                <ul>
                                    <li>
                                        <a
                                            href={lesOrmesUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Home
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={`${lesOrmesUrl}/about-les-ormes/the-resort/`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            The Resort
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={`${lesOrmesUrl}/accommodation`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Accommodation
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={`${lesOrmesUrl}/food-drink`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Food &amp; Drink
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={`${lesOrmesUrl}/events-and-parties`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Events and Parties
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={`${lesOrmesUrl}/help`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Help
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-3">
                                <h4>Get in touch</h4>
                                <ul>
                                    <li>
                                        <a
                                            href={`${lesOrmesUrl}/contact`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Contact
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={`${lesOrmesUrl}/blog`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Blog
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={`${lesOrmesUrl}/about-les-ormes/the-bosdet-foundation`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            The Bosdet Foundation
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-3">
                                <h4>Legal</h4>
                                <ul>
                                    <li>
                                        <a
                                            href={`${lesOrmesUrl}/jersey-visit-safe-scheme`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Jersey Visit Safe Scheme
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={`${lesOrmesUrl}/legal/privacy-policy`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Privacy policy
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={`${lesOrmesUrl}/legal/cookie-policy`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Cookie policy
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={`${lesOrmesUrl}/legal/terms`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Terms &amp; Conditions
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fw full-content-container">
                    <div className="footer-nav-items">
                        <p>
                            <small className="a-footer-link">
                                &copy; 2022 Les Ormes. All Rights Reserved.{" "}
                                <span>
                                    Site by{" "}
                                    <a
                                        href="https://www.webreality.co.uk"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Webreality
                                    </a>
                                    .
                                </span>
                            </small>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default SiteFooter;
