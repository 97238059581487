import { useMutation } from "@apollo/client";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import REQUEST_PASSWORD_RESET_MUTATION from "../../../data/User/RequestPasswordResetMutation";
import { useAppContext } from "../../Context/AppContext";
import { FormContextProvider } from "../../Context/FormContext";
import { useNotification } from "../../Context/NotificationContext";
import Button, { BUTTON_COLOR } from "../../Util/Button";
import InputField, { InputFieldType } from "../../Util/InputField";
import { NOTIFICATION_BAR_MESSAGE } from "../../Util/NotificationBar";
import PageHeader from "../../Util/PageHeader";

const RequestPasswordReset = () => {
    const { organisationId, entryRoute } = useAppContext();
    const emailInputRef = useRef<HTMLInputElement | null>(null);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const {
        setShowNotification,
        setNotificationColor,
        setNotificationMessage,
        setNotificationSubMessage
    } = useNotification();

    const showError = () => {
        setNotificationColor(NOTIFICATION_BAR_MESSAGE.FAILURE);
        setNotificationMessage("There was a problem");
        setNotificationSubMessage("");
        setShowNotification(true);
    };

    const [requestPasswordResetMutation] = useMutation(
        REQUEST_PASSWORD_RESET_MUTATION
    );

    async function requestPasswordResetMutationExtended(): Promise<boolean> {
        try {
            const { data, errors } = await requestPasswordResetMutation({
                variables: {
                    email: emailInputRef?.current?.value,
                    organisationId
                }
            });

            // doesn't matter if null
            if (data) {
                return true;
            }

            if (errors && errors.length > 0) {
                showError();
                return false;
            }
        } catch (e) {
            showError();
            return false;
        }

        return false;
    }

    async function handleRequestClick() {
        if (emailInputRef?.current?.value) {
            const handle = await requestPasswordResetMutationExtended();
            setIsSuccessful(handle);
        }
    }

    return (
        <>
            <div className="fw">
                <PageHeader title="Request a password reset" />
                <div className="pw">
                    <div className="clearfix" />
                    <div className="content fw">
                        <div className="two-thirds-container">
                            <div className="m-login a-box v-box-secondary">
                                <header>
                                    <h3>
                                        {isSuccessful
                                            ? "Thanks. Please check your inbox"
                                            : "Want to reset you password?"}{" "}
                                    </h3>
                                </header>
                                <div className="clearfix" />
                                <div className="password-reset-form">
                                    {isSuccessful ? (
                                        <>
                                            <div className="fw">
                                                <div className="mb-5">
                                                    <p>
                                                        If an account linked to
                                                        that email address
                                                        exists in our records,
                                                        we will send you an
                                                        email to reset your
                                                        password.
                                                    </p>
                                                    <div className="clearfix" />
                                                </div>
                                                <div className="clearfix" />
                                                <Link to={entryRoute}>
                                                    <Button
                                                        title="Back"
                                                        color={
                                                            BUTTON_COLOR.PRIMARY
                                                        }
                                                    />
                                                </Link>
                                            </div>
                                            <div className="clearfix" />
                                        </>
                                    ) : (
                                        <>
                                            <div>
                                                <p>
                                                    Enter your email address,
                                                    and we will send you an
                                                    email with instructions to
                                                    reset your password
                                                </p>

                                                <FormContextProvider>
                                                    <InputField
                                                        label="Email"
                                                        type={
                                                            InputFieldType.EMAIL
                                                        }
                                                        placeholder="Please enter your email"
                                                        reference={
                                                            emailInputRef
                                                        }
                                                        required={true}
                                                    />
                                                    <Button
                                                        title="Submit"
                                                        handleClick={
                                                            handleRequestClick
                                                        }
                                                        color={
                                                            BUTTON_COLOR.PRIMARY
                                                        }
                                                    />
                                                    <div className="clearfix" />
                                                </FormContextProvider>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RequestPasswordReset;
