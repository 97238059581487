import React from "react";

interface IPropsHtml {
    content: string;
}

const Html = ({ content }: IPropsHtml) => {
    return content ? (
        <div
            dangerouslySetInnerHTML={{
                __html: content.replace(
                    /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
                    "illegalscript"
                )
            }}
        />
    ) : null;
};

export default Html;
