import { gql } from "@apollo/client";
import queryFields from "./queryFields";

const REMOVE_FROM_BASKET = gql`
    mutation removeFromBasket($value: RemoveFromBasketInput!) {
        removeFromBasket(input: $value) {
            ${queryFields}
        }
    }
`;

export default REMOVE_FROM_BASKET;
