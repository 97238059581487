import { gql } from "@apollo/client";

const ACCEPT_MEMBERSHIP_SHARE_MUTATION = gql`
    mutation acceptMembershipShare($customerMembershipShareId: ID!) {
        acceptMembershipShare(
            input: { customerMembershipShareId: $customerMembershipShareId }
        ) {
            id
            membershipOwner {
                id
                name
            }
            sharedCustomerMembership {
                id
                name
                hasBeenActivated
                membership {
                    id
                    name
                }
                customer {
                    name
                }
            }
            sharedWithCustomer {
                id
                name
            }
            sharedToEmail
            isConfirmed
            confirmedAt
            createdAt
            updatedAt
        }
    }
`;

export default ACCEPT_MEMBERSHIP_SHARE_MUTATION;
