import React from "react";
import { useAppContext } from "../../Context/AppContext";
import Button, {
    BUTTON_COLOR,
    BUTTON_ICONS,
    BUTTON_POSITION
} from "../../Util/Button";

const Dashboard = () => {
    const { setPageTitle } = useAppContext();

    setPageTitle("Dashboard");

    return (
        <>
            <header>
                <h4>Useful Links</h4>
            </header>
            <div className="content">
                <div className="o-useful-links ">
                    <div className="m-useful-box half-container a-box v-box-secondary">
                        <header>
                            <p className="lead color-brand">Park Map</p>
                        </header>
                        <div className="content">
                            <p>
                                Find your way around the Les Ormes Resort and
                                Jersey easily!
                            </p>
                        </div>
                        <footer>
                            <Button
                                title="View Map"
                                icon={BUTTON_ICONS.NONE}
                                color={BUTTON_COLOR.TERTIARY}
                                position={BUTTON_POSITION.LEFT}
                                link=""
                            />
                        </footer>
                    </div>
                    <div className="m-useful-box half-container a-box v-box-secondary">
                        <header>
                            <p className="lead color-brand">My Activities</p>
                        </header>
                        <div className="content">
                            <p>
                                Find your way around the Les Ormes Resort and
                                Jersey easily!
                            </p>
                        </div>
                        <footer>
                            <Button
                                title="Book now"
                                icon={BUTTON_ICONS.NONE}
                                color={BUTTON_COLOR.TERTIARY}
                                position={BUTTON_POSITION.LEFT}
                                link="activities"
                            />
                        </footer>
                    </div>
                    <div className="m-useful-box half-container a-box v-box-secondary">
                        <header>
                            <p className="lead color-brand">
                                Preparing for your Stay
                            </p>
                        </header>
                        <div className="content">
                            <p>
                                Book activities! You’ll never get bored at Les
                                Ormes, with plenty to do no matter the weather.
                            </p>
                        </div>
                        <footer>
                            <Button
                                title="Find out more"
                                icon={BUTTON_ICONS.NONE}
                                color={BUTTON_COLOR.TERTIARY}
                                position={BUTTON_POSITION.LEFT}
                            />
                        </footer>
                    </div>
                    <div className="m-useful-box half-container a-box v-box-secondary">
                        <header>
                            <p className="lead color-brand">During your Stay</p>
                        </header>
                        <div className="content">
                            <p>
                                Explore Jersey! Where to go and what to do! We
                                have all the information you’ll need!
                            </p>
                        </div>
                        <footer>
                            <Button
                                title="Find out more"
                                icon={BUTTON_ICONS.NONE}
                                color={BUTTON_COLOR.TERTIARY}
                                position={BUTTON_POSITION.LEFT}
                            />
                        </footer>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
