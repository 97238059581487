import { gql } from "@apollo/client";
import queryFields from "./queryField";

const ADD_NEW_CREDENTIAL_TO_CUSTOMER_MUTATION = gql`
    mutation addNewCredentialToMyCustomer(
        $newCredentialEmail: String!,
        $newCredentialFirstName: String!,
        $newCredentialLastName: String!
    ) {
        addNewCredentialToMyCustomer(input: {
            newCredentialEmail: $newCredentialEmail,
            newCredentialFirstName: $newCredentialFirstName,
            newCredentialLastName: $newCredentialLastName
        }) {
            ${queryFields}
        }
    }
`;

export default ADD_NEW_CREDENTIAL_TO_CUSTOMER_MUTATION;
