import React from "react";
import { useAppContext } from "../../Context/AppContext";
import PageHeader from "../../Util/PageHeader";

const Error404Page = () => {
    const { entryRoute } = useAppContext();
    return (
        <>
            <div className="fw">
                <PageHeader title="Oops!" />
                <div className="pw">
                    <div className="clearfix" />
                    <div className="content fw">
                        <div className="two-thirds-container">
                            <div className="m-login a-box v-box-secondary">
                                <header>
                                    <h3>Sorry, this page has gone walkabout</h3>
                                </header>
                                <div className="clearfix" />
                                <p>
                                    Please <a href={entryRoute}>click here</a>{" "}
                                    to go back to the activities listing.
                                </p>

                                <div className="clearfix" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Error404Page;
