import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { useAccountContext } from "../../../Context/AccountContext";
import CustomerMembershipCard from "../../../CustomerMembership/CustomerMembershipCard";

const Memberships = () => {
    const { customer } = useAccountContext();

    if (!customer) {
        return null;
    }

    const customerMemberships = customer.memberships ?? [];
    //console.log(customerMemberships);
    const activeCustomerMemberships = customerMemberships.filter((m) =>
        Boolean(
            m.hasBeenActivated &&
                m.expiresAt &&
                moment.utc().isSameOrBefore(m.expiresAt)
        )
    );
    const expiredCustomerMemberships = customerMemberships.filter((m) => {
        return Boolean(
            m.hasBeenActivated &&
                m.expiresAt &&
                moment.utc().isAfter(moment(m.expiresAt).utc()) &&
                moment(m.expiresAt)
                    .utc()
                    .isBetween(
                        moment().utc().subtract(6, "months"),
                        moment.utc()
                    )
        );
    });
    const inactiveCustomerMemberships = customerMemberships.filter(
        (m) => !m.hasBeenActivated
    );

    const membershipsSharedWithMe = customer.membershipsSharedWithMe;
    const activeMembershipsSharedWithMe =
        membershipsSharedWithMe &&
        membershipsSharedWithMe.filter((m) =>
            Boolean(
                m.isConfirmed &&
                    m.sharedCustomerMembership &&
                    !m.sharedCustomerMembership.isDisabled &&
                    m.sharedCustomerMembership.hasBeenActivated &&
                    m.sharedCustomerMembership.expiresAt &&
                    !moment
                        .utc()
                        .isAfter(
                            moment(m.sharedCustomerMembership.expiresAt).utc()
                        )
            )
        );

    const inactiveMembershipsSharedWithMe =
        membershipsSharedWithMe &&
        membershipsSharedWithMe.filter((m) =>
            Boolean(
                !m.isConfirmed &&
                    m.sharedCustomerMembership &&
                    !m.sharedCustomerMembership.isDisabled &&
                    m.sharedCustomerMembership.hasBeenActivated &&
                    m.sharedCustomerMembership.expiresAt &&
                    !moment
                        .utc()
                        .isAfter(
                            moment(m.sharedCustomerMembership.expiresAt).utc()
                        )
            )
        );

    return (
        <div className="o-membership-container">
            <header>
                <h3>Memberships</h3>
            </header>
            <div>
                {(!inactiveCustomerMemberships ||
                    inactiveCustomerMemberships.length === 0) &&
                (!activeCustomerMemberships ||
                    activeCustomerMemberships.length === 0) ? (
                    <header>
                        <p>
                            You haven&apos;t bought any memberships yet.{" "}
                            <Link to={`/memberships`}>
                                See what&apos;s available.
                            </Link>
                        </p>
                    </header>
                ) : null}

                {inactiveCustomerMemberships &&
                inactiveCustomerMemberships.length > 0 ? (
                    <div className="m-membership fw">
                        <header>
                            <p className="lead">Inactive Memberships</p>
                        </header>

                        {inactiveCustomerMemberships.map((membership) => (
                            <CustomerMembershipCard
                                key={membership.id}
                                customerMembership={membership}
                                isExpired={false}
                                isRenewalAvailable={moment(
                                    membership.renewalAvailableAt
                                )
                                    .utc()
                                    .isBefore(moment().utc())}
                            />
                        ))}
                    </div>
                ) : null}
                {activeCustomerMemberships &&
                activeCustomerMemberships.length > 0 ? (
                    <div className="m-membership fw">
                        <header>
                            <p className="lead">Active Memberships</p>
                        </header>
                        {activeCustomerMemberships.map((membership) => (
                            <CustomerMembershipCard
                                key={membership.id}
                                customerMembership={membership}
                                isExpired={false}
                                isRenewalAvailable={moment(
                                    membership.renewalAvailableAt
                                )
                                    .utc()
                                    .isBefore(moment().utc())}
                            />
                        ))}
                    </div>
                ) : null}
                {expiredCustomerMemberships &&
                expiredCustomerMemberships.length > 0 ? (
                    <div className="m-membership fw">
                        <header>
                            <p className="lead">Expired Memberships</p>
                        </header>
                        {expiredCustomerMemberships.map((membership) => (
                            <CustomerMembershipCard
                                key={membership.id}
                                customerMembership={membership}
                                isExpired={true}
                                isRenewalAvailable={moment(
                                    membership.renewalAvailableAt
                                )
                                    .utc()
                                    .isBefore(moment().utc())}
                            />
                        ))}
                    </div>
                ) : null}
                {activeMembershipsSharedWithMe &&
                activeMembershipsSharedWithMe.length > 0 ? (
                    <div className="m-membership fw">
                        <header>
                            <p className="lead">Linked memberships</p>
                        </header>
                        {activeMembershipsSharedWithMe.map((membership) => (
                            <CustomerMembershipCard
                                key={membership.sharedCustomerMembership.id}
                                customerMembership={
                                    membership.sharedCustomerMembership
                                }
                                isExpired={true}
                                isShared={true}
                                onRemove={() => {
                                    console.log("removed");
                                }}
                                sharingId={membership.id}
                                isRenewalAvailable={false}
                            />
                        ))}
                    </div>
                ) : null}
                {inactiveMembershipsSharedWithMe &&
                inactiveMembershipsSharedWithMe.length > 0 ? (
                    <div className="m-membership fw">
                        <header>
                            <p className="lead">
                                Membership links you need to accept
                            </p>
                        </header>
                        {inactiveMembershipsSharedWithMe.map((membership) => (
                            <CustomerMembershipCard
                                key={membership.sharedCustomerMembership.id}
                                customerMembership={
                                    membership.sharedCustomerMembership
                                }
                                isExpired={true}
                                isShared={true}
                                needSharingApproval={true}
                                onRemove={() => {
                                    console.log("removed");
                                }}
                                sharingId={membership.id}
                                isRenewalAvailable={false}
                            />
                        ))}
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default Memberships;
