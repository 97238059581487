import { gql } from "@apollo/client";
import queryField from "./queryField";

const TOGGLE_CREDENTIAL_MUTATION = gql`
    mutation toggleCredentialOnMyCustomer(
        $targetCredentialId: ID!
    ) {
        toggleCredentialOnMyCustomer(
            input: {
                targetCredentialId: $targetCredentialId
            }
        ) {
            ${queryField}
        }
    }
`;

export default TOGGLE_CREDENTIAL_MUTATION;
