import React, { useState, useEffect } from "react";
import { InputFieldType } from "../Util/InputField";
import { useAccountContext } from "../Context/AccountContext";
import CheckBox from "../Util/CheckBox";
import UpdateField from "../Util/InputField/UpdateField";
import { IUpdateMyCredentialInput } from "../../generated/dataInterfaces";
import { useNotification } from "../Context/NotificationContext";
import { NOTIFICATION_BAR_MESSAGE } from "../Util/NotificationBar";

const EditCredentialForm = () => {
    const { credential, updateMyCredential } = useAccountContext();
    const {
        setNotificationColor,
        setNotificationMessage,
        setNotificationSubMessage,
        setShowNotification
    } = useNotification();

    const [previousData, setPreviousData] =
        useState<ICredentialPreviousData | null>(null);

    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [mobileNumber, setMobileNumber] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const [isReceiveEmailChecked, setIsReceivedEmailChecked] = useState(false);
    const [isReceiveSMSChecked, setIsReceiveSMSChecked] = useState(true);

    const [locked, setLocked] = useState(true);

    useEffect(() => {
        if (credential) {
            setFirstName(credential.firstName);
            setLastName(credential.lastName);
            setEmail(credential.email);
            setMobileNumber(credential.mobileNumber);
            setIsReceiveSMSChecked(credential?.doesReceiveSms ?? false);
            setIsReceivedEmailChecked(credential?.doesReceiveEmails ?? true);
        }
    }, [credential]);

    const onHandleEditClick = async () => {
        // if the customer has edited their details then we need to save them
        if (locked) {
            const pData: ICredentialPreviousData = {
                firstName,
                lastName,
                email,
                mobileNumber,
                receiveEmail: isReceiveEmailChecked,
                receiveSms: isReceiveSMSChecked
            };
            setPreviousData(pData);
        } else {
            if (password && password.length < 6) {
                return;
            }

            // mutation here
            const updateMyCredentialsInput: IUpdateMyCredentialInput = {
                firstName,
                lastName,
                mobileNumber,
                password,
                doesReceiveEmails: isReceiveEmailChecked,
                doesReceiveSms: isReceiveSMSChecked
            };
            const successful = await updateMyCredential({
                variables: updateMyCredentialsInput
            });
            // // set the states
            if (successful) {
                // unlock all fields
                setNotificationColor(NOTIFICATION_BAR_MESSAGE.SUCCESS);
                setNotificationMessage("Your details were updated. ");
                setNotificationSubMessage(
                    password ? "Your password was also changed." : ""
                );
                setShowNotification(true);
            }
        }

        // change button title
        setLocked((e) => !e);
    };

    const onHandleCancelClick = () => {
        // save data to what it was previously
        setFirstName(previousData?.firstName ?? "");
        setLastName(previousData?.lastName ?? "");
        setEmail(previousData?.email ?? "");
        setMobileNumber(previousData?.mobileNumber ?? "");
        setIsReceivedEmailChecked(previousData?.receiveEmail ?? false);
        setIsReceiveSMSChecked(previousData?.receiveSms ?? true);

        // change button title
        setLocked((e) => !e);

        return false;
    };

    if (!credential) {
        return null;
    }

    return (
        <>
            {credential.isPrimaryCredential ? (
                <label>You are the primary user for this account</label>
            ) : null}
            {/* FIRST NAME */}
            <div className="m-form-field">
                <label>
                    <strong>First name</strong>
                </label>
                <input
                    type={InputFieldType.TEXT}
                    placeholder="Please enter your first name"
                    value={firstName}
                    disabled={locked}
                    onChange={(e) => {
                        setFirstName(e.target.value);
                    }}
                />
            </div>
            {/* END FIRST NAME */}
            {/* LAST NAME */}
            <div className="m-form-field">
                <label>
                    <strong>Last name</strong>
                </label>
                <input
                    type={InputFieldType.TEXT}
                    placeholder="Please enter your last name"
                    value={lastName}
                    disabled={locked}
                    onChange={(e) => {
                        setLastName(e.target.value);
                    }}
                />
            </div>
            {/* END LAST NAME */}
            {/* EMAIL */}
            <div className="m-form-field">
                <label>
                    <strong>Email</strong>
                </label>
                <input
                    type={InputFieldType.TEXT}
                    placeholder="Please enter your email"
                    value={email}
                    disabled={true}
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                />
            </div>
            {/* END EMAIL */}
            {/* MOBILE NUMBER */}
            <div className="m-form-field">
                <label>
                    <strong>Mobile number</strong>
                </label>
                <input
                    type={InputFieldType.TEXT}
                    placeholder="Please enter your mobile number"
                    value={mobileNumber}
                    disabled={locked}
                    onChange={(e) => {
                        setMobileNumber(e.target.value);
                    }}
                />
            </div>
            {/* END MOBILE NUMBER */}
            {/* PASSWORD */}
            <div className="m-form-field">
                <label>
                    <strong>Change your password</strong>
                </label>
                <input
                    minLength={8}
                    type={InputFieldType.PASSWORD}
                    placeholder="Only enter a password if you wish to change it. Minimum 6 characters"
                    value={password}
                    disabled={locked}
                    onChange={(e) => {
                        setPassword(e.target.value);
                    }}
                />
            </div>
            {/* END PASSWORD */}
            <div className="clearfix" />
            <div className="mt-2">
                <CheckBox
                    disabled={locked}
                    label="I would like to receive occasional emails from Les Ormes"
                    checked={[isReceiveEmailChecked, setIsReceivedEmailChecked]}
                />
                <CheckBox
                    disabled={locked}
                    label="I would like to receive booking confirmations by SMS"
                    checked={[isReceiveSMSChecked, setIsReceiveSMSChecked]}
                />
            </div>
            <footer className="m-edit-form">
                <UpdateField
                    isHidden={false}
                    lockedMode={locked}
                    handleSaveClick={onHandleEditClick}
                    handleCancelClick={onHandleCancelClick}
                    editTitle="Update my details"
                    saveTitle="Save changes"
                />
            </footer>

            {/* {credential.isPrimaryCredential && !data.isPrimaryCredential ? (
                <footer>
                    {data.isDisabled ? (
                        <Button
                            color={BUTTON_COLOR.PRIMARY}
                            size={BUTTON_SIZE.THIRD}
                            title="Enable credential"
                            handleClick={handleDisableClick}
                        />
                    ) : (
                        <Button
                            color={BUTTON_COLOR.WARNING}
                            size={BUTTON_SIZE.THIRD}
                            title="Disable credential"
                            handleClick={handleDisableClick}
                        />
                    )}
                </footer>
            ) : null} */}
        </>
    );
};

export default EditCredentialForm;

interface ICredentialPreviousData {
    firstName: string;
    lastName: string;
    email: string;
    mobileNumber: string;
    receiveEmail: boolean;
    receiveSms: boolean;
}
