import React, { useState, useEffect } from "react";
import useInterval from "../Util/useInterval";
import moment from "moment";
import useBasket from "../Context/BasketContext/useBasket";

const Timer = () => {
    const [timeLeft, setTimeLeft] = useState<number>(0);
    const [isExpired, setIsExpired] = useState(false);
    const { basketData, destroyBasket } = useBasket();

    useEffect(() => {
        if (basketData) {
            // maybe doing too much calc, but should run too often
            const expiresAt = moment(basketData.expiresAt);
            const now = moment.utc();
            let seconds = moment.duration(expiresAt.diff(now)).asSeconds();

            if (seconds < 0) {
                seconds = 0;
            }

            setTimeLeft(seconds);
            setIsExpired(false);
        }
    }, [basketData]);

    useInterval(() => {
        setTimeLeft(timeLeft - 1);
    }, 1000);

    const convertTime = () => {
        if (timeLeft < 0) {
            setIsExpired(true);
            destroyBasket();
            return "expired";
        }
        return moment.utc(timeLeft * 1000).format("mm:ss");
    };

    return (
        <div className="m-timer">
            <i className="far fa-clock" />
            <label>
                {isExpired ? (
                    <strong>
                        Your basket has expired. Please reload the page and
                        start again.
                    </strong>
                ) : (
                    <>
                        <strong>Time to complete checkout</strong>
                        <span>{convertTime()}</span>
                    </>
                )}
            </label>
        </div>
    );
};

export default Timer;
