import React, { createContext, useContext, useState } from "react";
import { NOTIFICATION_BAR_MESSAGE } from "../Util/NotificationBar";

const NotificationContext = createContext<INotificationContext | null>(null);

export const NotificationContextProvider = ({
    children
}: {
    children: React.ReactNode;
}) => {
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationColor, setNotificationColor] = useState<string>(
        "bg-color-ui-success"
    );
    const [notificationType, setNotificationType] =
        useState<NOTIFICATION_BAR_MESSAGE>(NOTIFICATION_BAR_MESSAGE.SUCCESS);
    const setNotificationColorExtended = (color: NOTIFICATION_BAR_MESSAGE) => {
        setNotificationType(color);
        switch (color) {
            case NOTIFICATION_BAR_MESSAGE.SUCCESS:
                setNotificationColor("bg-color-ui-success");
                break;
            case NOTIFICATION_BAR_MESSAGE.WARNING:
                setNotificationColor("bg-color-ui-warning");
                break;
            case NOTIFICATION_BAR_MESSAGE.FAILURE:
                setNotificationColor("bg-color-ui-highlight");
                break;
        }
    };
    const [notificationMessage, setNotificationMessage] = useState<string>("");
    const [notificationSubMessage, setNotificationSubMessage] =
        useState<string>("");

    const notificationContext: INotificationContext = {
        showNotification,
        setShowNotification,
        notificationColor,
        setNotificationColor: setNotificationColorExtended,
        notificationMessage,
        setNotificationMessage,
        notificationSubMessage,
        setNotificationSubMessage,
        notificationType
    };

    return (
        <NotificationContext.Provider value={notificationContext}>
            {children}
        </NotificationContext.Provider>
    );
};

export interface INotificationContext {
    showNotification: boolean;
    setShowNotification: (value: boolean) => void;
    notificationColor: string;
    setNotificationColor: (value: NOTIFICATION_BAR_MESSAGE) => void;
    notificationMessage: string;
    setNotificationMessage: (value: string) => void;
    notificationSubMessage: string;
    setNotificationSubMessage: (value: string) => void;
    notificationType: NOTIFICATION_BAR_MESSAGE;
}

export default NotificationContext;

const useNotification = () => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error(
            "useNotification must be used within a NotificationContextProvider"
        );
    }
    const {
        showNotification,
        setShowNotification,
        notificationColor,
        setNotificationColor,
        notificationMessage,
        setNotificationMessage,
        notificationSubMessage,
        setNotificationSubMessage,
        notificationType
    } = context;
    return {
        showNotification,
        setShowNotification,
        notificationColor,
        setNotificationColor,
        notificationMessage,
        setNotificationMessage,
        notificationSubMessage,
        setNotificationSubMessage,
        notificationType
    };
};

export { useNotification };
