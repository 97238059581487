import React from "react";

const CheckBox = ({
    checked,
    label,
    link,
    disabled,
    dataTestId = ""
}: ICheckBox) => {
    const [isChecked, setIsChecked] = checked;

    const handleClick = () => {
        if (!disabled) {
            setIsChecked((a) => !a);
        }
    };

    return (
        <>
            <div className="a-checkbox">
                {link ? (
                    <>
                        <label className="container">
                            <span
                                className={`check-box ${
                                    isChecked ? "active" : null
                                }`}
                                onClick={handleClick}
                            />
                            <strong
                                onClick={handleClick}
                                data-testid={dataTestId}
                            >
                                {link.preLinkString}{" "}
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={link.to}
                                >
                                    {link.label}
                                </a>{" "}
                                {link.postLinkString}
                            </strong>
                        </label>
                    </>
                ) : (
                    <label className="container">
                        <span
                            className={`check-box ${
                                isChecked ? "active" : null
                            }`}
                            onClick={handleClick}
                        />
                        <strong onClick={handleClick}>{label}</strong>
                    </label>
                )}
                <div className="clearfix" />
            </div>
        </>
    );
};
export interface ICheckBox {
    checked: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    label?: any;
    disabled?: boolean;
    link?: ILinkBuilder;
    dataTestId?: string;
}

export default CheckBox;

export interface ILinkBuilder {
    preLinkString: string;
    to: string;
    label: string;
    postLinkString?: string;
}
