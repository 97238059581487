import { gql } from "@apollo/client";

const GET_ACTIVITY_TIMES_BY_DATE = gql`
    query activityTimesForDate($activityId: ID!, $date: DateTime) {
        activityTimesForDate(input: { activityId: $activityId, date: $date }) {
            activityId
            date
            timeOfDay
            activityDetails {
                activityInstanceId
                quantityAvailable
                numberPerBooking
                ticketsAvailable
                minTicketsPerBooking
                packageDescription
                price
            }
            extras {
                extraId
                name
                description
                price
            }
        }
    }
`;

export default GET_ACTIVITY_TIMES_BY_DATE;
