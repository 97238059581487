import { useMutation } from "@apollo/client";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import UPDATE_MEMBERSHIP from "../../../../data/CustomerMembership/UpdateMembership";
import {
    ICustomerMembership,
    IUpdateMembershipInput
} from "../../../../generated/dataInterfaces";
import { useAccountContext } from "../../../Context/AccountContext";
import { useNotification } from "../../../Context/NotificationContext";
import { InputFieldType } from "../../../Util/InputField";
import { NOTIFICATION_BAR_MESSAGE } from "../../../Util/NotificationBar";
import PhotoUpload from "./PhotoUpload";

const EditMembership = () => {
    const { membershipId }: { membershipId?: string } = useParams();
    const { customer } = useAccountContext();
    const {
        setNotificationColor,
        setNotificationMessage,
        setNotificationSubMessage,
        setShowNotification
    } = useNotification();
    const [updateMembership, { loading: updateMembershipLoading }] =
        useMutation<
            { updateMembership: ICustomerMembership },
            { value: IUpdateMembershipInput }
        >(UPDATE_MEMBERSHIP, {
            onCompleted({ updateMembership }) {
                if (updateMembership.name === membershipName) {
                    setNotificationColor(NOTIFICATION_BAR_MESSAGE.SUCCESS);
                    setNotificationMessage("Membership updated!");
                    setNotificationSubMessage("");
                    setBase64ImageData(null);
                    setShowNotification(true);
                }
            },
            onError(error) {
                setNotificationColor(NOTIFICATION_BAR_MESSAGE.FAILURE);
                setNotificationMessage("Your membership wasn't updated.");
                setNotificationSubMessage("");
                setShowNotification(true);
                console.log(error);
            }
        });

    const [membershipName, setMembershipName] = useState("");
    const [base64ImageData, setBase64ImageData] = useState<string | null>(null);

    const currentMembership = useMemo<ICustomerMembership | null>(() => {
        if (customer && customer.memberships && membershipId) {
            const membership: ICustomerMembership | null =
                customer.memberships.find((m) => m.id === membershipId) ?? null;
            return membership;
        }

        return null;
    }, [customer, membershipId]);

    useEffect(() => {
        if (!membershipName && currentMembership && currentMembership.name) {
            setMembershipName(currentMembership.name);
        }
    }, [membershipName, currentMembership]);

    if (!currentMembership) {
        return null;
    }

    return (
        <div>
            <h3>Edit your membership</h3>
            <div className="clearfix"></div>
            <div className="content">
                <div className="two-thirds-container">
                    <div className="m-form-field">
                        <label>
                            <strong>Membership type</strong>
                        </label>
                        <p className="pb-3">
                            {currentMembership.membership.name}
                            <label>
                                STARTS:{" "}
                                <strong>
                                    {moment(currentMembership.completedAt)
                                        .utc()
                                        .format("dddd, MMMM Do YYYY")}
                                </strong>
                                &nbsp;&nbsp;&nbsp; ENDS:{" "}
                                <strong>
                                    {moment(currentMembership.expiresAt)
                                        .utc()
                                        .format("dddd, MMMM Do YYYY")}
                                </strong>
                            </label>
                        </p>
                    </div>
                </div>
                <div className="third-container right image">
                    <img
                        alt={currentMembership.name}
                        src={currentMembership?.photo?.base64 ?? ""}
                    />
                </div>
            </div>
            <div className="content">
                <div className="m-form-field">
                    <label>
                        <strong>Membership name</strong>
                    </label>
                    <input
                        type={InputFieldType.TEXT}
                        placeholder="Enter a membership name"
                        value={membershipName}
                        disabled={false}
                        onChange={(e) => {
                            setMembershipName(e.target.value);
                        }}
                    />
                </div>
            </div>{" "}
            <div className="clearfix"></div>
            {currentMembership.canPhotoBeChanged ? (
                <div className="content">
                    <div className="m-form-field">
                        <label>
                            <strong>Photo of member</strong>
                        </label>
                        <div className="clearfix"></div>
                        <PhotoUpload
                            base64ImageData={base64ImageData ?? ""}
                            setBase64ImageData={setBase64ImageData}
                        />
                    </div>
                </div>
            ) : null}
            <footer className="m-edit-form">
                <div className="m-save-fields ">
                    <div className="half-container left">
                        <button
                            className="m-btn fw no-icon primary two-thirds-container left"
                            disabled={
                                !membershipName || updateMembershipLoading
                            }
                            onClick={async () => {
                                if (
                                    customer &&
                                    membershipId &&
                                    membershipName
                                ) {
                                    await updateMembership({
                                        variables: {
                                            value: {
                                                customerId: customer.id,
                                                customerMembershipId:
                                                    membershipId,
                                                customerMembershipName:
                                                    membershipName ||
                                                    `${customer.name}'s ${currentMembership.membership.name}`,
                                                photoInput: base64ImageData
                                                    ? {
                                                          base64DataImage:
                                                              base64ImageData
                                                      }
                                                    : null
                                            }
                                        }
                                    });
                                }
                            }}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default EditMembership;
