import isElementInViewport from "../Util/isElementInViewport";
import scrollBy from "../Util/scrollAnimate";
import React, { useEffect } from "react";

const wireUpBasketButton = () => {
    const indicatorElement = document.getElementById("basket-in-view");

    if (indicatorElement) {
        document.body.onscroll = () => {
            if (isElementInViewport(indicatorElement)) {
                const buttonContainerElement =
                    document.getElementById("basket-link");
                if (buttonContainerElement) {
                    buttonContainerElement.classList.add("basket-in");
                }
            } else {
                const buttonContainerElement =
                    document.getElementById("basket-link");
                if (buttonContainerElement) {
                    buttonContainerElement.classList.remove("basket-in");
                }
            }
        };
    }
};

const ViewBasketButtonMobile = ({
    shouldShow
}: IViewBasketButtonMobileProps) => {
    useEffect(() => {
        wireUpBasketButton();
    });

    return (
        <>
            <div id="basket-in-view" />

            <div
                id="basket-link"
                className={`m-basket-link ${shouldShow ? "" : "hide"}`}
            >
                <button
                    className="m-btn fw"
                    onClick={() => {
                        scrollBy(document.body.scrollHeight, 1500);
                    }}
                >
                    View Basket
                </button>
            </div>
        </>
    );
};

interface IViewBasketButtonMobileProps {
    shouldShow: boolean;
}

export default ViewBasketButtonMobile;
