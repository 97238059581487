import { useQuery } from "@apollo/client";
import moment from "moment";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import GET_TRANSACTION from "../../../data/Transaction/GetTransaction";
import { ITransaction } from "../../../generated/dataInterfaces";
//import TransactionTracking from "../../Analytics/TransactionTracking";
import AutoLogout from "../../Checkout/AutoLogout";
import BookingConfirmation from "../../Checkout/BookingConfirmation";
import PackageBookings from "../../Checkout/Package/PackageBookings";
import useBasket from "../../Context/BasketContext/useBasket";
import CustomerMembershipCard from "../../CustomerMembership/CustomerMembershipCard";
import PageHeader from "../../Util/PageHeader";
import CheckoutCompleteTracking from "../../Analytics/CheckoutCompleteTracking";

/**
 *
 * two methods of entry, one from the check-out page. Another would be from user directly typing the URL or clicking
 * from within the confirmation email
 *
 */
const CheckoutSuccess = () => {
    const { transactionId }: { transactionId?: string } = useParams();
    const { destroyBasket } = useBasket();

    useEffect(() => {
        destroyBasket();
    });

    const { data } = useQuery<{ getTransaction: ITransaction }>(
        GET_TRANSACTION,
        {
            variables: {
                transactionId
            },
            skip: !transactionId
        }
    );

    if (!transactionId || !data || !data.getTransaction) {
        return null;
    }

    const transaction = data.getTransaction;
    const { bookings } = transaction;
    const memberships = transaction.memberships;

    const singleBookings = bookings?.filter(
        (booking) => booking.packageReference === null
    );
    const packageBookings = bookings?.filter(
        (booking) => booking.packageReference !== null
    );

    let title = "";
    let description = (
        <p>
            You will receive an email for each of the bookings below. Please
            show this to a member of staff when you arrive at Les Ormes.
        </p>
    );

    switch (true) {
        case bookings?.length === 1:
            title = "Your booking is complete!";
            break;
        case (bookings?.length ?? 0) > 1:
            title = "Your bookings are complete!";
            break;
        case (memberships?.length ?? 0) >= 1 &&
            memberships?.some((m) => m.canPhotoBeChanged):
            description = (
                <div>
                    <p>
                        You memberships are not activated until you provided a
                        photograph.
                    </p>
                    <p>
                        If you just renewed your membership you can update your
                        photo.
                    </p>
                    <br />
                    <p>
                        You can access your memberships via{" "}
                        <Link to="/account/memberships">My Account</Link>
                    </p>
                </div>
            );
            break;
        default:
            title = "Purchase complete!";
            break;
    }

    return (
        <div className="fw o-transaction-successful">
            <PageHeader title={title} />
            <div className="pw">
                <div className="two-thirds-container">
                    <div className="transaction-content">
                        <p className="lead">
                            <strong>Important Information</strong>
                        </p>
                        <div>{description} </div>
                        <AutoLogout />
                        <div className="clearfix" />
                    </div>
                </div>
                <div className="clearfix" />
                <div className="fw">
                    {singleBookings ? (
                        <div className="m-booking-list">
                            {singleBookings.map((booking, index) => (
                                <BookingConfirmation
                                    key={index}
                                    data={booking}
                                />
                            ))}
                        </div>
                    ) : null}
                    {packageBookings ? (
                        <div className="m-booking-list">
                            <PackageBookings bookings={packageBookings} />
                        </div>
                    ) : null}
                    <div>
                        {memberships?.map((membership) => (
                            <CustomerMembershipCard
                                key={membership.id}
                                customerMembership={membership}
                                isExpired={false}
                                isRenewalAvailable={moment(
                                    membership.renewalAvailableAt
                                )
                                    .utc()
                                    .isBefore(moment().utc())}
                            />
                        ))}
                    </div>
                </div>
            </div>
            {transactionId ? (
                <CheckoutCompleteTracking
                    transactionId={transactionId}
                    transactionTotal={transaction.amount}
                    bookings={bookings ?? []}
                    memberships={memberships ?? []}
                />
            ) : null}
        </div>
    );
};

export default CheckoutSuccess;
