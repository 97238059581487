import React, { useEffect } from "react";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import { useAppContext, useAppRedirect } from "../Context/AppContext";
import { useAuthenticated } from "../Context/AuthContext";
import decodeJwt from "jwt-decode";
import IsLoggedIn from "../Util/IsLoggedIn";
import { use } from "chai";

interface IPrivateRouteProps extends RouteProps {
    component: any;
}

const PrivateRoute = ({
    component: Component,
    ...rest
}: IPrivateRouteProps) => {
    //const { isAuthenticated } = useAuthenticated();
    const { redirectTo, setRedirectTo } = useAppContext();
    const location = useLocation();
    const path = location?.pathname;

    const isLoggedIn = IsLoggedIn();
    console.log("isLoggedIn", isLoggedIn);

    useEffect(() => {
        if (!isLoggedIn) {
            console.log("path", path);
            setRedirectTo(path);
        }
    }, []);

    return (
        <Route
            {...rest}
            render={(props) =>
                isLoggedIn === true ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/log-in",
                            state: { from: path }
                        }}
                    />
                )
            }
        />
    );
};

export default PrivateRoute;
