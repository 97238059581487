import React from "react";
import { ICoupon } from "../../generated/dataInterfaces";
import BasketCouponItem from "./BasketCouponItem";

const BasketCoupons = ({ coupons }: IBasketCouponsProps) => {
    if (!coupons || coupons.length === 0) {
        return null;
    }

    return (
        <div className="coupons">
            <p>
                <strong>Coupons applied</strong>
            </p>
            <div className="clearfix" />
            {coupons.map((coupon, i) => {
                return <BasketCouponItem key={i} coupon={coupon} />;
            })}
            <div className="clearfix" />
        </div>
    );
};

interface IBasketCouponsProps {
    coupons: ICoupon[] | null;
}

export default BasketCoupons;
