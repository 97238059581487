import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ICustomerMembership } from "../../generated/dataInterfaces";
import useCustomerMemberships from "../Context/CustomerMembershipsContext";
import { useNotification } from "../Context/NotificationContext";
import Button, {
    BUTTON_COLOR,
    BUTTON_ICONS,
    BUTTON_POSITION,
    BUTTON_SIZE
} from "../Util/Button";
import { NOTIFICATION_BAR_MESSAGE } from "../Util/NotificationBar";

const CustomerMembershipCard = ({
    customerMembership,
    isExpired,
    isRenewalAvailable,
    isShared,
    needSharingApproval,
    onRemove,
    sharingId
}: IMembershipProps) => {
    const {
        setNotificationColor,
        setNotificationMessage,
        setNotificationSubMessage,
        setShowNotification
    } = useNotification();

    // this is hacky
    const [hide, setHide] = useState(false);

    const { removeMembershipShareMutation, acceptMembershipShareMutation } =
        useCustomerMemberships();

    const removeShareClick = async () => {
        if (!sharingId) {
            return;
        }
        if (
            confirm(
                "Are you sure? You will no longer be able to use this membership on behalf of the other person, unless they decide to share it with you again"
            )
        ) {
            const result = await removeMembershipShareMutation({
                variables: {
                    customerMembershipShareId: sharingId
                }
            });

            if (result) {
                setNotificationColor(NOTIFICATION_BAR_MESSAGE.SUCCESS);
                setNotificationMessage("Membership link removed.");
                setNotificationSubMessage(
                    "You will no longer be able to use this membership"
                );

                setHide(true);
                if (onRemove) {
                    onRemove();
                }
            } else {
                setNotificationColor(NOTIFICATION_BAR_MESSAGE.FAILURE);
                setNotificationMessage("The membership link was not removed.");
            }

            setShowNotification(true);
        }
    };

    const approveShareClick = async () => {
        if (!sharingId) {
            return;
        }
        const result = await acceptMembershipShareMutation({
            variables: {
                customerMembershipShareId: sharingId
            }
        });

        if (result) {
            setNotificationColor(NOTIFICATION_BAR_MESSAGE.SUCCESS);
            setNotificationMessage("Membership link accepted.");
            setNotificationSubMessage(
                "You will be able to use this membership when checking out"
            );
            setHide(true);
        } else {
            setNotificationColor(NOTIFICATION_BAR_MESSAGE.FAILURE);
            setNotificationMessage("The membership link was not accepted.");
        }

        setShowNotification(true);
    };

    if (hide) {
        return null;
    }

    return (
        <div className="m-membership-card">
            <div className="content">
                <div className="left">
                    <header>
                        <h3>
                            {!isShared
                                ? customerMembership.name
                                : customerMembership.customer.name}
                            {customerMembership.hasBeenActivated &&
                            !isShared ? (
                                <label>
                                    <Link
                                        to={`/account/memberships/edit/${customerMembership.id}`}
                                    >
                                        EDIT
                                    </Link>
                                </label>
                            ) : null}
                        </h3>

                        {customerMembership.hasBeenActivated ? (
                            <h6>{customerMembership.membership.name}</h6>
                        ) : null}
                    </header>

                    <label>
                        STARTS:{" "}
                        <strong>
                            {moment(customerMembership.completedAt)
                                .utc()
                                .format("dddd, MMMM Do YYYY")}
                        </strong>
                        &nbsp;&nbsp;&nbsp; ENDS:{" "}
                        <strong>
                            {moment(customerMembership.expiresAt)
                                .utc()
                                .format("dddd, MMMM Do YYYY")}
                        </strong>
                    </label>

                    {!isExpired &&
                    customerMembership.photo &&
                    customerMembership.hasBeenActivated ? (
                        <Link
                            data-testid="activities-on-membership-link"
                            className="m-btn mt-2 v-btn-icon list v-btn-secondary"
                            to={`/membership-activities/${customerMembership.membership.id}`}
                        >
                            View activities
                        </Link>
                    ) : null}

                    {!isExpired &&
                    customerMembership.hasBeenActivated &&
                    customerMembership.membership.canBeCrossAuthorised ? (
                        <Link
                            className="m-btn mt-2 v-btn-icon list v-btn-secondary"
                            to={`/account/memberships/share/${customerMembership.id}`}
                        >
                            Manage linked members
                        </Link>
                    ) : null}

                    {!isExpired && customerMembership.canPhotoBeChanged ? (
                        <Link
                            className="m-btn mt-2 v-btn-icon list v-btn-secondary"
                            to={`/account/memberships/edit/${customerMembership.id}`}
                        >
                            Change photo
                        </Link>
                    ) : null}
                    {isRenewalAvailable ? (
                        <Link
                            className="m-btn mt-2 v-btn-icon list v-btn-secondary"
                            to="/memberships/renewal"
                        >
                            Renew
                        </Link>
                    ) : null}
                </div>
                {customerMembership.photo &&
                customerMembership.hasBeenActivated ? (
                    <div className="right">
                        <div className="image">
                            <img
                                alt={customerMembership.name}
                                src={customerMembership?.photo?.base64 ?? ""}
                            />
                        </div>
                    </div>
                ) : null}
            </div>
            {!isExpired && !customerMembership.hasBeenActivated ? (
                <div className="m-alert fw">
                    Important. Membership not yet active.{" "}
                    <Link
                        data-testid="activate-membership-button"
                        to={`/account/memberships/activate/${customerMembership.id}`}
                    >
                        Activate now
                    </Link>
                </div>
            ) : null}
            {isShared ? (
                <>
                    {needSharingApproval ? (
                        <Button
                            style={{ marginRight: "20px" }}
                            icon={BUTTON_ICONS.SUBMIT}
                            size={BUTTON_SIZE.SMALL}
                            color={BUTTON_COLOR.SUCCESS}
                            position={BUTTON_POSITION.LEFT}
                            handleClick={() => approveShareClick()}
                            title="ACCEPT"
                        />
                    ) : null}
                    <Button
                        icon={BUTTON_ICONS.DELETE}
                        size={BUTTON_SIZE.SMALL}
                        color={BUTTON_COLOR.WARNING}
                        position={BUTTON_POSITION.LEFT}
                        handleClick={() => removeShareClick()}
                        title="Remove"
                    />
                </>
            ) : null}
        </div>
    );
};

interface IMembershipProps {
    customerMembership: ICustomerMembership;
    isExpired: boolean;
    isRenewalAvailable: boolean;
    isShared?: boolean;
    needSharingApproval?: boolean;
    sharingId?: string;
    onRemove?: () => void;
}

export default CustomerMembershipCard;
