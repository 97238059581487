import React, { createContext, useContext, useMemo, useState } from "react";
import { ICompleteTransactionFromBasketResponse } from "../../generated/dataInterfaces";

const TransactionContext = createContext<ITrasactionContext | null>(null);

function TransactionContextProvider({
    children
}: {
    children: React.ReactNode;
}) {
    const [transactionData, setTransactionData] =
        useState<ICompleteTransactionFromBasketResponse | null>(null);
    const [bookingData, setBookingData] = useState(null);

    const values = useMemo(() => {
        return {
            transactionData,
            setTransactionData,
            bookingData,
            setBookingData
        };
    }, [transactionData, bookingData]);

    return (
        <TransactionContext.Provider value={values}>
            {children}
        </TransactionContext.Provider>
    );
}

interface ITrasactionContext {
    transactionData: ICompleteTransactionFromBasketResponse | null;
    setTransactionData: React.Dispatch<
        React.SetStateAction<ICompleteTransactionFromBasketResponse | null>
    >;
    bookingData: null;
    setBookingData: React.Dispatch<React.SetStateAction<null>>;
}

function useTransaction() {
    const context = useContext(TransactionContext);
    if (!context) {
        throw new Error("[UseTransaction] doesn't have any provider");
    }
    const { transactionData, setTransactionData, bookingData, setBookingData } =
        context;
    return { transactionData, setTransactionData, bookingData, setBookingData };
}

export { TransactionContextProvider, useTransaction };
