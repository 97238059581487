import React, { useEffect, useState } from "react";
import useBasket from "../../Context/BasketContext/useBasket";
import useCoupon from "../../Context/BasketContext/useCoupon";
import { useNotification } from "../../Context/NotificationContext";
import { NOTIFICATION_BAR_MESSAGE } from "../../Util/NotificationBar";
import { Link } from "react-router-dom";

const Discount = () => {
    const { applyCouponCode } = useCoupon();
    const [couponCode, setCouponCode] = useState("");
    const { basketId } = useBasket();
    const {
        setNotificationColor,
        setNotificationMessage,
        setNotificationSubMessage,
        setShowNotification
    } = useNotification();

    useEffect(() => {
        setShowNotification(false);
    }, []);

    if (!basketId) {
        return null;
    }

    const handleClick = async () => {
        const { data } = await applyCouponCode({
            variables: { value: { basketId, couponCode } }
        });

        console.log("coupon:", data);

        if (!data?.applyCoupon) {
            setNotificationColor(NOTIFICATION_BAR_MESSAGE.FAILURE);
            setNotificationMessage("No coupon found");
            setNotificationSubMessage(
                "It may have expired or been limited in use"
            );
        } else {
            setNotificationColor(NOTIFICATION_BAR_MESSAGE.SUCCESS);
            setNotificationMessage("Coupon applied successfully");
            setNotificationSubMessage("");
            setShowNotification(true);
        }
        setShowNotification(true);
        setCouponCode("");
    };

    return (
        <div className="m-discount-form">
            <header>
                <h3>Payment</h3>
            </header>
            <div className="clearfix" />
            <div className="fields">
                <div className="m-form-field">
                    <label>
                        <strong>Apply your membership</strong>
                    </label>
                    <div className="left-container">
                        <Link
                            to="/apply-memberships"
                            className="m-btn v-btn-secondary"
                        >
                            Apply Membership Here
                        </Link>
                    </div>
                </div>
            </div>
            <div className="fields">
                <div className="m-form-field">
                    <label>
                        <strong>Do you have a coupon?</strong>
                    </label>
                    <div className="left-container">
                        <input
                            type="text"
                            placeholder="Please enter coupon code"
                            value={couponCode}
                            onChange={(event) => {
                                setCouponCode(event.target.value);
                                console.log(event.target.value);
                            }}
                            data-testid="coupon-input"
                        />
                    </div>
                </div>
                <div className="m-form-field">
                    <div className="right-container">
                        <button
                            className="m-btn v-btn-secondary fw"
                            onClick={handleClick}
                            data-testid="apply-coupon-button"
                        >
                            Apply Coupon
                        </button>
                    </div>
                </div>
            </div>
            <div className="clearfix" />
        </div>
    );
};

export default Discount;
