import React, { useEffect, useState } from "react";
import QuantitySelector from "../Util/QuantitySelector";
import { useActivityContext } from "./ActivityContext";

const TicketSelector = () => {
    const {
        activityData,
        activityName,
        setActivityName,
        setTicketQuantity,
        selectedActivityInstance,
        ticketQuantity
    } = useActivityContext();
    const [price, setPrice] = useState(0);
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);

    useEffect(() => {
        if (selectedActivityInstance) {
            setActivityName(
                activityData?.ticketDescription
                    ? activityData.ticketDescription
                    : activityData?.name ?? null
            );
            setPrice(selectedActivityInstance.activityDetails.price);
            setMin(
                selectedActivityInstance.activityDetails.minTicketsPerBooking
            );
            setMax(selectedActivityInstance.activityDetails.ticketsAvailable);
            // reset the quantity
            setTicketQuantity(
                selectedActivityInstance.activityDetails.minTicketsPerBooking
            );
        }
    }, [
        selectedActivityInstance,
        activityData,
        setActivityName,
        setTicketQuantity,
        setMax,
        setMin
    ]);

    const handleChange = (value: number): void => {
        setTicketQuantity(value);
    };

    return (
        <div className="m-activity-ticket-selector section fw ">
            <label>
                <strong>Select an option</strong>
            </label>
            {selectedActivityInstance ? (
                <div className="fw">
                    <div className="content">
                        <p className="">
                            {ticketQuantity} x {activityName}
                            <br />
                            <strong>£{price * ticketQuantity}</strong>
                        </p>
                    </div>
                    <QuantitySelector
                        min={min}
                        max={max}
                        quantity={ticketQuantity}
                        onChange={handleChange}
                        dataTestIdPrefix="ticket"
                    />
                </div>
            ) : (
                <div className="fw">
                    <div className="content">
                        <p className=" fade">1 x Option</p>
                    </div>
                    <QuantitySelector
                        min={0}
                        max={0}
                        quantity={0}
                        disabled={true}
                        onChange={handleChange}
                    />
                </div>
            )}
        </div>
    );
};

export default TicketSelector;
