import React, { useEffect, useState } from "react";
import { useForm } from "../../Context/FormContext";
import Button, {
    BUTTON_COLOR,
    BUTTON_SIZE,
    BUTTON_POSITION,
    BUTTON_ICONS
} from "../Button";
import UpdateField from "./UpdateField";

const InputField = ({
    type,
    label,
    value,
    placeholder,
    reference,
    required = false,
    partnered,
    update,
    disabled,
    pattern
}: IInputFieldProps) => {
    const { check, setCheck, setReady } = useForm();
    const [isDisabled, setIsDisabled] = useState(disabled);
    const previousValue = value;
    const [currentValue, setCurrentValue] = useState(value);
    const buttonTitle = `update ${label}`;

    useEffect(() => {
        // if check is true do a quick check on the input to make sure it is correct
        if (check) {
            if (!reference.current.value && required) {
                reference.current.classList.add("v-input-warning");
                setReady(false);
                return;
            }
            if (!reference.current.validity.valid) {
                reference.current.classList.add("v-input-warning");
                setReady(false);
                return;
            }
            if (partnered) {
                if (reference.current.value !== partnered.current.value) {
                    reference.current.setCustomValidity(
                        "The fields don't match"
                    );
                    reference.current.classList.add("v-input-warning");
                    setReady(false);
                    return;
                } else {
                    reference.current.setCustomValidity(null);
                }
            }
            setReady(true);
        }
        return () => {
            setCheck(false);
        };
    }, [check, partnered, required, setCheck, reference, setReady]);

    /**
     *  checks if a value has been entered once a required warning has been displayed on the input
     */
    const handleChange = () => {
        if (
            reference.current.classList.contains("v-input-warning") &&
            reference.current.value
        ) {
            reference.current.classList.remove("v-input-warning");
        }
    };

    const updateButtonClick = () => {
        setIsDisabled(false);
    };

    const handleSaveClick = () => {
        // save the new data
        setIsDisabled(true);
    };

    const handleCancelClick = () => {
        setIsDisabled(true);
        setCurrentValue(previousValue);
    };

    return (
        <div className="m-form-field">
            <label>
                <strong>{label}</strong>
            </label>
            <input
                className={update ? "two-thirds-container left" : ""}
                ref={reference}
                type={type}
                placeholder={placeholder}
                onChange={handleChange}
                value={currentValue}
                disabled={isDisabled}
                pattern={pattern}
                required={required}
            />
            {update && isDisabled ? (
                <Button
                    color={BUTTON_COLOR.TERTIARY}
                    title={buttonTitle}
                    size={BUTTON_SIZE.THIRD}
                    icon={BUTTON_ICONS.NONE}
                    position={BUTTON_POSITION.RIGHT}
                    handleClick={updateButtonClick}
                />
            ) : null}
            <div className="clearfix" />
            {update ? (
                <UpdateField
                    isHidden={isDisabled ?? false}
                    handleSaveClick={handleSaveClick}
                    handleCancelClick={handleCancelClick}
                />
            ) : null}
        </div>
    );
};

export default InputField;

export interface IInputFieldProps {
    type: InputFieldType;
    label: string;
    placeholder: string;
    value?: string;
    reference?: any;
    required?: boolean;
    check?: boolean;
    partnered?: any;
    update?: boolean;
    disabled?: boolean;
    pattern?: string;
}

export enum InputFieldType {
    TEXT = "text",
    TEL = "tel",
    TIME = "time",
    WEEK = "week",
    PASSWORD = "password",
    EMAIL = "email",
    HIDDEN = "hidden"
}
