import React from "react";

const QuantitySelector = ({
    min,
    max,
    onChange,
    disabled,
    quantity,
    dataTestIdPrefix = ""
}: IQuantitySelector) => {
    return (
        <div className="m-quantity-selector">
            <div className="fw">
                <div
                    className={`previous ${
                        quantity === min || disabled ? "disabled" : ""
                    }`}
                    onClick={() => {
                        if (quantity <= min) {
                            return;
                        }
                        onChange(quantity - 1);
                    }}
                    data-testid={`${dataTestIdPrefix}-decrement-quantity-element`}
                />
                <div className="current">
                    <p
                        data-testid={`${dataTestIdPrefix}-quantity-element`}
                        className={`lead ${disabled ? "fade" : ""}`}
                    >
                        {quantity}
                    </p>
                </div>
                <div
                    className={`next ${
                        quantity === max || disabled ? "disabled" : ""
                    }`}
                    onClick={() => {
                        if (quantity >= max) {
                            return;
                        }
                        onChange(quantity + 1);
                    }}
                    data-testid={`${dataTestIdPrefix}-increase-quantity-element`}
                />
            </div>
        </div>
    );
};

interface IQuantitySelector {
    min: number;
    max: number;
    quantity: number;
    disabled?: boolean;
    onChange: (value: number) => void;
    dataTestIdPrefix?: string;
}

export default QuantitySelector;
