import { useCallback, useEffect } from "react";

function useInterval(callback: any, delay: number) {
    const savedCallback = useCallback(callback, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback();
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
        return;
    }, [delay, savedCallback]);
}

export default useInterval;
