import produce from "immer";
import moment from "moment";
import React from "react";
import { IBasketItem } from "../../../generated/dataInterfaces";
import { IBasketItemIdBookingNamesPair } from "../../Context/BasketContext/interfaces";
import Html from "../../Util/Html";
import { InputFieldType } from "../../Util/InputField";

interface IActivityBookingNamesProps {
    basketItemActivity: IBasketItem;
    basketItemIdBookingNamesPairs: IBasketItemIdBookingNamesPair[];
    setBasketItemIdBookingNamesPairs: React.Dispatch<
        React.SetStateAction<IBasketItemIdBookingNamesPair[]>
    >;
}

const ActivityBookingNames = ({
    basketItemActivity,
    basketItemIdBookingNamesPairs,
    setBasketItemIdBookingNamesPairs
}: IActivityBookingNamesProps) => {
    const { basketActivity } = basketItemActivity;
    const basketItemIdBookingNamesPairIndex =
        basketItemIdBookingNamesPairs.findIndex(
            (basketItemIdBookingNamesPair) =>
                basketItemIdBookingNamesPair.basketActivityId ===
                basketItemActivity?.basketActivity?.id
        );
    const bookingNames = basketItemIdBookingNamesPairs[
        basketItemIdBookingNamesPairIndex
    ]
        ? basketItemIdBookingNamesPairs[basketItemIdBookingNamesPairIndex]
              .bookingNames
        : [];

    if (!basketActivity) {
        return null;
    }

    return (
        <div className="m-booking-details fw">
            <header className="a-box">
                <h4>{basketActivity.description}</h4>
                <label>
                    {basketActivity.activityInstance.activity.keyInformation}
                </label>
                <div className="clearfix" />
            </header>
            <div>
                <div className="a-box v-box-secondary main">
                    <div className="section fw">
                        <div className="color-text-secondary">
                            <h4>
                                {moment
                                    .utc(basketActivity.startsAt)
                                    .format("dddd, MMMM Do YYYY")}
                            </h4>
                            <p className="activity-info">
                                {`${basketActivity.quantity} x ${
                                    basketActivity.activityInstance.activity
                                        .ticketDescription
                                } at ${moment
                                    .utc(basketActivity.startsAt)
                                    .format("HH:mm")}`}
                            </p>

                            <div className="mt-2 fw">
                                <Html
                                    content={
                                        basketActivity?.activityInstance
                                            ?.activity?.bookingNameInfo ?? ""
                                    }
                                />
                            </div>

                            {Array.from(
                                Array(
                                    basketActivity.activityInstance.activity
                                        .quantityPerBooking *
                                        basketActivity.quantity +
                                        1
                                ).keys()
                            )
                                .slice(1)
                                .map((participantIndex) => {
                                    return (
                                        <div
                                            key={participantIndex}
                                            className="m-form-field"
                                        >
                                            <label>
                                                <strong>
                                                    Details{" "}
                                                    {bookingNames.length > 1
                                                        ? participantIndex
                                                        : ""}
                                                </strong>
                                            </label>
                                            <input
                                                type={InputFieldType.TEXT}
                                                placeholder="Please enter details"
                                                value={
                                                    bookingNames[
                                                        participantIndex - 1
                                                    ]
                                                }
                                                data-testid="booking-name-input"
                                                disabled={false}
                                                onChange={(e) => {
                                                    const nextBasketItemIdBookingNamesPairs =
                                                        produce(
                                                            basketItemIdBookingNamesPairs,
                                                            (
                                                                draftBasketItemIdBookingNamesPairs
                                                            ) => {
                                                                draftBasketItemIdBookingNamesPairs[
                                                                    basketItemIdBookingNamesPairIndex
                                                                ].bookingNames[
                                                                    participantIndex -
                                                                        1
                                                                ] =
                                                                    e.target.value;
                                                            }
                                                        );
                                                    setBasketItemIdBookingNamesPairs(
                                                        nextBasketItemIdBookingNamesPairs
                                                    );
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                    <div className="clearfix" />
                </div>
            </div>
            <div className="clearfix" />
        </div>
    );
};
export default ActivityBookingNames;
