import { useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import GET_ACTIVITY_TIMES_BY_DATE from "../../data/Activity/ActivityByDate";
import useBasket from "../Context/BasketContext/useBasket";
import { useNotification } from "../Context/NotificationContext";
import Html from "../Util/Html";
import { NOTIFICATION_BAR_MESSAGE } from "../Util/NotificationBar";
import { useActivityContext } from "./ActivityContext";
import DateSelector from "./DateSelector";
import ExtraSelector from "./ExtraSelector";
import TicketSelector from "./TicketSelector";
import TimeSelector from "./TimeSelector";

interface IActivityBookingProps {
    dates: string[] | null;
}

const ActivityBooking = ({ dates }: IActivityBookingProps) => {
    const {
        toggle,
        setToggle,
        activityData,
        setActivityName,
        activityTimeData,
        setActivityTimeData,
        selectedActivityInstanceId,
        selectedDate,
        setSelectedDate,
        hasDateChanged,
        bookActivity
    } = useActivityContext();

    const {
        setShowNotification,
        setNotificationColor,
        setNotificationMessage,
        setNotificationSubMessage
    } = useNotification();
    const { addItemToBasket } = useBasket();

    const { data, loading } = useQuery(GET_ACTIVITY_TIMES_BY_DATE, {
        variables: {
            activityId: activityData?.id,
            date: selectedDate
        },
        skip: !hasDateChanged,
        fetchPolicy: "no-cache"
    });

    if (!loading && activityData && data && data.activityTimesForDate) {
        setActivityName(activityData.name);
        setActivityTimeData(data.activityTimesForDate);
    }

    if (activityData === null) {
        return null;
    }
    // console.log(
    //     "🚀 ~ file: ActivityBooking.tsx ~ line 56 ~ ActivityBooking ~ activityData",
    //     activityData
    // );

    const kebabCase = (string) =>
        string
            .replace(/([a-z])([A-Z])/g, "$1-$2")
            .replace(/[\s_]+/g, "-")
            .toLowerCase();

    const handleClick = () => {
        setToggle(toggle ? false : true);
    };

    const handleAddClick = async () => {
        const model = bookActivity();

        if (model) {
            await addItemToBasket({ variables: { value: model } });
            // reset the counts, etc
            setSelectedDate(selectedDate);

            setNotificationMessage(
                "Activity successfully added to your basket"
            );
            setNotificationSubMessage("continue shopping?");
            setNotificationColor(NOTIFICATION_BAR_MESSAGE.SUCCESS);
            setShowNotification(true);
        }
    };

    const extras = activityTimeData?.find(
        (activityInstance) =>
            activityInstance.activityDetails.activityInstanceId ===
            selectedActivityInstanceId
    )?.extras;

    // scroll to
    useEffect(() => {
        const anchor = window.location.hash.slice(1);
        if (anchor) {
            const anchorEl = document.getElementById(anchor);
            if (anchorEl) {
                anchorEl.scrollIntoView();
                setToggle(true);
            }
        }
    }, []);

    return (
        <div
            className="m-activity-booking fw"
            id={kebabCase(activityData.name)}
        >
            <header
                onClick={handleClick}
                className={`a-box ${toggle ? "on" : "off"}`}
                data-testid="activity-accordion"
            >
                <div
                    className="booking-image"
                    style={{ backgroundImage: `url(${activityData.imageUrl})` }}
                ></div>
                <div className="booking-content">
                    <h4 className="bold">{activityData.name}</h4>
                    <p className="bold">
                        {activityData.keyInformation
                            ? activityData.keyInformation
                            : "Activity"}
                    </p>
                </div>
                <div className="clearfix" />
            </header>
            <div className={`toggle-selector ${toggle ? "on" : "off"}`}>
                <div className="fw a-box main">
                    <div className="section fw">
                        <div className="color-text-secondary">
                            <Html content={activityData.description} />
                        </div>
                        {activityData.needHelpUrl ? (
                            <p>
                                <a
                                    href={activityData.needHelpUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Need help?
                                </a>
                            </p>
                        ) : null}
                    </div>
                    <>
                        <DateSelector dates={dates ?? []} />

                        <TimeSelector />

                        <TicketSelector />

                        {extras && extras.length > 0 ? (
                            <div className="m-activity-extra-selector section fw ">
                                <label>
                                    <strong>Select any extras</strong>
                                </label>
                                {extras.map((extra) => {
                                    return (
                                        <ExtraSelector
                                            key={extra.extraId}
                                            data={extra}
                                        />
                                    );
                                })}
                            </div>
                        ) : null}
                    </>

                    <footer className="">
                        <button
                            className="m-btn pull-right"
                            disabled={selectedActivityInstanceId ? false : true}
                            onClick={async () => {
                                await handleAddClick();
                            }}
                            data-testid="add-to-basket-button"
                        >
                            Add to Basket
                        </button>
                    </footer>
                </div>
            </div>
            <div className="clearfix" />
        </div>
    );
};

export default ActivityBooking;
