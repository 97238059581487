import { ApolloProvider } from "@apollo/client";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useAppContext } from "./Context/AppContext";
import { AuthContextProvider } from "./Context/AuthContext";
import { BasketContextProvider } from "./Context/BasketContext";
import { CustomerMembershipsContextProvider } from "./Context/CustomerMembershipsContext";
import { NotificationContextProvider } from "./Context/NotificationContext";
import { TransactionContextProvider } from "./Context/TransactionContext";
import Account from "./Pages/Account";
import ActivitiesSelection from "./Pages/ActivitiesSelection";
import ApplyMemberships from "./Pages/ApplyMemberships";
import BookingDetails from "./Pages/BookingDetails";
import CheckIn from "./Pages/CheckIn";
import CheckoutPage from "./Pages/Checkout/CheckoutPage";
import CheckoutSuccess from "./Pages/Checkout/CheckoutSuccess";
import Error404Page from "./Pages/Error/404";
import LoginPage from "./Pages/Login";
import MembershipsByGroupSelection from "./Pages/MembershipsByGroupSelection";
import MembershipsRenewal from "./Pages/MembershipsRenewal";
import MembershipsSelection from "./Pages/MembershipsSelection";
import RequestPasswordReset from "./Pages/Password/RequestPasswordReset";
import ResetPassword from "./Pages/Password/ResetPassword";
import Register from "./Pages/Register";
//import GaTracking from "./Util/GaTracking";
import PrivateRoute from "./Util/PrivateRoute";
import ScrollToTopOnRouteChange from "./Util/ScrollToTopOnRouteChange";
import SiteFooter from "./Util/SiteFooter";
import CheckoutIntegrate from "./Pages/Checkout/CheckoutIntegrate";
import RedirectToMain from "./Pages/RedirectToMain";

const App = () => {
    const { apolloClient } = useAppContext();

    if (!apolloClient) {
        return null;
    }

    return (
        <ApolloProvider client={apolloClient}>
            <AuthContextProvider>
                <NotificationContextProvider>
                    <TransactionContextProvider>
                        <BasketContextProvider>
                            <CustomerMembershipsContextProvider>
                                <BrowserRouter>
                                    {/* <GaTracking /> */}
                                    <ScrollToTopOnRouteChange />
                                    <Switch>
                                        <Route
                                            exact
                                            path="/"
                                            component={RedirectToMain}
                                        />
                                        <Route
                                            exact
                                            path="/resource/:resourceStub"
                                            component={ActivitiesSelection}
                                        />
                                        <Route
                                            exact
                                            path="/group/:resourceGroupStub"
                                            component={ActivitiesSelection}
                                        />
                                        <Route
                                            exact
                                            path="/facility/:facilityStub"
                                            component={ActivitiesSelection}
                                        />
                                        <Route
                                            exact
                                            path="/membership-activities/:membershipId"
                                            component={ActivitiesSelection}
                                        />

                                        <Route
                                            exact
                                            path="/log-in"
                                            component={LoginPage}
                                        />
                                        <Route
                                            exact
                                            path="/booking-details"
                                            component={BookingDetails}
                                        />
                                        <Route
                                            exact
                                            path="/register"
                                            component={Register}
                                        />
                                        <Route
                                            exact
                                            path="/request-password-reset"
                                            component={RequestPasswordReset}
                                        />
                                        <Route
                                            path="/reset-password/:securityToken"
                                            component={ResetPassword}
                                        />
                                        <PrivateRoute
                                            path="/apply-memberships"
                                            component={ApplyMemberships}
                                        />
                                        <PrivateRoute
                                            path="/check-out/success/:transactionId"
                                            component={CheckoutSuccess}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/check-out"
                                            component={CheckoutPage}
                                        />
                                        <Route
                                            exact
                                            path="/check-out/integrate/:basketId"
                                            component={CheckoutIntegrate}
                                        />
                                        <Route
                                            exact
                                            path="/memberships/groups"
                                            component={
                                                MembershipsByGroupSelection
                                            }
                                        />
                                        <Route
                                            exact
                                            path="/memberships/groups/:membershipGroupStub"
                                            component={
                                                MembershipsByGroupSelection
                                            }
                                        />
                                        <Route
                                            exact
                                            path="/memberships/renewal"
                                            component={MembershipsRenewal}
                                        />

                                        <Route
                                            exact
                                            path="/memberships"
                                            component={MembershipsSelection}
                                        />
                                        <PrivateRoute
                                            path="/account"
                                            component={Account}
                                        />
                                        <Route
                                            exact
                                            path="/check-in/:bookingReference"
                                            component={CheckIn}
                                        />
                                        <Route component={Error404Page} />
                                    </Switch>
                                    <SiteFooter />
                                </BrowserRouter>
                            </CustomerMembershipsContextProvider>
                        </BasketContextProvider>
                    </TransactionContextProvider>
                </NotificationContextProvider>
            </AuthContextProvider>
        </ApolloProvider>
    );
};
export default App;
