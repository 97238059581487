import { gql } from "@apollo/client";

const REQUEST_PASSWORD_RESET_MUTATION = gql`
    mutation requestPasswordReset($email: String!, $organisationId: ID!) {
        requestPasswordReset(
            input: { email: $email, organisationId: $organisationId }
        ) {
            id
            email
        }
    }
`;

export default REQUEST_PASSWORD_RESET_MUTATION;
