import React from "react";
import { IResource } from "../../generated/dataInterfaces";
import Resource from "./Resource";
import ResourceListLoading from "./Loading/ResourceList";

const ResourceList = ({ resources }: IResourceListProps) => {
    return resources ? (
        <>
            {resources.map((resource) => (
                <Resource key={resource.id} resource={resource} />
            ))}
        </>
    ) : (
        <ResourceListLoading />
    );
};

interface IResourceListProps {
    resources: IResource[];
}

export default ResourceList;
