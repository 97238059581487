import React from "react";
import { IExtra } from "../../generated/dataInterfaces";

const BasketItemExtra = ({
    description,
    pricePerItem,
    quantity,
    discountPerItem,
    isItemRemovable
}: IBasketItemExtra) => {
    return (
        <>
            <p>
                {quantity} x {description}
                <span className={isItemRemovable ? "icon" : "no-icon"}>
                    £{(quantity * pricePerItem).toFixed(2)}
                </span>
            </p>
            {discountPerItem ? (
                <p className="discount">
                    Discount
                    <span className={isItemRemovable ? "icon" : "no-icon"}>
                        -£{(discountPerItem * quantity).toFixed(2)}
                    </span>
                </p>
            ) : null}
        </>
    );
};

export default BasketItemExtra;

interface IBasketItemExtra {
    id: string;
    extraId: string;
    description: string;
    pricePerItem: number;
    quantity: number;
    extra: IExtra;
    discountPerItem: number;
    isItemRemovable: boolean;
}
