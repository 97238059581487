import React from "react";
import { IRemoveFromBasketInput } from "../../generated/dataInterfaces";
import useBasket from "../Context/BasketContext/useBasket";

const BasketMembershipItem = ({
    id,
    membershipName,
    quantity,
    currency,
    price,
    isItemRemovable,
    isRenewal
}: IBasketMembershipItemProps) => {
    const { basketId, removeItemFromBasket } = useBasket();
    if (!basketId) {
        return null;
    }

    const handleRemoveClick = async () => {
        const removeItemData: IRemoveFromBasketInput = {
            basketId,
            basketItemId: id
        };
        await removeItemFromBasket({ variables: { value: removeItemData } });
    };

    return (
        <div className="basket-item a-box v-box-secondary">
            <p>
                <strong>{membershipName}</strong>
            </p>
            <p>
                {`${quantity} x Membership${quantity > 1 ? "s" : ""} ${
                    isRenewal ? "Renewal" : ""
                }`}
                <span className={isItemRemovable ? "icon" : "no-icon"}>
                    {currency}
                    {price.toFixed(2)}
                    {isItemRemovable ? (
                        <i
                            onClick={handleRemoveClick}
                            className="far fa-trash-alt color-ui-warning"
                        />
                    ) : null}
                </span>
            </p>

            <div className="clearfix" />
        </div>
    );
};

export default BasketMembershipItem;

interface IBasketMembershipItemProps {
    id: string;
    membershipName: string;
    quantity: number;
    currency: string;
    price: number;
    isItemRemovable: boolean;
    isRenewal: boolean;
}
