import { MutationFunctionOptions, useMutation, useQuery } from "@apollo/client";
import { ExecutionResult } from "graphql";
import React, { createContext, useContext, useMemo, useState } from "react";
import ADD_NEW_CREDENTIAL_TO_CUSTOMER_MUTATION from "../../data/Account/AddNewCredentialToCustomerMutation";
import MY_CREDENTIAL_QUERY from "../../data/Account/MyCredentialQuery";
import TOGGLE_CREDENTIAL_MUTATION from "../../data/Account/ToggleCredentialOnMyCustomerMutation";
import UPDATE_MY_CREDENTIAL_MUTATION from "../../data/Account/UpdateMyCredentialMutation";
import UPDATE_MY_CUSTOMER_MUTATION from "../../data/Account/UpdateMyCustomerMutation";
import {
    IAddNewCredentialToCustomerInput,
    ICredential,
    ICustomer,
    IToggleCredentialInput,
    IUpdateMyCredentialInput,
    IUpdateMyCustomerInput
} from "../../generated/dataInterfaces";
import { NOTIFICATION_BAR_MESSAGE } from "../Util/NotificationBar";
import { useAppContext } from "./AppContext";
import { useNotification } from "./NotificationContext";

const AccountContext = createContext<IAccountContext | null>(null);

function AccountContextProvider({ children }: { children: React.ReactNode }) {
    const { credentialId, customerId } = useAppContext();

    const {
        setShowNotification,
        setNotificationColor,
        setNotificationMessage,
        setNotificationSubMessage
    } = useNotification();

    const [isAddCredentialShowing, setIsAddCredentialShowing] =
        useState<boolean>(false);

    const { data } = useQuery<{ myCredential: ICredential }>(
        MY_CREDENTIAL_QUERY,
        {
            skip: !credentialId && !customerId,
            fetchPolicy: "cache-and-network",
            partialRefetch: true
        }
    );

    const credential: ICredential | null = data ? data.myCredential : null;
    const fullName = credential
        ? `${credential.firstName} ${credential.lastName}`
        : "";
    const customer: ICustomer | null = data ? data.myCredential.customer : null;
    const customerCredentials: ICredential[] | null =
        customer && customer.credentials ? customer.credentials : null;

    const [updateMyCustomer] = useMutation<
        { updateMyCustomer: ICustomer },
        IUpdateMyCustomerInput
    >(UPDATE_MY_CUSTOMER_MUTATION);

    const [addNewCredentialToCustomer] = useMutation<
        { addNewCredentialToMyCustomer: ICredential },
        IAddNewCredentialToCustomerInput
    >(ADD_NEW_CREDENTIAL_TO_CUSTOMER_MUTATION, {
        onCompleted() {
            setNotificationColor(NOTIFICATION_BAR_MESSAGE.SUCCESS);
            setNotificationMessage("Account holder will be emailed to accept");
            setNotificationSubMessage("");
            setShowNotification(true);
        },
        onError(errors) {
            setNotificationColor(NOTIFICATION_BAR_MESSAGE.FAILURE);
            setNotificationMessage(
                "Additional log in failed, please try again"
            );
            setNotificationSubMessage("");
            setShowNotification(true);
            console.log(errors);
        }
    });

    const [updateMyCredential] = useMutation<
        { updateMyCredential: ICredential },
        IUpdateMyCredentialInput
    >(UPDATE_MY_CREDENTIAL_MUTATION);

    const [toggleCredential] = useMutation<
        { toggleCredentialOnMyCustomer: ICredential },
        IToggleCredentialInput
    >(TOGGLE_CREDENTIAL_MUTATION);

    const values = useMemo<IAccountContext | null>(
        () => ({
            credential,
            customer,
            customerCredentials,
            fullName,
            isAddCredentialShowing,
            setIsAddCredentialShowing,
            updateMyCustomer,
            updateMyCredential,
            addNewCredentialToCustomer,
            toggleCredential
        }),
        [
            credential,
            customer,
            customerCredentials,
            fullName,
            isAddCredentialShowing,
            addNewCredentialToCustomer,
            toggleCredential,
            updateMyCredential,
            updateMyCustomer
        ]
    );

    return (
        <AccountContext.Provider value={values}>
            {" "}
            {children}{" "}
        </AccountContext.Provider>
    );
}

interface IAccountContext {
    credential: ICredential | null;
    customer: ICustomer | null;
    customerCredentials: ICredential[] | null;
    fullName: string;
    isAddCredentialShowing: boolean;
    setIsAddCredentialShowing: React.Dispatch<React.SetStateAction<boolean>>;
    updateMyCustomer: (
        options?: MutationFunctionOptions<
            {
                updateMyCustomer: ICustomer;
            },
            IUpdateMyCustomerInput
        >
    ) => Promise<
        ExecutionResult<{
            updateMyCustomer: ICustomer;
        }>
    >;
    updateMyCredential: (
        options?: MutationFunctionOptions<
            {
                updateMyCredential: ICredential;
            },
            IUpdateMyCredentialInput
        >
    ) => Promise<
        ExecutionResult<{
            updateMyCredential: ICredential;
        }>
    >;
    addNewCredentialToCustomer: (
        options?: MutationFunctionOptions<
            {
                addNewCredentialToMyCustomer: ICredential;
            },
            IAddNewCredentialToCustomerInput
        >
    ) => Promise<
        ExecutionResult<{
            addNewCredentialToMyCustomer: ICredential;
        }>
    >;
    toggleCredential: (
        options?: MutationFunctionOptions<
            {
                toggleCredentialOnMyCustomer: ICredential;
            },
            IToggleCredentialInput
        >
    ) => Promise<
        ExecutionResult<{
            toggleCredentialOnMyCustomer: ICredential;
        }>
    >;
}

const useAccountContext = () => {
    const context = useContext(AccountContext);
    if (!context) {
        throw new Error(
            "useAccountContext must be used within a AccountContextProvider"
        );
    }
    const {
        credential,
        customer,
        customerCredentials,
        fullName,
        isAddCredentialShowing,
        setIsAddCredentialShowing,
        updateMyCustomer,
        updateMyCredential,
        addNewCredentialToCustomer,
        toggleCredential
    } = context;
    return {
        credential,
        customer,
        customerCredentials,
        fullName,
        isAddCredentialShowing,
        setIsAddCredentialShowing,
        updateMyCustomer,
        updateMyCredential,
        addNewCredentialToCustomer,
        toggleCredential
    };
};

export { AccountContextProvider, useAccountContext };
