import React from "react";
import { IMembershipGroup } from "../../generated/dataInterfaces";

const MembershipLink = ({ membershipGroup }: IMembershipLinkProp) => {
    if (!membershipGroup) {
        return null;
    }
    return (
        <div className="m-resource-list">
            <h3>{membershipGroup ? membershipGroup.name : "Memberships"}</h3>
            <section>
                <p>
                    We now offer memberships.{" "}
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                            membershipGroup
                                ? `/memberships/groups/${membershipGroup.stub}`
                                : "/memberships/groups/"
                        }
                    >
                        Click here to find out more
                    </a>
                </p>
            </section>
        </div>
    );
};

interface IMembershipLinkProp {
    membershipGroup: IMembershipGroup | null;
}

export default MembershipLink;
