export type Maybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
    { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
    { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    DateTime: any;
    Json: any;
};

export type IAcceptMembershipShareInput = {
    customerMembershipShareId: Scalars["ID"];
};

export type IRenewBasketInput = {
    basketId: Scalars["ID"];
};


export type IActivateMembershipInput = {
    customerId: Scalars["ID"];
    customerMembershipId: Scalars["ID"];
    customerMembershipName?: Maybe<Scalars["String"]>;
    photoInput: IUploadPhotoInput;
};

export type IActivateMembershipResponse = {
    __typename?: "ActivateMembershipResponse";
    isActivated: Scalars["Boolean"];
    base64?: Maybe<Scalars["String"]>;
};

export type IActivity = {
    __typename?: "Activity";
    id: Scalars["ID"];
    resource: IResource;
    packages?: Maybe<Array<IPackage>>;
    activityInstances?: Maybe<Array<IActivityInstance>>;
    activityInstanceTemplates?: Maybe<Array<IActivityInstanceTemplate>>;
    name: Scalars["String"];
    isBookingNameRequired?: Maybe<Scalars["Boolean"]>;
    bookingNameInfo?: Maybe<Scalars["String"]>;
    description: Scalars["String"];
    keyInformation?: Maybe<Scalars["String"]>;
    stub?: Maybe<Scalars["String"]>;
    imageUrl: Scalars["String"];
    needHelpUrl?: Maybe<Scalars["String"]>;
    isAvailable: Scalars["Boolean"];
    isHiddenFromPublic: Scalars["Boolean"];
    defaultSlotLengthMinutes: Scalars["Int"];
    quantityPerBooking: Scalars["Int"];
    minimumQuantityPerBooking: Scalars["Int"];
    bookMinDaysInAdvance: Scalars["Int"];
    bookMaxDaysInAdvance: Scalars["Int"];
    ticketDescription?: Maybe<Scalars["String"]>;
    defaultPricePerBooking: Scalars["Float"];
    colorScheme?: Maybe<Scalars["String"]>;
    ordering: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
    bookingConfirmEmailText?: Maybe<Scalars["String"]>;
    checkoutCompleteText?: Maybe<Scalars["String"]>;
    otherMetadata?: Maybe<Scalars["Json"]>;
    isSelfCheckInEnabled: Scalars["Boolean"];
    howManyMinutesCheckInOpensBeforeActivityStarts: Scalars["Int"];
    howManyMinutesCheckInClosesAfterActivityStarts: Scalars["Int"];
    isPackaged: Scalars["Boolean"];
    mustPreventBookingAfterFirstInstanceIsPast?: Maybe<Scalars["Boolean"]>;
    isDeleted?: Maybe<Scalars["Boolean"]>;
    deletedAt?: Maybe<Scalars["DateTime"]>;
    deletedByUser?: Maybe<IUser>;
    dates: Array<Scalars["DateTime"]>;
};

export type IActivityPackagesArgs = {
    where?: Maybe<IPackageWhereInput>;
    orderBy?: Maybe<IPackageOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type IActivityActivityInstancesArgs = {
    where?: Maybe<IActivityInstanceWhereInput>;
    orderBy?: Maybe<IActivityInstanceOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type IActivityActivityInstanceTemplatesArgs = {
    where?: Maybe<IActivityInstanceTemplateWhereInput>;
    orderBy?: Maybe<IActivityInstanceTemplateOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type IActivityAvailabilityInput = {
    activityId: Scalars["ID"];
    date?: Maybe<Scalars["DateTime"]>;
};

export type IActivityBasketInput = {
    activityInstanceId: Scalars["ID"];
    quantity: Scalars["Int"];
};

export type IActivityInstance = {
    __typename?: "ActivityInstance";
    id: Scalars["ID"];
    activity: IActivity;
    package?: Maybe<IPackage>;
    isFirstOfPackage: Scalars["Boolean"];
    daySchedule: IDaySchedule;
    startTime: Scalars["String"];
    endTime: Scalars["String"];
    quantityUnavailable: Scalars["Int"];
    maxCapacity: Scalars["Int"];
    totalCheckedIn?: Maybe<Scalars["Int"]>;
    pricePerBooking: Scalars["Float"];
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
};

export enum IActivityInstanceOrderByInput {
    IdAsc = "id_ASC",
    IdDesc = "id_DESC",
    IsFirstOfPackageAsc = "isFirstOfPackage_ASC",
    IsFirstOfPackageDesc = "isFirstOfPackage_DESC",
    StartTimeAsc = "startTime_ASC",
    StartTimeDesc = "startTime_DESC",
    EndTimeAsc = "endTime_ASC",
    EndTimeDesc = "endTime_DESC",
    QuantityUnavailableAsc = "quantityUnavailable_ASC",
    QuantityUnavailableDesc = "quantityUnavailable_DESC",
    MaxCapacityAsc = "maxCapacity_ASC",
    MaxCapacityDesc = "maxCapacity_DESC",
    TotalCheckedInAsc = "totalCheckedIn_ASC",
    TotalCheckedInDesc = "totalCheckedIn_DESC",
    PricePerBookingAsc = "pricePerBooking_ASC",
    PricePerBookingDesc = "pricePerBooking_DESC",
    CreatedAtAsc = "createdAt_ASC",
    CreatedAtDesc = "createdAt_DESC",
    UpdatedAtAsc = "updatedAt_ASC",
    UpdatedAtDesc = "updatedAt_DESC"
}

export type IActivityInstanceTemplate = {
    __typename?: "ActivityInstanceTemplate";
    id: Scalars["ID"];
    dayScheduleTemplate: IDayScheduleTemplate;
    activity: IActivity;
    startTime: Scalars["String"];
    endTime: Scalars["String"];
    maxCapacity: Scalars["Int"];
    pricePerBooking: Scalars["Float"];
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
};

export enum IActivityInstanceTemplateOrderByInput {
    IdAsc = "id_ASC",
    IdDesc = "id_DESC",
    StartTimeAsc = "startTime_ASC",
    StartTimeDesc = "startTime_DESC",
    EndTimeAsc = "endTime_ASC",
    EndTimeDesc = "endTime_DESC",
    MaxCapacityAsc = "maxCapacity_ASC",
    MaxCapacityDesc = "maxCapacity_DESC",
    PricePerBookingAsc = "pricePerBooking_ASC",
    PricePerBookingDesc = "pricePerBooking_DESC",
    CreatedAtAsc = "createdAt_ASC",
    CreatedAtDesc = "createdAt_DESC",
    UpdatedAtAsc = "updatedAt_ASC",
    UpdatedAtDesc = "updatedAt_DESC"
}

export type IActivityInstanceTemplateWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    dayScheduleTemplate?: Maybe<IDayScheduleTemplateWhereInput>;
    activity?: Maybe<IActivityWhereInput>;
    startTime?: Maybe<Scalars["String"]>;
    startTime_not?: Maybe<Scalars["String"]>;
    startTime_in?: Maybe<Array<Scalars["String"]>>;
    startTime_not_in?: Maybe<Array<Scalars["String"]>>;
    startTime_lt?: Maybe<Scalars["String"]>;
    startTime_lte?: Maybe<Scalars["String"]>;
    startTime_gt?: Maybe<Scalars["String"]>;
    startTime_gte?: Maybe<Scalars["String"]>;
    startTime_contains?: Maybe<Scalars["String"]>;
    startTime_not_contains?: Maybe<Scalars["String"]>;
    startTime_starts_with?: Maybe<Scalars["String"]>;
    startTime_not_starts_with?: Maybe<Scalars["String"]>;
    startTime_ends_with?: Maybe<Scalars["String"]>;
    startTime_not_ends_with?: Maybe<Scalars["String"]>;
    endTime?: Maybe<Scalars["String"]>;
    endTime_not?: Maybe<Scalars["String"]>;
    endTime_in?: Maybe<Array<Scalars["String"]>>;
    endTime_not_in?: Maybe<Array<Scalars["String"]>>;
    endTime_lt?: Maybe<Scalars["String"]>;
    endTime_lte?: Maybe<Scalars["String"]>;
    endTime_gt?: Maybe<Scalars["String"]>;
    endTime_gte?: Maybe<Scalars["String"]>;
    endTime_contains?: Maybe<Scalars["String"]>;
    endTime_not_contains?: Maybe<Scalars["String"]>;
    endTime_starts_with?: Maybe<Scalars["String"]>;
    endTime_not_starts_with?: Maybe<Scalars["String"]>;
    endTime_ends_with?: Maybe<Scalars["String"]>;
    endTime_not_ends_with?: Maybe<Scalars["String"]>;
    maxCapacity?: Maybe<Scalars["Int"]>;
    maxCapacity_not?: Maybe<Scalars["Int"]>;
    maxCapacity_in?: Maybe<Array<Scalars["Int"]>>;
    maxCapacity_not_in?: Maybe<Array<Scalars["Int"]>>;
    maxCapacity_lt?: Maybe<Scalars["Int"]>;
    maxCapacity_lte?: Maybe<Scalars["Int"]>;
    maxCapacity_gt?: Maybe<Scalars["Int"]>;
    maxCapacity_gte?: Maybe<Scalars["Int"]>;
    pricePerBooking?: Maybe<Scalars["Float"]>;
    pricePerBooking_not?: Maybe<Scalars["Float"]>;
    pricePerBooking_in?: Maybe<Array<Scalars["Float"]>>;
    pricePerBooking_not_in?: Maybe<Array<Scalars["Float"]>>;
    pricePerBooking_lt?: Maybe<Scalars["Float"]>;
    pricePerBooking_lte?: Maybe<Scalars["Float"]>;
    pricePerBooking_gt?: Maybe<Scalars["Float"]>;
    pricePerBooking_gte?: Maybe<Scalars["Float"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    createdAt_not?: Maybe<Scalars["DateTime"]>;
    createdAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_lt?: Maybe<Scalars["DateTime"]>;
    createdAt_lte?: Maybe<Scalars["DateTime"]>;
    createdAt_gt?: Maybe<Scalars["DateTime"]>;
    createdAt_gte?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    updatedAt_not?: Maybe<Scalars["DateTime"]>;
    updatedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_lt?: Maybe<Scalars["DateTime"]>;
    updatedAt_lte?: Maybe<Scalars["DateTime"]>;
    updatedAt_gt?: Maybe<Scalars["DateTime"]>;
    updatedAt_gte?: Maybe<Scalars["DateTime"]>;
    AND?: Maybe<Array<IActivityInstanceTemplateWhereInput>>;
};

export type IActivityInstanceWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    activity?: Maybe<IActivityWhereInput>;
    package?: Maybe<IPackageWhereInput>;
    isFirstOfPackage?: Maybe<Scalars["Boolean"]>;
    isFirstOfPackage_not?: Maybe<Scalars["Boolean"]>;
    daySchedule?: Maybe<IDayScheduleWhereInput>;
    bookings_some?: Maybe<IBookingWhereInput>;
    startTime?: Maybe<Scalars["String"]>;
    startTime_not?: Maybe<Scalars["String"]>;
    startTime_in?: Maybe<Array<Scalars["String"]>>;
    startTime_not_in?: Maybe<Array<Scalars["String"]>>;
    startTime_lt?: Maybe<Scalars["String"]>;
    startTime_lte?: Maybe<Scalars["String"]>;
    startTime_gt?: Maybe<Scalars["String"]>;
    startTime_gte?: Maybe<Scalars["String"]>;
    startTime_contains?: Maybe<Scalars["String"]>;
    startTime_not_contains?: Maybe<Scalars["String"]>;
    startTime_starts_with?: Maybe<Scalars["String"]>;
    startTime_not_starts_with?: Maybe<Scalars["String"]>;
    startTime_ends_with?: Maybe<Scalars["String"]>;
    startTime_not_ends_with?: Maybe<Scalars["String"]>;
    endTime?: Maybe<Scalars["String"]>;
    endTime_not?: Maybe<Scalars["String"]>;
    endTime_in?: Maybe<Array<Scalars["String"]>>;
    endTime_not_in?: Maybe<Array<Scalars["String"]>>;
    endTime_lt?: Maybe<Scalars["String"]>;
    endTime_lte?: Maybe<Scalars["String"]>;
    endTime_gt?: Maybe<Scalars["String"]>;
    endTime_gte?: Maybe<Scalars["String"]>;
    endTime_contains?: Maybe<Scalars["String"]>;
    endTime_not_contains?: Maybe<Scalars["String"]>;
    endTime_starts_with?: Maybe<Scalars["String"]>;
    endTime_not_starts_with?: Maybe<Scalars["String"]>;
    endTime_ends_with?: Maybe<Scalars["String"]>;
    endTime_not_ends_with?: Maybe<Scalars["String"]>;
    quantityUnavailable?: Maybe<Scalars["Int"]>;
    quantityUnavailable_not?: Maybe<Scalars["Int"]>;
    quantityUnavailable_in?: Maybe<Array<Scalars["Int"]>>;
    quantityUnavailable_not_in?: Maybe<Array<Scalars["Int"]>>;
    quantityUnavailable_lt?: Maybe<Scalars["Int"]>;
    quantityUnavailable_lte?: Maybe<Scalars["Int"]>;
    quantityUnavailable_gt?: Maybe<Scalars["Int"]>;
    quantityUnavailable_gte?: Maybe<Scalars["Int"]>;
    maxCapacity?: Maybe<Scalars["Int"]>;
    maxCapacity_not?: Maybe<Scalars["Int"]>;
    maxCapacity_in?: Maybe<Array<Scalars["Int"]>>;
    maxCapacity_not_in?: Maybe<Array<Scalars["Int"]>>;
    maxCapacity_lt?: Maybe<Scalars["Int"]>;
    maxCapacity_lte?: Maybe<Scalars["Int"]>;
    maxCapacity_gt?: Maybe<Scalars["Int"]>;
    maxCapacity_gte?: Maybe<Scalars["Int"]>;
    totalCheckedIn?: Maybe<Scalars["Int"]>;
    totalCheckedIn_not?: Maybe<Scalars["Int"]>;
    totalCheckedIn_in?: Maybe<Array<Scalars["Int"]>>;
    totalCheckedIn_not_in?: Maybe<Array<Scalars["Int"]>>;
    totalCheckedIn_lt?: Maybe<Scalars["Int"]>;
    totalCheckedIn_lte?: Maybe<Scalars["Int"]>;
    totalCheckedIn_gt?: Maybe<Scalars["Int"]>;
    totalCheckedIn_gte?: Maybe<Scalars["Int"]>;
    pricePerBooking?: Maybe<Scalars["Float"]>;
    pricePerBooking_not?: Maybe<Scalars["Float"]>;
    pricePerBooking_in?: Maybe<Array<Scalars["Float"]>>;
    pricePerBooking_not_in?: Maybe<Array<Scalars["Float"]>>;
    pricePerBooking_lt?: Maybe<Scalars["Float"]>;
    pricePerBooking_lte?: Maybe<Scalars["Float"]>;
    pricePerBooking_gt?: Maybe<Scalars["Float"]>;
    pricePerBooking_gte?: Maybe<Scalars["Float"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    createdAt_not?: Maybe<Scalars["DateTime"]>;
    createdAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_lt?: Maybe<Scalars["DateTime"]>;
    createdAt_lte?: Maybe<Scalars["DateTime"]>;
    createdAt_gt?: Maybe<Scalars["DateTime"]>;
    createdAt_gte?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    updatedAt_not?: Maybe<Scalars["DateTime"]>;
    updatedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_lt?: Maybe<Scalars["DateTime"]>;
    updatedAt_lte?: Maybe<Scalars["DateTime"]>;
    updatedAt_gt?: Maybe<Scalars["DateTime"]>;
    updatedAt_gte?: Maybe<Scalars["DateTime"]>;
    AND?: Maybe<Array<IActivityInstanceWhereInput>>;
};

export type IActivityWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    resource?: Maybe<IResourceWhereInput>;
    packages_some?: Maybe<IPackageWhereInput>;
    activityInstances_some?: Maybe<IActivityInstanceWhereInput>;
    activityInstanceTemplates_some?: Maybe<IActivityInstanceTemplateWhereInput>;
    name?: Maybe<Scalars["String"]>;
    name_not?: Maybe<Scalars["String"]>;
    name_in?: Maybe<Array<Scalars["String"]>>;
    name_not_in?: Maybe<Array<Scalars["String"]>>;
    name_lt?: Maybe<Scalars["String"]>;
    name_lte?: Maybe<Scalars["String"]>;
    name_gt?: Maybe<Scalars["String"]>;
    name_gte?: Maybe<Scalars["String"]>;
    name_contains?: Maybe<Scalars["String"]>;
    name_not_contains?: Maybe<Scalars["String"]>;
    name_starts_with?: Maybe<Scalars["String"]>;
    name_not_starts_with?: Maybe<Scalars["String"]>;
    name_ends_with?: Maybe<Scalars["String"]>;
    name_not_ends_with?: Maybe<Scalars["String"]>;
    isBookingNameRequired?: Maybe<Scalars["Boolean"]>;
    isBookingNameRequired_not?: Maybe<Scalars["Boolean"]>;
    bookingNameInfo?: Maybe<Scalars["String"]>;
    bookingNameInfo_not?: Maybe<Scalars["String"]>;
    bookingNameInfo_in?: Maybe<Array<Scalars["String"]>>;
    bookingNameInfo_not_in?: Maybe<Array<Scalars["String"]>>;
    bookingNameInfo_lt?: Maybe<Scalars["String"]>;
    bookingNameInfo_lte?: Maybe<Scalars["String"]>;
    bookingNameInfo_gt?: Maybe<Scalars["String"]>;
    bookingNameInfo_gte?: Maybe<Scalars["String"]>;
    bookingNameInfo_contains?: Maybe<Scalars["String"]>;
    bookingNameInfo_not_contains?: Maybe<Scalars["String"]>;
    bookingNameInfo_starts_with?: Maybe<Scalars["String"]>;
    bookingNameInfo_not_starts_with?: Maybe<Scalars["String"]>;
    bookingNameInfo_ends_with?: Maybe<Scalars["String"]>;
    bookingNameInfo_not_ends_with?: Maybe<Scalars["String"]>;
    description?: Maybe<Scalars["String"]>;
    description_not?: Maybe<Scalars["String"]>;
    description_in?: Maybe<Array<Scalars["String"]>>;
    description_not_in?: Maybe<Array<Scalars["String"]>>;
    description_lt?: Maybe<Scalars["String"]>;
    description_lte?: Maybe<Scalars["String"]>;
    description_gt?: Maybe<Scalars["String"]>;
    description_gte?: Maybe<Scalars["String"]>;
    description_contains?: Maybe<Scalars["String"]>;
    description_not_contains?: Maybe<Scalars["String"]>;
    description_starts_with?: Maybe<Scalars["String"]>;
    description_not_starts_with?: Maybe<Scalars["String"]>;
    description_ends_with?: Maybe<Scalars["String"]>;
    description_not_ends_with?: Maybe<Scalars["String"]>;
    keyInformation?: Maybe<Scalars["String"]>;
    keyInformation_not?: Maybe<Scalars["String"]>;
    keyInformation_in?: Maybe<Array<Scalars["String"]>>;
    keyInformation_not_in?: Maybe<Array<Scalars["String"]>>;
    keyInformation_lt?: Maybe<Scalars["String"]>;
    keyInformation_lte?: Maybe<Scalars["String"]>;
    keyInformation_gt?: Maybe<Scalars["String"]>;
    keyInformation_gte?: Maybe<Scalars["String"]>;
    keyInformation_contains?: Maybe<Scalars["String"]>;
    keyInformation_not_contains?: Maybe<Scalars["String"]>;
    keyInformation_starts_with?: Maybe<Scalars["String"]>;
    keyInformation_not_starts_with?: Maybe<Scalars["String"]>;
    keyInformation_ends_with?: Maybe<Scalars["String"]>;
    keyInformation_not_ends_with?: Maybe<Scalars["String"]>;
    stub?: Maybe<Scalars["String"]>;
    stub_not?: Maybe<Scalars["String"]>;
    stub_in?: Maybe<Array<Scalars["String"]>>;
    stub_not_in?: Maybe<Array<Scalars["String"]>>;
    stub_lt?: Maybe<Scalars["String"]>;
    stub_lte?: Maybe<Scalars["String"]>;
    stub_gt?: Maybe<Scalars["String"]>;
    stub_gte?: Maybe<Scalars["String"]>;
    stub_contains?: Maybe<Scalars["String"]>;
    stub_not_contains?: Maybe<Scalars["String"]>;
    stub_starts_with?: Maybe<Scalars["String"]>;
    stub_not_starts_with?: Maybe<Scalars["String"]>;
    stub_ends_with?: Maybe<Scalars["String"]>;
    stub_not_ends_with?: Maybe<Scalars["String"]>;
    imageUrl?: Maybe<Scalars["String"]>;
    imageUrl_not?: Maybe<Scalars["String"]>;
    imageUrl_in?: Maybe<Array<Scalars["String"]>>;
    imageUrl_not_in?: Maybe<Array<Scalars["String"]>>;
    imageUrl_lt?: Maybe<Scalars["String"]>;
    imageUrl_lte?: Maybe<Scalars["String"]>;
    imageUrl_gt?: Maybe<Scalars["String"]>;
    imageUrl_gte?: Maybe<Scalars["String"]>;
    imageUrl_contains?: Maybe<Scalars["String"]>;
    imageUrl_not_contains?: Maybe<Scalars["String"]>;
    imageUrl_starts_with?: Maybe<Scalars["String"]>;
    imageUrl_not_starts_with?: Maybe<Scalars["String"]>;
    imageUrl_ends_with?: Maybe<Scalars["String"]>;
    imageUrl_not_ends_with?: Maybe<Scalars["String"]>;
    needHelpUrl?: Maybe<Scalars["String"]>;
    needHelpUrl_not?: Maybe<Scalars["String"]>;
    needHelpUrl_in?: Maybe<Array<Scalars["String"]>>;
    needHelpUrl_not_in?: Maybe<Array<Scalars["String"]>>;
    needHelpUrl_lt?: Maybe<Scalars["String"]>;
    needHelpUrl_lte?: Maybe<Scalars["String"]>;
    needHelpUrl_gt?: Maybe<Scalars["String"]>;
    needHelpUrl_gte?: Maybe<Scalars["String"]>;
    needHelpUrl_contains?: Maybe<Scalars["String"]>;
    needHelpUrl_not_contains?: Maybe<Scalars["String"]>;
    needHelpUrl_starts_with?: Maybe<Scalars["String"]>;
    needHelpUrl_not_starts_with?: Maybe<Scalars["String"]>;
    needHelpUrl_ends_with?: Maybe<Scalars["String"]>;
    needHelpUrl_not_ends_with?: Maybe<Scalars["String"]>;
    isAvailable?: Maybe<Scalars["Boolean"]>;
    isAvailable_not?: Maybe<Scalars["Boolean"]>;
    isHiddenFromPublic?: Maybe<Scalars["Boolean"]>;
    isHiddenFromPublic_not?: Maybe<Scalars["Boolean"]>;
    defaultSlotLengthMinutes?: Maybe<Scalars["Int"]>;
    defaultSlotLengthMinutes_not?: Maybe<Scalars["Int"]>;
    defaultSlotLengthMinutes_in?: Maybe<Array<Scalars["Int"]>>;
    defaultSlotLengthMinutes_not_in?: Maybe<Array<Scalars["Int"]>>;
    defaultSlotLengthMinutes_lt?: Maybe<Scalars["Int"]>;
    defaultSlotLengthMinutes_lte?: Maybe<Scalars["Int"]>;
    defaultSlotLengthMinutes_gt?: Maybe<Scalars["Int"]>;
    defaultSlotLengthMinutes_gte?: Maybe<Scalars["Int"]>;
    quantityPerBooking?: Maybe<Scalars["Int"]>;
    quantityPerBooking_not?: Maybe<Scalars["Int"]>;
    quantityPerBooking_in?: Maybe<Array<Scalars["Int"]>>;
    quantityPerBooking_not_in?: Maybe<Array<Scalars["Int"]>>;
    quantityPerBooking_lt?: Maybe<Scalars["Int"]>;
    quantityPerBooking_lte?: Maybe<Scalars["Int"]>;
    quantityPerBooking_gt?: Maybe<Scalars["Int"]>;
    quantityPerBooking_gte?: Maybe<Scalars["Int"]>;
    minimumQuantityPerBooking?: Maybe<Scalars["Int"]>;
    minimumQuantityPerBooking_not?: Maybe<Scalars["Int"]>;
    minimumQuantityPerBooking_in?: Maybe<Array<Scalars["Int"]>>;
    minimumQuantityPerBooking_not_in?: Maybe<Array<Scalars["Int"]>>;
    minimumQuantityPerBooking_lt?: Maybe<Scalars["Int"]>;
    minimumQuantityPerBooking_lte?: Maybe<Scalars["Int"]>;
    minimumQuantityPerBooking_gt?: Maybe<Scalars["Int"]>;
    minimumQuantityPerBooking_gte?: Maybe<Scalars["Int"]>;
    bookMinDaysInAdvance?: Maybe<Scalars["Int"]>;
    bookMinDaysInAdvance_not?: Maybe<Scalars["Int"]>;
    bookMinDaysInAdvance_in?: Maybe<Array<Scalars["Int"]>>;
    bookMinDaysInAdvance_not_in?: Maybe<Array<Scalars["Int"]>>;
    bookMinDaysInAdvance_lt?: Maybe<Scalars["Int"]>;
    bookMinDaysInAdvance_lte?: Maybe<Scalars["Int"]>;
    bookMinDaysInAdvance_gt?: Maybe<Scalars["Int"]>;
    bookMinDaysInAdvance_gte?: Maybe<Scalars["Int"]>;
    bookMaxDaysInAdvance?: Maybe<Scalars["Int"]>;
    bookMaxDaysInAdvance_not?: Maybe<Scalars["Int"]>;
    bookMaxDaysInAdvance_in?: Maybe<Array<Scalars["Int"]>>;
    bookMaxDaysInAdvance_not_in?: Maybe<Array<Scalars["Int"]>>;
    bookMaxDaysInAdvance_lt?: Maybe<Scalars["Int"]>;
    bookMaxDaysInAdvance_lte?: Maybe<Scalars["Int"]>;
    bookMaxDaysInAdvance_gt?: Maybe<Scalars["Int"]>;
    bookMaxDaysInAdvance_gte?: Maybe<Scalars["Int"]>;
    ticketDescription?: Maybe<Scalars["String"]>;
    ticketDescription_not?: Maybe<Scalars["String"]>;
    ticketDescription_in?: Maybe<Array<Scalars["String"]>>;
    ticketDescription_not_in?: Maybe<Array<Scalars["String"]>>;
    ticketDescription_lt?: Maybe<Scalars["String"]>;
    ticketDescription_lte?: Maybe<Scalars["String"]>;
    ticketDescription_gt?: Maybe<Scalars["String"]>;
    ticketDescription_gte?: Maybe<Scalars["String"]>;
    ticketDescription_contains?: Maybe<Scalars["String"]>;
    ticketDescription_not_contains?: Maybe<Scalars["String"]>;
    ticketDescription_starts_with?: Maybe<Scalars["String"]>;
    ticketDescription_not_starts_with?: Maybe<Scalars["String"]>;
    ticketDescription_ends_with?: Maybe<Scalars["String"]>;
    ticketDescription_not_ends_with?: Maybe<Scalars["String"]>;
    defaultPricePerBooking?: Maybe<Scalars["Float"]>;
    defaultPricePerBooking_not?: Maybe<Scalars["Float"]>;
    defaultPricePerBooking_in?: Maybe<Array<Scalars["Float"]>>;
    defaultPricePerBooking_not_in?: Maybe<Array<Scalars["Float"]>>;
    defaultPricePerBooking_lt?: Maybe<Scalars["Float"]>;
    defaultPricePerBooking_lte?: Maybe<Scalars["Float"]>;
    defaultPricePerBooking_gt?: Maybe<Scalars["Float"]>;
    defaultPricePerBooking_gte?: Maybe<Scalars["Float"]>;
    colorScheme?: Maybe<Scalars["String"]>;
    colorScheme_not?: Maybe<Scalars["String"]>;
    colorScheme_in?: Maybe<Array<Scalars["String"]>>;
    colorScheme_not_in?: Maybe<Array<Scalars["String"]>>;
    colorScheme_lt?: Maybe<Scalars["String"]>;
    colorScheme_lte?: Maybe<Scalars["String"]>;
    colorScheme_gt?: Maybe<Scalars["String"]>;
    colorScheme_gte?: Maybe<Scalars["String"]>;
    colorScheme_contains?: Maybe<Scalars["String"]>;
    colorScheme_not_contains?: Maybe<Scalars["String"]>;
    colorScheme_starts_with?: Maybe<Scalars["String"]>;
    colorScheme_not_starts_with?: Maybe<Scalars["String"]>;
    colorScheme_ends_with?: Maybe<Scalars["String"]>;
    colorScheme_not_ends_with?: Maybe<Scalars["String"]>;
    ordering?: Maybe<Scalars["Int"]>;
    ordering_not?: Maybe<Scalars["Int"]>;
    ordering_in?: Maybe<Array<Scalars["Int"]>>;
    ordering_not_in?: Maybe<Array<Scalars["Int"]>>;
    ordering_lt?: Maybe<Scalars["Int"]>;
    ordering_lte?: Maybe<Scalars["Int"]>;
    ordering_gt?: Maybe<Scalars["Int"]>;
    ordering_gte?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    createdAt_not?: Maybe<Scalars["DateTime"]>;
    createdAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_lt?: Maybe<Scalars["DateTime"]>;
    createdAt_lte?: Maybe<Scalars["DateTime"]>;
    createdAt_gt?: Maybe<Scalars["DateTime"]>;
    createdAt_gte?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    updatedAt_not?: Maybe<Scalars["DateTime"]>;
    updatedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_lt?: Maybe<Scalars["DateTime"]>;
    updatedAt_lte?: Maybe<Scalars["DateTime"]>;
    updatedAt_gt?: Maybe<Scalars["DateTime"]>;
    updatedAt_gte?: Maybe<Scalars["DateTime"]>;
    bookingConfirmEmailText?: Maybe<Scalars["String"]>;
    bookingConfirmEmailText_not?: Maybe<Scalars["String"]>;
    bookingConfirmEmailText_in?: Maybe<Array<Scalars["String"]>>;
    bookingConfirmEmailText_not_in?: Maybe<Array<Scalars["String"]>>;
    bookingConfirmEmailText_lt?: Maybe<Scalars["String"]>;
    bookingConfirmEmailText_lte?: Maybe<Scalars["String"]>;
    bookingConfirmEmailText_gt?: Maybe<Scalars["String"]>;
    bookingConfirmEmailText_gte?: Maybe<Scalars["String"]>;
    bookingConfirmEmailText_contains?: Maybe<Scalars["String"]>;
    bookingConfirmEmailText_not_contains?: Maybe<Scalars["String"]>;
    bookingConfirmEmailText_starts_with?: Maybe<Scalars["String"]>;
    bookingConfirmEmailText_not_starts_with?: Maybe<Scalars["String"]>;
    bookingConfirmEmailText_ends_with?: Maybe<Scalars["String"]>;
    bookingConfirmEmailText_not_ends_with?: Maybe<Scalars["String"]>;
    checkoutCompleteText?: Maybe<Scalars["String"]>;
    checkoutCompleteText_not?: Maybe<Scalars["String"]>;
    checkoutCompleteText_in?: Maybe<Array<Scalars["String"]>>;
    checkoutCompleteText_not_in?: Maybe<Array<Scalars["String"]>>;
    checkoutCompleteText_lt?: Maybe<Scalars["String"]>;
    checkoutCompleteText_lte?: Maybe<Scalars["String"]>;
    checkoutCompleteText_gt?: Maybe<Scalars["String"]>;
    checkoutCompleteText_gte?: Maybe<Scalars["String"]>;
    checkoutCompleteText_contains?: Maybe<Scalars["String"]>;
    checkoutCompleteText_not_contains?: Maybe<Scalars["String"]>;
    checkoutCompleteText_starts_with?: Maybe<Scalars["String"]>;
    checkoutCompleteText_not_starts_with?: Maybe<Scalars["String"]>;
    checkoutCompleteText_ends_with?: Maybe<Scalars["String"]>;
    checkoutCompleteText_not_ends_with?: Maybe<Scalars["String"]>;
    isSelfCheckInEnabled?: Maybe<Scalars["Boolean"]>;
    isSelfCheckInEnabled_not?: Maybe<Scalars["Boolean"]>;
    howManyMinutesCheckInOpensBeforeActivityStarts?: Maybe<Scalars["Int"]>;
    howManyMinutesCheckInOpensBeforeActivityStarts_not?: Maybe<Scalars["Int"]>;
    howManyMinutesCheckInOpensBeforeActivityStarts_in?: Maybe<
        Array<Scalars["Int"]>
    >;
    howManyMinutesCheckInOpensBeforeActivityStarts_not_in?: Maybe<
        Array<Scalars["Int"]>
    >;
    howManyMinutesCheckInOpensBeforeActivityStarts_lt?: Maybe<Scalars["Int"]>;
    howManyMinutesCheckInOpensBeforeActivityStarts_lte?: Maybe<Scalars["Int"]>;
    howManyMinutesCheckInOpensBeforeActivityStarts_gt?: Maybe<Scalars["Int"]>;
    howManyMinutesCheckInOpensBeforeActivityStarts_gte?: Maybe<Scalars["Int"]>;
    howManyMinutesCheckInClosesAfterActivityStarts?: Maybe<Scalars["Int"]>;
    howManyMinutesCheckInClosesAfterActivityStarts_not?: Maybe<Scalars["Int"]>;
    howManyMinutesCheckInClosesAfterActivityStarts_in?: Maybe<
        Array<Scalars["Int"]>
    >;
    howManyMinutesCheckInClosesAfterActivityStarts_not_in?: Maybe<
        Array<Scalars["Int"]>
    >;
    howManyMinutesCheckInClosesAfterActivityStarts_lt?: Maybe<Scalars["Int"]>;
    howManyMinutesCheckInClosesAfterActivityStarts_lte?: Maybe<Scalars["Int"]>;
    howManyMinutesCheckInClosesAfterActivityStarts_gt?: Maybe<Scalars["Int"]>;
    howManyMinutesCheckInClosesAfterActivityStarts_gte?: Maybe<Scalars["Int"]>;
    isPackaged?: Maybe<Scalars["Boolean"]>;
    isPackaged_not?: Maybe<Scalars["Boolean"]>;
    mustPreventBookingAfterFirstInstanceIsPast?: Maybe<Scalars["Boolean"]>;
    mustPreventBookingAfterFirstInstanceIsPast_not?: Maybe<Scalars["Boolean"]>;
    isDeleted?: Maybe<Scalars["Boolean"]>;
    isDeleted_not?: Maybe<Scalars["Boolean"]>;
    deletedAt?: Maybe<Scalars["DateTime"]>;
    deletedAt_not?: Maybe<Scalars["DateTime"]>;
    deletedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    deletedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    deletedAt_lt?: Maybe<Scalars["DateTime"]>;
    deletedAt_lte?: Maybe<Scalars["DateTime"]>;
    deletedAt_gt?: Maybe<Scalars["DateTime"]>;
    deletedAt_gte?: Maybe<Scalars["DateTime"]>;
    deletedByUser?: Maybe<IUserWhereInput>;
    AND?: Maybe<Array<IActivityWhereInput>>;
};

export type IAddNewCredentialToCustomerInput = {
    newCredentialEmail: Scalars["String"];
    newCredentialFirstName: Scalars["String"];
    newCredentialLastName: Scalars["String"];
};

export type IAddToBasketInput = {
    basketId?: Maybe<Scalars["ID"]>;
    organisationId: Scalars["ID"];
    activity?: Maybe<IActivityBasketInput>;
    membership?: Maybe<IMembershipBasketInput>;
    extras?: Maybe<Array<IExtraBasketInput>>;
    overbookingAllowed?: Maybe<Scalars["Boolean"]>;
};

export type IApplyCouponInput = {
    basketId: Scalars["ID"];
    couponCode: Scalars["String"];
};

export type IApplyMembershipInput = {
    customerId: Scalars["ID"];
    customerMembershipId: Scalars["String"];
    basketId: Scalars["ID"];
    basketActivityId: Scalars["ID"];
    quantityIndex: Scalars["Int"];
    shouldRemoveCustomerMembership: Scalars["Boolean"];
};

export type IAssignBasketToCustomerInput = {
    basketId: Scalars["ID"];
    customerId: Scalars["ID"];
};

export type IAuthenticateInput = {
    organisationId: Scalars["ID"];
    username: Scalars["String"];
    password: Scalars["String"];
};

export type IAuthPayload = {
    __typename?: "AuthPayload";
    token: Scalars["String"];
};

export type IAvailableTime = {
    __typename?: "AvailableTime";
    activityId: Scalars["ID"];
    activityInstanceId: Scalars["ID"];
    date: Scalars["String"];
    timeOfDay: Scalars["String"];
    activityDetails: IBookableActivity;
    extras: Array<IBookableExtra>;
};

export type IBasket = {
    __typename?: "Basket";
    id: Scalars["ID"];
    organisation: IOrganisation;
    expiresAt: Scalars["DateTime"];
    items?: Maybe<Array<IBasketItem>>;
    customerId?: Maybe<Scalars["ID"]>;
    couponIds: Array<Scalars["ID"]>;
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
    total: Scalars["Float"];
    coupons?: Maybe<Array<ICoupon>>;
};

export type IBasketItemsArgs = {
    where?: Maybe<IBasketItemWhereInput>;
    orderBy?: Maybe<IBasketItemOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type IBasketActivity = {
    __typename?: "BasketActivity";
    id: Scalars["ID"];
    description: Scalars["String"];
    quantity: Scalars["Int"];
    pricePerItem: Scalars["Float"];
    discountPerItem: Scalars["Float"];
    activityInstanceId: Scalars["ID"];
    quantityPerBooking: Scalars["Int"];
    startsAt: Scalars["DateTime"];
    endsAt: Scalars["DateTime"];
    bookingNameInfo?: Maybe<Scalars["String"]>;
    areBookingNamesRequired: Scalars["Boolean"];
    appliedCustomerMembershipIds: Array<Scalars["String"]>;
    membershipDiscount: Scalars["Float"];
    activityInstance: IActivityInstance;
    applicableCustomerMemberships?: Maybe<Array<ICustomerMembership>>;
};

export type IBasketActivityWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    description?: Maybe<Scalars["String"]>;
    description_not?: Maybe<Scalars["String"]>;
    description_in?: Maybe<Array<Scalars["String"]>>;
    description_not_in?: Maybe<Array<Scalars["String"]>>;
    description_lt?: Maybe<Scalars["String"]>;
    description_lte?: Maybe<Scalars["String"]>;
    description_gt?: Maybe<Scalars["String"]>;
    description_gte?: Maybe<Scalars["String"]>;
    description_contains?: Maybe<Scalars["String"]>;
    description_not_contains?: Maybe<Scalars["String"]>;
    description_starts_with?: Maybe<Scalars["String"]>;
    description_not_starts_with?: Maybe<Scalars["String"]>;
    description_ends_with?: Maybe<Scalars["String"]>;
    description_not_ends_with?: Maybe<Scalars["String"]>;
    quantity?: Maybe<Scalars["Int"]>;
    quantity_not?: Maybe<Scalars["Int"]>;
    quantity_in?: Maybe<Array<Scalars["Int"]>>;
    quantity_not_in?: Maybe<Array<Scalars["Int"]>>;
    quantity_lt?: Maybe<Scalars["Int"]>;
    quantity_lte?: Maybe<Scalars["Int"]>;
    quantity_gt?: Maybe<Scalars["Int"]>;
    quantity_gte?: Maybe<Scalars["Int"]>;
    pricePerItem?: Maybe<Scalars["Float"]>;
    pricePerItem_not?: Maybe<Scalars["Float"]>;
    pricePerItem_in?: Maybe<Array<Scalars["Float"]>>;
    pricePerItem_not_in?: Maybe<Array<Scalars["Float"]>>;
    pricePerItem_lt?: Maybe<Scalars["Float"]>;
    pricePerItem_lte?: Maybe<Scalars["Float"]>;
    pricePerItem_gt?: Maybe<Scalars["Float"]>;
    pricePerItem_gte?: Maybe<Scalars["Float"]>;
    discountPerItem?: Maybe<Scalars["Float"]>;
    discountPerItem_not?: Maybe<Scalars["Float"]>;
    discountPerItem_in?: Maybe<Array<Scalars["Float"]>>;
    discountPerItem_not_in?: Maybe<Array<Scalars["Float"]>>;
    discountPerItem_lt?: Maybe<Scalars["Float"]>;
    discountPerItem_lte?: Maybe<Scalars["Float"]>;
    discountPerItem_gt?: Maybe<Scalars["Float"]>;
    discountPerItem_gte?: Maybe<Scalars["Float"]>;
    activityInstanceId?: Maybe<Scalars["ID"]>;
    activityInstanceId_not?: Maybe<Scalars["ID"]>;
    activityInstanceId_in?: Maybe<Array<Scalars["ID"]>>;
    activityInstanceId_not_in?: Maybe<Array<Scalars["ID"]>>;
    activityInstanceId_lt?: Maybe<Scalars["ID"]>;
    activityInstanceId_lte?: Maybe<Scalars["ID"]>;
    activityInstanceId_gt?: Maybe<Scalars["ID"]>;
    activityInstanceId_gte?: Maybe<Scalars["ID"]>;
    activityInstanceId_contains?: Maybe<Scalars["ID"]>;
    activityInstanceId_not_contains?: Maybe<Scalars["ID"]>;
    activityInstanceId_starts_with?: Maybe<Scalars["ID"]>;
    activityInstanceId_not_starts_with?: Maybe<Scalars["ID"]>;
    activityInstanceId_ends_with?: Maybe<Scalars["ID"]>;
    activityInstanceId_not_ends_with?: Maybe<Scalars["ID"]>;
    quantityPerBooking?: Maybe<Scalars["Int"]>;
    quantityPerBooking_not?: Maybe<Scalars["Int"]>;
    quantityPerBooking_in?: Maybe<Array<Scalars["Int"]>>;
    quantityPerBooking_not_in?: Maybe<Array<Scalars["Int"]>>;
    quantityPerBooking_lt?: Maybe<Scalars["Int"]>;
    quantityPerBooking_lte?: Maybe<Scalars["Int"]>;
    quantityPerBooking_gt?: Maybe<Scalars["Int"]>;
    quantityPerBooking_gte?: Maybe<Scalars["Int"]>;
    startsAt?: Maybe<Scalars["DateTime"]>;
    startsAt_not?: Maybe<Scalars["DateTime"]>;
    startsAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    startsAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    startsAt_lt?: Maybe<Scalars["DateTime"]>;
    startsAt_lte?: Maybe<Scalars["DateTime"]>;
    startsAt_gt?: Maybe<Scalars["DateTime"]>;
    startsAt_gte?: Maybe<Scalars["DateTime"]>;
    endsAt?: Maybe<Scalars["DateTime"]>;
    endsAt_not?: Maybe<Scalars["DateTime"]>;
    endsAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    endsAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    endsAt_lt?: Maybe<Scalars["DateTime"]>;
    endsAt_lte?: Maybe<Scalars["DateTime"]>;
    endsAt_gt?: Maybe<Scalars["DateTime"]>;
    endsAt_gte?: Maybe<Scalars["DateTime"]>;
    bookingNameInfo?: Maybe<Scalars["String"]>;
    bookingNameInfo_not?: Maybe<Scalars["String"]>;
    bookingNameInfo_in?: Maybe<Array<Scalars["String"]>>;
    bookingNameInfo_not_in?: Maybe<Array<Scalars["String"]>>;
    bookingNameInfo_lt?: Maybe<Scalars["String"]>;
    bookingNameInfo_lte?: Maybe<Scalars["String"]>;
    bookingNameInfo_gt?: Maybe<Scalars["String"]>;
    bookingNameInfo_gte?: Maybe<Scalars["String"]>;
    bookingNameInfo_contains?: Maybe<Scalars["String"]>;
    bookingNameInfo_not_contains?: Maybe<Scalars["String"]>;
    bookingNameInfo_starts_with?: Maybe<Scalars["String"]>;
    bookingNameInfo_not_starts_with?: Maybe<Scalars["String"]>;
    bookingNameInfo_ends_with?: Maybe<Scalars["String"]>;
    bookingNameInfo_not_ends_with?: Maybe<Scalars["String"]>;
    areBookingNamesRequired?: Maybe<Scalars["Boolean"]>;
    areBookingNamesRequired_not?: Maybe<Scalars["Boolean"]>;
    membershipDiscount?: Maybe<Scalars["Float"]>;
    membershipDiscount_not?: Maybe<Scalars["Float"]>;
    membershipDiscount_in?: Maybe<Array<Scalars["Float"]>>;
    membershipDiscount_not_in?: Maybe<Array<Scalars["Float"]>>;
    membershipDiscount_lt?: Maybe<Scalars["Float"]>;
    membershipDiscount_lte?: Maybe<Scalars["Float"]>;
    membershipDiscount_gt?: Maybe<Scalars["Float"]>;
    membershipDiscount_gte?: Maybe<Scalars["Float"]>;
    AND?: Maybe<Array<IBasketActivityWhereInput>>;
};

export type IBasketExtra = {
    __typename?: "BasketExtra";
    id: Scalars["ID"];
    description: Scalars["String"];
    quantity: Scalars["Int"];
    pricePerItem: Scalars["Float"];
    discountPerItem: Scalars["Float"];
    extraId: Scalars["ID"];
    extra: IExtra;
};

export type IBasketExtraRestrictedWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    description?: Maybe<Scalars["String"]>;
    description_not?: Maybe<Scalars["String"]>;
    description_in?: Maybe<Array<Scalars["String"]>>;
    description_not_in?: Maybe<Array<Scalars["String"]>>;
    description_lt?: Maybe<Scalars["String"]>;
    description_lte?: Maybe<Scalars["String"]>;
    description_gt?: Maybe<Scalars["String"]>;
    description_gte?: Maybe<Scalars["String"]>;
    description_contains?: Maybe<Scalars["String"]>;
    description_not_contains?: Maybe<Scalars["String"]>;
    description_starts_with?: Maybe<Scalars["String"]>;
    description_not_starts_with?: Maybe<Scalars["String"]>;
    description_ends_with?: Maybe<Scalars["String"]>;
    description_not_ends_with?: Maybe<Scalars["String"]>;
    quantity?: Maybe<Scalars["Int"]>;
    quantity_not?: Maybe<Scalars["Int"]>;
    quantity_in?: Maybe<Array<Scalars["Int"]>>;
    quantity_not_in?: Maybe<Array<Scalars["Int"]>>;
    quantity_lt?: Maybe<Scalars["Int"]>;
    quantity_lte?: Maybe<Scalars["Int"]>;
    quantity_gt?: Maybe<Scalars["Int"]>;
    quantity_gte?: Maybe<Scalars["Int"]>;
    pricePerItem?: Maybe<Scalars["Float"]>;
    pricePerItem_not?: Maybe<Scalars["Float"]>;
    pricePerItem_in?: Maybe<Array<Scalars["Float"]>>;
    pricePerItem_not_in?: Maybe<Array<Scalars["Float"]>>;
    pricePerItem_lt?: Maybe<Scalars["Float"]>;
    pricePerItem_lte?: Maybe<Scalars["Float"]>;
    pricePerItem_gt?: Maybe<Scalars["Float"]>;
    pricePerItem_gte?: Maybe<Scalars["Float"]>;
    discountPerItem?: Maybe<Scalars["Float"]>;
    discountPerItem_not?: Maybe<Scalars["Float"]>;
    discountPerItem_in?: Maybe<Array<Scalars["Float"]>>;
    discountPerItem_not_in?: Maybe<Array<Scalars["Float"]>>;
    discountPerItem_lt?: Maybe<Scalars["Float"]>;
    discountPerItem_lte?: Maybe<Scalars["Float"]>;
    discountPerItem_gt?: Maybe<Scalars["Float"]>;
    discountPerItem_gte?: Maybe<Scalars["Float"]>;
    extraId?: Maybe<Scalars["ID"]>;
    extraId_not?: Maybe<Scalars["ID"]>;
    extraId_in?: Maybe<Array<Scalars["ID"]>>;
    extraId_not_in?: Maybe<Array<Scalars["ID"]>>;
    extraId_lt?: Maybe<Scalars["ID"]>;
    extraId_lte?: Maybe<Scalars["ID"]>;
    extraId_gt?: Maybe<Scalars["ID"]>;
    extraId_gte?: Maybe<Scalars["ID"]>;
    extraId_contains?: Maybe<Scalars["ID"]>;
    extraId_not_contains?: Maybe<Scalars["ID"]>;
    extraId_starts_with?: Maybe<Scalars["ID"]>;
    extraId_not_starts_with?: Maybe<Scalars["ID"]>;
    extraId_ends_with?: Maybe<Scalars["ID"]>;
    extraId_not_ends_with?: Maybe<Scalars["ID"]>;
    AND?: Maybe<Array<IBasketExtraRestrictedWhereInput>>;
};

export type IBasketExtraWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    description?: Maybe<Scalars["String"]>;
    description_not?: Maybe<Scalars["String"]>;
    description_in?: Maybe<Array<Scalars["String"]>>;
    description_not_in?: Maybe<Array<Scalars["String"]>>;
    description_lt?: Maybe<Scalars["String"]>;
    description_lte?: Maybe<Scalars["String"]>;
    description_gt?: Maybe<Scalars["String"]>;
    description_gte?: Maybe<Scalars["String"]>;
    description_contains?: Maybe<Scalars["String"]>;
    description_not_contains?: Maybe<Scalars["String"]>;
    description_starts_with?: Maybe<Scalars["String"]>;
    description_not_starts_with?: Maybe<Scalars["String"]>;
    description_ends_with?: Maybe<Scalars["String"]>;
    description_not_ends_with?: Maybe<Scalars["String"]>;
    quantity?: Maybe<Scalars["Int"]>;
    quantity_not?: Maybe<Scalars["Int"]>;
    quantity_in?: Maybe<Array<Scalars["Int"]>>;
    quantity_not_in?: Maybe<Array<Scalars["Int"]>>;
    quantity_lt?: Maybe<Scalars["Int"]>;
    quantity_lte?: Maybe<Scalars["Int"]>;
    quantity_gt?: Maybe<Scalars["Int"]>;
    quantity_gte?: Maybe<Scalars["Int"]>;
    pricePerItem?: Maybe<Scalars["Float"]>;
    pricePerItem_not?: Maybe<Scalars["Float"]>;
    pricePerItem_in?: Maybe<Array<Scalars["Float"]>>;
    pricePerItem_not_in?: Maybe<Array<Scalars["Float"]>>;
    pricePerItem_lt?: Maybe<Scalars["Float"]>;
    pricePerItem_lte?: Maybe<Scalars["Float"]>;
    pricePerItem_gt?: Maybe<Scalars["Float"]>;
    pricePerItem_gte?: Maybe<Scalars["Float"]>;
    discountPerItem?: Maybe<Scalars["Float"]>;
    discountPerItem_not?: Maybe<Scalars["Float"]>;
    discountPerItem_in?: Maybe<Array<Scalars["Float"]>>;
    discountPerItem_not_in?: Maybe<Array<Scalars["Float"]>>;
    discountPerItem_lt?: Maybe<Scalars["Float"]>;
    discountPerItem_lte?: Maybe<Scalars["Float"]>;
    discountPerItem_gt?: Maybe<Scalars["Float"]>;
    discountPerItem_gte?: Maybe<Scalars["Float"]>;
    extraId?: Maybe<Scalars["ID"]>;
    extraId_not?: Maybe<Scalars["ID"]>;
    extraId_in?: Maybe<Array<Scalars["ID"]>>;
    extraId_not_in?: Maybe<Array<Scalars["ID"]>>;
    extraId_lt?: Maybe<Scalars["ID"]>;
    extraId_lte?: Maybe<Scalars["ID"]>;
    extraId_gt?: Maybe<Scalars["ID"]>;
    extraId_gte?: Maybe<Scalars["ID"]>;
    extraId_contains?: Maybe<Scalars["ID"]>;
    extraId_not_contains?: Maybe<Scalars["ID"]>;
    extraId_starts_with?: Maybe<Scalars["ID"]>;
    extraId_not_starts_with?: Maybe<Scalars["ID"]>;
    extraId_ends_with?: Maybe<Scalars["ID"]>;
    extraId_not_ends_with?: Maybe<Scalars["ID"]>;
    AND?: Maybe<Array<IBasketExtraWhereInput>>;
};

export type IBasketItem = {
    __typename?: "BasketItem";
    id: Scalars["ID"];
    basket?: Maybe<IBasket>;
    start: Scalars["DateTime"];
    end: Scalars["DateTime"];
    basketActivity?: Maybe<IBasketActivity>;
    basketExtras?: Maybe<Array<IBasketExtra>>;
    basketMembership?: Maybe<IBasketMembership>;
};

export type IBasketItemIdBookingNamesPairInput = {
    basketActivityId: Scalars["ID"];
    bookingNames: Array<Scalars["String"]>;
};

export enum IBasketItemOrderByInput {
    IdAsc = "id_ASC",
    IdDesc = "id_DESC",
    StartAsc = "start_ASC",
    StartDesc = "start_DESC",
    EndAsc = "end_ASC",
    EndDesc = "end_DESC"
}

export type IBasketItemWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    basket?: Maybe<IBasketWhereInput>;
    start?: Maybe<Scalars["DateTime"]>;
    start_not?: Maybe<Scalars["DateTime"]>;
    start_in?: Maybe<Array<Scalars["DateTime"]>>;
    start_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    start_lt?: Maybe<Scalars["DateTime"]>;
    start_lte?: Maybe<Scalars["DateTime"]>;
    start_gt?: Maybe<Scalars["DateTime"]>;
    start_gte?: Maybe<Scalars["DateTime"]>;
    end?: Maybe<Scalars["DateTime"]>;
    end_not?: Maybe<Scalars["DateTime"]>;
    end_in?: Maybe<Array<Scalars["DateTime"]>>;
    end_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    end_lt?: Maybe<Scalars["DateTime"]>;
    end_lte?: Maybe<Scalars["DateTime"]>;
    end_gt?: Maybe<Scalars["DateTime"]>;
    end_gte?: Maybe<Scalars["DateTime"]>;
    basketActivity?: Maybe<IBasketActivityWhereInput>;
    basketExtras_some?: Maybe<IBasketExtraWhereInput>;
    basketExtras_every?: Maybe<IBasketExtraRestrictedWhereInput>;
    basketExtras_none?: Maybe<IBasketExtraRestrictedWhereInput>;
    basketMembership?: Maybe<IBasketMembershipWhereInput>;
    AND?: Maybe<Array<IBasketItemWhereInput>>;
};

export type IBasketMembership = {
    __typename?: "BasketMembership";
    id: Scalars["ID"];
    description: Scalars["String"];
    quantity: Scalars["Int"];
    pricePerItem: Scalars["Float"];
    discountPerItem: Scalars["Float"];
    membershipId: Scalars["ID"];
    isRenewal?: Maybe<Scalars["Boolean"]>;
    customerMembershipId?: Maybe<Scalars["ID"]>;
};

export type IBasketMembershipWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    description?: Maybe<Scalars["String"]>;
    description_not?: Maybe<Scalars["String"]>;
    description_in?: Maybe<Array<Scalars["String"]>>;
    description_not_in?: Maybe<Array<Scalars["String"]>>;
    description_lt?: Maybe<Scalars["String"]>;
    description_lte?: Maybe<Scalars["String"]>;
    description_gt?: Maybe<Scalars["String"]>;
    description_gte?: Maybe<Scalars["String"]>;
    description_contains?: Maybe<Scalars["String"]>;
    description_not_contains?: Maybe<Scalars["String"]>;
    description_starts_with?: Maybe<Scalars["String"]>;
    description_not_starts_with?: Maybe<Scalars["String"]>;
    description_ends_with?: Maybe<Scalars["String"]>;
    description_not_ends_with?: Maybe<Scalars["String"]>;
    quantity?: Maybe<Scalars["Int"]>;
    quantity_not?: Maybe<Scalars["Int"]>;
    quantity_in?: Maybe<Array<Scalars["Int"]>>;
    quantity_not_in?: Maybe<Array<Scalars["Int"]>>;
    quantity_lt?: Maybe<Scalars["Int"]>;
    quantity_lte?: Maybe<Scalars["Int"]>;
    quantity_gt?: Maybe<Scalars["Int"]>;
    quantity_gte?: Maybe<Scalars["Int"]>;
    pricePerItem?: Maybe<Scalars["Float"]>;
    pricePerItem_not?: Maybe<Scalars["Float"]>;
    pricePerItem_in?: Maybe<Array<Scalars["Float"]>>;
    pricePerItem_not_in?: Maybe<Array<Scalars["Float"]>>;
    pricePerItem_lt?: Maybe<Scalars["Float"]>;
    pricePerItem_lte?: Maybe<Scalars["Float"]>;
    pricePerItem_gt?: Maybe<Scalars["Float"]>;
    pricePerItem_gte?: Maybe<Scalars["Float"]>;
    discountPerItem?: Maybe<Scalars["Float"]>;
    discountPerItem_not?: Maybe<Scalars["Float"]>;
    discountPerItem_in?: Maybe<Array<Scalars["Float"]>>;
    discountPerItem_not_in?: Maybe<Array<Scalars["Float"]>>;
    discountPerItem_lt?: Maybe<Scalars["Float"]>;
    discountPerItem_lte?: Maybe<Scalars["Float"]>;
    discountPerItem_gt?: Maybe<Scalars["Float"]>;
    discountPerItem_gte?: Maybe<Scalars["Float"]>;
    membershipId?: Maybe<Scalars["ID"]>;
    membershipId_not?: Maybe<Scalars["ID"]>;
    membershipId_in?: Maybe<Array<Scalars["ID"]>>;
    membershipId_not_in?: Maybe<Array<Scalars["ID"]>>;
    membershipId_lt?: Maybe<Scalars["ID"]>;
    membershipId_lte?: Maybe<Scalars["ID"]>;
    membershipId_gt?: Maybe<Scalars["ID"]>;
    membershipId_gte?: Maybe<Scalars["ID"]>;
    membershipId_contains?: Maybe<Scalars["ID"]>;
    membershipId_not_contains?: Maybe<Scalars["ID"]>;
    membershipId_starts_with?: Maybe<Scalars["ID"]>;
    membershipId_not_starts_with?: Maybe<Scalars["ID"]>;
    membershipId_ends_with?: Maybe<Scalars["ID"]>;
    membershipId_not_ends_with?: Maybe<Scalars["ID"]>;
    isRenewal?: Maybe<Scalars["Boolean"]>;
    isRenewal_not?: Maybe<Scalars["Boolean"]>;
    customerMembershipId?: Maybe<Scalars["ID"]>;
    customerMembershipId_not?: Maybe<Scalars["ID"]>;
    customerMembershipId_in?: Maybe<Array<Scalars["ID"]>>;
    customerMembershipId_not_in?: Maybe<Array<Scalars["ID"]>>;
    customerMembershipId_lt?: Maybe<Scalars["ID"]>;
    customerMembershipId_lte?: Maybe<Scalars["ID"]>;
    customerMembershipId_gt?: Maybe<Scalars["ID"]>;
    customerMembershipId_gte?: Maybe<Scalars["ID"]>;
    customerMembershipId_contains?: Maybe<Scalars["ID"]>;
    customerMembershipId_not_contains?: Maybe<Scalars["ID"]>;
    customerMembershipId_starts_with?: Maybe<Scalars["ID"]>;
    customerMembershipId_not_starts_with?: Maybe<Scalars["ID"]>;
    customerMembershipId_ends_with?: Maybe<Scalars["ID"]>;
    customerMembershipId_not_ends_with?: Maybe<Scalars["ID"]>;
    AND?: Maybe<Array<IBasketMembershipWhereInput>>;
};

export type IBasketWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    organisation?: Maybe<IOrganisationWhereInput>;
    expiresAt?: Maybe<Scalars["DateTime"]>;
    expiresAt_not?: Maybe<Scalars["DateTime"]>;
    expiresAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    expiresAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    expiresAt_lt?: Maybe<Scalars["DateTime"]>;
    expiresAt_lte?: Maybe<Scalars["DateTime"]>;
    expiresAt_gt?: Maybe<Scalars["DateTime"]>;
    expiresAt_gte?: Maybe<Scalars["DateTime"]>;
    items_some?: Maybe<IBasketItemWhereInput>;
    customerId?: Maybe<Scalars["ID"]>;
    customerId_not?: Maybe<Scalars["ID"]>;
    customerId_in?: Maybe<Array<Scalars["ID"]>>;
    customerId_not_in?: Maybe<Array<Scalars["ID"]>>;
    customerId_lt?: Maybe<Scalars["ID"]>;
    customerId_lte?: Maybe<Scalars["ID"]>;
    customerId_gt?: Maybe<Scalars["ID"]>;
    customerId_gte?: Maybe<Scalars["ID"]>;
    customerId_contains?: Maybe<Scalars["ID"]>;
    customerId_not_contains?: Maybe<Scalars["ID"]>;
    customerId_starts_with?: Maybe<Scalars["ID"]>;
    customerId_not_starts_with?: Maybe<Scalars["ID"]>;
    customerId_ends_with?: Maybe<Scalars["ID"]>;
    customerId_not_ends_with?: Maybe<Scalars["ID"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    createdAt_not?: Maybe<Scalars["DateTime"]>;
    createdAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_lt?: Maybe<Scalars["DateTime"]>;
    createdAt_lte?: Maybe<Scalars["DateTime"]>;
    createdAt_gt?: Maybe<Scalars["DateTime"]>;
    createdAt_gte?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    updatedAt_not?: Maybe<Scalars["DateTime"]>;
    updatedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_lt?: Maybe<Scalars["DateTime"]>;
    updatedAt_lte?: Maybe<Scalars["DateTime"]>;
    updatedAt_gt?: Maybe<Scalars["DateTime"]>;
    updatedAt_gte?: Maybe<Scalars["DateTime"]>;
    AND?: Maybe<Array<IBasketWhereInput>>;
};

export type IBookableActivity = {
    __typename?: "BookableActivity";
    activityInstanceId: Scalars["ID"];
    quantityAvailable: Scalars["Int"];
    ticketsAvailable: Scalars["Int"];
    numberPerBooking: Scalars["Int"];
    minTicketsPerBooking: Scalars["Int"];
    packageDescription: Scalars["String"];
    price: Scalars["Float"];
};

export type IBookableExtra = {
    __typename?: "BookableExtra";
    extraId: Scalars["ID"];
    name: Scalars["String"];
    description?: Maybe<Scalars["String"]>;
    price: Scalars["Float"];
};

export type IBooking = {
    __typename?: "Booking";
    id: Scalars["ID"];
    organisation: IOrganisation;
    bookingExtras?: Maybe<Array<IBookingExtra>>;
    transactions?: Maybe<Array<ITransaction>>;
    customer?: Maybe<ICustomer>;
    madeByCredential?: Maybe<ICredential>;
    activityInstance: IActivityInstance;
    activityQuantity: Scalars["Int"];
    activityPrice: Scalars["Float"];
    activityDiscount: Scalars["Float"];
    activityStartsAt?: Maybe<Scalars["DateTime"]>;
    customerNotes?: Maybe<Array<INote>>;
    adminNotes?: Maybe<Array<INote>>;
    midAdminNotes?: Maybe<Array<INote>>;
    completedAt?: Maybe<Scalars["DateTime"]>;
    reference: Scalars["String"];
    package?: Maybe<IPackage>;
    packageReference?: Maybe<Scalars["String"]>;
    nominalCode: Scalars["String"];
    bookingNames: Array<Scalars["String"]>;
    subTotal: Scalars["Float"];
    discount: Scalars["Float"];
    total: Scalars["Float"];
    balance: Scalars["Float"];
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
    bookingStatus: IBookingStatus;
    customerConfirmationEmailId?: Maybe<Scalars["String"]>;
    adminConfirmationEmailId?: Maybe<Scalars["String"]>;
    checkedInAt?: Maybe<Scalars["DateTime"]>;
    checkedInByUserId?: Maybe<Scalars["ID"]>;
    otherMetadata?: Maybe<Scalars["Json"]>;
    couponIds: Array<Scalars["ID"]>;
    searchableUpper?: Maybe<Scalars["String"]>;
    customerMemberships?: Maybe<Array<ICustomerMembership>>;
    selfCheckInOpensAt?: Maybe<Scalars["DateTime"]>;
    selfCheckInClosesAt?: Maybe<Scalars["DateTime"]>;
    isSelfCheckedIn: Scalars["Boolean"];
};

export type IBookingBookingExtrasArgs = {
    where?: Maybe<IBookingExtraWhereInput>;
    orderBy?: Maybe<IBookingExtraOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type IBookingTransactionsArgs = {
    where?: Maybe<ITransactionWhereInput>;
    orderBy?: Maybe<ITransactionOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type IBookingCustomerNotesArgs = {
    where?: Maybe<INoteWhereInput>;
    orderBy?: Maybe<INoteOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type IBookingAdminNotesArgs = {
    where?: Maybe<INoteWhereInput>;
    orderBy?: Maybe<INoteOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type IBookingMidAdminNotesArgs = {
    where?: Maybe<INoteWhereInput>;
    orderBy?: Maybe<INoteOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type IBookingCustomerMembershipsArgs = {
    where?: Maybe<ICustomerMembershipWhereInput>;
    orderBy?: Maybe<ICustomerMembershipOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type IBookingExtra = {
    __typename?: "BookingExtra";
    id: Scalars["ID"];
    booking: IBooking;
    name: Scalars["String"];
    description: Scalars["String"];
    quantity: Scalars["Int"];
    pricePerItem: Scalars["Float"];
    discountPerItem: Scalars["Float"];
    extraId: Scalars["ID"];
};

export enum IBookingExtraOrderByInput {
    IdAsc = "id_ASC",
    IdDesc = "id_DESC",
    NameAsc = "name_ASC",
    NameDesc = "name_DESC",
    DescriptionAsc = "description_ASC",
    DescriptionDesc = "description_DESC",
    QuantityAsc = "quantity_ASC",
    QuantityDesc = "quantity_DESC",
    PricePerItemAsc = "pricePerItem_ASC",
    PricePerItemDesc = "pricePerItem_DESC",
    DiscountPerItemAsc = "discountPerItem_ASC",
    DiscountPerItemDesc = "discountPerItem_DESC",
    ExtraIdAsc = "extraId_ASC",
    ExtraIdDesc = "extraId_DESC"
}

export type IBookingExtraWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    booking?: Maybe<IBookingWhereInput>;
    name?: Maybe<Scalars["String"]>;
    name_not?: Maybe<Scalars["String"]>;
    name_in?: Maybe<Array<Scalars["String"]>>;
    name_not_in?: Maybe<Array<Scalars["String"]>>;
    name_lt?: Maybe<Scalars["String"]>;
    name_lte?: Maybe<Scalars["String"]>;
    name_gt?: Maybe<Scalars["String"]>;
    name_gte?: Maybe<Scalars["String"]>;
    name_contains?: Maybe<Scalars["String"]>;
    name_not_contains?: Maybe<Scalars["String"]>;
    name_starts_with?: Maybe<Scalars["String"]>;
    name_not_starts_with?: Maybe<Scalars["String"]>;
    name_ends_with?: Maybe<Scalars["String"]>;
    name_not_ends_with?: Maybe<Scalars["String"]>;
    description?: Maybe<Scalars["String"]>;
    description_not?: Maybe<Scalars["String"]>;
    description_in?: Maybe<Array<Scalars["String"]>>;
    description_not_in?: Maybe<Array<Scalars["String"]>>;
    description_lt?: Maybe<Scalars["String"]>;
    description_lte?: Maybe<Scalars["String"]>;
    description_gt?: Maybe<Scalars["String"]>;
    description_gte?: Maybe<Scalars["String"]>;
    description_contains?: Maybe<Scalars["String"]>;
    description_not_contains?: Maybe<Scalars["String"]>;
    description_starts_with?: Maybe<Scalars["String"]>;
    description_not_starts_with?: Maybe<Scalars["String"]>;
    description_ends_with?: Maybe<Scalars["String"]>;
    description_not_ends_with?: Maybe<Scalars["String"]>;
    quantity?: Maybe<Scalars["Int"]>;
    quantity_not?: Maybe<Scalars["Int"]>;
    quantity_in?: Maybe<Array<Scalars["Int"]>>;
    quantity_not_in?: Maybe<Array<Scalars["Int"]>>;
    quantity_lt?: Maybe<Scalars["Int"]>;
    quantity_lte?: Maybe<Scalars["Int"]>;
    quantity_gt?: Maybe<Scalars["Int"]>;
    quantity_gte?: Maybe<Scalars["Int"]>;
    pricePerItem?: Maybe<Scalars["Float"]>;
    pricePerItem_not?: Maybe<Scalars["Float"]>;
    pricePerItem_in?: Maybe<Array<Scalars["Float"]>>;
    pricePerItem_not_in?: Maybe<Array<Scalars["Float"]>>;
    pricePerItem_lt?: Maybe<Scalars["Float"]>;
    pricePerItem_lte?: Maybe<Scalars["Float"]>;
    pricePerItem_gt?: Maybe<Scalars["Float"]>;
    pricePerItem_gte?: Maybe<Scalars["Float"]>;
    discountPerItem?: Maybe<Scalars["Float"]>;
    discountPerItem_not?: Maybe<Scalars["Float"]>;
    discountPerItem_in?: Maybe<Array<Scalars["Float"]>>;
    discountPerItem_not_in?: Maybe<Array<Scalars["Float"]>>;
    discountPerItem_lt?: Maybe<Scalars["Float"]>;
    discountPerItem_lte?: Maybe<Scalars["Float"]>;
    discountPerItem_gt?: Maybe<Scalars["Float"]>;
    discountPerItem_gte?: Maybe<Scalars["Float"]>;
    extraId?: Maybe<Scalars["ID"]>;
    extraId_not?: Maybe<Scalars["ID"]>;
    extraId_in?: Maybe<Array<Scalars["ID"]>>;
    extraId_not_in?: Maybe<Array<Scalars["ID"]>>;
    extraId_lt?: Maybe<Scalars["ID"]>;
    extraId_lte?: Maybe<Scalars["ID"]>;
    extraId_gt?: Maybe<Scalars["ID"]>;
    extraId_gte?: Maybe<Scalars["ID"]>;
    extraId_contains?: Maybe<Scalars["ID"]>;
    extraId_not_contains?: Maybe<Scalars["ID"]>;
    extraId_starts_with?: Maybe<Scalars["ID"]>;
    extraId_not_starts_with?: Maybe<Scalars["ID"]>;
    extraId_ends_with?: Maybe<Scalars["ID"]>;
    extraId_not_ends_with?: Maybe<Scalars["ID"]>;
    AND?: Maybe<Array<IBookingExtraWhereInput>>;
};

export enum IBookingOrderByInput {
    IdAsc = "id_ASC",
    IdDesc = "id_DESC",
    ActivityQuantityAsc = "activityQuantity_ASC",
    ActivityQuantityDesc = "activityQuantity_DESC",
    ActivityPriceAsc = "activityPrice_ASC",
    ActivityPriceDesc = "activityPrice_DESC",
    ActivityDiscountAsc = "activityDiscount_ASC",
    ActivityDiscountDesc = "activityDiscount_DESC",
    ActivityStartsAtAsc = "activityStartsAt_ASC",
    ActivityStartsAtDesc = "activityStartsAt_DESC",
    CompletedAtAsc = "completedAt_ASC",
    CompletedAtDesc = "completedAt_DESC",
    ReferenceAsc = "reference_ASC",
    ReferenceDesc = "reference_DESC",
    PackageReferenceAsc = "packageReference_ASC",
    PackageReferenceDesc = "packageReference_DESC",
    NominalCodeAsc = "nominalCode_ASC",
    NominalCodeDesc = "nominalCode_DESC",
    SubTotalAsc = "subTotal_ASC",
    SubTotalDesc = "subTotal_DESC",
    DiscountAsc = "discount_ASC",
    DiscountDesc = "discount_DESC",
    TotalAsc = "total_ASC",
    TotalDesc = "total_DESC",
    BalanceAsc = "balance_ASC",
    BalanceDesc = "balance_DESC",
    CreatedAtAsc = "createdAt_ASC",
    CreatedAtDesc = "createdAt_DESC",
    UpdatedAtAsc = "updatedAt_ASC",
    UpdatedAtDesc = "updatedAt_DESC",
    BookingStatusAsc = "bookingStatus_ASC",
    BookingStatusDesc = "bookingStatus_DESC",
    CustomerConfirmationEmailIdAsc = "customerConfirmationEmailId_ASC",
    CustomerConfirmationEmailIdDesc = "customerConfirmationEmailId_DESC",
    AdminConfirmationEmailIdAsc = "adminConfirmationEmailId_ASC",
    AdminConfirmationEmailIdDesc = "adminConfirmationEmailId_DESC",
    CheckedInAtAsc = "checkedInAt_ASC",
    CheckedInAtDesc = "checkedInAt_DESC",
    CheckedInByUserIdAsc = "checkedInByUserId_ASC",
    CheckedInByUserIdDesc = "checkedInByUserId_DESC",
    OtherMetadataAsc = "otherMetadata_ASC",
    OtherMetadataDesc = "otherMetadata_DESC",
    SearchableUpperAsc = "searchableUpper_ASC",
    SearchableUpperDesc = "searchableUpper_DESC",
    SelfCheckInOpensAtAsc = "selfCheckInOpensAt_ASC",
    SelfCheckInOpensAtDesc = "selfCheckInOpensAt_DESC",
    SelfCheckInClosesAtAsc = "selfCheckInClosesAt_ASC",
    SelfCheckInClosesAtDesc = "selfCheckInClosesAt_DESC",
    IsSelfCheckedInAsc = "isSelfCheckedIn_ASC",
    IsSelfCheckedInDesc = "isSelfCheckedIn_DESC"
}

export enum IBookingStatus {
    Inprogress = "INPROGRESS",
    Complete = "COMPLETE",
    Cancelled = "CANCELLED"
}

export type IBookingWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    organisation?: Maybe<IOrganisationWhereInput>;
    bookingExtras_some?: Maybe<IBookingExtraWhereInput>;
    transactions_some?: Maybe<ITransactionWhereInput>;
    customer?: Maybe<ICustomerWhereInput>;
    madeByCredential?: Maybe<ICredentialWhereInput>;
    activityInstance?: Maybe<IActivityInstanceWhereInput>;
    activityQuantity?: Maybe<Scalars["Int"]>;
    activityQuantity_not?: Maybe<Scalars["Int"]>;
    activityQuantity_in?: Maybe<Array<Scalars["Int"]>>;
    activityQuantity_not_in?: Maybe<Array<Scalars["Int"]>>;
    activityQuantity_lt?: Maybe<Scalars["Int"]>;
    activityQuantity_lte?: Maybe<Scalars["Int"]>;
    activityQuantity_gt?: Maybe<Scalars["Int"]>;
    activityQuantity_gte?: Maybe<Scalars["Int"]>;
    activityPrice?: Maybe<Scalars["Float"]>;
    activityPrice_not?: Maybe<Scalars["Float"]>;
    activityPrice_in?: Maybe<Array<Scalars["Float"]>>;
    activityPrice_not_in?: Maybe<Array<Scalars["Float"]>>;
    activityPrice_lt?: Maybe<Scalars["Float"]>;
    activityPrice_lte?: Maybe<Scalars["Float"]>;
    activityPrice_gt?: Maybe<Scalars["Float"]>;
    activityPrice_gte?: Maybe<Scalars["Float"]>;
    activityDiscount?: Maybe<Scalars["Float"]>;
    activityDiscount_not?: Maybe<Scalars["Float"]>;
    activityDiscount_in?: Maybe<Array<Scalars["Float"]>>;
    activityDiscount_not_in?: Maybe<Array<Scalars["Float"]>>;
    activityDiscount_lt?: Maybe<Scalars["Float"]>;
    activityDiscount_lte?: Maybe<Scalars["Float"]>;
    activityDiscount_gt?: Maybe<Scalars["Float"]>;
    activityDiscount_gte?: Maybe<Scalars["Float"]>;
    activityStartsAt?: Maybe<Scalars["DateTime"]>;
    activityStartsAt_not?: Maybe<Scalars["DateTime"]>;
    activityStartsAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    activityStartsAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    activityStartsAt_lt?: Maybe<Scalars["DateTime"]>;
    activityStartsAt_lte?: Maybe<Scalars["DateTime"]>;
    activityStartsAt_gt?: Maybe<Scalars["DateTime"]>;
    activityStartsAt_gte?: Maybe<Scalars["DateTime"]>;
    customerNotes_some?: Maybe<INoteWhereInput>;
    adminNotes_some?: Maybe<INoteWhereInput>;
    midAdminNotes_some?: Maybe<INoteWhereInput>;
    completedAt?: Maybe<Scalars["DateTime"]>;
    completedAt_not?: Maybe<Scalars["DateTime"]>;
    completedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    completedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    completedAt_lt?: Maybe<Scalars["DateTime"]>;
    completedAt_lte?: Maybe<Scalars["DateTime"]>;
    completedAt_gt?: Maybe<Scalars["DateTime"]>;
    completedAt_gte?: Maybe<Scalars["DateTime"]>;
    reference?: Maybe<Scalars["String"]>;
    reference_not?: Maybe<Scalars["String"]>;
    reference_in?: Maybe<Array<Scalars["String"]>>;
    reference_not_in?: Maybe<Array<Scalars["String"]>>;
    reference_lt?: Maybe<Scalars["String"]>;
    reference_lte?: Maybe<Scalars["String"]>;
    reference_gt?: Maybe<Scalars["String"]>;
    reference_gte?: Maybe<Scalars["String"]>;
    reference_contains?: Maybe<Scalars["String"]>;
    reference_not_contains?: Maybe<Scalars["String"]>;
    reference_starts_with?: Maybe<Scalars["String"]>;
    reference_not_starts_with?: Maybe<Scalars["String"]>;
    reference_ends_with?: Maybe<Scalars["String"]>;
    reference_not_ends_with?: Maybe<Scalars["String"]>;
    package?: Maybe<IPackageWhereInput>;
    packageReference?: Maybe<Scalars["String"]>;
    packageReference_not?: Maybe<Scalars["String"]>;
    packageReference_in?: Maybe<Array<Scalars["String"]>>;
    packageReference_not_in?: Maybe<Array<Scalars["String"]>>;
    packageReference_lt?: Maybe<Scalars["String"]>;
    packageReference_lte?: Maybe<Scalars["String"]>;
    packageReference_gt?: Maybe<Scalars["String"]>;
    packageReference_gte?: Maybe<Scalars["String"]>;
    packageReference_contains?: Maybe<Scalars["String"]>;
    packageReference_not_contains?: Maybe<Scalars["String"]>;
    packageReference_starts_with?: Maybe<Scalars["String"]>;
    packageReference_not_starts_with?: Maybe<Scalars["String"]>;
    packageReference_ends_with?: Maybe<Scalars["String"]>;
    packageReference_not_ends_with?: Maybe<Scalars["String"]>;
    nominalCode?: Maybe<Scalars["String"]>;
    nominalCode_not?: Maybe<Scalars["String"]>;
    nominalCode_in?: Maybe<Array<Scalars["String"]>>;
    nominalCode_not_in?: Maybe<Array<Scalars["String"]>>;
    nominalCode_lt?: Maybe<Scalars["String"]>;
    nominalCode_lte?: Maybe<Scalars["String"]>;
    nominalCode_gt?: Maybe<Scalars["String"]>;
    nominalCode_gte?: Maybe<Scalars["String"]>;
    nominalCode_contains?: Maybe<Scalars["String"]>;
    nominalCode_not_contains?: Maybe<Scalars["String"]>;
    nominalCode_starts_with?: Maybe<Scalars["String"]>;
    nominalCode_not_starts_with?: Maybe<Scalars["String"]>;
    nominalCode_ends_with?: Maybe<Scalars["String"]>;
    nominalCode_not_ends_with?: Maybe<Scalars["String"]>;
    subTotal?: Maybe<Scalars["Float"]>;
    subTotal_not?: Maybe<Scalars["Float"]>;
    subTotal_in?: Maybe<Array<Scalars["Float"]>>;
    subTotal_not_in?: Maybe<Array<Scalars["Float"]>>;
    subTotal_lt?: Maybe<Scalars["Float"]>;
    subTotal_lte?: Maybe<Scalars["Float"]>;
    subTotal_gt?: Maybe<Scalars["Float"]>;
    subTotal_gte?: Maybe<Scalars["Float"]>;
    discount?: Maybe<Scalars["Float"]>;
    discount_not?: Maybe<Scalars["Float"]>;
    discount_in?: Maybe<Array<Scalars["Float"]>>;
    discount_not_in?: Maybe<Array<Scalars["Float"]>>;
    discount_lt?: Maybe<Scalars["Float"]>;
    discount_lte?: Maybe<Scalars["Float"]>;
    discount_gt?: Maybe<Scalars["Float"]>;
    discount_gte?: Maybe<Scalars["Float"]>;
    total?: Maybe<Scalars["Float"]>;
    total_not?: Maybe<Scalars["Float"]>;
    total_in?: Maybe<Array<Scalars["Float"]>>;
    total_not_in?: Maybe<Array<Scalars["Float"]>>;
    total_lt?: Maybe<Scalars["Float"]>;
    total_lte?: Maybe<Scalars["Float"]>;
    total_gt?: Maybe<Scalars["Float"]>;
    total_gte?: Maybe<Scalars["Float"]>;
    balance?: Maybe<Scalars["Float"]>;
    balance_not?: Maybe<Scalars["Float"]>;
    balance_in?: Maybe<Array<Scalars["Float"]>>;
    balance_not_in?: Maybe<Array<Scalars["Float"]>>;
    balance_lt?: Maybe<Scalars["Float"]>;
    balance_lte?: Maybe<Scalars["Float"]>;
    balance_gt?: Maybe<Scalars["Float"]>;
    balance_gte?: Maybe<Scalars["Float"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    createdAt_not?: Maybe<Scalars["DateTime"]>;
    createdAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_lt?: Maybe<Scalars["DateTime"]>;
    createdAt_lte?: Maybe<Scalars["DateTime"]>;
    createdAt_gt?: Maybe<Scalars["DateTime"]>;
    createdAt_gte?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    updatedAt_not?: Maybe<Scalars["DateTime"]>;
    updatedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_lt?: Maybe<Scalars["DateTime"]>;
    updatedAt_lte?: Maybe<Scalars["DateTime"]>;
    updatedAt_gt?: Maybe<Scalars["DateTime"]>;
    updatedAt_gte?: Maybe<Scalars["DateTime"]>;
    bookingStatus?: Maybe<IBookingStatus>;
    bookingStatus_not?: Maybe<IBookingStatus>;
    bookingStatus_in?: Maybe<Array<IBookingStatus>>;
    bookingStatus_not_in?: Maybe<Array<IBookingStatus>>;
    customerConfirmationEmailId?: Maybe<Scalars["String"]>;
    customerConfirmationEmailId_not?: Maybe<Scalars["String"]>;
    customerConfirmationEmailId_in?: Maybe<Array<Scalars["String"]>>;
    customerConfirmationEmailId_not_in?: Maybe<Array<Scalars["String"]>>;
    customerConfirmationEmailId_lt?: Maybe<Scalars["String"]>;
    customerConfirmationEmailId_lte?: Maybe<Scalars["String"]>;
    customerConfirmationEmailId_gt?: Maybe<Scalars["String"]>;
    customerConfirmationEmailId_gte?: Maybe<Scalars["String"]>;
    customerConfirmationEmailId_contains?: Maybe<Scalars["String"]>;
    customerConfirmationEmailId_not_contains?: Maybe<Scalars["String"]>;
    customerConfirmationEmailId_starts_with?: Maybe<Scalars["String"]>;
    customerConfirmationEmailId_not_starts_with?: Maybe<Scalars["String"]>;
    customerConfirmationEmailId_ends_with?: Maybe<Scalars["String"]>;
    customerConfirmationEmailId_not_ends_with?: Maybe<Scalars["String"]>;
    adminConfirmationEmailId?: Maybe<Scalars["String"]>;
    adminConfirmationEmailId_not?: Maybe<Scalars["String"]>;
    adminConfirmationEmailId_in?: Maybe<Array<Scalars["String"]>>;
    adminConfirmationEmailId_not_in?: Maybe<Array<Scalars["String"]>>;
    adminConfirmationEmailId_lt?: Maybe<Scalars["String"]>;
    adminConfirmationEmailId_lte?: Maybe<Scalars["String"]>;
    adminConfirmationEmailId_gt?: Maybe<Scalars["String"]>;
    adminConfirmationEmailId_gte?: Maybe<Scalars["String"]>;
    adminConfirmationEmailId_contains?: Maybe<Scalars["String"]>;
    adminConfirmationEmailId_not_contains?: Maybe<Scalars["String"]>;
    adminConfirmationEmailId_starts_with?: Maybe<Scalars["String"]>;
    adminConfirmationEmailId_not_starts_with?: Maybe<Scalars["String"]>;
    adminConfirmationEmailId_ends_with?: Maybe<Scalars["String"]>;
    adminConfirmationEmailId_not_ends_with?: Maybe<Scalars["String"]>;
    checkedInAt?: Maybe<Scalars["DateTime"]>;
    checkedInAt_not?: Maybe<Scalars["DateTime"]>;
    checkedInAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    checkedInAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    checkedInAt_lt?: Maybe<Scalars["DateTime"]>;
    checkedInAt_lte?: Maybe<Scalars["DateTime"]>;
    checkedInAt_gt?: Maybe<Scalars["DateTime"]>;
    checkedInAt_gte?: Maybe<Scalars["DateTime"]>;
    checkedInByUserId?: Maybe<Scalars["ID"]>;
    checkedInByUserId_not?: Maybe<Scalars["ID"]>;
    checkedInByUserId_in?: Maybe<Array<Scalars["ID"]>>;
    checkedInByUserId_not_in?: Maybe<Array<Scalars["ID"]>>;
    checkedInByUserId_lt?: Maybe<Scalars["ID"]>;
    checkedInByUserId_lte?: Maybe<Scalars["ID"]>;
    checkedInByUserId_gt?: Maybe<Scalars["ID"]>;
    checkedInByUserId_gte?: Maybe<Scalars["ID"]>;
    checkedInByUserId_contains?: Maybe<Scalars["ID"]>;
    checkedInByUserId_not_contains?: Maybe<Scalars["ID"]>;
    checkedInByUserId_starts_with?: Maybe<Scalars["ID"]>;
    checkedInByUserId_not_starts_with?: Maybe<Scalars["ID"]>;
    checkedInByUserId_ends_with?: Maybe<Scalars["ID"]>;
    checkedInByUserId_not_ends_with?: Maybe<Scalars["ID"]>;
    searchableUpper?: Maybe<Scalars["String"]>;
    searchableUpper_not?: Maybe<Scalars["String"]>;
    searchableUpper_in?: Maybe<Array<Scalars["String"]>>;
    searchableUpper_not_in?: Maybe<Array<Scalars["String"]>>;
    searchableUpper_lt?: Maybe<Scalars["String"]>;
    searchableUpper_lte?: Maybe<Scalars["String"]>;
    searchableUpper_gt?: Maybe<Scalars["String"]>;
    searchableUpper_gte?: Maybe<Scalars["String"]>;
    searchableUpper_contains?: Maybe<Scalars["String"]>;
    searchableUpper_not_contains?: Maybe<Scalars["String"]>;
    searchableUpper_starts_with?: Maybe<Scalars["String"]>;
    searchableUpper_not_starts_with?: Maybe<Scalars["String"]>;
    searchableUpper_ends_with?: Maybe<Scalars["String"]>;
    searchableUpper_not_ends_with?: Maybe<Scalars["String"]>;
    customerMemberships_some?: Maybe<ICustomerMembershipWhereInput>;
    selfCheckInOpensAt?: Maybe<Scalars["DateTime"]>;
    selfCheckInOpensAt_not?: Maybe<Scalars["DateTime"]>;
    selfCheckInOpensAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    selfCheckInOpensAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    selfCheckInOpensAt_lt?: Maybe<Scalars["DateTime"]>;
    selfCheckInOpensAt_lte?: Maybe<Scalars["DateTime"]>;
    selfCheckInOpensAt_gt?: Maybe<Scalars["DateTime"]>;
    selfCheckInOpensAt_gte?: Maybe<Scalars["DateTime"]>;
    selfCheckInClosesAt?: Maybe<Scalars["DateTime"]>;
    selfCheckInClosesAt_not?: Maybe<Scalars["DateTime"]>;
    selfCheckInClosesAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    selfCheckInClosesAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    selfCheckInClosesAt_lt?: Maybe<Scalars["DateTime"]>;
    selfCheckInClosesAt_lte?: Maybe<Scalars["DateTime"]>;
    selfCheckInClosesAt_gt?: Maybe<Scalars["DateTime"]>;
    selfCheckInClosesAt_gte?: Maybe<Scalars["DateTime"]>;
    isSelfCheckedIn?: Maybe<Scalars["Boolean"]>;
    isSelfCheckedIn_not?: Maybe<Scalars["Boolean"]>;
    AND?: Maybe<Array<IBookingWhereInput>>;
};

export type IBraintreeClientToken = {
    __typename?: "BraintreeClientToken";
    token: Scalars["String"];
};

export type ICheckInInfo = {
    __typename?: "CheckInInfo";
    isCheckInAvailable: Scalars["Boolean"];
    isAlreadyCheckedIn: Scalars["Boolean"];
    preCheckInInstruction?: Maybe<Scalars["String"]>;
    postCheckInInstruction?: Maybe<Scalars["String"]>;
    bookingReference?: Maybe<Scalars["String"]>;
    selfCheckInOpensAt?: Maybe<Scalars["DateTime"]>;
    selfCheckInClosesAt?: Maybe<Scalars["DateTime"]>;
    activityStartsAt: Scalars["String"];
    activityName: Scalars["String"];
    resourceName: Scalars["String"];
};

export type ICompleteTransactionFromBasketInput = {
    basketId: Scalars["ID"];
    sendCustomerEmails?: Maybe<Scalars["Boolean"]>;
    sendAdminEmails?: Maybe<Scalars["Boolean"]>;
    braintreeNonce?: Maybe<Scalars["String"]>;
    basketItemIdBookingNamesPairsInput?: Maybe<
        Array<IBasketItemIdBookingNamesPairInput>
    >;
};

export type ICompleteTransactionFromBasketResponse = {
    __typename?: "CompleteTransactionFromBasketResponse";
    bookings?: Maybe<Array<IBooking>>;
    customerMemberships?: Maybe<Array<ICustomerMembership>>;
    transactionId: Scalars["ID"];
    isSuccessful: Scalars["Boolean"];
    errorMessage?: Maybe<Scalars["String"]>;
};

export type ICoupon = {
    __typename?: "Coupon";
    id: Scalars["ID"];
    organisation: IOrganisation;
    discount: IDiscount;
    name: Scalars["String"];
    couponCode: Scalars["String"];
    isSingleUse: Scalars["Boolean"];
    isSingleUsePerCustomer: Scalars["Boolean"];
    isActive: Scalars["Boolean"];
    validFrom?: Maybe<Scalars["DateTime"]>;
    validTo?: Maybe<Scalars["DateTime"]>;
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
};

export type ICouponWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    organisation?: Maybe<IOrganisationWhereInput>;
    discount?: Maybe<IDiscountWhereInput>;
    name?: Maybe<Scalars["String"]>;
    name_not?: Maybe<Scalars["String"]>;
    name_in?: Maybe<Array<Scalars["String"]>>;
    name_not_in?: Maybe<Array<Scalars["String"]>>;
    name_lt?: Maybe<Scalars["String"]>;
    name_lte?: Maybe<Scalars["String"]>;
    name_gt?: Maybe<Scalars["String"]>;
    name_gte?: Maybe<Scalars["String"]>;
    name_contains?: Maybe<Scalars["String"]>;
    name_not_contains?: Maybe<Scalars["String"]>;
    name_starts_with?: Maybe<Scalars["String"]>;
    name_not_starts_with?: Maybe<Scalars["String"]>;
    name_ends_with?: Maybe<Scalars["String"]>;
    name_not_ends_with?: Maybe<Scalars["String"]>;
    couponCode?: Maybe<Scalars["String"]>;
    couponCode_not?: Maybe<Scalars["String"]>;
    couponCode_in?: Maybe<Array<Scalars["String"]>>;
    couponCode_not_in?: Maybe<Array<Scalars["String"]>>;
    couponCode_lt?: Maybe<Scalars["String"]>;
    couponCode_lte?: Maybe<Scalars["String"]>;
    couponCode_gt?: Maybe<Scalars["String"]>;
    couponCode_gte?: Maybe<Scalars["String"]>;
    couponCode_contains?: Maybe<Scalars["String"]>;
    couponCode_not_contains?: Maybe<Scalars["String"]>;
    couponCode_starts_with?: Maybe<Scalars["String"]>;
    couponCode_not_starts_with?: Maybe<Scalars["String"]>;
    couponCode_ends_with?: Maybe<Scalars["String"]>;
    couponCode_not_ends_with?: Maybe<Scalars["String"]>;
    isSingleUse?: Maybe<Scalars["Boolean"]>;
    isSingleUse_not?: Maybe<Scalars["Boolean"]>;
    isSingleUsePerCustomer?: Maybe<Scalars["Boolean"]>;
    isSingleUsePerCustomer_not?: Maybe<Scalars["Boolean"]>;
    isActive?: Maybe<Scalars["Boolean"]>;
    isActive_not?: Maybe<Scalars["Boolean"]>;
    validFrom?: Maybe<Scalars["DateTime"]>;
    validFrom_not?: Maybe<Scalars["DateTime"]>;
    validFrom_in?: Maybe<Array<Scalars["DateTime"]>>;
    validFrom_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    validFrom_lt?: Maybe<Scalars["DateTime"]>;
    validFrom_lte?: Maybe<Scalars["DateTime"]>;
    validFrom_gt?: Maybe<Scalars["DateTime"]>;
    validFrom_gte?: Maybe<Scalars["DateTime"]>;
    validTo?: Maybe<Scalars["DateTime"]>;
    validTo_not?: Maybe<Scalars["DateTime"]>;
    validTo_in?: Maybe<Array<Scalars["DateTime"]>>;
    validTo_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    validTo_lt?: Maybe<Scalars["DateTime"]>;
    validTo_lte?: Maybe<Scalars["DateTime"]>;
    validTo_gt?: Maybe<Scalars["DateTime"]>;
    validTo_gte?: Maybe<Scalars["DateTime"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    createdAt_not?: Maybe<Scalars["DateTime"]>;
    createdAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_lt?: Maybe<Scalars["DateTime"]>;
    createdAt_lte?: Maybe<Scalars["DateTime"]>;
    createdAt_gt?: Maybe<Scalars["DateTime"]>;
    createdAt_gte?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    updatedAt_not?: Maybe<Scalars["DateTime"]>;
    updatedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_lt?: Maybe<Scalars["DateTime"]>;
    updatedAt_lte?: Maybe<Scalars["DateTime"]>;
    updatedAt_gt?: Maybe<Scalars["DateTime"]>;
    updatedAt_gte?: Maybe<Scalars["DateTime"]>;
    AND?: Maybe<Array<ICouponWhereInput>>;
};

export type ICreateCustomerWithCredentialInput = {
    organisationId: Scalars["ID"];
    email: Scalars["String"];
    firstName: Scalars["String"];
    lastName: Scalars["String"];
    postcode: Scalars["String"];
    phone: Scalars["String"];
    password: Scalars["String"];
    doesReceiveEmail?: Maybe<Scalars["Boolean"]>;
    doesReceiveSms?: Maybe<Scalars["Boolean"]>;
};

export type ICredential = {
    __typename?: "Credential";
    id: Scalars["ID"];
    customer: ICustomer;
    bookings?: Maybe<Array<IBooking>>;
    isPrimaryCredential: Scalars["Boolean"];
    login: Scalars["String"];
    email: Scalars["String"];
    firstName: Scalars["String"];
    lastName: Scalars["String"];
    mobileNumber: Scalars["String"];
    doesReceiveEmails: Scalars["Boolean"];
    doesReceiveSms: Scalars["Boolean"];
    password?: Maybe<IPassword>;
    isLockedOut: Scalars["Boolean"];
    isDisabled: Scalars["Boolean"];
    lastLoginAt?: Maybe<Scalars["DateTime"]>;
    securityStamp: Scalars["String"];
    passwordResetRequestedAt?: Maybe<Scalars["DateTime"]>;
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
    searchableUpper?: Maybe<Scalars["String"]>;
};

export type ICredentialBookingsArgs = {
    where?: Maybe<IBookingWhereInput>;
    orderBy?: Maybe<IBookingOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export enum ICredentialOrderByInput {
    IdAsc = "id_ASC",
    IdDesc = "id_DESC",
    IsPrimaryCredentialAsc = "isPrimaryCredential_ASC",
    IsPrimaryCredentialDesc = "isPrimaryCredential_DESC",
    LoginAsc = "login_ASC",
    LoginDesc = "login_DESC",
    EmailAsc = "email_ASC",
    EmailDesc = "email_DESC",
    FirstNameAsc = "firstName_ASC",
    FirstNameDesc = "firstName_DESC",
    LastNameAsc = "lastName_ASC",
    LastNameDesc = "lastName_DESC",
    MobileNumberAsc = "mobileNumber_ASC",
    MobileNumberDesc = "mobileNumber_DESC",
    DoesReceiveEmailsAsc = "doesReceiveEmails_ASC",
    DoesReceiveEmailsDesc = "doesReceiveEmails_DESC",
    DoesReceiveSmsAsc = "doesReceiveSms_ASC",
    DoesReceiveSmsDesc = "doesReceiveSms_DESC",
    IsLockedOutAsc = "isLockedOut_ASC",
    IsLockedOutDesc = "isLockedOut_DESC",
    IsDisabledAsc = "isDisabled_ASC",
    IsDisabledDesc = "isDisabled_DESC",
    LastLoginAtAsc = "lastLoginAt_ASC",
    LastLoginAtDesc = "lastLoginAt_DESC",
    SecurityStampAsc = "securityStamp_ASC",
    SecurityStampDesc = "securityStamp_DESC",
    PasswordResetRequestedAtAsc = "passwordResetRequestedAt_ASC",
    PasswordResetRequestedAtDesc = "passwordResetRequestedAt_DESC",
    CreatedAtAsc = "createdAt_ASC",
    CreatedAtDesc = "createdAt_DESC",
    UpdatedAtAsc = "updatedAt_ASC",
    UpdatedAtDesc = "updatedAt_DESC",
    SearchableUpperAsc = "searchableUpper_ASC",
    SearchableUpperDesc = "searchableUpper_DESC"
}

export type ICredentialWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    customer?: Maybe<ICustomerWhereInput>;
    bookings_some?: Maybe<IBookingWhereInput>;
    isPrimaryCredential?: Maybe<Scalars["Boolean"]>;
    isPrimaryCredential_not?: Maybe<Scalars["Boolean"]>;
    login?: Maybe<Scalars["String"]>;
    login_not?: Maybe<Scalars["String"]>;
    login_in?: Maybe<Array<Scalars["String"]>>;
    login_not_in?: Maybe<Array<Scalars["String"]>>;
    login_lt?: Maybe<Scalars["String"]>;
    login_lte?: Maybe<Scalars["String"]>;
    login_gt?: Maybe<Scalars["String"]>;
    login_gte?: Maybe<Scalars["String"]>;
    login_contains?: Maybe<Scalars["String"]>;
    login_not_contains?: Maybe<Scalars["String"]>;
    login_starts_with?: Maybe<Scalars["String"]>;
    login_not_starts_with?: Maybe<Scalars["String"]>;
    login_ends_with?: Maybe<Scalars["String"]>;
    login_not_ends_with?: Maybe<Scalars["String"]>;
    email?: Maybe<Scalars["String"]>;
    email_not?: Maybe<Scalars["String"]>;
    email_in?: Maybe<Array<Scalars["String"]>>;
    email_not_in?: Maybe<Array<Scalars["String"]>>;
    email_lt?: Maybe<Scalars["String"]>;
    email_lte?: Maybe<Scalars["String"]>;
    email_gt?: Maybe<Scalars["String"]>;
    email_gte?: Maybe<Scalars["String"]>;
    email_contains?: Maybe<Scalars["String"]>;
    email_not_contains?: Maybe<Scalars["String"]>;
    email_starts_with?: Maybe<Scalars["String"]>;
    email_not_starts_with?: Maybe<Scalars["String"]>;
    email_ends_with?: Maybe<Scalars["String"]>;
    email_not_ends_with?: Maybe<Scalars["String"]>;
    firstName?: Maybe<Scalars["String"]>;
    firstName_not?: Maybe<Scalars["String"]>;
    firstName_in?: Maybe<Array<Scalars["String"]>>;
    firstName_not_in?: Maybe<Array<Scalars["String"]>>;
    firstName_lt?: Maybe<Scalars["String"]>;
    firstName_lte?: Maybe<Scalars["String"]>;
    firstName_gt?: Maybe<Scalars["String"]>;
    firstName_gte?: Maybe<Scalars["String"]>;
    firstName_contains?: Maybe<Scalars["String"]>;
    firstName_not_contains?: Maybe<Scalars["String"]>;
    firstName_starts_with?: Maybe<Scalars["String"]>;
    firstName_not_starts_with?: Maybe<Scalars["String"]>;
    firstName_ends_with?: Maybe<Scalars["String"]>;
    firstName_not_ends_with?: Maybe<Scalars["String"]>;
    lastName?: Maybe<Scalars["String"]>;
    lastName_not?: Maybe<Scalars["String"]>;
    lastName_in?: Maybe<Array<Scalars["String"]>>;
    lastName_not_in?: Maybe<Array<Scalars["String"]>>;
    lastName_lt?: Maybe<Scalars["String"]>;
    lastName_lte?: Maybe<Scalars["String"]>;
    lastName_gt?: Maybe<Scalars["String"]>;
    lastName_gte?: Maybe<Scalars["String"]>;
    lastName_contains?: Maybe<Scalars["String"]>;
    lastName_not_contains?: Maybe<Scalars["String"]>;
    lastName_starts_with?: Maybe<Scalars["String"]>;
    lastName_not_starts_with?: Maybe<Scalars["String"]>;
    lastName_ends_with?: Maybe<Scalars["String"]>;
    lastName_not_ends_with?: Maybe<Scalars["String"]>;
    mobileNumber?: Maybe<Scalars["String"]>;
    mobileNumber_not?: Maybe<Scalars["String"]>;
    mobileNumber_in?: Maybe<Array<Scalars["String"]>>;
    mobileNumber_not_in?: Maybe<Array<Scalars["String"]>>;
    mobileNumber_lt?: Maybe<Scalars["String"]>;
    mobileNumber_lte?: Maybe<Scalars["String"]>;
    mobileNumber_gt?: Maybe<Scalars["String"]>;
    mobileNumber_gte?: Maybe<Scalars["String"]>;
    mobileNumber_contains?: Maybe<Scalars["String"]>;
    mobileNumber_not_contains?: Maybe<Scalars["String"]>;
    mobileNumber_starts_with?: Maybe<Scalars["String"]>;
    mobileNumber_not_starts_with?: Maybe<Scalars["String"]>;
    mobileNumber_ends_with?: Maybe<Scalars["String"]>;
    mobileNumber_not_ends_with?: Maybe<Scalars["String"]>;
    doesReceiveEmails?: Maybe<Scalars["Boolean"]>;
    doesReceiveEmails_not?: Maybe<Scalars["Boolean"]>;
    doesReceiveSms?: Maybe<Scalars["Boolean"]>;
    doesReceiveSms_not?: Maybe<Scalars["Boolean"]>;
    password?: Maybe<IPasswordWhereInput>;
    isLockedOut?: Maybe<Scalars["Boolean"]>;
    isLockedOut_not?: Maybe<Scalars["Boolean"]>;
    isDisabled?: Maybe<Scalars["Boolean"]>;
    isDisabled_not?: Maybe<Scalars["Boolean"]>;
    lastLoginAt?: Maybe<Scalars["DateTime"]>;
    lastLoginAt_not?: Maybe<Scalars["DateTime"]>;
    lastLoginAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    lastLoginAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    lastLoginAt_lt?: Maybe<Scalars["DateTime"]>;
    lastLoginAt_lte?: Maybe<Scalars["DateTime"]>;
    lastLoginAt_gt?: Maybe<Scalars["DateTime"]>;
    lastLoginAt_gte?: Maybe<Scalars["DateTime"]>;
    securityStamp?: Maybe<Scalars["String"]>;
    securityStamp_not?: Maybe<Scalars["String"]>;
    securityStamp_in?: Maybe<Array<Scalars["String"]>>;
    securityStamp_not_in?: Maybe<Array<Scalars["String"]>>;
    securityStamp_lt?: Maybe<Scalars["String"]>;
    securityStamp_lte?: Maybe<Scalars["String"]>;
    securityStamp_gt?: Maybe<Scalars["String"]>;
    securityStamp_gte?: Maybe<Scalars["String"]>;
    securityStamp_contains?: Maybe<Scalars["String"]>;
    securityStamp_not_contains?: Maybe<Scalars["String"]>;
    securityStamp_starts_with?: Maybe<Scalars["String"]>;
    securityStamp_not_starts_with?: Maybe<Scalars["String"]>;
    securityStamp_ends_with?: Maybe<Scalars["String"]>;
    securityStamp_not_ends_with?: Maybe<Scalars["String"]>;
    passwordResetRequestedAt?: Maybe<Scalars["DateTime"]>;
    passwordResetRequestedAt_not?: Maybe<Scalars["DateTime"]>;
    passwordResetRequestedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    passwordResetRequestedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    passwordResetRequestedAt_lt?: Maybe<Scalars["DateTime"]>;
    passwordResetRequestedAt_lte?: Maybe<Scalars["DateTime"]>;
    passwordResetRequestedAt_gt?: Maybe<Scalars["DateTime"]>;
    passwordResetRequestedAt_gte?: Maybe<Scalars["DateTime"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    createdAt_not?: Maybe<Scalars["DateTime"]>;
    createdAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_lt?: Maybe<Scalars["DateTime"]>;
    createdAt_lte?: Maybe<Scalars["DateTime"]>;
    createdAt_gt?: Maybe<Scalars["DateTime"]>;
    createdAt_gte?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    updatedAt_not?: Maybe<Scalars["DateTime"]>;
    updatedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_lt?: Maybe<Scalars["DateTime"]>;
    updatedAt_lte?: Maybe<Scalars["DateTime"]>;
    updatedAt_gt?: Maybe<Scalars["DateTime"]>;
    updatedAt_gte?: Maybe<Scalars["DateTime"]>;
    searchableUpper?: Maybe<Scalars["String"]>;
    searchableUpper_not?: Maybe<Scalars["String"]>;
    searchableUpper_in?: Maybe<Array<Scalars["String"]>>;
    searchableUpper_not_in?: Maybe<Array<Scalars["String"]>>;
    searchableUpper_lt?: Maybe<Scalars["String"]>;
    searchableUpper_lte?: Maybe<Scalars["String"]>;
    searchableUpper_gt?: Maybe<Scalars["String"]>;
    searchableUpper_gte?: Maybe<Scalars["String"]>;
    searchableUpper_contains?: Maybe<Scalars["String"]>;
    searchableUpper_not_contains?: Maybe<Scalars["String"]>;
    searchableUpper_starts_with?: Maybe<Scalars["String"]>;
    searchableUpper_not_starts_with?: Maybe<Scalars["String"]>;
    searchableUpper_ends_with?: Maybe<Scalars["String"]>;
    searchableUpper_not_ends_with?: Maybe<Scalars["String"]>;
    AND?: Maybe<Array<ICredentialWhereInput>>;
};

export type ICustomer = {
    __typename?: "Customer";
    id: Scalars["ID"];
    organisation: IOrganisation;
    transactions?: Maybe<Array<ITransaction>>;
    credentials?: Maybe<Array<ICredential>>;
    bookings?: Maybe<Array<IBooking>>;
    benefits?: Maybe<Array<ICustomerBenefit>>;
    customerNotes?: Maybe<Array<INote>>;
    adminNotes?: Maybe<Array<INote>>;
    midAdminNotes?: Maybe<Array<INote>>;
    name: Scalars["String"];
    isDisabled: Scalars["Boolean"];
    postcode: Scalars["String"];
    addressInfo?: Maybe<Scalars["String"]>;
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
    isSelfCateringCustomer: Scalars["Boolean"];
    selfCateringIdentifer?: Maybe<Scalars["String"]>;
    relatedSelfCateringBookingIds: Array<Scalars["String"]>;
    usedCouponIds: Array<Scalars["ID"]>;
    searchableUpper?: Maybe<Scalars["String"]>;
    memberships?: Maybe<Array<ICustomerMembership>>;
    sharedMemberships?: Maybe<Array<ICustomerMembershipSharing>>;
    membershipsSharedWithMe?: Maybe<Array<ICustomerMembershipSharing>>;
};

export type ICustomerTransactionsArgs = {
    where?: Maybe<ITransactionWhereInput>;
    orderBy?: Maybe<ITransactionOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type ICustomerCredentialsArgs = {
    where?: Maybe<ICredentialWhereInput>;
    orderBy?: Maybe<ICredentialOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type ICustomerBookingsArgs = {
    where?: Maybe<IBookingWhereInput>;
    orderBy?: Maybe<IBookingOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type ICustomerBenefitsArgs = {
    where?: Maybe<ICustomerBenefitWhereInput>;
    orderBy?: Maybe<ICustomerBenefitOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type ICustomerCustomerNotesArgs = {
    where?: Maybe<INoteWhereInput>;
    orderBy?: Maybe<INoteOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type ICustomerAdminNotesArgs = {
    where?: Maybe<INoteWhereInput>;
    orderBy?: Maybe<INoteOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type ICustomerMidAdminNotesArgs = {
    where?: Maybe<INoteWhereInput>;
    orderBy?: Maybe<INoteOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type ICustomerMembershipsArgs = {
    where?: Maybe<ICustomerMembershipWhereInput>;
    orderBy?: Maybe<ICustomerMembershipOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type ICustomerSharedMembershipsArgs = {
    where?: Maybe<ICustomerMembershipSharingWhereInput>;
    orderBy?: Maybe<ICustomerMembershipSharingOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type ICustomerMembershipsSharedWithMeArgs = {
    where?: Maybe<ICustomerMembershipSharingWhereInput>;
    orderBy?: Maybe<ICustomerMembershipSharingOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type ICustomerBenefit = {
    __typename?: "CustomerBenefit";
    id: Scalars["ID"];
    name: Scalars["String"];
    description?: Maybe<Scalars["String"]>;
    expiresAt: Scalars["DateTime"];
    customer?: Maybe<ICustomer>;
    discount: IDiscount;
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
};

export enum ICustomerBenefitOrderByInput {
    IdAsc = "id_ASC",
    IdDesc = "id_DESC",
    NameAsc = "name_ASC",
    NameDesc = "name_DESC",
    DescriptionAsc = "description_ASC",
    DescriptionDesc = "description_DESC",
    ExpiresAtAsc = "expiresAt_ASC",
    ExpiresAtDesc = "expiresAt_DESC",
    CreatedAtAsc = "createdAt_ASC",
    CreatedAtDesc = "createdAt_DESC",
    UpdatedAtAsc = "updatedAt_ASC",
    UpdatedAtDesc = "updatedAt_DESC"
}

export type ICustomerBenefitWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    name?: Maybe<Scalars["String"]>;
    name_not?: Maybe<Scalars["String"]>;
    name_in?: Maybe<Array<Scalars["String"]>>;
    name_not_in?: Maybe<Array<Scalars["String"]>>;
    name_lt?: Maybe<Scalars["String"]>;
    name_lte?: Maybe<Scalars["String"]>;
    name_gt?: Maybe<Scalars["String"]>;
    name_gte?: Maybe<Scalars["String"]>;
    name_contains?: Maybe<Scalars["String"]>;
    name_not_contains?: Maybe<Scalars["String"]>;
    name_starts_with?: Maybe<Scalars["String"]>;
    name_not_starts_with?: Maybe<Scalars["String"]>;
    name_ends_with?: Maybe<Scalars["String"]>;
    name_not_ends_with?: Maybe<Scalars["String"]>;
    description?: Maybe<Scalars["String"]>;
    description_not?: Maybe<Scalars["String"]>;
    description_in?: Maybe<Array<Scalars["String"]>>;
    description_not_in?: Maybe<Array<Scalars["String"]>>;
    description_lt?: Maybe<Scalars["String"]>;
    description_lte?: Maybe<Scalars["String"]>;
    description_gt?: Maybe<Scalars["String"]>;
    description_gte?: Maybe<Scalars["String"]>;
    description_contains?: Maybe<Scalars["String"]>;
    description_not_contains?: Maybe<Scalars["String"]>;
    description_starts_with?: Maybe<Scalars["String"]>;
    description_not_starts_with?: Maybe<Scalars["String"]>;
    description_ends_with?: Maybe<Scalars["String"]>;
    description_not_ends_with?: Maybe<Scalars["String"]>;
    expiresAt?: Maybe<Scalars["DateTime"]>;
    expiresAt_not?: Maybe<Scalars["DateTime"]>;
    expiresAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    expiresAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    expiresAt_lt?: Maybe<Scalars["DateTime"]>;
    expiresAt_lte?: Maybe<Scalars["DateTime"]>;
    expiresAt_gt?: Maybe<Scalars["DateTime"]>;
    expiresAt_gte?: Maybe<Scalars["DateTime"]>;
    customer?: Maybe<ICustomerWhereInput>;
    discount?: Maybe<IDiscountWhereInput>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    createdAt_not?: Maybe<Scalars["DateTime"]>;
    createdAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_lt?: Maybe<Scalars["DateTime"]>;
    createdAt_lte?: Maybe<Scalars["DateTime"]>;
    createdAt_gt?: Maybe<Scalars["DateTime"]>;
    createdAt_gte?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    updatedAt_not?: Maybe<Scalars["DateTime"]>;
    updatedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_lt?: Maybe<Scalars["DateTime"]>;
    updatedAt_lte?: Maybe<Scalars["DateTime"]>;
    updatedAt_gt?: Maybe<Scalars["DateTime"]>;
    updatedAt_gte?: Maybe<Scalars["DateTime"]>;
    AND?: Maybe<Array<ICustomerBenefitWhereInput>>;
};

export type ICustomerMembership = {
    __typename?: "CustomerMembership";
    id: Scalars["ID"];
    name: Scalars["String"];
    membership: IMembership;
    customer: ICustomer;
    transactions?: Maybe<Array<ITransaction>>;
    hasBeenActivated?: Maybe<Scalars["Boolean"]>;
    activatedAt?: Maybe<Scalars["DateTime"]>;
    isDisabled?: Maybe<Scalars["Boolean"]>;
    disabledAt?: Maybe<Scalars["DateTime"]>;
    disabledByUser?: Maybe<IUser>;
    expiresAt?: Maybe<Scalars["DateTime"]>;
    completedAt?: Maybe<Scalars["DateTime"]>;
    amountPaid: Scalars["Float"];
    photoUploadedAt?: Maybe<Scalars["DateTime"]>;
    photoUpdatedByUser?: Maybe<IUser>;
    photoUpdatedByUserAt?: Maybe<Scalars["DateTime"]>;
    isActivationEmailSent?: Maybe<Scalars["Boolean"]>;
    activationEmailSentAt?: Maybe<Scalars["DateTime"]>;
    renewalAvailableAt?: Maybe<Scalars["DateTime"]>;
    renewalReminder1SendOn?: Maybe<Scalars["DateTime"]>;
    hasRenewalReminder1BeenSent?: Maybe<Scalars["Boolean"]>;
    renewalReminder2SendOn?: Maybe<Scalars["DateTime"]>;
    hasRenewalReminder2BeenSent?: Maybe<Scalars["Boolean"]>;
    canPhotoBeChanged?: Maybe<Scalars["Boolean"]>;
    photoLastChangedAt?: Maybe<Scalars["DateTime"]>;
    customerNotes?: Maybe<Array<INote>>;
    adminNotes?: Maybe<Array<INote>>;
    midAdminNotes?: Maybe<Array<INote>>;
    renewals?: Maybe<Array<ICustomerMembershipRenewal>>;
    createdByUser?: Maybe<IUser>;
    bookings?: Maybe<Array<IBooking>>;
    ownedMembershipSharing?: Maybe<Array<ICustomerMembershipSharing>>;
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
    photo: IPhotoData;
};

export type ICustomerMembershipTransactionsArgs = {
    where?: Maybe<ITransactionWhereInput>;
    orderBy?: Maybe<ITransactionOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type ICustomerMembershipCustomerNotesArgs = {
    where?: Maybe<INoteWhereInput>;
    orderBy?: Maybe<INoteOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type ICustomerMembershipAdminNotesArgs = {
    where?: Maybe<INoteWhereInput>;
    orderBy?: Maybe<INoteOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type ICustomerMembershipMidAdminNotesArgs = {
    where?: Maybe<INoteWhereInput>;
    orderBy?: Maybe<INoteOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type ICustomerMembershipRenewalsArgs = {
    where?: Maybe<ICustomerMembershipRenewalWhereInput>;
    orderBy?: Maybe<ICustomerMembershipRenewalOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type ICustomerMembershipBookingsArgs = {
    where?: Maybe<IBookingWhereInput>;
    orderBy?: Maybe<IBookingOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type ICustomerMembershipOwnedMembershipSharingArgs = {
    where?: Maybe<ICustomerMembershipSharingWhereInput>;
    orderBy?: Maybe<ICustomerMembershipSharingOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export enum ICustomerMembershipOrderByInput {
    IdAsc = "id_ASC",
    IdDesc = "id_DESC",
    NameAsc = "name_ASC",
    NameDesc = "name_DESC",
    HasBeenActivatedAsc = "hasBeenActivated_ASC",
    HasBeenActivatedDesc = "hasBeenActivated_DESC",
    ActivatedAtAsc = "activatedAt_ASC",
    ActivatedAtDesc = "activatedAt_DESC",
    IsDisabledAsc = "isDisabled_ASC",
    IsDisabledDesc = "isDisabled_DESC",
    DisabledAtAsc = "disabledAt_ASC",
    DisabledAtDesc = "disabledAt_DESC",
    ExpiresAtAsc = "expiresAt_ASC",
    ExpiresAtDesc = "expiresAt_DESC",
    CompletedAtAsc = "completedAt_ASC",
    CompletedAtDesc = "completedAt_DESC",
    AmountPaidAsc = "amountPaid_ASC",
    AmountPaidDesc = "amountPaid_DESC",
    PhotoUploadedAtAsc = "photoUploadedAt_ASC",
    PhotoUploadedAtDesc = "photoUploadedAt_DESC",
    PhotoUpdatedByUserAtAsc = "photoUpdatedByUserAt_ASC",
    PhotoUpdatedByUserAtDesc = "photoUpdatedByUserAt_DESC",
    IsActivationEmailSentAsc = "isActivationEmailSent_ASC",
    IsActivationEmailSentDesc = "isActivationEmailSent_DESC",
    ActivationEmailSentAtAsc = "activationEmailSentAt_ASC",
    ActivationEmailSentAtDesc = "activationEmailSentAt_DESC",
    RenewalAvailableAtAsc = "renewalAvailableAt_ASC",
    RenewalAvailableAtDesc = "renewalAvailableAt_DESC",
    RenewalReminder1SendOnAsc = "renewalReminder1SendOn_ASC",
    RenewalReminder1SendOnDesc = "renewalReminder1SendOn_DESC",
    HasRenewalReminder1BeenSentAsc = "hasRenewalReminder1BeenSent_ASC",
    HasRenewalReminder1BeenSentDesc = "hasRenewalReminder1BeenSent_DESC",
    RenewalReminder2SendOnAsc = "renewalReminder2SendOn_ASC",
    RenewalReminder2SendOnDesc = "renewalReminder2SendOn_DESC",
    HasRenewalReminder2BeenSentAsc = "hasRenewalReminder2BeenSent_ASC",
    HasRenewalReminder2BeenSentDesc = "hasRenewalReminder2BeenSent_DESC",
    CanPhotoBeChangedAsc = "canPhotoBeChanged_ASC",
    CanPhotoBeChangedDesc = "canPhotoBeChanged_DESC",
    PhotoLastChangedAtAsc = "photoLastChangedAt_ASC",
    PhotoLastChangedAtDesc = "photoLastChangedAt_DESC",
    CreatedAtAsc = "createdAt_ASC",
    CreatedAtDesc = "createdAt_DESC",
    UpdatedAtAsc = "updatedAt_ASC",
    UpdatedAtDesc = "updatedAt_DESC"
}

export type ICustomerMembershipRenewal = {
    __typename?: "CustomerMembershipRenewal";
    id: Scalars["ID"];
    customerMembership: ICustomerMembership;
    transactions?: Maybe<Array<ITransaction>>;
    completedAt?: Maybe<Scalars["DateTime"]>;
    amountPaid: Scalars["Float"];
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
};

export type ICustomerMembershipRenewalTransactionsArgs = {
    where?: Maybe<ITransactionWhereInput>;
    orderBy?: Maybe<ITransactionOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export enum ICustomerMembershipRenewalOrderByInput {
    IdAsc = "id_ASC",
    IdDesc = "id_DESC",
    CompletedAtAsc = "completedAt_ASC",
    CompletedAtDesc = "completedAt_DESC",
    AmountPaidAsc = "amountPaid_ASC",
    AmountPaidDesc = "amountPaid_DESC",
    CreatedAtAsc = "createdAt_ASC",
    CreatedAtDesc = "createdAt_DESC",
    UpdatedAtAsc = "updatedAt_ASC",
    UpdatedAtDesc = "updatedAt_DESC"
}

export type ICustomerMembershipRenewalWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    customerMembership?: Maybe<ICustomerMembershipWhereInput>;
    transactions_some?: Maybe<ITransactionWhereInput>;
    completedAt?: Maybe<Scalars["DateTime"]>;
    completedAt_not?: Maybe<Scalars["DateTime"]>;
    completedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    completedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    completedAt_lt?: Maybe<Scalars["DateTime"]>;
    completedAt_lte?: Maybe<Scalars["DateTime"]>;
    completedAt_gt?: Maybe<Scalars["DateTime"]>;
    completedAt_gte?: Maybe<Scalars["DateTime"]>;
    amountPaid?: Maybe<Scalars["Float"]>;
    amountPaid_not?: Maybe<Scalars["Float"]>;
    amountPaid_in?: Maybe<Array<Scalars["Float"]>>;
    amountPaid_not_in?: Maybe<Array<Scalars["Float"]>>;
    amountPaid_lt?: Maybe<Scalars["Float"]>;
    amountPaid_lte?: Maybe<Scalars["Float"]>;
    amountPaid_gt?: Maybe<Scalars["Float"]>;
    amountPaid_gte?: Maybe<Scalars["Float"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    createdAt_not?: Maybe<Scalars["DateTime"]>;
    createdAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_lt?: Maybe<Scalars["DateTime"]>;
    createdAt_lte?: Maybe<Scalars["DateTime"]>;
    createdAt_gt?: Maybe<Scalars["DateTime"]>;
    createdAt_gte?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    updatedAt_not?: Maybe<Scalars["DateTime"]>;
    updatedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_lt?: Maybe<Scalars["DateTime"]>;
    updatedAt_lte?: Maybe<Scalars["DateTime"]>;
    updatedAt_gt?: Maybe<Scalars["DateTime"]>;
    updatedAt_gte?: Maybe<Scalars["DateTime"]>;
    AND?: Maybe<Array<ICustomerMembershipRenewalWhereInput>>;
};

export type ICustomerMembershipSharing = {
    __typename?: "CustomerMembershipSharing";
    id: Scalars["ID"];
    membershipOwner: ICustomer;
    sharedCustomerMembership: ICustomerMembership;
    sharedWithCustomer: ICustomer;
    sharedToEmail: Scalars["String"];
    isConfirmed: Scalars["Boolean"];
    confirmedAt?: Maybe<Scalars["DateTime"]>;
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
};

export enum ICustomerMembershipSharingOrderByInput {
    IdAsc = "id_ASC",
    IdDesc = "id_DESC",
    SharedToEmailAsc = "sharedToEmail_ASC",
    SharedToEmailDesc = "sharedToEmail_DESC",
    IsConfirmedAsc = "isConfirmed_ASC",
    IsConfirmedDesc = "isConfirmed_DESC",
    ConfirmedAtAsc = "confirmedAt_ASC",
    ConfirmedAtDesc = "confirmedAt_DESC",
    CreatedAtAsc = "createdAt_ASC",
    CreatedAtDesc = "createdAt_DESC",
    UpdatedAtAsc = "updatedAt_ASC",
    UpdatedAtDesc = "updatedAt_DESC"
}

export type ICustomerMembershipSharingWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    membershipOwner?: Maybe<ICustomerWhereInput>;
    sharedCustomerMembership?: Maybe<ICustomerMembershipWhereInput>;
    sharedWithCustomer?: Maybe<ICustomerWhereInput>;
    sharedToEmail?: Maybe<Scalars["String"]>;
    sharedToEmail_not?: Maybe<Scalars["String"]>;
    sharedToEmail_in?: Maybe<Array<Scalars["String"]>>;
    sharedToEmail_not_in?: Maybe<Array<Scalars["String"]>>;
    sharedToEmail_lt?: Maybe<Scalars["String"]>;
    sharedToEmail_lte?: Maybe<Scalars["String"]>;
    sharedToEmail_gt?: Maybe<Scalars["String"]>;
    sharedToEmail_gte?: Maybe<Scalars["String"]>;
    sharedToEmail_contains?: Maybe<Scalars["String"]>;
    sharedToEmail_not_contains?: Maybe<Scalars["String"]>;
    sharedToEmail_starts_with?: Maybe<Scalars["String"]>;
    sharedToEmail_not_starts_with?: Maybe<Scalars["String"]>;
    sharedToEmail_ends_with?: Maybe<Scalars["String"]>;
    sharedToEmail_not_ends_with?: Maybe<Scalars["String"]>;
    isConfirmed?: Maybe<Scalars["Boolean"]>;
    isConfirmed_not?: Maybe<Scalars["Boolean"]>;
    confirmedAt?: Maybe<Scalars["DateTime"]>;
    confirmedAt_not?: Maybe<Scalars["DateTime"]>;
    confirmedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    confirmedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    confirmedAt_lt?: Maybe<Scalars["DateTime"]>;
    confirmedAt_lte?: Maybe<Scalars["DateTime"]>;
    confirmedAt_gt?: Maybe<Scalars["DateTime"]>;
    confirmedAt_gte?: Maybe<Scalars["DateTime"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    createdAt_not?: Maybe<Scalars["DateTime"]>;
    createdAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_lt?: Maybe<Scalars["DateTime"]>;
    createdAt_lte?: Maybe<Scalars["DateTime"]>;
    createdAt_gt?: Maybe<Scalars["DateTime"]>;
    createdAt_gte?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    updatedAt_not?: Maybe<Scalars["DateTime"]>;
    updatedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_lt?: Maybe<Scalars["DateTime"]>;
    updatedAt_lte?: Maybe<Scalars["DateTime"]>;
    updatedAt_gt?: Maybe<Scalars["DateTime"]>;
    updatedAt_gte?: Maybe<Scalars["DateTime"]>;
    AND?: Maybe<Array<ICustomerMembershipSharingWhereInput>>;
};

export type ICustomerMembershipWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    name?: Maybe<Scalars["String"]>;
    name_not?: Maybe<Scalars["String"]>;
    name_in?: Maybe<Array<Scalars["String"]>>;
    name_not_in?: Maybe<Array<Scalars["String"]>>;
    name_lt?: Maybe<Scalars["String"]>;
    name_lte?: Maybe<Scalars["String"]>;
    name_gt?: Maybe<Scalars["String"]>;
    name_gte?: Maybe<Scalars["String"]>;
    name_contains?: Maybe<Scalars["String"]>;
    name_not_contains?: Maybe<Scalars["String"]>;
    name_starts_with?: Maybe<Scalars["String"]>;
    name_not_starts_with?: Maybe<Scalars["String"]>;
    name_ends_with?: Maybe<Scalars["String"]>;
    name_not_ends_with?: Maybe<Scalars["String"]>;
    membership?: Maybe<IMembershipWhereInput>;
    customer?: Maybe<ICustomerWhereInput>;
    transactions_some?: Maybe<ITransactionWhereInput>;
    hasBeenActivated?: Maybe<Scalars["Boolean"]>;
    hasBeenActivated_not?: Maybe<Scalars["Boolean"]>;
    activatedAt?: Maybe<Scalars["DateTime"]>;
    activatedAt_not?: Maybe<Scalars["DateTime"]>;
    activatedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    activatedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    activatedAt_lt?: Maybe<Scalars["DateTime"]>;
    activatedAt_lte?: Maybe<Scalars["DateTime"]>;
    activatedAt_gt?: Maybe<Scalars["DateTime"]>;
    activatedAt_gte?: Maybe<Scalars["DateTime"]>;
    isDisabled?: Maybe<Scalars["Boolean"]>;
    isDisabled_not?: Maybe<Scalars["Boolean"]>;
    disabledAt?: Maybe<Scalars["DateTime"]>;
    disabledAt_not?: Maybe<Scalars["DateTime"]>;
    disabledAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    disabledAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    disabledAt_lt?: Maybe<Scalars["DateTime"]>;
    disabledAt_lte?: Maybe<Scalars["DateTime"]>;
    disabledAt_gt?: Maybe<Scalars["DateTime"]>;
    disabledAt_gte?: Maybe<Scalars["DateTime"]>;
    disabledByUser?: Maybe<IUserWhereInput>;
    expiresAt?: Maybe<Scalars["DateTime"]>;
    expiresAt_not?: Maybe<Scalars["DateTime"]>;
    expiresAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    expiresAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    expiresAt_lt?: Maybe<Scalars["DateTime"]>;
    expiresAt_lte?: Maybe<Scalars["DateTime"]>;
    expiresAt_gt?: Maybe<Scalars["DateTime"]>;
    expiresAt_gte?: Maybe<Scalars["DateTime"]>;
    completedAt?: Maybe<Scalars["DateTime"]>;
    completedAt_not?: Maybe<Scalars["DateTime"]>;
    completedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    completedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    completedAt_lt?: Maybe<Scalars["DateTime"]>;
    completedAt_lte?: Maybe<Scalars["DateTime"]>;
    completedAt_gt?: Maybe<Scalars["DateTime"]>;
    completedAt_gte?: Maybe<Scalars["DateTime"]>;
    amountPaid?: Maybe<Scalars["Float"]>;
    amountPaid_not?: Maybe<Scalars["Float"]>;
    amountPaid_in?: Maybe<Array<Scalars["Float"]>>;
    amountPaid_not_in?: Maybe<Array<Scalars["Float"]>>;
    amountPaid_lt?: Maybe<Scalars["Float"]>;
    amountPaid_lte?: Maybe<Scalars["Float"]>;
    amountPaid_gt?: Maybe<Scalars["Float"]>;
    amountPaid_gte?: Maybe<Scalars["Float"]>;
    photoUploadedAt?: Maybe<Scalars["DateTime"]>;
    photoUploadedAt_not?: Maybe<Scalars["DateTime"]>;
    photoUploadedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    photoUploadedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    photoUploadedAt_lt?: Maybe<Scalars["DateTime"]>;
    photoUploadedAt_lte?: Maybe<Scalars["DateTime"]>;
    photoUploadedAt_gt?: Maybe<Scalars["DateTime"]>;
    photoUploadedAt_gte?: Maybe<Scalars["DateTime"]>;
    photoUpdatedByUser?: Maybe<IUserWhereInput>;
    photoUpdatedByUserAt?: Maybe<Scalars["DateTime"]>;
    photoUpdatedByUserAt_not?: Maybe<Scalars["DateTime"]>;
    photoUpdatedByUserAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    photoUpdatedByUserAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    photoUpdatedByUserAt_lt?: Maybe<Scalars["DateTime"]>;
    photoUpdatedByUserAt_lte?: Maybe<Scalars["DateTime"]>;
    photoUpdatedByUserAt_gt?: Maybe<Scalars["DateTime"]>;
    photoUpdatedByUserAt_gte?: Maybe<Scalars["DateTime"]>;
    isActivationEmailSent?: Maybe<Scalars["Boolean"]>;
    isActivationEmailSent_not?: Maybe<Scalars["Boolean"]>;
    activationEmailSentAt?: Maybe<Scalars["DateTime"]>;
    activationEmailSentAt_not?: Maybe<Scalars["DateTime"]>;
    activationEmailSentAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    activationEmailSentAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    activationEmailSentAt_lt?: Maybe<Scalars["DateTime"]>;
    activationEmailSentAt_lte?: Maybe<Scalars["DateTime"]>;
    activationEmailSentAt_gt?: Maybe<Scalars["DateTime"]>;
    activationEmailSentAt_gte?: Maybe<Scalars["DateTime"]>;
    renewalAvailableAt?: Maybe<Scalars["DateTime"]>;
    renewalAvailableAt_not?: Maybe<Scalars["DateTime"]>;
    renewalAvailableAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    renewalAvailableAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    renewalAvailableAt_lt?: Maybe<Scalars["DateTime"]>;
    renewalAvailableAt_lte?: Maybe<Scalars["DateTime"]>;
    renewalAvailableAt_gt?: Maybe<Scalars["DateTime"]>;
    renewalAvailableAt_gte?: Maybe<Scalars["DateTime"]>;
    renewalReminder1SendOn?: Maybe<Scalars["DateTime"]>;
    renewalReminder1SendOn_not?: Maybe<Scalars["DateTime"]>;
    renewalReminder1SendOn_in?: Maybe<Array<Scalars["DateTime"]>>;
    renewalReminder1SendOn_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    renewalReminder1SendOn_lt?: Maybe<Scalars["DateTime"]>;
    renewalReminder1SendOn_lte?: Maybe<Scalars["DateTime"]>;
    renewalReminder1SendOn_gt?: Maybe<Scalars["DateTime"]>;
    renewalReminder1SendOn_gte?: Maybe<Scalars["DateTime"]>;
    hasRenewalReminder1BeenSent?: Maybe<Scalars["Boolean"]>;
    hasRenewalReminder1BeenSent_not?: Maybe<Scalars["Boolean"]>;
    renewalReminder2SendOn?: Maybe<Scalars["DateTime"]>;
    renewalReminder2SendOn_not?: Maybe<Scalars["DateTime"]>;
    renewalReminder2SendOn_in?: Maybe<Array<Scalars["DateTime"]>>;
    renewalReminder2SendOn_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    renewalReminder2SendOn_lt?: Maybe<Scalars["DateTime"]>;
    renewalReminder2SendOn_lte?: Maybe<Scalars["DateTime"]>;
    renewalReminder2SendOn_gt?: Maybe<Scalars["DateTime"]>;
    renewalReminder2SendOn_gte?: Maybe<Scalars["DateTime"]>;
    hasRenewalReminder2BeenSent?: Maybe<Scalars["Boolean"]>;
    hasRenewalReminder2BeenSent_not?: Maybe<Scalars["Boolean"]>;
    canPhotoBeChanged?: Maybe<Scalars["Boolean"]>;
    canPhotoBeChanged_not?: Maybe<Scalars["Boolean"]>;
    photoLastChangedAt?: Maybe<Scalars["DateTime"]>;
    photoLastChangedAt_not?: Maybe<Scalars["DateTime"]>;
    photoLastChangedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    photoLastChangedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    photoLastChangedAt_lt?: Maybe<Scalars["DateTime"]>;
    photoLastChangedAt_lte?: Maybe<Scalars["DateTime"]>;
    photoLastChangedAt_gt?: Maybe<Scalars["DateTime"]>;
    photoLastChangedAt_gte?: Maybe<Scalars["DateTime"]>;
    customerNotes_some?: Maybe<INoteWhereInput>;
    adminNotes_some?: Maybe<INoteWhereInput>;
    midAdminNotes_some?: Maybe<INoteWhereInput>;
    renewals_some?: Maybe<ICustomerMembershipRenewalWhereInput>;
    createdByUser?: Maybe<IUserWhereInput>;
    bookings_some?: Maybe<IBookingWhereInput>;
    ownedMembershipSharing_some?: Maybe<ICustomerMembershipSharingWhereInput>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    createdAt_not?: Maybe<Scalars["DateTime"]>;
    createdAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_lt?: Maybe<Scalars["DateTime"]>;
    createdAt_lte?: Maybe<Scalars["DateTime"]>;
    createdAt_gt?: Maybe<Scalars["DateTime"]>;
    createdAt_gte?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    updatedAt_not?: Maybe<Scalars["DateTime"]>;
    updatedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_lt?: Maybe<Scalars["DateTime"]>;
    updatedAt_lte?: Maybe<Scalars["DateTime"]>;
    updatedAt_gt?: Maybe<Scalars["DateTime"]>;
    updatedAt_gte?: Maybe<Scalars["DateTime"]>;
    AND?: Maybe<Array<ICustomerMembershipWhereInput>>;
};

export type ICustomerWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    organisation?: Maybe<IOrganisationWhereInput>;
    transactions_some?: Maybe<ITransactionWhereInput>;
    credentials_some?: Maybe<ICredentialWhereInput>;
    bookings_some?: Maybe<IBookingWhereInput>;
    benefits_some?: Maybe<ICustomerBenefitWhereInput>;
    customerNotes_some?: Maybe<INoteWhereInput>;
    adminNotes_some?: Maybe<INoteWhereInput>;
    midAdminNotes_some?: Maybe<INoteWhereInput>;
    name?: Maybe<Scalars["String"]>;
    name_not?: Maybe<Scalars["String"]>;
    name_in?: Maybe<Array<Scalars["String"]>>;
    name_not_in?: Maybe<Array<Scalars["String"]>>;
    name_lt?: Maybe<Scalars["String"]>;
    name_lte?: Maybe<Scalars["String"]>;
    name_gt?: Maybe<Scalars["String"]>;
    name_gte?: Maybe<Scalars["String"]>;
    name_contains?: Maybe<Scalars["String"]>;
    name_not_contains?: Maybe<Scalars["String"]>;
    name_starts_with?: Maybe<Scalars["String"]>;
    name_not_starts_with?: Maybe<Scalars["String"]>;
    name_ends_with?: Maybe<Scalars["String"]>;
    name_not_ends_with?: Maybe<Scalars["String"]>;
    isDisabled?: Maybe<Scalars["Boolean"]>;
    isDisabled_not?: Maybe<Scalars["Boolean"]>;
    postcode?: Maybe<Scalars["String"]>;
    postcode_not?: Maybe<Scalars["String"]>;
    postcode_in?: Maybe<Array<Scalars["String"]>>;
    postcode_not_in?: Maybe<Array<Scalars["String"]>>;
    postcode_lt?: Maybe<Scalars["String"]>;
    postcode_lte?: Maybe<Scalars["String"]>;
    postcode_gt?: Maybe<Scalars["String"]>;
    postcode_gte?: Maybe<Scalars["String"]>;
    postcode_contains?: Maybe<Scalars["String"]>;
    postcode_not_contains?: Maybe<Scalars["String"]>;
    postcode_starts_with?: Maybe<Scalars["String"]>;
    postcode_not_starts_with?: Maybe<Scalars["String"]>;
    postcode_ends_with?: Maybe<Scalars["String"]>;
    postcode_not_ends_with?: Maybe<Scalars["String"]>;
    addressInfo?: Maybe<Scalars["String"]>;
    addressInfo_not?: Maybe<Scalars["String"]>;
    addressInfo_in?: Maybe<Array<Scalars["String"]>>;
    addressInfo_not_in?: Maybe<Array<Scalars["String"]>>;
    addressInfo_lt?: Maybe<Scalars["String"]>;
    addressInfo_lte?: Maybe<Scalars["String"]>;
    addressInfo_gt?: Maybe<Scalars["String"]>;
    addressInfo_gte?: Maybe<Scalars["String"]>;
    addressInfo_contains?: Maybe<Scalars["String"]>;
    addressInfo_not_contains?: Maybe<Scalars["String"]>;
    addressInfo_starts_with?: Maybe<Scalars["String"]>;
    addressInfo_not_starts_with?: Maybe<Scalars["String"]>;
    addressInfo_ends_with?: Maybe<Scalars["String"]>;
    addressInfo_not_ends_with?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    createdAt_not?: Maybe<Scalars["DateTime"]>;
    createdAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_lt?: Maybe<Scalars["DateTime"]>;
    createdAt_lte?: Maybe<Scalars["DateTime"]>;
    createdAt_gt?: Maybe<Scalars["DateTime"]>;
    createdAt_gte?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    updatedAt_not?: Maybe<Scalars["DateTime"]>;
    updatedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_lt?: Maybe<Scalars["DateTime"]>;
    updatedAt_lte?: Maybe<Scalars["DateTime"]>;
    updatedAt_gt?: Maybe<Scalars["DateTime"]>;
    updatedAt_gte?: Maybe<Scalars["DateTime"]>;
    isSelfCateringCustomer?: Maybe<Scalars["Boolean"]>;
    isSelfCateringCustomer_not?: Maybe<Scalars["Boolean"]>;
    selfCateringIdentifer?: Maybe<Scalars["String"]>;
    selfCateringIdentifer_not?: Maybe<Scalars["String"]>;
    selfCateringIdentifer_in?: Maybe<Array<Scalars["String"]>>;
    selfCateringIdentifer_not_in?: Maybe<Array<Scalars["String"]>>;
    selfCateringIdentifer_lt?: Maybe<Scalars["String"]>;
    selfCateringIdentifer_lte?: Maybe<Scalars["String"]>;
    selfCateringIdentifer_gt?: Maybe<Scalars["String"]>;
    selfCateringIdentifer_gte?: Maybe<Scalars["String"]>;
    selfCateringIdentifer_contains?: Maybe<Scalars["String"]>;
    selfCateringIdentifer_not_contains?: Maybe<Scalars["String"]>;
    selfCateringIdentifer_starts_with?: Maybe<Scalars["String"]>;
    selfCateringIdentifer_not_starts_with?: Maybe<Scalars["String"]>;
    selfCateringIdentifer_ends_with?: Maybe<Scalars["String"]>;
    selfCateringIdentifer_not_ends_with?: Maybe<Scalars["String"]>;
    searchableUpper?: Maybe<Scalars["String"]>;
    searchableUpper_not?: Maybe<Scalars["String"]>;
    searchableUpper_in?: Maybe<Array<Scalars["String"]>>;
    searchableUpper_not_in?: Maybe<Array<Scalars["String"]>>;
    searchableUpper_lt?: Maybe<Scalars["String"]>;
    searchableUpper_lte?: Maybe<Scalars["String"]>;
    searchableUpper_gt?: Maybe<Scalars["String"]>;
    searchableUpper_gte?: Maybe<Scalars["String"]>;
    searchableUpper_contains?: Maybe<Scalars["String"]>;
    searchableUpper_not_contains?: Maybe<Scalars["String"]>;
    searchableUpper_starts_with?: Maybe<Scalars["String"]>;
    searchableUpper_not_starts_with?: Maybe<Scalars["String"]>;
    searchableUpper_ends_with?: Maybe<Scalars["String"]>;
    searchableUpper_not_ends_with?: Maybe<Scalars["String"]>;
    memberships_some?: Maybe<ICustomerMembershipWhereInput>;
    sharedMemberships_some?: Maybe<ICustomerMembershipSharingWhereInput>;
    membershipsSharedWithMe_some?: Maybe<ICustomerMembershipSharingWhereInput>;
    AND?: Maybe<Array<ICustomerWhereInput>>;
};

export type IDaySchedule = {
    __typename?: "DaySchedule";
    id: Scalars["ID"];
    resource: IResource;
    date: Scalars["DateTime"];
    activityInstances?: Maybe<Array<IActivityInstance>>;
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
};

export type IDayScheduleActivityInstancesArgs = {
    where?: Maybe<IActivityInstanceWhereInput>;
    orderBy?: Maybe<IActivityInstanceOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type IDayScheduleTemplate = {
    __typename?: "DayScheduleTemplate";
    id: Scalars["ID"];
    weekTemplate: IWeekTemplate;
    activityInstanceTemplates?: Maybe<Array<IActivityInstanceTemplate>>;
    dayOfWeek: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
};

export type IDayScheduleTemplateActivityInstanceTemplatesArgs = {
    where?: Maybe<IActivityInstanceTemplateWhereInput>;
    orderBy?: Maybe<IActivityInstanceTemplateOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export enum IDayScheduleTemplateOrderByInput {
    IdAsc = "id_ASC",
    IdDesc = "id_DESC",
    DayOfWeekAsc = "dayOfWeek_ASC",
    DayOfWeekDesc = "dayOfWeek_DESC",
    CreatedAtAsc = "createdAt_ASC",
    CreatedAtDesc = "createdAt_DESC",
    UpdatedAtAsc = "updatedAt_ASC",
    UpdatedAtDesc = "updatedAt_DESC"
}

export type IDayScheduleTemplateWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    weekTemplate?: Maybe<IWeekTemplateWhereInput>;
    activityInstanceTemplates_some?: Maybe<IActivityInstanceTemplateWhereInput>;
    dayOfWeek?: Maybe<Scalars["Int"]>;
    dayOfWeek_not?: Maybe<Scalars["Int"]>;
    dayOfWeek_in?: Maybe<Array<Scalars["Int"]>>;
    dayOfWeek_not_in?: Maybe<Array<Scalars["Int"]>>;
    dayOfWeek_lt?: Maybe<Scalars["Int"]>;
    dayOfWeek_lte?: Maybe<Scalars["Int"]>;
    dayOfWeek_gt?: Maybe<Scalars["Int"]>;
    dayOfWeek_gte?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    createdAt_not?: Maybe<Scalars["DateTime"]>;
    createdAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_lt?: Maybe<Scalars["DateTime"]>;
    createdAt_lte?: Maybe<Scalars["DateTime"]>;
    createdAt_gt?: Maybe<Scalars["DateTime"]>;
    createdAt_gte?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    updatedAt_not?: Maybe<Scalars["DateTime"]>;
    updatedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_lt?: Maybe<Scalars["DateTime"]>;
    updatedAt_lte?: Maybe<Scalars["DateTime"]>;
    updatedAt_gt?: Maybe<Scalars["DateTime"]>;
    updatedAt_gte?: Maybe<Scalars["DateTime"]>;
    AND?: Maybe<Array<IDayScheduleTemplateWhereInput>>;
};

export type IDayScheduleWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    resource?: Maybe<IResourceWhereInput>;
    date?: Maybe<Scalars["DateTime"]>;
    date_not?: Maybe<Scalars["DateTime"]>;
    date_in?: Maybe<Array<Scalars["DateTime"]>>;
    date_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    date_lt?: Maybe<Scalars["DateTime"]>;
    date_lte?: Maybe<Scalars["DateTime"]>;
    date_gt?: Maybe<Scalars["DateTime"]>;
    date_gte?: Maybe<Scalars["DateTime"]>;
    activityInstances_some?: Maybe<IActivityInstanceWhereInput>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    createdAt_not?: Maybe<Scalars["DateTime"]>;
    createdAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_lt?: Maybe<Scalars["DateTime"]>;
    createdAt_lte?: Maybe<Scalars["DateTime"]>;
    createdAt_gt?: Maybe<Scalars["DateTime"]>;
    createdAt_gte?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    updatedAt_not?: Maybe<Scalars["DateTime"]>;
    updatedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_lt?: Maybe<Scalars["DateTime"]>;
    updatedAt_lte?: Maybe<Scalars["DateTime"]>;
    updatedAt_gt?: Maybe<Scalars["DateTime"]>;
    updatedAt_gte?: Maybe<Scalars["DateTime"]>;
    AND?: Maybe<Array<IDayScheduleWhereInput>>;
};

export type IDestroyBasketInput = {
    basketId: Scalars["ID"];
};

export type IDiscount = {
    __typename?: "Discount";
    id: Scalars["ID"];
    organisation?: Maybe<IOrganisation>;
    name: Scalars["String"];
    description: Scalars["String"];
    isActive: Scalars["Boolean"];
    discountAmount: Scalars["Float"];
    discountType: IDiscountType;
    appliesToActivities: Scalars["Boolean"];
    appliesToExtras: Scalars["Boolean"];
    validDaysOfWeek: Array<Scalars["Int"]>;
    validActivityIds: Array<Scalars["ID"]>;
    validForMaxQuantity?: Maybe<Scalars["Int"]>;
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
};

export enum IDiscountType {
    Money = "MONEY",
    Percentage = "PERCENTAGE"
}

export type IDiscountWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    organisation?: Maybe<IOrganisationWhereInput>;
    coupons_some?: Maybe<ICouponWhereInput>;
    name?: Maybe<Scalars["String"]>;
    name_not?: Maybe<Scalars["String"]>;
    name_in?: Maybe<Array<Scalars["String"]>>;
    name_not_in?: Maybe<Array<Scalars["String"]>>;
    name_lt?: Maybe<Scalars["String"]>;
    name_lte?: Maybe<Scalars["String"]>;
    name_gt?: Maybe<Scalars["String"]>;
    name_gte?: Maybe<Scalars["String"]>;
    name_contains?: Maybe<Scalars["String"]>;
    name_not_contains?: Maybe<Scalars["String"]>;
    name_starts_with?: Maybe<Scalars["String"]>;
    name_not_starts_with?: Maybe<Scalars["String"]>;
    name_ends_with?: Maybe<Scalars["String"]>;
    name_not_ends_with?: Maybe<Scalars["String"]>;
    description?: Maybe<Scalars["String"]>;
    description_not?: Maybe<Scalars["String"]>;
    description_in?: Maybe<Array<Scalars["String"]>>;
    description_not_in?: Maybe<Array<Scalars["String"]>>;
    description_lt?: Maybe<Scalars["String"]>;
    description_lte?: Maybe<Scalars["String"]>;
    description_gt?: Maybe<Scalars["String"]>;
    description_gte?: Maybe<Scalars["String"]>;
    description_contains?: Maybe<Scalars["String"]>;
    description_not_contains?: Maybe<Scalars["String"]>;
    description_starts_with?: Maybe<Scalars["String"]>;
    description_not_starts_with?: Maybe<Scalars["String"]>;
    description_ends_with?: Maybe<Scalars["String"]>;
    description_not_ends_with?: Maybe<Scalars["String"]>;
    isActive?: Maybe<Scalars["Boolean"]>;
    isActive_not?: Maybe<Scalars["Boolean"]>;
    discountAmount?: Maybe<Scalars["Float"]>;
    discountAmount_not?: Maybe<Scalars["Float"]>;
    discountAmount_in?: Maybe<Array<Scalars["Float"]>>;
    discountAmount_not_in?: Maybe<Array<Scalars["Float"]>>;
    discountAmount_lt?: Maybe<Scalars["Float"]>;
    discountAmount_lte?: Maybe<Scalars["Float"]>;
    discountAmount_gt?: Maybe<Scalars["Float"]>;
    discountAmount_gte?: Maybe<Scalars["Float"]>;
    discountType?: Maybe<IDiscountType>;
    discountType_not?: Maybe<IDiscountType>;
    discountType_in?: Maybe<Array<IDiscountType>>;
    discountType_not_in?: Maybe<Array<IDiscountType>>;
    appliesToActivities?: Maybe<Scalars["Boolean"]>;
    appliesToActivities_not?: Maybe<Scalars["Boolean"]>;
    appliesToExtras?: Maybe<Scalars["Boolean"]>;
    appliesToExtras_not?: Maybe<Scalars["Boolean"]>;
    validForMaxQuantity?: Maybe<Scalars["Int"]>;
    validForMaxQuantity_not?: Maybe<Scalars["Int"]>;
    validForMaxQuantity_in?: Maybe<Array<Scalars["Int"]>>;
    validForMaxQuantity_not_in?: Maybe<Array<Scalars["Int"]>>;
    validForMaxQuantity_lt?: Maybe<Scalars["Int"]>;
    validForMaxQuantity_lte?: Maybe<Scalars["Int"]>;
    validForMaxQuantity_gt?: Maybe<Scalars["Int"]>;
    validForMaxQuantity_gte?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    createdAt_not?: Maybe<Scalars["DateTime"]>;
    createdAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_lt?: Maybe<Scalars["DateTime"]>;
    createdAt_lte?: Maybe<Scalars["DateTime"]>;
    createdAt_gt?: Maybe<Scalars["DateTime"]>;
    createdAt_gte?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    updatedAt_not?: Maybe<Scalars["DateTime"]>;
    updatedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_lt?: Maybe<Scalars["DateTime"]>;
    updatedAt_lte?: Maybe<Scalars["DateTime"]>;
    updatedAt_gt?: Maybe<Scalars["DateTime"]>;
    updatedAt_gte?: Maybe<Scalars["DateTime"]>;
    AND?: Maybe<Array<IDiscountWhereInput>>;
};

export type IExtra = {
    __typename?: "Extra";
    id: Scalars["ID"];
    resource?: Maybe<IResource>;
    name: Scalars["String"];
    description: Scalars["String"];
    price: Scalars["Float"];
    isAvailable: Scalars["Boolean"];
    otherMetadata?: Maybe<Scalars["Json"]>;
};

export type IExtraBasketInput = {
    extraId: Scalars["ID"];
    quantity: Scalars["Int"];
};

export enum IExtraOrderByInput {
    IdAsc = "id_ASC",
    IdDesc = "id_DESC",
    NameAsc = "name_ASC",
    NameDesc = "name_DESC",
    DescriptionAsc = "description_ASC",
    DescriptionDesc = "description_DESC",
    PriceAsc = "price_ASC",
    PriceDesc = "price_DESC",
    IsAvailableAsc = "isAvailable_ASC",
    IsAvailableDesc = "isAvailable_DESC",
    OtherMetadataAsc = "otherMetadata_ASC",
    OtherMetadataDesc = "otherMetadata_DESC"
}

export type IExtraWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    resource?: Maybe<IResourceWhereInput>;
    name?: Maybe<Scalars["String"]>;
    name_not?: Maybe<Scalars["String"]>;
    name_in?: Maybe<Array<Scalars["String"]>>;
    name_not_in?: Maybe<Array<Scalars["String"]>>;
    name_lt?: Maybe<Scalars["String"]>;
    name_lte?: Maybe<Scalars["String"]>;
    name_gt?: Maybe<Scalars["String"]>;
    name_gte?: Maybe<Scalars["String"]>;
    name_contains?: Maybe<Scalars["String"]>;
    name_not_contains?: Maybe<Scalars["String"]>;
    name_starts_with?: Maybe<Scalars["String"]>;
    name_not_starts_with?: Maybe<Scalars["String"]>;
    name_ends_with?: Maybe<Scalars["String"]>;
    name_not_ends_with?: Maybe<Scalars["String"]>;
    description?: Maybe<Scalars["String"]>;
    description_not?: Maybe<Scalars["String"]>;
    description_in?: Maybe<Array<Scalars["String"]>>;
    description_not_in?: Maybe<Array<Scalars["String"]>>;
    description_lt?: Maybe<Scalars["String"]>;
    description_lte?: Maybe<Scalars["String"]>;
    description_gt?: Maybe<Scalars["String"]>;
    description_gte?: Maybe<Scalars["String"]>;
    description_contains?: Maybe<Scalars["String"]>;
    description_not_contains?: Maybe<Scalars["String"]>;
    description_starts_with?: Maybe<Scalars["String"]>;
    description_not_starts_with?: Maybe<Scalars["String"]>;
    description_ends_with?: Maybe<Scalars["String"]>;
    description_not_ends_with?: Maybe<Scalars["String"]>;
    price?: Maybe<Scalars["Float"]>;
    price_not?: Maybe<Scalars["Float"]>;
    price_in?: Maybe<Array<Scalars["Float"]>>;
    price_not_in?: Maybe<Array<Scalars["Float"]>>;
    price_lt?: Maybe<Scalars["Float"]>;
    price_lte?: Maybe<Scalars["Float"]>;
    price_gt?: Maybe<Scalars["Float"]>;
    price_gte?: Maybe<Scalars["Float"]>;
    isAvailable?: Maybe<Scalars["Boolean"]>;
    isAvailable_not?: Maybe<Scalars["Boolean"]>;
    AND?: Maybe<Array<IExtraWhereInput>>;
};

export type IFacility = {
    __typename?: "Facility";
    id: Scalars["ID"];
    organisation?: Maybe<IOrganisation>;
    resources?: Maybe<Array<IResource>>;
    name: Scalars["String"];
    description: Scalars["String"];
    nominalCode?: Maybe<Scalars["String"]>;
    stub?: Maybe<Scalars["String"]>;
    isAvailable: Scalars["Boolean"];
    isHiddenFromPublic: Scalars["Boolean"];
    isShownOnDefaultView?: Maybe<Scalars["Boolean"]>;
    ordering: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
    otherMetadata?: Maybe<Scalars["Json"]>;
    isDeleted?: Maybe<Scalars["Boolean"]>;
    deletedAt?: Maybe<Scalars["DateTime"]>;
    deletedByUser?: Maybe<IUser>;
};

export type IFacilityResourcesArgs = {
    where?: Maybe<IResourceWhereInput>;
    orderBy?: Maybe<IResourceOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type IFacilityWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    organisation?: Maybe<IOrganisationWhereInput>;
    resources_some?: Maybe<IResourceWhereInput>;
    name?: Maybe<Scalars["String"]>;
    name_not?: Maybe<Scalars["String"]>;
    name_in?: Maybe<Array<Scalars["String"]>>;
    name_not_in?: Maybe<Array<Scalars["String"]>>;
    name_lt?: Maybe<Scalars["String"]>;
    name_lte?: Maybe<Scalars["String"]>;
    name_gt?: Maybe<Scalars["String"]>;
    name_gte?: Maybe<Scalars["String"]>;
    name_contains?: Maybe<Scalars["String"]>;
    name_not_contains?: Maybe<Scalars["String"]>;
    name_starts_with?: Maybe<Scalars["String"]>;
    name_not_starts_with?: Maybe<Scalars["String"]>;
    name_ends_with?: Maybe<Scalars["String"]>;
    name_not_ends_with?: Maybe<Scalars["String"]>;
    description?: Maybe<Scalars["String"]>;
    description_not?: Maybe<Scalars["String"]>;
    description_in?: Maybe<Array<Scalars["String"]>>;
    description_not_in?: Maybe<Array<Scalars["String"]>>;
    description_lt?: Maybe<Scalars["String"]>;
    description_lte?: Maybe<Scalars["String"]>;
    description_gt?: Maybe<Scalars["String"]>;
    description_gte?: Maybe<Scalars["String"]>;
    description_contains?: Maybe<Scalars["String"]>;
    description_not_contains?: Maybe<Scalars["String"]>;
    description_starts_with?: Maybe<Scalars["String"]>;
    description_not_starts_with?: Maybe<Scalars["String"]>;
    description_ends_with?: Maybe<Scalars["String"]>;
    description_not_ends_with?: Maybe<Scalars["String"]>;
    nominalCode?: Maybe<Scalars["String"]>;
    nominalCode_not?: Maybe<Scalars["String"]>;
    nominalCode_in?: Maybe<Array<Scalars["String"]>>;
    nominalCode_not_in?: Maybe<Array<Scalars["String"]>>;
    nominalCode_lt?: Maybe<Scalars["String"]>;
    nominalCode_lte?: Maybe<Scalars["String"]>;
    nominalCode_gt?: Maybe<Scalars["String"]>;
    nominalCode_gte?: Maybe<Scalars["String"]>;
    nominalCode_contains?: Maybe<Scalars["String"]>;
    nominalCode_not_contains?: Maybe<Scalars["String"]>;
    nominalCode_starts_with?: Maybe<Scalars["String"]>;
    nominalCode_not_starts_with?: Maybe<Scalars["String"]>;
    nominalCode_ends_with?: Maybe<Scalars["String"]>;
    nominalCode_not_ends_with?: Maybe<Scalars["String"]>;
    stub?: Maybe<Scalars["String"]>;
    stub_not?: Maybe<Scalars["String"]>;
    stub_in?: Maybe<Array<Scalars["String"]>>;
    stub_not_in?: Maybe<Array<Scalars["String"]>>;
    stub_lt?: Maybe<Scalars["String"]>;
    stub_lte?: Maybe<Scalars["String"]>;
    stub_gt?: Maybe<Scalars["String"]>;
    stub_gte?: Maybe<Scalars["String"]>;
    stub_contains?: Maybe<Scalars["String"]>;
    stub_not_contains?: Maybe<Scalars["String"]>;
    stub_starts_with?: Maybe<Scalars["String"]>;
    stub_not_starts_with?: Maybe<Scalars["String"]>;
    stub_ends_with?: Maybe<Scalars["String"]>;
    stub_not_ends_with?: Maybe<Scalars["String"]>;
    isAvailable?: Maybe<Scalars["Boolean"]>;
    isAvailable_not?: Maybe<Scalars["Boolean"]>;
    isHiddenFromPublic?: Maybe<Scalars["Boolean"]>;
    isHiddenFromPublic_not?: Maybe<Scalars["Boolean"]>;
    isShownOnDefaultView?: Maybe<Scalars["Boolean"]>;
    isShownOnDefaultView_not?: Maybe<Scalars["Boolean"]>;
    ordering?: Maybe<Scalars["Int"]>;
    ordering_not?: Maybe<Scalars["Int"]>;
    ordering_in?: Maybe<Array<Scalars["Int"]>>;
    ordering_not_in?: Maybe<Array<Scalars["Int"]>>;
    ordering_lt?: Maybe<Scalars["Int"]>;
    ordering_lte?: Maybe<Scalars["Int"]>;
    ordering_gt?: Maybe<Scalars["Int"]>;
    ordering_gte?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    createdAt_not?: Maybe<Scalars["DateTime"]>;
    createdAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_lt?: Maybe<Scalars["DateTime"]>;
    createdAt_lte?: Maybe<Scalars["DateTime"]>;
    createdAt_gt?: Maybe<Scalars["DateTime"]>;
    createdAt_gte?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    updatedAt_not?: Maybe<Scalars["DateTime"]>;
    updatedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_lt?: Maybe<Scalars["DateTime"]>;
    updatedAt_lte?: Maybe<Scalars["DateTime"]>;
    updatedAt_gt?: Maybe<Scalars["DateTime"]>;
    updatedAt_gte?: Maybe<Scalars["DateTime"]>;
    isDeleted?: Maybe<Scalars["Boolean"]>;
    isDeleted_not?: Maybe<Scalars["Boolean"]>;
    deletedAt?: Maybe<Scalars["DateTime"]>;
    deletedAt_not?: Maybe<Scalars["DateTime"]>;
    deletedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    deletedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    deletedAt_lt?: Maybe<Scalars["DateTime"]>;
    deletedAt_lte?: Maybe<Scalars["DateTime"]>;
    deletedAt_gt?: Maybe<Scalars["DateTime"]>;
    deletedAt_gte?: Maybe<Scalars["DateTime"]>;
    deletedByUser?: Maybe<IUserWhereInput>;
    AND?: Maybe<Array<IFacilityWhereInput>>;
};

export type IMembership = {
    __typename?: "Membership";
    id: Scalars["ID"];
    organisation: IOrganisation;
    name: Scalars["String"];
    description?: Maybe<Scalars["String"]>;
    imageUrl?: Maybe<Scalars["String"]>;
    price: Scalars["Float"];
    resources?: Maybe<Array<IResource>>;
    membershipGroups?: Maybe<Array<IMembershipGroup>>;
    allowedBookingsPerDay?: Maybe<Scalars["Int"]>;
    isLimitlessInBookingsPerDay: Scalars["Boolean"];
    doesRequirePhoto: Scalars["Boolean"];
    canBeCrossAuthorised?: Maybe<Scalars["Boolean"]>;
    discountAmount: Scalars["Float"];
    lengthInDays: Scalars["Int"];
    validDaysOfWeek: Array<Scalars["Int"]>;
    allowedTimesInDay?: Maybe<Array<ITimeInterval>>;
    renewalReminderDaysBeforeExpiry?: Maybe<Scalars["Int"]>;
    renewalAvailableDaysBeforeExpiry?: Maybe<Scalars["Int"]>;
    isRenewable?: Maybe<Scalars["Boolean"]>;
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
    isAvailable: Scalars["Boolean"];
    isHiddenFromPublic: Scalars["Boolean"];
    isDeleted: Scalars["Boolean"];
    deletedAt?: Maybe<Scalars["DateTime"]>;
    deletedByUser?: Maybe<IUser>;
    ordering: Scalars["Int"];
    discountType: IDiscountType;
};

export type IMembershipResourcesArgs = {
    where?: Maybe<IResourceWhereInput>;
    orderBy?: Maybe<IResourceOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type IMembershipMembershipGroupsArgs = {
    where?: Maybe<IMembershipGroupWhereInput>;
    orderBy?: Maybe<IMembershipGroupOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type IMembershipBasketInput = {
    membershipId: Scalars["ID"];
    customerMembershipId?: Maybe<Scalars["ID"]>;
    isRenewal?: Maybe<Scalars["Boolean"]>;
    quantity: Scalars["Int"];
};

export type IMembershipGroup = {
    __typename?: "MembershipGroup";
    id: Scalars["ID"];
    organisation: IOrganisation;
    name: Scalars["String"];
    stub: Scalars["String"];
    memberships?: Maybe<Array<IMembership>>;
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
    isAvailable: Scalars["Boolean"];
    isHiddenFromPublic: Scalars["Boolean"];
    isDeleted: Scalars["Boolean"];
    deletedAt?: Maybe<Scalars["DateTime"]>;
    deletedByUser?: Maybe<IUser>;
    ordering: Scalars["Int"];
};

export type IMembershipGroupMembershipsArgs = {
    where?: Maybe<IMembershipWhereInput>;
    orderBy?: Maybe<IMembershipOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type IMembershipGroupByResourceGroupStubInput = {
    orgId: Scalars["ID"];
    resourceGroupStub?: Maybe<Scalars["String"]>;
};

export enum IMembershipGroupOrderByInput {
    IdAsc = "id_ASC",
    IdDesc = "id_DESC",
    NameAsc = "name_ASC",
    NameDesc = "name_DESC",
    StubAsc = "stub_ASC",
    StubDesc = "stub_DESC",
    CreatedAtAsc = "createdAt_ASC",
    CreatedAtDesc = "createdAt_DESC",
    UpdatedAtAsc = "updatedAt_ASC",
    UpdatedAtDesc = "updatedAt_DESC",
    IsAvailableAsc = "isAvailable_ASC",
    IsAvailableDesc = "isAvailable_DESC",
    IsHiddenFromPublicAsc = "isHiddenFromPublic_ASC",
    IsHiddenFromPublicDesc = "isHiddenFromPublic_DESC",
    IsDeletedAsc = "isDeleted_ASC",
    IsDeletedDesc = "isDeleted_DESC",
    DeletedAtAsc = "deletedAt_ASC",
    DeletedAtDesc = "deletedAt_DESC",
    OrderingAsc = "ordering_ASC",
    OrderingDesc = "ordering_DESC"
}

export type IMembershipGroupsByOrgInput = {
    orgId: Scalars["ID"];
    membershipGroupStub?: Maybe<Scalars["String"]>;
};

export type IMembershipGroupWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    organisation?: Maybe<IOrganisationWhereInput>;
    name?: Maybe<Scalars["String"]>;
    name_not?: Maybe<Scalars["String"]>;
    name_in?: Maybe<Array<Scalars["String"]>>;
    name_not_in?: Maybe<Array<Scalars["String"]>>;
    name_lt?: Maybe<Scalars["String"]>;
    name_lte?: Maybe<Scalars["String"]>;
    name_gt?: Maybe<Scalars["String"]>;
    name_gte?: Maybe<Scalars["String"]>;
    name_contains?: Maybe<Scalars["String"]>;
    name_not_contains?: Maybe<Scalars["String"]>;
    name_starts_with?: Maybe<Scalars["String"]>;
    name_not_starts_with?: Maybe<Scalars["String"]>;
    name_ends_with?: Maybe<Scalars["String"]>;
    name_not_ends_with?: Maybe<Scalars["String"]>;
    stub?: Maybe<Scalars["String"]>;
    stub_not?: Maybe<Scalars["String"]>;
    stub_in?: Maybe<Array<Scalars["String"]>>;
    stub_not_in?: Maybe<Array<Scalars["String"]>>;
    stub_lt?: Maybe<Scalars["String"]>;
    stub_lte?: Maybe<Scalars["String"]>;
    stub_gt?: Maybe<Scalars["String"]>;
    stub_gte?: Maybe<Scalars["String"]>;
    stub_contains?: Maybe<Scalars["String"]>;
    stub_not_contains?: Maybe<Scalars["String"]>;
    stub_starts_with?: Maybe<Scalars["String"]>;
    stub_not_starts_with?: Maybe<Scalars["String"]>;
    stub_ends_with?: Maybe<Scalars["String"]>;
    stub_not_ends_with?: Maybe<Scalars["String"]>;
    memberships_some?: Maybe<IMembershipWhereInput>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    createdAt_not?: Maybe<Scalars["DateTime"]>;
    createdAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_lt?: Maybe<Scalars["DateTime"]>;
    createdAt_lte?: Maybe<Scalars["DateTime"]>;
    createdAt_gt?: Maybe<Scalars["DateTime"]>;
    createdAt_gte?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    updatedAt_not?: Maybe<Scalars["DateTime"]>;
    updatedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_lt?: Maybe<Scalars["DateTime"]>;
    updatedAt_lte?: Maybe<Scalars["DateTime"]>;
    updatedAt_gt?: Maybe<Scalars["DateTime"]>;
    updatedAt_gte?: Maybe<Scalars["DateTime"]>;
    isAvailable?: Maybe<Scalars["Boolean"]>;
    isAvailable_not?: Maybe<Scalars["Boolean"]>;
    isHiddenFromPublic?: Maybe<Scalars["Boolean"]>;
    isHiddenFromPublic_not?: Maybe<Scalars["Boolean"]>;
    isDeleted?: Maybe<Scalars["Boolean"]>;
    isDeleted_not?: Maybe<Scalars["Boolean"]>;
    deletedAt?: Maybe<Scalars["DateTime"]>;
    deletedAt_not?: Maybe<Scalars["DateTime"]>;
    deletedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    deletedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    deletedAt_lt?: Maybe<Scalars["DateTime"]>;
    deletedAt_lte?: Maybe<Scalars["DateTime"]>;
    deletedAt_gt?: Maybe<Scalars["DateTime"]>;
    deletedAt_gte?: Maybe<Scalars["DateTime"]>;
    deletedByUser?: Maybe<IUserWhereInput>;
    ordering?: Maybe<Scalars["Int"]>;
    ordering_not?: Maybe<Scalars["Int"]>;
    ordering_in?: Maybe<Array<Scalars["Int"]>>;
    ordering_not_in?: Maybe<Array<Scalars["Int"]>>;
    ordering_lt?: Maybe<Scalars["Int"]>;
    ordering_lte?: Maybe<Scalars["Int"]>;
    ordering_gt?: Maybe<Scalars["Int"]>;
    ordering_gte?: Maybe<Scalars["Int"]>;
    AND?: Maybe<Array<IMembershipGroupWhereInput>>;
};

export enum IMembershipOrderByInput {
    IdAsc = "id_ASC",
    IdDesc = "id_DESC",
    NameAsc = "name_ASC",
    NameDesc = "name_DESC",
    DescriptionAsc = "description_ASC",
    DescriptionDesc = "description_DESC",
    ImageUrlAsc = "imageUrl_ASC",
    ImageUrlDesc = "imageUrl_DESC",
    PriceAsc = "price_ASC",
    PriceDesc = "price_DESC",
    AllowedBookingsPerDayAsc = "allowedBookingsPerDay_ASC",
    AllowedBookingsPerDayDesc = "allowedBookingsPerDay_DESC",
    IsLimitlessInBookingsPerDayAsc = "isLimitlessInBookingsPerDay_ASC",
    IsLimitlessInBookingsPerDayDesc = "isLimitlessInBookingsPerDay_DESC",
    DoesRequirePhotoAsc = "doesRequirePhoto_ASC",
    DoesRequirePhotoDesc = "doesRequirePhoto_DESC",
    CanBeCrossAuthorisedAsc = "canBeCrossAuthorised_ASC",
    CanBeCrossAuthorisedDesc = "canBeCrossAuthorised_DESC",
    DiscountAmountAsc = "discountAmount_ASC",
    DiscountAmountDesc = "discountAmount_DESC",
    LengthInDaysAsc = "lengthInDays_ASC",
    LengthInDaysDesc = "lengthInDays_DESC",
    RenewalReminderDaysBeforeExpiryAsc = "renewalReminderDaysBeforeExpiry_ASC",
    RenewalReminderDaysBeforeExpiryDesc = "renewalReminderDaysBeforeExpiry_DESC",
    RenewalAvailableDaysBeforeExpiryAsc = "renewalAvailableDaysBeforeExpiry_ASC",
    RenewalAvailableDaysBeforeExpiryDesc = "renewalAvailableDaysBeforeExpiry_DESC",
    IsRenewableAsc = "isRenewable_ASC",
    IsRenewableDesc = "isRenewable_DESC",
    CreatedAtAsc = "createdAt_ASC",
    CreatedAtDesc = "createdAt_DESC",
    UpdatedAtAsc = "updatedAt_ASC",
    UpdatedAtDesc = "updatedAt_DESC",
    IsAvailableAsc = "isAvailable_ASC",
    IsAvailableDesc = "isAvailable_DESC",
    IsHiddenFromPublicAsc = "isHiddenFromPublic_ASC",
    IsHiddenFromPublicDesc = "isHiddenFromPublic_DESC",
    IsDeletedAsc = "isDeleted_ASC",
    IsDeletedDesc = "isDeleted_DESC",
    DeletedAtAsc = "deletedAt_ASC",
    DeletedAtDesc = "deletedAt_DESC",
    OrderingAsc = "ordering_ASC",
    OrderingDesc = "ordering_DESC",
    DiscountTypeAsc = "discountType_ASC",
    DiscountTypeDesc = "discountType_DESC"
}

export type IMembershipsByOrgInput = {
    orgId: Scalars["ID"];
};

export type IMembershipWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    organisation?: Maybe<IOrganisationWhereInput>;
    customerMemberships_some?: Maybe<ICustomerMembershipWhereInput>;
    name?: Maybe<Scalars["String"]>;
    name_not?: Maybe<Scalars["String"]>;
    name_in?: Maybe<Array<Scalars["String"]>>;
    name_not_in?: Maybe<Array<Scalars["String"]>>;
    name_lt?: Maybe<Scalars["String"]>;
    name_lte?: Maybe<Scalars["String"]>;
    name_gt?: Maybe<Scalars["String"]>;
    name_gte?: Maybe<Scalars["String"]>;
    name_contains?: Maybe<Scalars["String"]>;
    name_not_contains?: Maybe<Scalars["String"]>;
    name_starts_with?: Maybe<Scalars["String"]>;
    name_not_starts_with?: Maybe<Scalars["String"]>;
    name_ends_with?: Maybe<Scalars["String"]>;
    name_not_ends_with?: Maybe<Scalars["String"]>;
    description?: Maybe<Scalars["String"]>;
    description_not?: Maybe<Scalars["String"]>;
    description_in?: Maybe<Array<Scalars["String"]>>;
    description_not_in?: Maybe<Array<Scalars["String"]>>;
    description_lt?: Maybe<Scalars["String"]>;
    description_lte?: Maybe<Scalars["String"]>;
    description_gt?: Maybe<Scalars["String"]>;
    description_gte?: Maybe<Scalars["String"]>;
    description_contains?: Maybe<Scalars["String"]>;
    description_not_contains?: Maybe<Scalars["String"]>;
    description_starts_with?: Maybe<Scalars["String"]>;
    description_not_starts_with?: Maybe<Scalars["String"]>;
    description_ends_with?: Maybe<Scalars["String"]>;
    description_not_ends_with?: Maybe<Scalars["String"]>;
    imageUrl?: Maybe<Scalars["String"]>;
    imageUrl_not?: Maybe<Scalars["String"]>;
    imageUrl_in?: Maybe<Array<Scalars["String"]>>;
    imageUrl_not_in?: Maybe<Array<Scalars["String"]>>;
    imageUrl_lt?: Maybe<Scalars["String"]>;
    imageUrl_lte?: Maybe<Scalars["String"]>;
    imageUrl_gt?: Maybe<Scalars["String"]>;
    imageUrl_gte?: Maybe<Scalars["String"]>;
    imageUrl_contains?: Maybe<Scalars["String"]>;
    imageUrl_not_contains?: Maybe<Scalars["String"]>;
    imageUrl_starts_with?: Maybe<Scalars["String"]>;
    imageUrl_not_starts_with?: Maybe<Scalars["String"]>;
    imageUrl_ends_with?: Maybe<Scalars["String"]>;
    imageUrl_not_ends_with?: Maybe<Scalars["String"]>;
    price?: Maybe<Scalars["Float"]>;
    price_not?: Maybe<Scalars["Float"]>;
    price_in?: Maybe<Array<Scalars["Float"]>>;
    price_not_in?: Maybe<Array<Scalars["Float"]>>;
    price_lt?: Maybe<Scalars["Float"]>;
    price_lte?: Maybe<Scalars["Float"]>;
    price_gt?: Maybe<Scalars["Float"]>;
    price_gte?: Maybe<Scalars["Float"]>;
    resources_some?: Maybe<IResourceWhereInput>;
    membershipGroups_some?: Maybe<IMembershipGroupWhereInput>;
    allowedBookingsPerDay?: Maybe<Scalars["Int"]>;
    allowedBookingsPerDay_not?: Maybe<Scalars["Int"]>;
    allowedBookingsPerDay_in?: Maybe<Array<Scalars["Int"]>>;
    allowedBookingsPerDay_not_in?: Maybe<Array<Scalars["Int"]>>;
    allowedBookingsPerDay_lt?: Maybe<Scalars["Int"]>;
    allowedBookingsPerDay_lte?: Maybe<Scalars["Int"]>;
    allowedBookingsPerDay_gt?: Maybe<Scalars["Int"]>;
    allowedBookingsPerDay_gte?: Maybe<Scalars["Int"]>;
    isLimitlessInBookingsPerDay?: Maybe<Scalars["Boolean"]>;
    isLimitlessInBookingsPerDay_not?: Maybe<Scalars["Boolean"]>;
    doesRequirePhoto?: Maybe<Scalars["Boolean"]>;
    doesRequirePhoto_not?: Maybe<Scalars["Boolean"]>;
    canBeCrossAuthorised?: Maybe<Scalars["Boolean"]>;
    canBeCrossAuthorised_not?: Maybe<Scalars["Boolean"]>;
    discountAmount?: Maybe<Scalars["Float"]>;
    discountAmount_not?: Maybe<Scalars["Float"]>;
    discountAmount_in?: Maybe<Array<Scalars["Float"]>>;
    discountAmount_not_in?: Maybe<Array<Scalars["Float"]>>;
    discountAmount_lt?: Maybe<Scalars["Float"]>;
    discountAmount_lte?: Maybe<Scalars["Float"]>;
    discountAmount_gt?: Maybe<Scalars["Float"]>;
    discountAmount_gte?: Maybe<Scalars["Float"]>;
    lengthInDays?: Maybe<Scalars["Int"]>;
    lengthInDays_not?: Maybe<Scalars["Int"]>;
    lengthInDays_in?: Maybe<Array<Scalars["Int"]>>;
    lengthInDays_not_in?: Maybe<Array<Scalars["Int"]>>;
    lengthInDays_lt?: Maybe<Scalars["Int"]>;
    lengthInDays_lte?: Maybe<Scalars["Int"]>;
    lengthInDays_gt?: Maybe<Scalars["Int"]>;
    lengthInDays_gte?: Maybe<Scalars["Int"]>;
    allowedTimesInDay_some?: Maybe<ITimeIntervalWhereInput>;
    allowedTimesInDay_every?: Maybe<ITimeIntervalRestrictedWhereInput>;
    allowedTimesInDay_none?: Maybe<ITimeIntervalRestrictedWhereInput>;
    renewalReminderDaysBeforeExpiry?: Maybe<Scalars["Int"]>;
    renewalReminderDaysBeforeExpiry_not?: Maybe<Scalars["Int"]>;
    renewalReminderDaysBeforeExpiry_in?: Maybe<Array<Scalars["Int"]>>;
    renewalReminderDaysBeforeExpiry_not_in?: Maybe<Array<Scalars["Int"]>>;
    renewalReminderDaysBeforeExpiry_lt?: Maybe<Scalars["Int"]>;
    renewalReminderDaysBeforeExpiry_lte?: Maybe<Scalars["Int"]>;
    renewalReminderDaysBeforeExpiry_gt?: Maybe<Scalars["Int"]>;
    renewalReminderDaysBeforeExpiry_gte?: Maybe<Scalars["Int"]>;
    renewalAvailableDaysBeforeExpiry?: Maybe<Scalars["Int"]>;
    renewalAvailableDaysBeforeExpiry_not?: Maybe<Scalars["Int"]>;
    renewalAvailableDaysBeforeExpiry_in?: Maybe<Array<Scalars["Int"]>>;
    renewalAvailableDaysBeforeExpiry_not_in?: Maybe<Array<Scalars["Int"]>>;
    renewalAvailableDaysBeforeExpiry_lt?: Maybe<Scalars["Int"]>;
    renewalAvailableDaysBeforeExpiry_lte?: Maybe<Scalars["Int"]>;
    renewalAvailableDaysBeforeExpiry_gt?: Maybe<Scalars["Int"]>;
    renewalAvailableDaysBeforeExpiry_gte?: Maybe<Scalars["Int"]>;
    isRenewable?: Maybe<Scalars["Boolean"]>;
    isRenewable_not?: Maybe<Scalars["Boolean"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    createdAt_not?: Maybe<Scalars["DateTime"]>;
    createdAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_lt?: Maybe<Scalars["DateTime"]>;
    createdAt_lte?: Maybe<Scalars["DateTime"]>;
    createdAt_gt?: Maybe<Scalars["DateTime"]>;
    createdAt_gte?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    updatedAt_not?: Maybe<Scalars["DateTime"]>;
    updatedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_lt?: Maybe<Scalars["DateTime"]>;
    updatedAt_lte?: Maybe<Scalars["DateTime"]>;
    updatedAt_gt?: Maybe<Scalars["DateTime"]>;
    updatedAt_gte?: Maybe<Scalars["DateTime"]>;
    isAvailable?: Maybe<Scalars["Boolean"]>;
    isAvailable_not?: Maybe<Scalars["Boolean"]>;
    isHiddenFromPublic?: Maybe<Scalars["Boolean"]>;
    isHiddenFromPublic_not?: Maybe<Scalars["Boolean"]>;
    isDeleted?: Maybe<Scalars["Boolean"]>;
    isDeleted_not?: Maybe<Scalars["Boolean"]>;
    deletedAt?: Maybe<Scalars["DateTime"]>;
    deletedAt_not?: Maybe<Scalars["DateTime"]>;
    deletedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    deletedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    deletedAt_lt?: Maybe<Scalars["DateTime"]>;
    deletedAt_lte?: Maybe<Scalars["DateTime"]>;
    deletedAt_gt?: Maybe<Scalars["DateTime"]>;
    deletedAt_gte?: Maybe<Scalars["DateTime"]>;
    deletedByUser?: Maybe<IUserWhereInput>;
    ordering?: Maybe<Scalars["Int"]>;
    ordering_not?: Maybe<Scalars["Int"]>;
    ordering_in?: Maybe<Array<Scalars["Int"]>>;
    ordering_not_in?: Maybe<Array<Scalars["Int"]>>;
    ordering_lt?: Maybe<Scalars["Int"]>;
    ordering_lte?: Maybe<Scalars["Int"]>;
    ordering_gt?: Maybe<Scalars["Int"]>;
    ordering_gte?: Maybe<Scalars["Int"]>;
    discountType?: Maybe<IDiscountType>;
    discountType_not?: Maybe<IDiscountType>;
    discountType_in?: Maybe<Array<IDiscountType>>;
    discountType_not_in?: Maybe<Array<IDiscountType>>;
    AND?: Maybe<Array<IMembershipWhereInput>>;
};

export type IMutation = {
    __typename?: "Mutation";
    createCustomerWithCredential: ICustomer;
    loginCustomer: IAuthPayload;
    updateMyCustomer: ICustomer;
    requestPasswordReset?: Maybe<ICredential>;
    resetPassword: ICredential;
    addNewCredentialToMyCustomer: ICredential;
    toggleCredentialOnMyCustomer: Array<ICredential>;
    updateMyCredential: ICredential;
    updateMyPassword: ICredential;
    addToBasket?: Maybe<IBasket>;
    removeFromBasket: IBasket;
    applyCoupon?: Maybe<IBasket>;
    removeCoupon: IBasket;
    destroyBasket: Scalars["ID"];
    assignBasketToCustomer?: Maybe<IBasket>;
    completeTransactionFromBasket: ICompleteTransactionFromBasketResponse;
    activateMembership?: Maybe<ICustomerMembership>;
    updateMembership: ICustomerMembership;
    applyMembership: IBasket;
    requestMembershipShare?: Maybe<ICustomerMembershipSharing>;
    removeMembershipShare: Scalars["Boolean"];
    acceptMembershipShare?: Maybe<ICustomerMembershipSharing>;
    selfCheckIn: ISelfCheckInResponse;
};

export type IMutationCreateCustomerWithCredentialArgs = {
    input: ICreateCustomerWithCredentialInput;
};

export type IMutationLoginCustomerArgs = {
    input: IAuthenticateInput;
};

export type IMutationUpdateMyCustomerArgs = {
    input?: Maybe<IUpdateMyCustomerInput>;
};

export type IMutationRequestPasswordResetArgs = {
    input: IRequestPasswordResetInput;
};

export type IMutationResetPasswordArgs = {
    input: IResetPasswordInput;
};

export type IMutationAddNewCredentialToMyCustomerArgs = {
    input?: Maybe<IAddNewCredentialToCustomerInput>;
};

export type IMutationToggleCredentialOnMyCustomerArgs = {
    input: IToggleCredentialInput;
};

export type IMutationUpdateMyCredentialArgs = {
    input?: Maybe<IUpdateMyCredentialInput>;
};

export type IMutationUpdateMyPasswordArgs = {
    input?: Maybe<IUpdateMyPasswordInput>;
};

export type IMutationAddToBasketArgs = {
    input: IAddToBasketInput;
};

export type IMutationRemoveFromBasketArgs = {
    input: IRemoveFromBasketInput;
};

export type IMutationApplyCouponArgs = {
    input: IApplyCouponInput;
};

export type IMutationRemoveCouponArgs = {
    input: IRemoveCouponInput;
};

export type IMutationDestroyBasketArgs = {
    input: IDestroyBasketInput;
};

export type IMutationAssignBasketToCustomerArgs = {
    input?: Maybe<IAssignBasketToCustomerInput>;
};

export type IMutationCompleteTransactionFromBasketArgs = {
    input?: Maybe<ICompleteTransactionFromBasketInput>;
};

export type IMutationActivateMembershipArgs = {
    data: IActivateMembershipInput;
};

export type IMutationUpdateMembershipArgs = {
    data: IUpdateMembershipInput;
};

export type IMutationApplyMembershipArgs = {
    data: IApplyMembershipInput;
};

export type IMutationRequestMembershipShareArgs = {
    input: IRequestMembershipShareInput;
};

export type IMutationRemoveMembershipShareArgs = {
    input: IRemoveMembershipShareInput;
};

export type IMutationAcceptMembershipShareArgs = {
    input: IAcceptMembershipShareInput;
};

export type IMutationSelfCheckInArgs = {
    input?: Maybe<ISelfCheckInput>;
};

export type INote = {
    __typename?: "Note";
    id: Scalars["ID"];
    createdAt: Scalars["DateTime"];
    createdByUser?: Maybe<IUser>;
    content: Scalars["String"];
    noteType?: Maybe<INoteType>;
};

export enum INoteOrderByInput {
    IdAsc = "id_ASC",
    IdDesc = "id_DESC",
    CreatedAtAsc = "createdAt_ASC",
    CreatedAtDesc = "createdAt_DESC",
    ContentAsc = "content_ASC",
    ContentDesc = "content_DESC",
    NoteTypeAsc = "noteType_ASC",
    NoteTypeDesc = "noteType_DESC"
}

export enum INoteType {
    Activities = "ACTIVITIES",
    Account = "ACCOUNT",
    FoodAndBeverages = "FOOD_AND_BEVERAGES"
}

export type INoteWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    createdAt_not?: Maybe<Scalars["DateTime"]>;
    createdAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_lt?: Maybe<Scalars["DateTime"]>;
    createdAt_lte?: Maybe<Scalars["DateTime"]>;
    createdAt_gt?: Maybe<Scalars["DateTime"]>;
    createdAt_gte?: Maybe<Scalars["DateTime"]>;
    createdByUser?: Maybe<IUserWhereInput>;
    content?: Maybe<Scalars["String"]>;
    content_not?: Maybe<Scalars["String"]>;
    content_in?: Maybe<Array<Scalars["String"]>>;
    content_not_in?: Maybe<Array<Scalars["String"]>>;
    content_lt?: Maybe<Scalars["String"]>;
    content_lte?: Maybe<Scalars["String"]>;
    content_gt?: Maybe<Scalars["String"]>;
    content_gte?: Maybe<Scalars["String"]>;
    content_contains?: Maybe<Scalars["String"]>;
    content_not_contains?: Maybe<Scalars["String"]>;
    content_starts_with?: Maybe<Scalars["String"]>;
    content_not_starts_with?: Maybe<Scalars["String"]>;
    content_ends_with?: Maybe<Scalars["String"]>;
    content_not_ends_with?: Maybe<Scalars["String"]>;
    noteType?: Maybe<INoteType>;
    noteType_not?: Maybe<INoteType>;
    noteType_in?: Maybe<Array<INoteType>>;
    noteType_not_in?: Maybe<Array<INoteType>>;
    AND?: Maybe<Array<INoteWhereInput>>;
};

export type IOrganisation = {
    __typename?: "Organisation";
    id: Scalars["ID"];
    name: Scalars["String"];
    isEnabled: Scalars["Boolean"];
    publicUrl: Scalars["String"];
    logoUrl?: Maybe<Scalars["String"]>;
    hosts: Scalars["String"];
};

export type IOrganisationWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    name?: Maybe<Scalars["String"]>;
    name_not?: Maybe<Scalars["String"]>;
    name_in?: Maybe<Array<Scalars["String"]>>;
    name_not_in?: Maybe<Array<Scalars["String"]>>;
    name_lt?: Maybe<Scalars["String"]>;
    name_lte?: Maybe<Scalars["String"]>;
    name_gt?: Maybe<Scalars["String"]>;
    name_gte?: Maybe<Scalars["String"]>;
    name_contains?: Maybe<Scalars["String"]>;
    name_not_contains?: Maybe<Scalars["String"]>;
    name_starts_with?: Maybe<Scalars["String"]>;
    name_not_starts_with?: Maybe<Scalars["String"]>;
    name_ends_with?: Maybe<Scalars["String"]>;
    name_not_ends_with?: Maybe<Scalars["String"]>;
    isEnabled?: Maybe<Scalars["Boolean"]>;
    isEnabled_not?: Maybe<Scalars["Boolean"]>;
    publicUrl?: Maybe<Scalars["String"]>;
    publicUrl_not?: Maybe<Scalars["String"]>;
    publicUrl_in?: Maybe<Array<Scalars["String"]>>;
    publicUrl_not_in?: Maybe<Array<Scalars["String"]>>;
    publicUrl_lt?: Maybe<Scalars["String"]>;
    publicUrl_lte?: Maybe<Scalars["String"]>;
    publicUrl_gt?: Maybe<Scalars["String"]>;
    publicUrl_gte?: Maybe<Scalars["String"]>;
    publicUrl_contains?: Maybe<Scalars["String"]>;
    publicUrl_not_contains?: Maybe<Scalars["String"]>;
    publicUrl_starts_with?: Maybe<Scalars["String"]>;
    publicUrl_not_starts_with?: Maybe<Scalars["String"]>;
    publicUrl_ends_with?: Maybe<Scalars["String"]>;
    publicUrl_not_ends_with?: Maybe<Scalars["String"]>;
    logoUrl?: Maybe<Scalars["String"]>;
    logoUrl_not?: Maybe<Scalars["String"]>;
    logoUrl_in?: Maybe<Array<Scalars["String"]>>;
    logoUrl_not_in?: Maybe<Array<Scalars["String"]>>;
    logoUrl_lt?: Maybe<Scalars["String"]>;
    logoUrl_lte?: Maybe<Scalars["String"]>;
    logoUrl_gt?: Maybe<Scalars["String"]>;
    logoUrl_gte?: Maybe<Scalars["String"]>;
    logoUrl_contains?: Maybe<Scalars["String"]>;
    logoUrl_not_contains?: Maybe<Scalars["String"]>;
    logoUrl_starts_with?: Maybe<Scalars["String"]>;
    logoUrl_not_starts_with?: Maybe<Scalars["String"]>;
    logoUrl_ends_with?: Maybe<Scalars["String"]>;
    logoUrl_not_ends_with?: Maybe<Scalars["String"]>;
    midAdminScheme?: Maybe<Scalars["String"]>;
    midAdminScheme_not?: Maybe<Scalars["String"]>;
    midAdminScheme_in?: Maybe<Array<Scalars["String"]>>;
    midAdminScheme_not_in?: Maybe<Array<Scalars["String"]>>;
    midAdminScheme_lt?: Maybe<Scalars["String"]>;
    midAdminScheme_lte?: Maybe<Scalars["String"]>;
    midAdminScheme_gt?: Maybe<Scalars["String"]>;
    midAdminScheme_gte?: Maybe<Scalars["String"]>;
    midAdminScheme_contains?: Maybe<Scalars["String"]>;
    midAdminScheme_not_contains?: Maybe<Scalars["String"]>;
    midAdminScheme_starts_with?: Maybe<Scalars["String"]>;
    midAdminScheme_not_starts_with?: Maybe<Scalars["String"]>;
    midAdminScheme_ends_with?: Maybe<Scalars["String"]>;
    midAdminScheme_not_ends_with?: Maybe<Scalars["String"]>;
    adminScheme?: Maybe<Scalars["String"]>;
    adminScheme_not?: Maybe<Scalars["String"]>;
    adminScheme_in?: Maybe<Array<Scalars["String"]>>;
    adminScheme_not_in?: Maybe<Array<Scalars["String"]>>;
    adminScheme_lt?: Maybe<Scalars["String"]>;
    adminScheme_lte?: Maybe<Scalars["String"]>;
    adminScheme_gt?: Maybe<Scalars["String"]>;
    adminScheme_gte?: Maybe<Scalars["String"]>;
    adminScheme_contains?: Maybe<Scalars["String"]>;
    adminScheme_not_contains?: Maybe<Scalars["String"]>;
    adminScheme_starts_with?: Maybe<Scalars["String"]>;
    adminScheme_not_starts_with?: Maybe<Scalars["String"]>;
    adminScheme_ends_with?: Maybe<Scalars["String"]>;
    adminScheme_not_ends_with?: Maybe<Scalars["String"]>;
    hosts?: Maybe<Scalars["String"]>;
    hosts_not?: Maybe<Scalars["String"]>;
    hosts_in?: Maybe<Array<Scalars["String"]>>;
    hosts_not_in?: Maybe<Array<Scalars["String"]>>;
    hosts_lt?: Maybe<Scalars["String"]>;
    hosts_lte?: Maybe<Scalars["String"]>;
    hosts_gt?: Maybe<Scalars["String"]>;
    hosts_gte?: Maybe<Scalars["String"]>;
    hosts_contains?: Maybe<Scalars["String"]>;
    hosts_not_contains?: Maybe<Scalars["String"]>;
    hosts_starts_with?: Maybe<Scalars["String"]>;
    hosts_not_starts_with?: Maybe<Scalars["String"]>;
    hosts_ends_with?: Maybe<Scalars["String"]>;
    hosts_not_ends_with?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    createdAt_not?: Maybe<Scalars["DateTime"]>;
    createdAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_lt?: Maybe<Scalars["DateTime"]>;
    createdAt_lte?: Maybe<Scalars["DateTime"]>;
    createdAt_gt?: Maybe<Scalars["DateTime"]>;
    createdAt_gte?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    updatedAt_not?: Maybe<Scalars["DateTime"]>;
    updatedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_lt?: Maybe<Scalars["DateTime"]>;
    updatedAt_lte?: Maybe<Scalars["DateTime"]>;
    updatedAt_gt?: Maybe<Scalars["DateTime"]>;
    updatedAt_gte?: Maybe<Scalars["DateTime"]>;
    users_some?: Maybe<IUserWhereInput>;
    facilities_some?: Maybe<IFacilityWhereInput>;
    customers_some?: Maybe<ICustomerWhereInput>;
    bookings_some?: Maybe<IBookingWhereInput>;
    discounts_some?: Maybe<IDiscountWhereInput>;
    coupons_some?: Maybe<ICouponWhereInput>;
    roles_some?: Maybe<IRoleWhereInput>;
    transactions_some?: Maybe<ITransactionWhereInput>;
    memberships_some?: Maybe<IMembershipWhereInput>;
    resourceGroups_some?: Maybe<IResourceGroupWhereInput>;
    membershipGroups_some?: Maybe<IMembershipGroupWhereInput>;
    AND?: Maybe<Array<IOrganisationWhereInput>>;
};

export type IPackage = {
    __typename?: "Package";
    id: Scalars["ID"];
    name: Scalars["String"];
    description: Scalars["String"];
    activity: IActivity;
    activityInstances?: Maybe<Array<IActivityInstance>>;
    bookings?: Maybe<Array<IBooking>>;
    isDeleted?: Maybe<Scalars["Boolean"]>;
    deletedAt?: Maybe<Scalars["DateTime"]>;
    deletedByUser?: Maybe<IUser>;
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
};

export type IPackageActivityInstancesArgs = {
    where?: Maybe<IActivityInstanceWhereInput>;
    orderBy?: Maybe<IActivityInstanceOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type IPackageBookingsArgs = {
    where?: Maybe<IBookingWhereInput>;
    orderBy?: Maybe<IBookingOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export enum IPackageOrderByInput {
    IdAsc = "id_ASC",
    IdDesc = "id_DESC",
    NameAsc = "name_ASC",
    NameDesc = "name_DESC",
    DescriptionAsc = "description_ASC",
    DescriptionDesc = "description_DESC",
    IsDeletedAsc = "isDeleted_ASC",
    IsDeletedDesc = "isDeleted_DESC",
    DeletedAtAsc = "deletedAt_ASC",
    DeletedAtDesc = "deletedAt_DESC",
    CreatedAtAsc = "createdAt_ASC",
    CreatedAtDesc = "createdAt_DESC",
    UpdatedAtAsc = "updatedAt_ASC",
    UpdatedAtDesc = "updatedAt_DESC"
}

export type IPackageWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    name?: Maybe<Scalars["String"]>;
    name_not?: Maybe<Scalars["String"]>;
    name_in?: Maybe<Array<Scalars["String"]>>;
    name_not_in?: Maybe<Array<Scalars["String"]>>;
    name_lt?: Maybe<Scalars["String"]>;
    name_lte?: Maybe<Scalars["String"]>;
    name_gt?: Maybe<Scalars["String"]>;
    name_gte?: Maybe<Scalars["String"]>;
    name_contains?: Maybe<Scalars["String"]>;
    name_not_contains?: Maybe<Scalars["String"]>;
    name_starts_with?: Maybe<Scalars["String"]>;
    name_not_starts_with?: Maybe<Scalars["String"]>;
    name_ends_with?: Maybe<Scalars["String"]>;
    name_not_ends_with?: Maybe<Scalars["String"]>;
    description?: Maybe<Scalars["String"]>;
    description_not?: Maybe<Scalars["String"]>;
    description_in?: Maybe<Array<Scalars["String"]>>;
    description_not_in?: Maybe<Array<Scalars["String"]>>;
    description_lt?: Maybe<Scalars["String"]>;
    description_lte?: Maybe<Scalars["String"]>;
    description_gt?: Maybe<Scalars["String"]>;
    description_gte?: Maybe<Scalars["String"]>;
    description_contains?: Maybe<Scalars["String"]>;
    description_not_contains?: Maybe<Scalars["String"]>;
    description_starts_with?: Maybe<Scalars["String"]>;
    description_not_starts_with?: Maybe<Scalars["String"]>;
    description_ends_with?: Maybe<Scalars["String"]>;
    description_not_ends_with?: Maybe<Scalars["String"]>;
    activity?: Maybe<IActivityWhereInput>;
    activityInstances_some?: Maybe<IActivityInstanceWhereInput>;
    bookings_some?: Maybe<IBookingWhereInput>;
    isDeleted?: Maybe<Scalars["Boolean"]>;
    isDeleted_not?: Maybe<Scalars["Boolean"]>;
    deletedAt?: Maybe<Scalars["DateTime"]>;
    deletedAt_not?: Maybe<Scalars["DateTime"]>;
    deletedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    deletedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    deletedAt_lt?: Maybe<Scalars["DateTime"]>;
    deletedAt_lte?: Maybe<Scalars["DateTime"]>;
    deletedAt_gt?: Maybe<Scalars["DateTime"]>;
    deletedAt_gte?: Maybe<Scalars["DateTime"]>;
    deletedByUser?: Maybe<IUserWhereInput>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    createdAt_not?: Maybe<Scalars["DateTime"]>;
    createdAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_lt?: Maybe<Scalars["DateTime"]>;
    createdAt_lte?: Maybe<Scalars["DateTime"]>;
    createdAt_gt?: Maybe<Scalars["DateTime"]>;
    createdAt_gte?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    updatedAt_not?: Maybe<Scalars["DateTime"]>;
    updatedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_lt?: Maybe<Scalars["DateTime"]>;
    updatedAt_lte?: Maybe<Scalars["DateTime"]>;
    updatedAt_gt?: Maybe<Scalars["DateTime"]>;
    updatedAt_gte?: Maybe<Scalars["DateTime"]>;
    AND?: Maybe<Array<IPackageWhereInput>>;
};

export type IPassword = {
    __typename?: "Password";
    hash: Scalars["String"];
    lastChangedAt: Scalars["DateTime"];
    numberOfIncorrectAttempts: Scalars["Int"];
    lastLockedOutAt?: Maybe<Scalars["DateTime"]>;
};

export type IPasswordWhereInput = {
    hash?: Maybe<Scalars["String"]>;
    hash_not?: Maybe<Scalars["String"]>;
    hash_in?: Maybe<Array<Scalars["String"]>>;
    hash_not_in?: Maybe<Array<Scalars["String"]>>;
    hash_lt?: Maybe<Scalars["String"]>;
    hash_lte?: Maybe<Scalars["String"]>;
    hash_gt?: Maybe<Scalars["String"]>;
    hash_gte?: Maybe<Scalars["String"]>;
    hash_contains?: Maybe<Scalars["String"]>;
    hash_not_contains?: Maybe<Scalars["String"]>;
    hash_starts_with?: Maybe<Scalars["String"]>;
    hash_not_starts_with?: Maybe<Scalars["String"]>;
    hash_ends_with?: Maybe<Scalars["String"]>;
    hash_not_ends_with?: Maybe<Scalars["String"]>;
    lastChangedAt?: Maybe<Scalars["DateTime"]>;
    lastChangedAt_not?: Maybe<Scalars["DateTime"]>;
    lastChangedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    lastChangedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    lastChangedAt_lt?: Maybe<Scalars["DateTime"]>;
    lastChangedAt_lte?: Maybe<Scalars["DateTime"]>;
    lastChangedAt_gt?: Maybe<Scalars["DateTime"]>;
    lastChangedAt_gte?: Maybe<Scalars["DateTime"]>;
    numberOfIncorrectAttempts?: Maybe<Scalars["Int"]>;
    numberOfIncorrectAttempts_not?: Maybe<Scalars["Int"]>;
    numberOfIncorrectAttempts_in?: Maybe<Array<Scalars["Int"]>>;
    numberOfIncorrectAttempts_not_in?: Maybe<Array<Scalars["Int"]>>;
    numberOfIncorrectAttempts_lt?: Maybe<Scalars["Int"]>;
    numberOfIncorrectAttempts_lte?: Maybe<Scalars["Int"]>;
    numberOfIncorrectAttempts_gt?: Maybe<Scalars["Int"]>;
    numberOfIncorrectAttempts_gte?: Maybe<Scalars["Int"]>;
    lastLockedOutAt?: Maybe<Scalars["DateTime"]>;
    lastLockedOutAt_not?: Maybe<Scalars["DateTime"]>;
    lastLockedOutAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    lastLockedOutAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    lastLockedOutAt_lt?: Maybe<Scalars["DateTime"]>;
    lastLockedOutAt_lte?: Maybe<Scalars["DateTime"]>;
    lastLockedOutAt_gt?: Maybe<Scalars["DateTime"]>;
    lastLockedOutAt_gte?: Maybe<Scalars["DateTime"]>;
    AND?: Maybe<Array<IPasswordWhereInput>>;
};

export type IPhotoData = {
    __typename?: "PhotoData";
    base64?: Maybe<Scalars["String"]>;
};

export type IQuery = {
    __typename?: "Query";
    activityTimesForDate: Array<IAvailableTime>;
    basketById?: Maybe<IBasket>;
    getOrganisationId: Scalars["String"];
    resourcesByOrg: Array<IResource>;
    resource: IResource;
    braintreeClientToken: IBraintreeClientToken;
    myCredential?: Maybe<ICredential>;
    getTransaction: ITransaction;
    membershipGroupsByOrg: Array<IMembershipGroup>;
    membershipGroupByResourceGroupStub?: Maybe<IMembershipGroup>;
    myCustomerMemberships?: Maybe<Array<ICustomerMembership>>;
    sharedCustomerMemberships?: Maybe<ISharedCustomerMembershipOutput>;
    membershipsByOrg: Array<IMembership>;
    checkInInfo: ICheckInInfo;
};

export type IQueryActivityTimesForDateArgs = {
    input?: Maybe<IActivityAvailabilityInput>;
};

export type IQueryBasketByIdArgs = {
    id?: Maybe<Scalars["ID"]>;
};

export type IQueryResourcesByOrgArgs = {
    input?: Maybe<IResourcesByOrgInput>;
};

export type IQueryResourceArgs = {
    input?: Maybe<IResourceInput>;
};

export type IQueryGetTransactionArgs = {
    id: Scalars["ID"];
};

export type IQueryMembershipGroupsByOrgArgs = {
    input?: Maybe<IMembershipGroupsByOrgInput>;
};

export type IQueryMembershipGroupByResourceGroupStubArgs = {
    input?: Maybe<IMembershipGroupByResourceGroupStubInput>;
};

export type IQuerySharedCustomerMembershipsArgs = {
    input?: Maybe<ISharedCustomerMembershipInput>;
};

export type IQueryMembershipsByOrgArgs = {
    input?: Maybe<IMembershipsByOrgInput>;
};

export type IQueryCheckInInfoArgs = {
    bookingReference: Scalars["String"];
};

export type IRemoveCouponInput = {
    basketId: Scalars["ID"];
    couponId: Scalars["ID"];
};

export type IRemoveFromBasketInput = {
    basketId: Scalars["ID"];
    basketItemId: Scalars["ID"];
};

export type IRemoveMembershipShareInput = {
    customerMembershipShareId: Scalars["ID"];
};

export type IRequestMembershipShareInput = {
    customerMembershipId: Scalars["ID"];
    emailAddress: Scalars["String"];
};

export type IRequestPasswordResetInput = {
    email: Scalars["String"];
    organisationId: Scalars["ID"];
};

export type IResetPasswordInput = {
    securityStamp: Scalars["String"];
    newPassword: Scalars["String"];
    organisationId: Scalars["ID"];
};

export type IResource = {
    __typename?: "Resource";
    id: Scalars["ID"];
    facility: IFacility;
    activities: Array<IActivity>;
    resourceGroups?: Maybe<Array<IResourceGroup>>;
    memberships?: Maybe<Array<IMembership>>;
    name: Scalars["String"];
    description: Scalars["String"];
    imageUrl: Scalars["String"];
    overrideNominalCode?: Maybe<Scalars["String"]>;
    stub?: Maybe<Scalars["String"]>;
    isAvailable: Scalars["Boolean"];
    isHiddenFromPublic: Scalars["Boolean"];
    isShownOnDefaultView?: Maybe<Scalars["Boolean"]>;
    defaultMaxCapacity: Scalars["Int"];
    defaultBookMinDaysInAdvance: Scalars["Int"];
    defaultBookMaxDaysInAdvance: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
    ordering: Scalars["Int"];
    dailyStartTime: Scalars["String"];
    dailyEndTime: Scalars["String"];
    rulesOfUseHeading?: Maybe<Scalars["String"]>;
    rulesOfUseUpdatedAt?: Maybe<Scalars["DateTime"]>;
    rulesOfUse?: Maybe<Scalars["String"]>;
    extras?: Maybe<Array<IExtra>>;
    otherMetadata?: Maybe<Scalars["Json"]>;
    preCheckInInstruction?: Maybe<Scalars["String"]>;
    postCheckInInstruction?: Maybe<Scalars["String"]>;
    isDeleted?: Maybe<Scalars["Boolean"]>;
    deletedAt?: Maybe<Scalars["DateTime"]>;
    deletedByUser?: Maybe<IUser>;
};

export type IResourceResourceGroupsArgs = {
    where?: Maybe<IResourceGroupWhereInput>;
    orderBy?: Maybe<IResourceGroupOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type IResourceMembershipsArgs = {
    where?: Maybe<IMembershipWhereInput>;
    orderBy?: Maybe<IMembershipOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type IResourceExtrasArgs = {
    where?: Maybe<IExtraWhereInput>;
    orderBy?: Maybe<IExtraOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type IResourceGroup = {
    __typename?: "ResourceGroup";
    id: Scalars["ID"];
    organisation: IOrganisation;
    name: Scalars["String"];
    stub: Scalars["String"];
    resources?: Maybe<Array<IResource>>;
    membershipGroup?: Maybe<IMembershipGroup>;
    ordering: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
    isDeleted?: Maybe<Scalars["Boolean"]>;
    deletedAt?: Maybe<Scalars["DateTime"]>;
    deletedByUser?: Maybe<IUser>;
};

export type IResourceGroupResourcesArgs = {
    where?: Maybe<IResourceWhereInput>;
    orderBy?: Maybe<IResourceOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export enum IResourceGroupOrderByInput {
    IdAsc = "id_ASC",
    IdDesc = "id_DESC",
    NameAsc = "name_ASC",
    NameDesc = "name_DESC",
    StubAsc = "stub_ASC",
    StubDesc = "stub_DESC",
    OrderingAsc = "ordering_ASC",
    OrderingDesc = "ordering_DESC",
    CreatedAtAsc = "createdAt_ASC",
    CreatedAtDesc = "createdAt_DESC",
    UpdatedAtAsc = "updatedAt_ASC",
    UpdatedAtDesc = "updatedAt_DESC",
    IsDeletedAsc = "isDeleted_ASC",
    IsDeletedDesc = "isDeleted_DESC",
    DeletedAtAsc = "deletedAt_ASC",
    DeletedAtDesc = "deletedAt_DESC"
}

export type IResourceGroupWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    organisation?: Maybe<IOrganisationWhereInput>;
    name?: Maybe<Scalars["String"]>;
    name_not?: Maybe<Scalars["String"]>;
    name_in?: Maybe<Array<Scalars["String"]>>;
    name_not_in?: Maybe<Array<Scalars["String"]>>;
    name_lt?: Maybe<Scalars["String"]>;
    name_lte?: Maybe<Scalars["String"]>;
    name_gt?: Maybe<Scalars["String"]>;
    name_gte?: Maybe<Scalars["String"]>;
    name_contains?: Maybe<Scalars["String"]>;
    name_not_contains?: Maybe<Scalars["String"]>;
    name_starts_with?: Maybe<Scalars["String"]>;
    name_not_starts_with?: Maybe<Scalars["String"]>;
    name_ends_with?: Maybe<Scalars["String"]>;
    name_not_ends_with?: Maybe<Scalars["String"]>;
    stub?: Maybe<Scalars["String"]>;
    stub_not?: Maybe<Scalars["String"]>;
    stub_in?: Maybe<Array<Scalars["String"]>>;
    stub_not_in?: Maybe<Array<Scalars["String"]>>;
    stub_lt?: Maybe<Scalars["String"]>;
    stub_lte?: Maybe<Scalars["String"]>;
    stub_gt?: Maybe<Scalars["String"]>;
    stub_gte?: Maybe<Scalars["String"]>;
    stub_contains?: Maybe<Scalars["String"]>;
    stub_not_contains?: Maybe<Scalars["String"]>;
    stub_starts_with?: Maybe<Scalars["String"]>;
    stub_not_starts_with?: Maybe<Scalars["String"]>;
    stub_ends_with?: Maybe<Scalars["String"]>;
    stub_not_ends_with?: Maybe<Scalars["String"]>;
    resources_some?: Maybe<IResourceWhereInput>;
    membershipGroup?: Maybe<IMembershipGroupWhereInput>;
    ordering?: Maybe<Scalars["Int"]>;
    ordering_not?: Maybe<Scalars["Int"]>;
    ordering_in?: Maybe<Array<Scalars["Int"]>>;
    ordering_not_in?: Maybe<Array<Scalars["Int"]>>;
    ordering_lt?: Maybe<Scalars["Int"]>;
    ordering_lte?: Maybe<Scalars["Int"]>;
    ordering_gt?: Maybe<Scalars["Int"]>;
    ordering_gte?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    createdAt_not?: Maybe<Scalars["DateTime"]>;
    createdAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_lt?: Maybe<Scalars["DateTime"]>;
    createdAt_lte?: Maybe<Scalars["DateTime"]>;
    createdAt_gt?: Maybe<Scalars["DateTime"]>;
    createdAt_gte?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    updatedAt_not?: Maybe<Scalars["DateTime"]>;
    updatedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_lt?: Maybe<Scalars["DateTime"]>;
    updatedAt_lte?: Maybe<Scalars["DateTime"]>;
    updatedAt_gt?: Maybe<Scalars["DateTime"]>;
    updatedAt_gte?: Maybe<Scalars["DateTime"]>;
    isDeleted?: Maybe<Scalars["Boolean"]>;
    isDeleted_not?: Maybe<Scalars["Boolean"]>;
    deletedAt?: Maybe<Scalars["DateTime"]>;
    deletedAt_not?: Maybe<Scalars["DateTime"]>;
    deletedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    deletedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    deletedAt_lt?: Maybe<Scalars["DateTime"]>;
    deletedAt_lte?: Maybe<Scalars["DateTime"]>;
    deletedAt_gt?: Maybe<Scalars["DateTime"]>;
    deletedAt_gte?: Maybe<Scalars["DateTime"]>;
    deletedByUser?: Maybe<IUserWhereInput>;
    AND?: Maybe<Array<IResourceGroupWhereInput>>;
};

export type IResourceInput = {
    resourceId: Scalars["ID"];
};

export enum IResourceOrderByInput {
    IdAsc = "id_ASC",
    IdDesc = "id_DESC",
    NameAsc = "name_ASC",
    NameDesc = "name_DESC",
    DescriptionAsc = "description_ASC",
    DescriptionDesc = "description_DESC",
    ImageUrlAsc = "imageUrl_ASC",
    ImageUrlDesc = "imageUrl_DESC",
    OverrideNominalCodeAsc = "overrideNominalCode_ASC",
    OverrideNominalCodeDesc = "overrideNominalCode_DESC",
    StubAsc = "stub_ASC",
    StubDesc = "stub_DESC",
    IsAvailableAsc = "isAvailable_ASC",
    IsAvailableDesc = "isAvailable_DESC",
    IsHiddenFromPublicAsc = "isHiddenFromPublic_ASC",
    IsHiddenFromPublicDesc = "isHiddenFromPublic_DESC",
    IsShownOnDefaultViewAsc = "isShownOnDefaultView_ASC",
    IsShownOnDefaultViewDesc = "isShownOnDefaultView_DESC",
    DefaultMaxCapacityAsc = "defaultMaxCapacity_ASC",
    DefaultMaxCapacityDesc = "defaultMaxCapacity_DESC",
    DefaultBookMinDaysInAdvanceAsc = "defaultBookMinDaysInAdvance_ASC",
    DefaultBookMinDaysInAdvanceDesc = "defaultBookMinDaysInAdvance_DESC",
    DefaultBookMaxDaysInAdvanceAsc = "defaultBookMaxDaysInAdvance_ASC",
    DefaultBookMaxDaysInAdvanceDesc = "defaultBookMaxDaysInAdvance_DESC",
    CreatedAtAsc = "createdAt_ASC",
    CreatedAtDesc = "createdAt_DESC",
    UpdatedAtAsc = "updatedAt_ASC",
    UpdatedAtDesc = "updatedAt_DESC",
    OrderingAsc = "ordering_ASC",
    OrderingDesc = "ordering_DESC",
    DailyStartTimeAsc = "dailyStartTime_ASC",
    DailyStartTimeDesc = "dailyStartTime_DESC",
    DailyEndTimeAsc = "dailyEndTime_ASC",
    DailyEndTimeDesc = "dailyEndTime_DESC",
    RulesOfUseHeadingAsc = "rulesOfUseHeading_ASC",
    RulesOfUseHeadingDesc = "rulesOfUseHeading_DESC",
    RulesOfUseUpdatedAtAsc = "rulesOfUseUpdatedAt_ASC",
    RulesOfUseUpdatedAtDesc = "rulesOfUseUpdatedAt_DESC",
    RulesOfUseAsc = "rulesOfUse_ASC",
    RulesOfUseDesc = "rulesOfUse_DESC",
    OtherMetadataAsc = "otherMetadata_ASC",
    OtherMetadataDesc = "otherMetadata_DESC",
    PreCheckInInstructionAsc = "preCheckInInstruction_ASC",
    PreCheckInInstructionDesc = "preCheckInInstruction_DESC",
    PostCheckInInstructionAsc = "postCheckInInstruction_ASC",
    PostCheckInInstructionDesc = "postCheckInInstruction_DESC",
    IsDeletedAsc = "isDeleted_ASC",
    IsDeletedDesc = "isDeleted_DESC",
    DeletedAtAsc = "deletedAt_ASC",
    DeletedAtDesc = "deletedAt_DESC"
}

export type IResourcesByOrgInput = {
    orgId: Scalars["ID"];
    resourceStub?: Maybe<Scalars["String"]>;
    facilityStub?: Maybe<Scalars["String"]>;
    resourceGroupStub?: Maybe<Scalars["String"]>;
    membershipId?: Maybe<Scalars["String"]>;
};

export type IResourceWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    facility?: Maybe<IFacilityWhereInput>;
    activities_some?: Maybe<IActivityWhereInput>;
    daySchedules_some?: Maybe<IDayScheduleWhereInput>;
    weekTemplates_some?: Maybe<IWeekTemplateWhereInput>;
    resourceGroups_some?: Maybe<IResourceGroupWhereInput>;
    memberships_some?: Maybe<IMembershipWhereInput>;
    name?: Maybe<Scalars["String"]>;
    name_not?: Maybe<Scalars["String"]>;
    name_in?: Maybe<Array<Scalars["String"]>>;
    name_not_in?: Maybe<Array<Scalars["String"]>>;
    name_lt?: Maybe<Scalars["String"]>;
    name_lte?: Maybe<Scalars["String"]>;
    name_gt?: Maybe<Scalars["String"]>;
    name_gte?: Maybe<Scalars["String"]>;
    name_contains?: Maybe<Scalars["String"]>;
    name_not_contains?: Maybe<Scalars["String"]>;
    name_starts_with?: Maybe<Scalars["String"]>;
    name_not_starts_with?: Maybe<Scalars["String"]>;
    name_ends_with?: Maybe<Scalars["String"]>;
    name_not_ends_with?: Maybe<Scalars["String"]>;
    description?: Maybe<Scalars["String"]>;
    description_not?: Maybe<Scalars["String"]>;
    description_in?: Maybe<Array<Scalars["String"]>>;
    description_not_in?: Maybe<Array<Scalars["String"]>>;
    description_lt?: Maybe<Scalars["String"]>;
    description_lte?: Maybe<Scalars["String"]>;
    description_gt?: Maybe<Scalars["String"]>;
    description_gte?: Maybe<Scalars["String"]>;
    description_contains?: Maybe<Scalars["String"]>;
    description_not_contains?: Maybe<Scalars["String"]>;
    description_starts_with?: Maybe<Scalars["String"]>;
    description_not_starts_with?: Maybe<Scalars["String"]>;
    description_ends_with?: Maybe<Scalars["String"]>;
    description_not_ends_with?: Maybe<Scalars["String"]>;
    imageUrl?: Maybe<Scalars["String"]>;
    imageUrl_not?: Maybe<Scalars["String"]>;
    imageUrl_in?: Maybe<Array<Scalars["String"]>>;
    imageUrl_not_in?: Maybe<Array<Scalars["String"]>>;
    imageUrl_lt?: Maybe<Scalars["String"]>;
    imageUrl_lte?: Maybe<Scalars["String"]>;
    imageUrl_gt?: Maybe<Scalars["String"]>;
    imageUrl_gte?: Maybe<Scalars["String"]>;
    imageUrl_contains?: Maybe<Scalars["String"]>;
    imageUrl_not_contains?: Maybe<Scalars["String"]>;
    imageUrl_starts_with?: Maybe<Scalars["String"]>;
    imageUrl_not_starts_with?: Maybe<Scalars["String"]>;
    imageUrl_ends_with?: Maybe<Scalars["String"]>;
    imageUrl_not_ends_with?: Maybe<Scalars["String"]>;
    overrideNominalCode?: Maybe<Scalars["String"]>;
    overrideNominalCode_not?: Maybe<Scalars["String"]>;
    overrideNominalCode_in?: Maybe<Array<Scalars["String"]>>;
    overrideNominalCode_not_in?: Maybe<Array<Scalars["String"]>>;
    overrideNominalCode_lt?: Maybe<Scalars["String"]>;
    overrideNominalCode_lte?: Maybe<Scalars["String"]>;
    overrideNominalCode_gt?: Maybe<Scalars["String"]>;
    overrideNominalCode_gte?: Maybe<Scalars["String"]>;
    overrideNominalCode_contains?: Maybe<Scalars["String"]>;
    overrideNominalCode_not_contains?: Maybe<Scalars["String"]>;
    overrideNominalCode_starts_with?: Maybe<Scalars["String"]>;
    overrideNominalCode_not_starts_with?: Maybe<Scalars["String"]>;
    overrideNominalCode_ends_with?: Maybe<Scalars["String"]>;
    overrideNominalCode_not_ends_with?: Maybe<Scalars["String"]>;
    stub?: Maybe<Scalars["String"]>;
    stub_not?: Maybe<Scalars["String"]>;
    stub_in?: Maybe<Array<Scalars["String"]>>;
    stub_not_in?: Maybe<Array<Scalars["String"]>>;
    stub_lt?: Maybe<Scalars["String"]>;
    stub_lte?: Maybe<Scalars["String"]>;
    stub_gt?: Maybe<Scalars["String"]>;
    stub_gte?: Maybe<Scalars["String"]>;
    stub_contains?: Maybe<Scalars["String"]>;
    stub_not_contains?: Maybe<Scalars["String"]>;
    stub_starts_with?: Maybe<Scalars["String"]>;
    stub_not_starts_with?: Maybe<Scalars["String"]>;
    stub_ends_with?: Maybe<Scalars["String"]>;
    stub_not_ends_with?: Maybe<Scalars["String"]>;
    isAvailable?: Maybe<Scalars["Boolean"]>;
    isAvailable_not?: Maybe<Scalars["Boolean"]>;
    isHiddenFromPublic?: Maybe<Scalars["Boolean"]>;
    isHiddenFromPublic_not?: Maybe<Scalars["Boolean"]>;
    isShownOnDefaultView?: Maybe<Scalars["Boolean"]>;
    isShownOnDefaultView_not?: Maybe<Scalars["Boolean"]>;
    defaultMaxCapacity?: Maybe<Scalars["Int"]>;
    defaultMaxCapacity_not?: Maybe<Scalars["Int"]>;
    defaultMaxCapacity_in?: Maybe<Array<Scalars["Int"]>>;
    defaultMaxCapacity_not_in?: Maybe<Array<Scalars["Int"]>>;
    defaultMaxCapacity_lt?: Maybe<Scalars["Int"]>;
    defaultMaxCapacity_lte?: Maybe<Scalars["Int"]>;
    defaultMaxCapacity_gt?: Maybe<Scalars["Int"]>;
    defaultMaxCapacity_gte?: Maybe<Scalars["Int"]>;
    defaultBookMinDaysInAdvance?: Maybe<Scalars["Int"]>;
    defaultBookMinDaysInAdvance_not?: Maybe<Scalars["Int"]>;
    defaultBookMinDaysInAdvance_in?: Maybe<Array<Scalars["Int"]>>;
    defaultBookMinDaysInAdvance_not_in?: Maybe<Array<Scalars["Int"]>>;
    defaultBookMinDaysInAdvance_lt?: Maybe<Scalars["Int"]>;
    defaultBookMinDaysInAdvance_lte?: Maybe<Scalars["Int"]>;
    defaultBookMinDaysInAdvance_gt?: Maybe<Scalars["Int"]>;
    defaultBookMinDaysInAdvance_gte?: Maybe<Scalars["Int"]>;
    defaultBookMaxDaysInAdvance?: Maybe<Scalars["Int"]>;
    defaultBookMaxDaysInAdvance_not?: Maybe<Scalars["Int"]>;
    defaultBookMaxDaysInAdvance_in?: Maybe<Array<Scalars["Int"]>>;
    defaultBookMaxDaysInAdvance_not_in?: Maybe<Array<Scalars["Int"]>>;
    defaultBookMaxDaysInAdvance_lt?: Maybe<Scalars["Int"]>;
    defaultBookMaxDaysInAdvance_lte?: Maybe<Scalars["Int"]>;
    defaultBookMaxDaysInAdvance_gt?: Maybe<Scalars["Int"]>;
    defaultBookMaxDaysInAdvance_gte?: Maybe<Scalars["Int"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    createdAt_not?: Maybe<Scalars["DateTime"]>;
    createdAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_lt?: Maybe<Scalars["DateTime"]>;
    createdAt_lte?: Maybe<Scalars["DateTime"]>;
    createdAt_gt?: Maybe<Scalars["DateTime"]>;
    createdAt_gte?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    updatedAt_not?: Maybe<Scalars["DateTime"]>;
    updatedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_lt?: Maybe<Scalars["DateTime"]>;
    updatedAt_lte?: Maybe<Scalars["DateTime"]>;
    updatedAt_gt?: Maybe<Scalars["DateTime"]>;
    updatedAt_gte?: Maybe<Scalars["DateTime"]>;
    ordering?: Maybe<Scalars["Int"]>;
    ordering_not?: Maybe<Scalars["Int"]>;
    ordering_in?: Maybe<Array<Scalars["Int"]>>;
    ordering_not_in?: Maybe<Array<Scalars["Int"]>>;
    ordering_lt?: Maybe<Scalars["Int"]>;
    ordering_lte?: Maybe<Scalars["Int"]>;
    ordering_gt?: Maybe<Scalars["Int"]>;
    ordering_gte?: Maybe<Scalars["Int"]>;
    dailyStartTime?: Maybe<Scalars["String"]>;
    dailyStartTime_not?: Maybe<Scalars["String"]>;
    dailyStartTime_in?: Maybe<Array<Scalars["String"]>>;
    dailyStartTime_not_in?: Maybe<Array<Scalars["String"]>>;
    dailyStartTime_lt?: Maybe<Scalars["String"]>;
    dailyStartTime_lte?: Maybe<Scalars["String"]>;
    dailyStartTime_gt?: Maybe<Scalars["String"]>;
    dailyStartTime_gte?: Maybe<Scalars["String"]>;
    dailyStartTime_contains?: Maybe<Scalars["String"]>;
    dailyStartTime_not_contains?: Maybe<Scalars["String"]>;
    dailyStartTime_starts_with?: Maybe<Scalars["String"]>;
    dailyStartTime_not_starts_with?: Maybe<Scalars["String"]>;
    dailyStartTime_ends_with?: Maybe<Scalars["String"]>;
    dailyStartTime_not_ends_with?: Maybe<Scalars["String"]>;
    dailyEndTime?: Maybe<Scalars["String"]>;
    dailyEndTime_not?: Maybe<Scalars["String"]>;
    dailyEndTime_in?: Maybe<Array<Scalars["String"]>>;
    dailyEndTime_not_in?: Maybe<Array<Scalars["String"]>>;
    dailyEndTime_lt?: Maybe<Scalars["String"]>;
    dailyEndTime_lte?: Maybe<Scalars["String"]>;
    dailyEndTime_gt?: Maybe<Scalars["String"]>;
    dailyEndTime_gte?: Maybe<Scalars["String"]>;
    dailyEndTime_contains?: Maybe<Scalars["String"]>;
    dailyEndTime_not_contains?: Maybe<Scalars["String"]>;
    dailyEndTime_starts_with?: Maybe<Scalars["String"]>;
    dailyEndTime_not_starts_with?: Maybe<Scalars["String"]>;
    dailyEndTime_ends_with?: Maybe<Scalars["String"]>;
    dailyEndTime_not_ends_with?: Maybe<Scalars["String"]>;
    rulesOfUseHeading?: Maybe<Scalars["String"]>;
    rulesOfUseHeading_not?: Maybe<Scalars["String"]>;
    rulesOfUseHeading_in?: Maybe<Array<Scalars["String"]>>;
    rulesOfUseHeading_not_in?: Maybe<Array<Scalars["String"]>>;
    rulesOfUseHeading_lt?: Maybe<Scalars["String"]>;
    rulesOfUseHeading_lte?: Maybe<Scalars["String"]>;
    rulesOfUseHeading_gt?: Maybe<Scalars["String"]>;
    rulesOfUseHeading_gte?: Maybe<Scalars["String"]>;
    rulesOfUseHeading_contains?: Maybe<Scalars["String"]>;
    rulesOfUseHeading_not_contains?: Maybe<Scalars["String"]>;
    rulesOfUseHeading_starts_with?: Maybe<Scalars["String"]>;
    rulesOfUseHeading_not_starts_with?: Maybe<Scalars["String"]>;
    rulesOfUseHeading_ends_with?: Maybe<Scalars["String"]>;
    rulesOfUseHeading_not_ends_with?: Maybe<Scalars["String"]>;
    rulesOfUseUpdatedAt?: Maybe<Scalars["DateTime"]>;
    rulesOfUseUpdatedAt_not?: Maybe<Scalars["DateTime"]>;
    rulesOfUseUpdatedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    rulesOfUseUpdatedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    rulesOfUseUpdatedAt_lt?: Maybe<Scalars["DateTime"]>;
    rulesOfUseUpdatedAt_lte?: Maybe<Scalars["DateTime"]>;
    rulesOfUseUpdatedAt_gt?: Maybe<Scalars["DateTime"]>;
    rulesOfUseUpdatedAt_gte?: Maybe<Scalars["DateTime"]>;
    rulesOfUse?: Maybe<Scalars["String"]>;
    rulesOfUse_not?: Maybe<Scalars["String"]>;
    rulesOfUse_in?: Maybe<Array<Scalars["String"]>>;
    rulesOfUse_not_in?: Maybe<Array<Scalars["String"]>>;
    rulesOfUse_lt?: Maybe<Scalars["String"]>;
    rulesOfUse_lte?: Maybe<Scalars["String"]>;
    rulesOfUse_gt?: Maybe<Scalars["String"]>;
    rulesOfUse_gte?: Maybe<Scalars["String"]>;
    rulesOfUse_contains?: Maybe<Scalars["String"]>;
    rulesOfUse_not_contains?: Maybe<Scalars["String"]>;
    rulesOfUse_starts_with?: Maybe<Scalars["String"]>;
    rulesOfUse_not_starts_with?: Maybe<Scalars["String"]>;
    rulesOfUse_ends_with?: Maybe<Scalars["String"]>;
    rulesOfUse_not_ends_with?: Maybe<Scalars["String"]>;
    extras_some?: Maybe<IExtraWhereInput>;
    preCheckInInstruction?: Maybe<Scalars["String"]>;
    preCheckInInstruction_not?: Maybe<Scalars["String"]>;
    preCheckInInstruction_in?: Maybe<Array<Scalars["String"]>>;
    preCheckInInstruction_not_in?: Maybe<Array<Scalars["String"]>>;
    preCheckInInstruction_lt?: Maybe<Scalars["String"]>;
    preCheckInInstruction_lte?: Maybe<Scalars["String"]>;
    preCheckInInstruction_gt?: Maybe<Scalars["String"]>;
    preCheckInInstruction_gte?: Maybe<Scalars["String"]>;
    preCheckInInstruction_contains?: Maybe<Scalars["String"]>;
    preCheckInInstruction_not_contains?: Maybe<Scalars["String"]>;
    preCheckInInstruction_starts_with?: Maybe<Scalars["String"]>;
    preCheckInInstruction_not_starts_with?: Maybe<Scalars["String"]>;
    preCheckInInstruction_ends_with?: Maybe<Scalars["String"]>;
    preCheckInInstruction_not_ends_with?: Maybe<Scalars["String"]>;
    postCheckInInstruction?: Maybe<Scalars["String"]>;
    postCheckInInstruction_not?: Maybe<Scalars["String"]>;
    postCheckInInstruction_in?: Maybe<Array<Scalars["String"]>>;
    postCheckInInstruction_not_in?: Maybe<Array<Scalars["String"]>>;
    postCheckInInstruction_lt?: Maybe<Scalars["String"]>;
    postCheckInInstruction_lte?: Maybe<Scalars["String"]>;
    postCheckInInstruction_gt?: Maybe<Scalars["String"]>;
    postCheckInInstruction_gte?: Maybe<Scalars["String"]>;
    postCheckInInstruction_contains?: Maybe<Scalars["String"]>;
    postCheckInInstruction_not_contains?: Maybe<Scalars["String"]>;
    postCheckInInstruction_starts_with?: Maybe<Scalars["String"]>;
    postCheckInInstruction_not_starts_with?: Maybe<Scalars["String"]>;
    postCheckInInstruction_ends_with?: Maybe<Scalars["String"]>;
    postCheckInInstruction_not_ends_with?: Maybe<Scalars["String"]>;
    isDeleted?: Maybe<Scalars["Boolean"]>;
    isDeleted_not?: Maybe<Scalars["Boolean"]>;
    deletedAt?: Maybe<Scalars["DateTime"]>;
    deletedAt_not?: Maybe<Scalars["DateTime"]>;
    deletedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    deletedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    deletedAt_lt?: Maybe<Scalars["DateTime"]>;
    deletedAt_lte?: Maybe<Scalars["DateTime"]>;
    deletedAt_gt?: Maybe<Scalars["DateTime"]>;
    deletedAt_gte?: Maybe<Scalars["DateTime"]>;
    deletedByUser?: Maybe<IUserWhereInput>;
    AND?: Maybe<Array<IResourceWhereInput>>;
};

export type IRole = {
    __typename?: "Role";
    id: Scalars["ID"];
    organisation: IOrganisation;
    users?: Maybe<Array<IUser>>;
    availableTasks?: Maybe<Array<ITask>>;
    name: Scalars["String"];
    description: Scalars["String"];
};

export type IRoleUsersArgs = {
    where?: Maybe<IUserWhereInput>;
    orderBy?: Maybe<IUserOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type IRoleAvailableTasksArgs = {
    where?: Maybe<ITaskWhereInput>;
    orderBy?: Maybe<ITaskOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export enum IRoleOrderByInput {
    IdAsc = "id_ASC",
    IdDesc = "id_DESC",
    NameAsc = "name_ASC",
    NameDesc = "name_DESC",
    DescriptionAsc = "description_ASC",
    DescriptionDesc = "description_DESC"
}

export type IRoleWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    organisation?: Maybe<IOrganisationWhereInput>;
    users_some?: Maybe<IUserWhereInput>;
    availableTasks_some?: Maybe<ITaskWhereInput>;
    name?: Maybe<Scalars["String"]>;
    name_not?: Maybe<Scalars["String"]>;
    name_in?: Maybe<Array<Scalars["String"]>>;
    name_not_in?: Maybe<Array<Scalars["String"]>>;
    name_lt?: Maybe<Scalars["String"]>;
    name_lte?: Maybe<Scalars["String"]>;
    name_gt?: Maybe<Scalars["String"]>;
    name_gte?: Maybe<Scalars["String"]>;
    name_contains?: Maybe<Scalars["String"]>;
    name_not_contains?: Maybe<Scalars["String"]>;
    name_starts_with?: Maybe<Scalars["String"]>;
    name_not_starts_with?: Maybe<Scalars["String"]>;
    name_ends_with?: Maybe<Scalars["String"]>;
    name_not_ends_with?: Maybe<Scalars["String"]>;
    description?: Maybe<Scalars["String"]>;
    description_not?: Maybe<Scalars["String"]>;
    description_in?: Maybe<Array<Scalars["String"]>>;
    description_not_in?: Maybe<Array<Scalars["String"]>>;
    description_lt?: Maybe<Scalars["String"]>;
    description_lte?: Maybe<Scalars["String"]>;
    description_gt?: Maybe<Scalars["String"]>;
    description_gte?: Maybe<Scalars["String"]>;
    description_contains?: Maybe<Scalars["String"]>;
    description_not_contains?: Maybe<Scalars["String"]>;
    description_starts_with?: Maybe<Scalars["String"]>;
    description_not_starts_with?: Maybe<Scalars["String"]>;
    description_ends_with?: Maybe<Scalars["String"]>;
    description_not_ends_with?: Maybe<Scalars["String"]>;
    AND?: Maybe<Array<IRoleWhereInput>>;
};

export type ISelfCheckInput = {
    bookingReference: Scalars["String"];
};

export type ISelfCheckInResponse = {
    __typename?: "SelfCheckInResponse";
    isSuccessful: Scalars["Boolean"];
};

export type ISharedCustomerMembershipInput = {
    customerMembershipId: Scalars["ID"];
};

export type ISharedCustomerMembershipOutput = {
    __typename?: "SharedCustomerMembershipOutput";
    customerMembershipSharings: Array<ICustomerMembershipSharing>;
    customerMembership: ICustomerMembership;
    membership: IMembership;
};

export type ITask = {
    __typename?: "Task";
    id: Scalars["ID"];
    availableForRoles?: Maybe<Array<IRole>>;
    name: Scalars["String"];
    groupName?: Maybe<Scalars["String"]>;
    description: Scalars["String"];
};

export type ITaskAvailableForRolesArgs = {
    where?: Maybe<IRoleWhereInput>;
    orderBy?: Maybe<IRoleOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export enum ITaskOrderByInput {
    IdAsc = "id_ASC",
    IdDesc = "id_DESC",
    NameAsc = "name_ASC",
    NameDesc = "name_DESC",
    GroupNameAsc = "groupName_ASC",
    GroupNameDesc = "groupName_DESC",
    DescriptionAsc = "description_ASC",
    DescriptionDesc = "description_DESC"
}

export type ITaskWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    availableForRoles_some?: Maybe<IRoleWhereInput>;
    name?: Maybe<Scalars["String"]>;
    name_not?: Maybe<Scalars["String"]>;
    name_in?: Maybe<Array<Scalars["String"]>>;
    name_not_in?: Maybe<Array<Scalars["String"]>>;
    name_lt?: Maybe<Scalars["String"]>;
    name_lte?: Maybe<Scalars["String"]>;
    name_gt?: Maybe<Scalars["String"]>;
    name_gte?: Maybe<Scalars["String"]>;
    name_contains?: Maybe<Scalars["String"]>;
    name_not_contains?: Maybe<Scalars["String"]>;
    name_starts_with?: Maybe<Scalars["String"]>;
    name_not_starts_with?: Maybe<Scalars["String"]>;
    name_ends_with?: Maybe<Scalars["String"]>;
    name_not_ends_with?: Maybe<Scalars["String"]>;
    groupName?: Maybe<Scalars["String"]>;
    groupName_not?: Maybe<Scalars["String"]>;
    groupName_in?: Maybe<Array<Scalars["String"]>>;
    groupName_not_in?: Maybe<Array<Scalars["String"]>>;
    groupName_lt?: Maybe<Scalars["String"]>;
    groupName_lte?: Maybe<Scalars["String"]>;
    groupName_gt?: Maybe<Scalars["String"]>;
    groupName_gte?: Maybe<Scalars["String"]>;
    groupName_contains?: Maybe<Scalars["String"]>;
    groupName_not_contains?: Maybe<Scalars["String"]>;
    groupName_starts_with?: Maybe<Scalars["String"]>;
    groupName_not_starts_with?: Maybe<Scalars["String"]>;
    groupName_ends_with?: Maybe<Scalars["String"]>;
    groupName_not_ends_with?: Maybe<Scalars["String"]>;
    description?: Maybe<Scalars["String"]>;
    description_not?: Maybe<Scalars["String"]>;
    description_in?: Maybe<Array<Scalars["String"]>>;
    description_not_in?: Maybe<Array<Scalars["String"]>>;
    description_lt?: Maybe<Scalars["String"]>;
    description_lte?: Maybe<Scalars["String"]>;
    description_gt?: Maybe<Scalars["String"]>;
    description_gte?: Maybe<Scalars["String"]>;
    description_contains?: Maybe<Scalars["String"]>;
    description_not_contains?: Maybe<Scalars["String"]>;
    description_starts_with?: Maybe<Scalars["String"]>;
    description_not_starts_with?: Maybe<Scalars["String"]>;
    description_ends_with?: Maybe<Scalars["String"]>;
    description_not_ends_with?: Maybe<Scalars["String"]>;
    AND?: Maybe<Array<ITaskWhereInput>>;
};

export type ITimeInterval = {
    __typename?: "TimeInterval";
    startTime?: Maybe<Scalars["String"]>;
    endTime?: Maybe<Scalars["String"]>;
};

export type ITimeIntervalRestrictedWhereInput = {
    startTime?: Maybe<Scalars["String"]>;
    startTime_not?: Maybe<Scalars["String"]>;
    startTime_in?: Maybe<Array<Scalars["String"]>>;
    startTime_not_in?: Maybe<Array<Scalars["String"]>>;
    startTime_lt?: Maybe<Scalars["String"]>;
    startTime_lte?: Maybe<Scalars["String"]>;
    startTime_gt?: Maybe<Scalars["String"]>;
    startTime_gte?: Maybe<Scalars["String"]>;
    startTime_contains?: Maybe<Scalars["String"]>;
    startTime_not_contains?: Maybe<Scalars["String"]>;
    startTime_starts_with?: Maybe<Scalars["String"]>;
    startTime_not_starts_with?: Maybe<Scalars["String"]>;
    startTime_ends_with?: Maybe<Scalars["String"]>;
    startTime_not_ends_with?: Maybe<Scalars["String"]>;
    endTime?: Maybe<Scalars["String"]>;
    endTime_not?: Maybe<Scalars["String"]>;
    endTime_in?: Maybe<Array<Scalars["String"]>>;
    endTime_not_in?: Maybe<Array<Scalars["String"]>>;
    endTime_lt?: Maybe<Scalars["String"]>;
    endTime_lte?: Maybe<Scalars["String"]>;
    endTime_gt?: Maybe<Scalars["String"]>;
    endTime_gte?: Maybe<Scalars["String"]>;
    endTime_contains?: Maybe<Scalars["String"]>;
    endTime_not_contains?: Maybe<Scalars["String"]>;
    endTime_starts_with?: Maybe<Scalars["String"]>;
    endTime_not_starts_with?: Maybe<Scalars["String"]>;
    endTime_ends_with?: Maybe<Scalars["String"]>;
    endTime_not_ends_with?: Maybe<Scalars["String"]>;
    AND?: Maybe<Array<ITimeIntervalRestrictedWhereInput>>;
};

export type ITimeIntervalWhereInput = {
    startTime?: Maybe<Scalars["String"]>;
    startTime_not?: Maybe<Scalars["String"]>;
    startTime_in?: Maybe<Array<Scalars["String"]>>;
    startTime_not_in?: Maybe<Array<Scalars["String"]>>;
    startTime_lt?: Maybe<Scalars["String"]>;
    startTime_lte?: Maybe<Scalars["String"]>;
    startTime_gt?: Maybe<Scalars["String"]>;
    startTime_gte?: Maybe<Scalars["String"]>;
    startTime_contains?: Maybe<Scalars["String"]>;
    startTime_not_contains?: Maybe<Scalars["String"]>;
    startTime_starts_with?: Maybe<Scalars["String"]>;
    startTime_not_starts_with?: Maybe<Scalars["String"]>;
    startTime_ends_with?: Maybe<Scalars["String"]>;
    startTime_not_ends_with?: Maybe<Scalars["String"]>;
    endTime?: Maybe<Scalars["String"]>;
    endTime_not?: Maybe<Scalars["String"]>;
    endTime_in?: Maybe<Array<Scalars["String"]>>;
    endTime_not_in?: Maybe<Array<Scalars["String"]>>;
    endTime_lt?: Maybe<Scalars["String"]>;
    endTime_lte?: Maybe<Scalars["String"]>;
    endTime_gt?: Maybe<Scalars["String"]>;
    endTime_gte?: Maybe<Scalars["String"]>;
    endTime_contains?: Maybe<Scalars["String"]>;
    endTime_not_contains?: Maybe<Scalars["String"]>;
    endTime_starts_with?: Maybe<Scalars["String"]>;
    endTime_not_starts_with?: Maybe<Scalars["String"]>;
    endTime_ends_with?: Maybe<Scalars["String"]>;
    endTime_not_ends_with?: Maybe<Scalars["String"]>;
    AND?: Maybe<Array<ITimeIntervalWhereInput>>;
};

export type IToggleCredentialInput = {
    targetCredentialId: Scalars["ID"];
};

export type ITransaction = {
    __typename?: "Transaction";
    id: Scalars["ID"];
    bookings?: Maybe<Array<IBooking>>;
    organisation: IOrganisation;
    customer?: Maybe<ICustomer>;
    madeByCredentialId: Scalars["ID"];
    isComplete: Scalars["Boolean"];
    description: Scalars["String"];
    completedAt?: Maybe<Scalars["DateTime"]>;
    amount: Scalars["Float"];
    providerIdentifier?: Maybe<Scalars["String"]>;
    cardReference?: Maybe<Scalars["String"]>;
    authCode?: Maybe<Scalars["String"]>;
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
    otherMetadata?: Maybe<Scalars["Json"]>;
    memberships?: Maybe<Array<ICustomerMembership>>;
    membershipRenewals?: Maybe<Array<ICustomerMembershipRenewal>>;
};

export type ITransactionBookingsArgs = {
    where?: Maybe<IBookingWhereInput>;
    orderBy?: Maybe<IBookingOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type ITransactionMembershipsArgs = {
    where?: Maybe<ICustomerMembershipWhereInput>;
    orderBy?: Maybe<ICustomerMembershipOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type ITransactionMembershipRenewalsArgs = {
    where?: Maybe<ICustomerMembershipRenewalWhereInput>;
    orderBy?: Maybe<ICustomerMembershipRenewalOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export enum ITransactionOrderByInput {
    IdAsc = "id_ASC",
    IdDesc = "id_DESC",
    MadeByCredentialIdAsc = "madeByCredentialId_ASC",
    MadeByCredentialIdDesc = "madeByCredentialId_DESC",
    IsCompleteAsc = "isComplete_ASC",
    IsCompleteDesc = "isComplete_DESC",
    DescriptionAsc = "description_ASC",
    DescriptionDesc = "description_DESC",
    CompletedAtAsc = "completedAt_ASC",
    CompletedAtDesc = "completedAt_DESC",
    AmountAsc = "amount_ASC",
    AmountDesc = "amount_DESC",
    ProviderIdentifierAsc = "providerIdentifier_ASC",
    ProviderIdentifierDesc = "providerIdentifier_DESC",
    CardReferenceAsc = "cardReference_ASC",
    CardReferenceDesc = "cardReference_DESC",
    AuthCodeAsc = "authCode_ASC",
    AuthCodeDesc = "authCode_DESC",
    CreatedAtAsc = "createdAt_ASC",
    CreatedAtDesc = "createdAt_DESC",
    UpdatedAtAsc = "updatedAt_ASC",
    UpdatedAtDesc = "updatedAt_DESC",
    OtherMetadataAsc = "otherMetadata_ASC",
    OtherMetadataDesc = "otherMetadata_DESC"
}

export type ITransactionWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    bookings_some?: Maybe<IBookingWhereInput>;
    organisation?: Maybe<IOrganisationWhereInput>;
    customer?: Maybe<ICustomerWhereInput>;
    madeByCredentialId?: Maybe<Scalars["ID"]>;
    madeByCredentialId_not?: Maybe<Scalars["ID"]>;
    madeByCredentialId_in?: Maybe<Array<Scalars["ID"]>>;
    madeByCredentialId_not_in?: Maybe<Array<Scalars["ID"]>>;
    madeByCredentialId_lt?: Maybe<Scalars["ID"]>;
    madeByCredentialId_lte?: Maybe<Scalars["ID"]>;
    madeByCredentialId_gt?: Maybe<Scalars["ID"]>;
    madeByCredentialId_gte?: Maybe<Scalars["ID"]>;
    madeByCredentialId_contains?: Maybe<Scalars["ID"]>;
    madeByCredentialId_not_contains?: Maybe<Scalars["ID"]>;
    madeByCredentialId_starts_with?: Maybe<Scalars["ID"]>;
    madeByCredentialId_not_starts_with?: Maybe<Scalars["ID"]>;
    madeByCredentialId_ends_with?: Maybe<Scalars["ID"]>;
    madeByCredentialId_not_ends_with?: Maybe<Scalars["ID"]>;
    isComplete?: Maybe<Scalars["Boolean"]>;
    isComplete_not?: Maybe<Scalars["Boolean"]>;
    description?: Maybe<Scalars["String"]>;
    description_not?: Maybe<Scalars["String"]>;
    description_in?: Maybe<Array<Scalars["String"]>>;
    description_not_in?: Maybe<Array<Scalars["String"]>>;
    description_lt?: Maybe<Scalars["String"]>;
    description_lte?: Maybe<Scalars["String"]>;
    description_gt?: Maybe<Scalars["String"]>;
    description_gte?: Maybe<Scalars["String"]>;
    description_contains?: Maybe<Scalars["String"]>;
    description_not_contains?: Maybe<Scalars["String"]>;
    description_starts_with?: Maybe<Scalars["String"]>;
    description_not_starts_with?: Maybe<Scalars["String"]>;
    description_ends_with?: Maybe<Scalars["String"]>;
    description_not_ends_with?: Maybe<Scalars["String"]>;
    completedAt?: Maybe<Scalars["DateTime"]>;
    completedAt_not?: Maybe<Scalars["DateTime"]>;
    completedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    completedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    completedAt_lt?: Maybe<Scalars["DateTime"]>;
    completedAt_lte?: Maybe<Scalars["DateTime"]>;
    completedAt_gt?: Maybe<Scalars["DateTime"]>;
    completedAt_gte?: Maybe<Scalars["DateTime"]>;
    amount?: Maybe<Scalars["Float"]>;
    amount_not?: Maybe<Scalars["Float"]>;
    amount_in?: Maybe<Array<Scalars["Float"]>>;
    amount_not_in?: Maybe<Array<Scalars["Float"]>>;
    amount_lt?: Maybe<Scalars["Float"]>;
    amount_lte?: Maybe<Scalars["Float"]>;
    amount_gt?: Maybe<Scalars["Float"]>;
    amount_gte?: Maybe<Scalars["Float"]>;
    providerIdentifier?: Maybe<Scalars["String"]>;
    providerIdentifier_not?: Maybe<Scalars["String"]>;
    providerIdentifier_in?: Maybe<Array<Scalars["String"]>>;
    providerIdentifier_not_in?: Maybe<Array<Scalars["String"]>>;
    providerIdentifier_lt?: Maybe<Scalars["String"]>;
    providerIdentifier_lte?: Maybe<Scalars["String"]>;
    providerIdentifier_gt?: Maybe<Scalars["String"]>;
    providerIdentifier_gte?: Maybe<Scalars["String"]>;
    providerIdentifier_contains?: Maybe<Scalars["String"]>;
    providerIdentifier_not_contains?: Maybe<Scalars["String"]>;
    providerIdentifier_starts_with?: Maybe<Scalars["String"]>;
    providerIdentifier_not_starts_with?: Maybe<Scalars["String"]>;
    providerIdentifier_ends_with?: Maybe<Scalars["String"]>;
    providerIdentifier_not_ends_with?: Maybe<Scalars["String"]>;
    cardReference?: Maybe<Scalars["String"]>;
    cardReference_not?: Maybe<Scalars["String"]>;
    cardReference_in?: Maybe<Array<Scalars["String"]>>;
    cardReference_not_in?: Maybe<Array<Scalars["String"]>>;
    cardReference_lt?: Maybe<Scalars["String"]>;
    cardReference_lte?: Maybe<Scalars["String"]>;
    cardReference_gt?: Maybe<Scalars["String"]>;
    cardReference_gte?: Maybe<Scalars["String"]>;
    cardReference_contains?: Maybe<Scalars["String"]>;
    cardReference_not_contains?: Maybe<Scalars["String"]>;
    cardReference_starts_with?: Maybe<Scalars["String"]>;
    cardReference_not_starts_with?: Maybe<Scalars["String"]>;
    cardReference_ends_with?: Maybe<Scalars["String"]>;
    cardReference_not_ends_with?: Maybe<Scalars["String"]>;
    authCode?: Maybe<Scalars["String"]>;
    authCode_not?: Maybe<Scalars["String"]>;
    authCode_in?: Maybe<Array<Scalars["String"]>>;
    authCode_not_in?: Maybe<Array<Scalars["String"]>>;
    authCode_lt?: Maybe<Scalars["String"]>;
    authCode_lte?: Maybe<Scalars["String"]>;
    authCode_gt?: Maybe<Scalars["String"]>;
    authCode_gte?: Maybe<Scalars["String"]>;
    authCode_contains?: Maybe<Scalars["String"]>;
    authCode_not_contains?: Maybe<Scalars["String"]>;
    authCode_starts_with?: Maybe<Scalars["String"]>;
    authCode_not_starts_with?: Maybe<Scalars["String"]>;
    authCode_ends_with?: Maybe<Scalars["String"]>;
    authCode_not_ends_with?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    createdAt_not?: Maybe<Scalars["DateTime"]>;
    createdAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_lt?: Maybe<Scalars["DateTime"]>;
    createdAt_lte?: Maybe<Scalars["DateTime"]>;
    createdAt_gt?: Maybe<Scalars["DateTime"]>;
    createdAt_gte?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    updatedAt_not?: Maybe<Scalars["DateTime"]>;
    updatedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_lt?: Maybe<Scalars["DateTime"]>;
    updatedAt_lte?: Maybe<Scalars["DateTime"]>;
    updatedAt_gt?: Maybe<Scalars["DateTime"]>;
    updatedAt_gte?: Maybe<Scalars["DateTime"]>;
    memberships_some?: Maybe<ICustomerMembershipWhereInput>;
    membershipRenewals_some?: Maybe<ICustomerMembershipRenewalWhereInput>;
    AND?: Maybe<Array<ITransactionWhereInput>>;
};

export type IUpdateMembershipInput = {
    customerId: Scalars["ID"];
    customerMembershipId: Scalars["ID"];
    customerMembershipName?: Maybe<Scalars["String"]>;
    photoInput?: Maybe<IUploadPhotoInput>;
};

export type IUpdateMyCredentialInput = {
    firstName: Scalars["String"];
    lastName: Scalars["String"];
    mobileNumber: Scalars["String"];
    password?: Maybe<Scalars["String"]>;
    doesReceiveEmails?: Maybe<Scalars["Boolean"]>;
    doesReceiveSms?: Maybe<Scalars["Boolean"]>;
};

export type IUpdateMyCustomerInput = {
    name: Scalars["String"];
    postcode: Scalars["String"];
};

export type IUpdateMyPasswordInput = {
    password: Scalars["String"];
};

export type IUploadPhotoInput = {
    base64DataImage: Scalars["String"];
};

export type IUser = {
    __typename?: "User";
    id: Scalars["ID"];
    organisation?: Maybe<IOrganisation>;
    role: IRole;
    createdCustomerNotes?: Maybe<Array<INote>>;
    name: Scalars["String"];
    emailAddress: Scalars["String"];
    password: IPassword;
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
    isDisabled: Scalars["Boolean"];
    isLockedOut: Scalars["Boolean"];
    lastLoginAt?: Maybe<Scalars["DateTime"]>;
    securityStamp: Scalars["String"];
    passwordResetRequestedAt?: Maybe<Scalars["DateTime"]>;
    otherMetadata?: Maybe<Scalars["Json"]>;
    searchableUpper?: Maybe<Scalars["String"]>;
};

export type IUserCreatedCustomerNotesArgs = {
    where?: Maybe<INoteWhereInput>;
    orderBy?: Maybe<INoteOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export enum IUserOrderByInput {
    IdAsc = "id_ASC",
    IdDesc = "id_DESC",
    NameAsc = "name_ASC",
    NameDesc = "name_DESC",
    EmailAddressAsc = "emailAddress_ASC",
    EmailAddressDesc = "emailAddress_DESC",
    CreatedAtAsc = "createdAt_ASC",
    CreatedAtDesc = "createdAt_DESC",
    UpdatedAtAsc = "updatedAt_ASC",
    UpdatedAtDesc = "updatedAt_DESC",
    IsDisabledAsc = "isDisabled_ASC",
    IsDisabledDesc = "isDisabled_DESC",
    IsLockedOutAsc = "isLockedOut_ASC",
    IsLockedOutDesc = "isLockedOut_DESC",
    LastLoginAtAsc = "lastLoginAt_ASC",
    LastLoginAtDesc = "lastLoginAt_DESC",
    SecurityStampAsc = "securityStamp_ASC",
    SecurityStampDesc = "securityStamp_DESC",
    PasswordResetRequestedAtAsc = "passwordResetRequestedAt_ASC",
    PasswordResetRequestedAtDesc = "passwordResetRequestedAt_DESC",
    OtherMetadataAsc = "otherMetadata_ASC",
    OtherMetadataDesc = "otherMetadata_DESC",
    SearchableUpperAsc = "searchableUpper_ASC",
    SearchableUpperDesc = "searchableUpper_DESC"
}

export type IUserWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    organisation?: Maybe<IOrganisationWhereInput>;
    role?: Maybe<IRoleWhereInput>;
    createdCustomerNotes_some?: Maybe<INoteWhereInput>;
    name?: Maybe<Scalars["String"]>;
    name_not?: Maybe<Scalars["String"]>;
    name_in?: Maybe<Array<Scalars["String"]>>;
    name_not_in?: Maybe<Array<Scalars["String"]>>;
    name_lt?: Maybe<Scalars["String"]>;
    name_lte?: Maybe<Scalars["String"]>;
    name_gt?: Maybe<Scalars["String"]>;
    name_gte?: Maybe<Scalars["String"]>;
    name_contains?: Maybe<Scalars["String"]>;
    name_not_contains?: Maybe<Scalars["String"]>;
    name_starts_with?: Maybe<Scalars["String"]>;
    name_not_starts_with?: Maybe<Scalars["String"]>;
    name_ends_with?: Maybe<Scalars["String"]>;
    name_not_ends_with?: Maybe<Scalars["String"]>;
    emailAddress?: Maybe<Scalars["String"]>;
    emailAddress_not?: Maybe<Scalars["String"]>;
    emailAddress_in?: Maybe<Array<Scalars["String"]>>;
    emailAddress_not_in?: Maybe<Array<Scalars["String"]>>;
    emailAddress_lt?: Maybe<Scalars["String"]>;
    emailAddress_lte?: Maybe<Scalars["String"]>;
    emailAddress_gt?: Maybe<Scalars["String"]>;
    emailAddress_gte?: Maybe<Scalars["String"]>;
    emailAddress_contains?: Maybe<Scalars["String"]>;
    emailAddress_not_contains?: Maybe<Scalars["String"]>;
    emailAddress_starts_with?: Maybe<Scalars["String"]>;
    emailAddress_not_starts_with?: Maybe<Scalars["String"]>;
    emailAddress_ends_with?: Maybe<Scalars["String"]>;
    emailAddress_not_ends_with?: Maybe<Scalars["String"]>;
    password?: Maybe<IPasswordWhereInput>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    createdAt_not?: Maybe<Scalars["DateTime"]>;
    createdAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_lt?: Maybe<Scalars["DateTime"]>;
    createdAt_lte?: Maybe<Scalars["DateTime"]>;
    createdAt_gt?: Maybe<Scalars["DateTime"]>;
    createdAt_gte?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    updatedAt_not?: Maybe<Scalars["DateTime"]>;
    updatedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_lt?: Maybe<Scalars["DateTime"]>;
    updatedAt_lte?: Maybe<Scalars["DateTime"]>;
    updatedAt_gt?: Maybe<Scalars["DateTime"]>;
    updatedAt_gte?: Maybe<Scalars["DateTime"]>;
    isDisabled?: Maybe<Scalars["Boolean"]>;
    isDisabled_not?: Maybe<Scalars["Boolean"]>;
    isLockedOut?: Maybe<Scalars["Boolean"]>;
    isLockedOut_not?: Maybe<Scalars["Boolean"]>;
    lastLoginAt?: Maybe<Scalars["DateTime"]>;
    lastLoginAt_not?: Maybe<Scalars["DateTime"]>;
    lastLoginAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    lastLoginAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    lastLoginAt_lt?: Maybe<Scalars["DateTime"]>;
    lastLoginAt_lte?: Maybe<Scalars["DateTime"]>;
    lastLoginAt_gt?: Maybe<Scalars["DateTime"]>;
    lastLoginAt_gte?: Maybe<Scalars["DateTime"]>;
    securityStamp?: Maybe<Scalars["String"]>;
    securityStamp_not?: Maybe<Scalars["String"]>;
    securityStamp_in?: Maybe<Array<Scalars["String"]>>;
    securityStamp_not_in?: Maybe<Array<Scalars["String"]>>;
    securityStamp_lt?: Maybe<Scalars["String"]>;
    securityStamp_lte?: Maybe<Scalars["String"]>;
    securityStamp_gt?: Maybe<Scalars["String"]>;
    securityStamp_gte?: Maybe<Scalars["String"]>;
    securityStamp_contains?: Maybe<Scalars["String"]>;
    securityStamp_not_contains?: Maybe<Scalars["String"]>;
    securityStamp_starts_with?: Maybe<Scalars["String"]>;
    securityStamp_not_starts_with?: Maybe<Scalars["String"]>;
    securityStamp_ends_with?: Maybe<Scalars["String"]>;
    securityStamp_not_ends_with?: Maybe<Scalars["String"]>;
    passwordResetRequestedAt?: Maybe<Scalars["DateTime"]>;
    passwordResetRequestedAt_not?: Maybe<Scalars["DateTime"]>;
    passwordResetRequestedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    passwordResetRequestedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    passwordResetRequestedAt_lt?: Maybe<Scalars["DateTime"]>;
    passwordResetRequestedAt_lte?: Maybe<Scalars["DateTime"]>;
    passwordResetRequestedAt_gt?: Maybe<Scalars["DateTime"]>;
    passwordResetRequestedAt_gte?: Maybe<Scalars["DateTime"]>;
    searchableUpper?: Maybe<Scalars["String"]>;
    searchableUpper_not?: Maybe<Scalars["String"]>;
    searchableUpper_in?: Maybe<Array<Scalars["String"]>>;
    searchableUpper_not_in?: Maybe<Array<Scalars["String"]>>;
    searchableUpper_lt?: Maybe<Scalars["String"]>;
    searchableUpper_lte?: Maybe<Scalars["String"]>;
    searchableUpper_gt?: Maybe<Scalars["String"]>;
    searchableUpper_gte?: Maybe<Scalars["String"]>;
    searchableUpper_contains?: Maybe<Scalars["String"]>;
    searchableUpper_not_contains?: Maybe<Scalars["String"]>;
    searchableUpper_starts_with?: Maybe<Scalars["String"]>;
    searchableUpper_not_starts_with?: Maybe<Scalars["String"]>;
    searchableUpper_ends_with?: Maybe<Scalars["String"]>;
    searchableUpper_not_ends_with?: Maybe<Scalars["String"]>;
    AND?: Maybe<Array<IUserWhereInput>>;
};

export type IWeekTemplate = {
    __typename?: "WeekTemplate";
    id: Scalars["ID"];
    resource?: Maybe<IResource>;
    dayScheduleTemplates?: Maybe<Array<IDayScheduleTemplate>>;
    name: Scalars["String"];
    description?: Maybe<Scalars["String"]>;
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
};

export type IWeekTemplateDayScheduleTemplatesArgs = {
    where?: Maybe<IDayScheduleTemplateWhereInput>;
    orderBy?: Maybe<IDayScheduleTemplateOrderByInput>;
    skip?: Maybe<Scalars["Int"]>;
    after?: Maybe<Scalars["String"]>;
    before?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    last?: Maybe<Scalars["Int"]>;
};

export type IWeekTemplateWhereInput = {
    id?: Maybe<Scalars["ID"]>;
    id_not?: Maybe<Scalars["ID"]>;
    id_in?: Maybe<Array<Scalars["ID"]>>;
    id_not_in?: Maybe<Array<Scalars["ID"]>>;
    id_lt?: Maybe<Scalars["ID"]>;
    id_lte?: Maybe<Scalars["ID"]>;
    id_gt?: Maybe<Scalars["ID"]>;
    id_gte?: Maybe<Scalars["ID"]>;
    id_contains?: Maybe<Scalars["ID"]>;
    id_not_contains?: Maybe<Scalars["ID"]>;
    id_starts_with?: Maybe<Scalars["ID"]>;
    id_not_starts_with?: Maybe<Scalars["ID"]>;
    id_ends_with?: Maybe<Scalars["ID"]>;
    id_not_ends_with?: Maybe<Scalars["ID"]>;
    resource?: Maybe<IResourceWhereInput>;
    dayScheduleTemplates_some?: Maybe<IDayScheduleTemplateWhereInput>;
    name?: Maybe<Scalars["String"]>;
    name_not?: Maybe<Scalars["String"]>;
    name_in?: Maybe<Array<Scalars["String"]>>;
    name_not_in?: Maybe<Array<Scalars["String"]>>;
    name_lt?: Maybe<Scalars["String"]>;
    name_lte?: Maybe<Scalars["String"]>;
    name_gt?: Maybe<Scalars["String"]>;
    name_gte?: Maybe<Scalars["String"]>;
    name_contains?: Maybe<Scalars["String"]>;
    name_not_contains?: Maybe<Scalars["String"]>;
    name_starts_with?: Maybe<Scalars["String"]>;
    name_not_starts_with?: Maybe<Scalars["String"]>;
    name_ends_with?: Maybe<Scalars["String"]>;
    name_not_ends_with?: Maybe<Scalars["String"]>;
    description?: Maybe<Scalars["String"]>;
    description_not?: Maybe<Scalars["String"]>;
    description_in?: Maybe<Array<Scalars["String"]>>;
    description_not_in?: Maybe<Array<Scalars["String"]>>;
    description_lt?: Maybe<Scalars["String"]>;
    description_lte?: Maybe<Scalars["String"]>;
    description_gt?: Maybe<Scalars["String"]>;
    description_gte?: Maybe<Scalars["String"]>;
    description_contains?: Maybe<Scalars["String"]>;
    description_not_contains?: Maybe<Scalars["String"]>;
    description_starts_with?: Maybe<Scalars["String"]>;
    description_not_starts_with?: Maybe<Scalars["String"]>;
    description_ends_with?: Maybe<Scalars["String"]>;
    description_not_ends_with?: Maybe<Scalars["String"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    createdAt_not?: Maybe<Scalars["DateTime"]>;
    createdAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    createdAt_lt?: Maybe<Scalars["DateTime"]>;
    createdAt_lte?: Maybe<Scalars["DateTime"]>;
    createdAt_gt?: Maybe<Scalars["DateTime"]>;
    createdAt_gte?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    updatedAt_not?: Maybe<Scalars["DateTime"]>;
    updatedAt_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_not_in?: Maybe<Array<Scalars["DateTime"]>>;
    updatedAt_lt?: Maybe<Scalars["DateTime"]>;
    updatedAt_lte?: Maybe<Scalars["DateTime"]>;
    updatedAt_gt?: Maybe<Scalars["DateTime"]>;
    updatedAt_gte?: Maybe<Scalars["DateTime"]>;
    AND?: Maybe<Array<IWeekTemplateWhereInput>>;
};
