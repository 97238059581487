import { gql } from "@apollo/client";

const GET_MEMBERSHIPS = gql`
    query membershipsByOrg($orgId: ID!) {
        membershipsByOrg(input: { orgId: $orgId }) {
            id
            name
            description
            price
            ordering
        }
    }
`;

export default GET_MEMBERSHIPS;
