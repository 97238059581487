import { gql } from "@apollo/client";

const CHECK_IN_MUTATION = gql`
    mutation selfCheckIn($input: SelfCheckInput!) {
        selfCheckIn(input: $input) {
            isSuccessful
        }
    }
`;

export default CHECK_IN_MUTATION;
