import { gql } from "@apollo/client";

const LOGIN_CUSTOMER = gql`
    mutation loginCustomer($value: AuthenticateInput!) {
        loginCustomer(input: $value) {
            token
        }
    }
`;

export default LOGIN_CUSTOMER;
