import { filter, map, sortBy } from "lodash";
import moment from "moment";
import React from "react";
import { IBooking } from "../../../../generated/dataInterfaces";
import ActivityBookingByDay from "../../../Account/ActivityBookingByDay";
import { IBookingWithPrettyDate } from "../../../Account/IBookingWithPrettyDate";
import PackagedBookings from "../../../Account/PackagedBookings";
import { useAccountContext } from "../../../Context/AccountContext";
import { useAppContext } from "../../../Context/AppContext";

const Activities = () => {
    const { setPageTitle } = useAppContext();
    const { credential } = useAccountContext();

    setPageTitle("Activities");

    if (!credential) {
        return false;
    }

    const bookings = credential.customer.bookings ?? [];

    const singleBookings = bookings.filter(
        (booking) => booking.packageReference === null
    );
    const packageBookings = bookings.filter(
        (booking) => booking.packageReference !== null
    );

    // dates uniq not working
    const dates = map(
        sortBy(singleBookings, (booking) =>
            moment(booking.activityStartsAt).startOf("day").toDate()
        ),
        (booking) => moment(booking.activityStartsAt).startOf("day").toDate()
    );

    const isDateInArray = (needle: Date, haystack: Date[]) => {
        for (let i = 0; i < haystack.length; i++) {
            if (needle.getTime() === haystack[i].getTime()) {
                return true;
            }
        }
        return false;
    };

    const days: Date[] = [];
    for (let i = 0; i < dates.length; i++) {
        if (!isDateInArray(dates[i], days)) {
            days.push(dates[i]);
        }
    }

    const futureDays = filter(
        days,
        (day) => moment(day) >= moment().startOf("day")
    );

    const pastDays = filter(
        days,
        (day) => moment(day) < moment().startOf("day")
    );

    // TODO - don't pass all bookings around
    const bookingsWithPrettyDate = map<IBooking, IBookingWithPrettyDate>(
        singleBookings,
        (booking) => {
            return {
                ...booking,
                prettyDate: moment(booking.activityStartsAt)
                    .utc()
                    .format("dddd, MMMM Do YYYY")
            };
        }
    );

    return (
        <>
            <header>
                <h4>Upcoming Bookings</h4>
            </header>
            <div className="o-upcoming-events">
                <div className="content">
                    {futureDays && futureDays.length ? (
                        futureDays.map((day) => (
                            <ActivityBookingByDay
                                key={moment(day).format("dddd, MMMM Do YYYY")}
                                day={moment(day).format("dddd, MMMM Do YYYY")}
                                bookings={bookingsWithPrettyDate}
                            />
                        ))
                    ) : (
                        <p>You have no upcoming bookings</p>
                    )}
                </div>
            </div>
            <header>
                <h4>Package Bookings</h4>
            </header>
            <div className="o-upcoming-events">
                <div className="content">
                    {packageBookings && packageBookings.length ? (
                        <PackagedBookings bookings={packageBookings} />
                    ) : (
                        <p>You have no packaged bookings</p>
                    )}
                </div>
            </div>
            <header>
                <h4>Past Bookings</h4>
            </header>
            <div className="o-upcoming-events">
                <div className="content">
                    {pastDays && pastDays.length ? (
                        pastDays.map((day) => (
                            <ActivityBookingByDay
                                key={moment(day).format("dddd, MMMM Do YYYY")}
                                day={moment(day).format("dddd, MMMM Do YYYY")}
                                bookings={bookingsWithPrettyDate}
                            />
                        ))
                    ) : (
                        <p>You have no past bookings</p>
                    )}
                </div>
            </div>
        </>
    );
};

export default Activities;
