import { concat, flatten } from "lodash";
import { useEffect } from "react";

import { IBooking, ICustomerMembership } from "../../generated/dataInterfaces";
import { useGoogleAnalytics } from "react-ga4-gtag";

const CheckoutCompleteTracking = ({
    transactionId,
    transactionTotal,
    bookings,
    memberships
}: IProps) => {
    const gtag = useGoogleAnalytics();

    useEffect(() => {
        if (gtag !== null) {
            let index = 0;
            const products = bookings.map((b) => {
                index++;
                return {
                    item_category: "Activities", // b.activityInstance.activity.resource.name,
                    item_id: b.activityInstance.activity.id,
                    item_name: b.activityInstance.activity.name,
                    price: b.activityPrice,
                    quantity: b.activityQuantity,
                    discount: b.activityDiscount,
                    coupon: b.couponIds?.join(", "),
                    index
                } as IProduct;
            });

            const extraProducts = flatten(
                bookings.map((b) =>
                    b?.bookingExtras?.map((e) => {
                        index++;
                        return {
                            item_category: "Activities", //  b.activityInstance.activity.resource.name,
                            item_id: e.extraId,
                            item_name: "Extra: " + e.name,
                            price: e.pricePerItem,
                            discount: e.discountPerItem,
                            quantity: e.quantity,
                            index
                        } as IProduct;
                    })
                )
            );

            const membershipProducts = memberships.map((m) => {
                index++;
                return {
                    item_category: "Activities", //"Memberships",
                    item_id: m.id,
                    item_name: "Membership: " + m.membership.name,
                    price: m.amountPaid,
                    quantity: 1,
                    index
                } as IProduct;
            });

            const allProducts = concat(
                products,
                extraProducts,
                membershipProducts
            );

            gtag("event", "purchase", {
                item_category: "Activities",
                transaction_id: transactionId,
                currency: "GBP",
                value: transactionTotal, //total value of items in the cart
                items: allProducts
            });
        }
    }, [gtag]);

    return null;
};
interface IProduct {
    item_category: string;
    item_id: string;
    item_name: string;
    coupon?: string;
    discount?: number;
    index?: number;
    price: number;
    quantity: number;
}

interface IProps {
    transactionId: string;
    transactionTotal: number;
    bookings: IBooking[];
    memberships: ICustomerMembership[];
}

export default CheckoutCompleteTracking;
