import { gql } from "@apollo/client";

const MY_CUSTOMER_MEMBERSHIPS = gql`
    query customerMemberships {
        myCustomerMemberships {
            id
            name
            hasBeenActivated
            canPhotoBeChanged
            expiresAt
            renewalAvailableAt
            membership {
                id
                name
                description
                price
            }
        }
    }
`;

export default MY_CUSTOMER_MEMBERSHIPS;
