import { gql } from "@apollo/client";

const UPDATE_MY_CUSTOMER_MUTATION = gql`
    mutation updateMyCustomer($name: String!, $postcode: String!) {
        updateMyCustomer(input: { name: $name, postcode: $postcode }) {
            id
            name
            postcode
        }
    }
`;

export default UPDATE_MY_CUSTOMER_MUTATION;
