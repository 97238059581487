import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import REQUEST_MEMBERSHIP_SHARE_MUTATION from "../../../../../data/CustomerMembershipSharing/RequestMembershipShare";
import SHARED_CUSTOMER_MEMBERSHIPS from "../../../../../data/CustomerMembershipSharing/SharedCustomerMemberships";
import {
    ICustomerMembership,
    ICustomerMembershipSharing,
    IMembership
} from "../../../../../generated/dataInterfaces";
import { useNotification } from "../../../../Context/NotificationContext";
import SummaryCustomerMembershipCard from "../../../../CustomerMembership/SummaryCustomerMembershipCard";
import { InputFieldType } from "../../../../Util/InputField";
import { NOTIFICATION_BAR_MESSAGE } from "../../../../Util/NotificationBar";
import SharedWith from "./SharedWith";

const ShareMembership = () => {
    const {
        setNotificationColor,
        setNotificationMessage,
        setNotificationSubMessage,
        setShowNotification
    } = useNotification();
    const [shouldLoadNow, setShouldLoadNow] = useState<boolean>();

    const { customerMembershipId }: { customerMembershipId?: string } =
        useParams();
    const [sharedMemberships, setSharedMemberships] = useState<
        ICustomerMembershipSharing[]
    >([]);
    const [currentCustomerMembership, setCustomerMembership] =
        useState<ICustomerMembership>();
    const [, setMembership] = useState<IMembership>();
    const [otherEmail, setOtherEmail] = useState("");

    const [requestMembershipShareMutation] = useMutation<
        { requestMembershipShare: ICustomerMembershipSharing },
        { customerMembershipId: string; emailAddress: string }
    >(REQUEST_MEMBERSHIP_SHARE_MUTATION, {
        onCompleted({ requestMembershipShare }) {
            console.log("requestMembershipShare", requestMembershipShare);
            if (requestMembershipShare) {
                setNotificationColor(NOTIFICATION_BAR_MESSAGE.SUCCESS);
                setNotificationMessage("Membership link request sent.");
                setNotificationSubMessage(
                    `${otherEmail} has been sent and email with a button to click to accept the request`
                );

                setOtherEmail("");
            } else {
                setNotificationColor(NOTIFICATION_BAR_MESSAGE.FAILURE);
                setNotificationMessage("Your membership was not linked.");
                setNotificationSubMessage(
                    "Either the user was not found, or you've already shared with this user."
                );
            }

            setShowNotification(true);
        },
        onError(error) {
            setNotificationColor(NOTIFICATION_BAR_MESSAGE.FAILURE);
            setNotificationMessage("Your membership wasn't shared.");
            setNotificationSubMessage(error.message);
            setShowNotification(true);
            console.log(error);
        }
    });

    const handleShareClick = async () => {
        const { data } = await requestMembershipShareMutation({
            variables: {
                customerMembershipId: customerMembershipId ?? "",
                emailAddress: otherEmail
            }
        });

        if (data) {
            setShouldLoadNow(true);
        }
    };

    useEffect(() => {
        if (customerMembershipId && !shouldLoadNow) {
            setShouldLoadNow(true);
        }
    }, [shouldLoadNow, customerMembershipId]);

    const { data } = useQuery(SHARED_CUSTOMER_MEMBERSHIPS, {
        variables: {
            customerMembershipId
        },
        skip: !shouldLoadNow,
        fetchPolicy: "no-cache"
    });

    const onRemove = () => {
        setShouldLoadNow(true);
    };

    const hasData = data && data.sharedCustomerMemberships;
    if (hasData) {
        console.log("Data", data);
        setSharedMemberships(
            data.sharedCustomerMemberships.customerMembershipSharings
        );
        setCustomerMembership(
            data.sharedCustomerMemberships.customerMembership
        );
        setMembership(data.sharedCustomerMemberships.membership);
        setShouldLoadNow(false);
    }

    return (
        <>
            <div>
                <h3>Link your membership</h3>
                <div className="clearfix"></div>

                <div className="content">
                    {currentCustomerMembership && (
                        <SummaryCustomerMembershipCard
                            useMembershipName={true}
                            customerMembership={currentCustomerMembership}
                        />
                    )}

                    {sharedMemberships && sharedMemberships.length ? (
                        <div>
                            <p>
                                <strong>
                                    You have linked this membership to{" "}
                                    {sharedMemberships.length}{" "}
                                    {sharedMemberships.length > 1
                                        ? "people"
                                        : "person"}
                                </strong>
                            </p>

                            <SharedWith
                                sharings={sharedMemberships}
                                onRemove={onRemove}
                            />
                        </div>
                    ) : (
                        <div>
                            <p>
                                <strong>
                                    You have not yet linked this membership.
                                </strong>
                            </p>
                        </div>
                    )}
                    <div>
                        <p>
                            When you link your membership, another person can
                            use your membership during checkout to book you on a
                            relevant activity.
                        </p>
                        <p>
                            The other person must already be registered as a
                            user of this website.
                        </p>
                        <p>
                            Please be aware that they will see your
                            membership&apos;s name, and your photo. By linking
                            this membership, you{" "}
                            <a
                                href="https://www.lesormesjersey.co.uk/activities/terms/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                accept the terms associated with linking a
                                membership.{" "}
                            </a>
                        </p>
                        <p>
                            They cannot use your membership for anyone other
                            than you. They do not have to have the same
                            membership.
                        </p>
                        <p>
                            To link this membership, please enter the
                            person&apos;s email address and click
                            &quot;Link&quot;.
                        </p>
                        <p>
                            They will receive an email, asking them to accept
                            your link request.
                        </p>

                        <div className="content">
                            <div className="m-form-field">
                                <label>
                                    <strong>Member&aspo;s email address</strong>
                                </label>
                                <input
                                    type={InputFieldType.TEXT}
                                    placeholder="Enter a members email address"
                                    value={otherEmail}
                                    disabled={false}
                                    onChange={(e) => {
                                        setOtherEmail(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <footer className="m-edit-form">
                            <div className="m-save-fields ">
                                <div className="half-container left">
                                    <button
                                        className="m-btn fw no-icon primary two-thirds-container left"
                                        disabled={!otherEmail}
                                        onClick={handleShareClick}
                                    >
                                        Link
                                    </button>
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShareMembership;
