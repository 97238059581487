import { useMutation } from "@apollo/client";
import React, { useCallback, useEffect, useRef, useState } from "react";
//import ReactGA from "react-ga";
import { Link, Redirect } from "react-router-dom";
import REGISTER_CUSTOMER_MUTATION from "../../../data/User/RegisterCustomerMutation";
import { ICreateCustomerWithCredentialInput } from "../../../generated/dataInterfaces";
import Basket from "../../Basket/Basket";
import { useAppContext, useAppRedirect } from "../../Context/AppContext";
import { useAuthenticated, useLogin } from "../../Context/AuthContext";
import { useForm } from "../../Context/FormContext";
import { useNotification } from "../../Context/NotificationContext";
import BreadCrumbs from "../../Util/Breadcrumbs";
import CheckBox from "../../Util/CheckBox";
import InputField, { InputFieldType } from "../../Util/InputField";
import { NOTIFICATION_BAR_MESSAGE } from "../../Util/NotificationBar";
import PageHeader from "../../Util/PageHeader";

const RegisterNewCustomer = () => {
    const firstNameRef = useRef<HTMLInputElement | null>(null);
    const lastNameRef = useRef<HTMLInputElement | null>(null);
    const emailRef = useRef<HTMLInputElement | null>(null);
    const postcodeRef = useRef<HTMLInputElement | null>(null);
    const phoneRef = useRef<HTMLInputElement | null>(null);
    const passwordRef = useRef<HTMLInputElement | null>(null);
    const { setCheck, setReady, ready } = useForm();
    const { loginCustomer } = useLogin();
    const { setIsAuthenticated } = useAuthenticated();
    const { setRedirectTo } = useAppRedirect();

    const [isComplete, setIsComplete] = useState(false);
    const { organisationId, gaTrackerNames } = useAppContext();

    const {
        setNotificationColor,
        setNotificationMessage,
        setNotificationSubMessage,
        setShowNotification
    } = useNotification();

    const [receiveSMS, setReceiveSMS] = useState(false);
    const [receiveEmail, setReceiveEmail] = useState(false);

    const handleRegisterClick = (event: React.MouseEvent) => {
        event.preventDefault();
        setCheck(true);
        setReady(false);
    };

    const [createCustomer] = useMutation(REGISTER_CUSTOMER_MUTATION);

    const createCustomerExtended = useCallback(
        async (mutationVariables: ICreateCustomerWithCredentialInput) => {
            const { data, errors } = await createCustomer({
                variables: {
                    value: mutationVariables
                }
            });

            const password = mutationVariables?.password ?? "";

            if (data && data["createCustomerWithCredential"]) {
                setIsComplete(true);
                const email =
                    data["createCustomerWithCredential"]["credentials"][0]
                        .login;
                const loggedIn = await loginCustomer(email, password);

                setIsAuthenticated(loggedIn);

                if (loggedIn) {
                    setNotificationColor(NOTIFICATION_BAR_MESSAGE.SUCCESS);
                    setNotificationMessage("Account created");
                    setNotificationSubMessage("Logged in");
                    setRedirectTo("/check-out");
                    setShowNotification(true);

                    // ReactGA.event(
                    //     {
                    //         category: "Registration",
                    //         action: "Registered"
                    //     },
                    //     gaTrackerNames
                    // );
                }
            }

            if (errors && errors.length > 0) {
                console.log(errors);

                setNotificationColor(NOTIFICATION_BAR_MESSAGE.WARNING);
                setNotificationMessage(errors[0].message);
                setNotificationSubMessage(
                    " Already have an account? Please log in."
                );

                setShowNotification(true);
            }
        },
        [
            createCustomer,
            loginCustomer,
            setIsAuthenticated,
            setNotificationColor,
            setNotificationMessage,
            setNotificationSubMessage,
            setRedirectTo,
            setShowNotification
        ]
    );

    useEffect(() => {
        void (async () => {
            // check that the fields are all filled in
            if (ready) {
                // create a data set for mutation

                if (
                    emailRef?.current?.validity.valid &&
                    firstNameRef?.current?.validity.valid &&
                    lastNameRef?.current?.validity.valid &&
                    postcodeRef?.current?.validity.valid &&
                    phoneRef?.current?.validity.valid &&
                    passwordRef?.current?.validity.valid
                ) {
                    if (!organisationId) {
                        throw new Error("Organisation id is missing");
                    }
                    const mutationVariables: ICreateCustomerWithCredentialInput =
                        {
                            organisationId,
                            email: emailRef.current.value,
                            firstName: firstNameRef.current.value,
                            lastName: lastNameRef.current.value,
                            postcode: postcodeRef.current.value,
                            phone: phoneRef.current.value,
                            password: passwordRef.current.value,
                            doesReceiveEmail: true,
                            doesReceiveSms: true
                        };

                    await createCustomerExtended(mutationVariables);
                } else {
                    setNotificationColor(NOTIFICATION_BAR_MESSAGE.FAILURE);
                    setNotificationMessage("Please check your details");
                    setNotificationSubMessage("and try again");
                    setShowNotification(true);
                }
            }
        })();
    }, [
        ready,
        createCustomerExtended,
        organisationId,
        setNotificationColor,
        setNotificationMessage,
        setNotificationSubMessage,
        setShowNotification
    ]);

    return (
        <>
            {isComplete ? (
                <Redirect to="/log-in" />
            ) : (
                <div className="fw">
                    <PageHeader title="Register" />
                    <div className="pw">
                        <BreadCrumbs step={1} />
                        <div className="clearfix" />
                        <div className="content fw">
                            <div className="two-thirds-container">
                                <div className="m-login a-box v-box-secondary">
                                    <header>
                                        <h3>Register</h3>
                                    </header>
                                    <div className="clearfix" />
                                    <div className="register-form">
                                        <div className="label">
                                            Already have an account?{"  "}
                                            <Link
                                                to="/log-in"
                                                className="color-text-tertiary"
                                            >
                                                Log in here
                                            </Link>
                                        </div>
                                        <div className="fields">
                                            {/* First Name ref */}
                                            <InputField
                                                type={InputFieldType.TEXT}
                                                label="First Name"
                                                placeholder="Your first name"
                                                reference={firstNameRef}
                                                required={true}
                                            />
                                            {/* Last Name ref */}
                                            <InputField
                                                type={InputFieldType.TEXT}
                                                label="Last Name"
                                                placeholder="Your last name"
                                                reference={lastNameRef}
                                                required={true}
                                            />
                                            {/* Email ref */}
                                            <InputField
                                                type={InputFieldType.EMAIL}
                                                label="Email"
                                                placeholder="A valid email address"
                                                reference={emailRef}
                                                required={true}
                                            />

                                            {/* Postcode ref */}
                                            <InputField
                                                type={InputFieldType.TEXT}
                                                label="Postcode"
                                                placeholder="JE1 1AA"
                                                reference={postcodeRef}
                                                required={true}
                                            />
                                            {/* Phone Number ref */}
                                            <InputField
                                                type={InputFieldType.TEXT}
                                                pattern="[0-9 +]*"
                                                label="Mobile Number"
                                                placeholder="+44 777 888 8888 or 07787787787"
                                                reference={phoneRef}
                                                required={true}
                                            />
                                            {/* Password ref */}
                                            <InputField
                                                type={InputFieldType.PASSWORD}
                                                label="Password"
                                                pattern="^.{6,}$"
                                                placeholder="Minimum 6 characters"
                                                reference={passwordRef}
                                                required={true}
                                            />

                                            <footer>
                                                <CheckBox
                                                    label="Would you like to receive emails from Les Ormes? We will always send you booking confirmations."
                                                    checked={[
                                                        receiveSMS,
                                                        setReceiveSMS
                                                    ]}
                                                />
                                                <CheckBox
                                                    label="Would you like to receive text messages from Les Ormes?"
                                                    checked={[
                                                        receiveEmail,
                                                        setReceiveEmail
                                                    ]}
                                                />

                                                <button
                                                    type="submit"
                                                    className="m-btn v-bg-secondary"
                                                    onClick={
                                                        handleRegisterClick
                                                    }
                                                >
                                                    Continue
                                                </button>
                                            </footer>
                                            <div className="clearfix" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <aside
                                id="basket-scroll"
                                className="sidebar third-container right"
                            >
                                <Basket
                                    isCheckout={true}
                                    shouldShowMembershipWarning={false}
                                />
                            </aside>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default RegisterNewCustomer;
