import React from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../Context/AppContext";

interface IPageBreadcrumbs {
    step?: string;
}

const PageBreadcrumbs = ({ step }: IPageBreadcrumbs) => {
    const { entryRoute, entryLabel } = useAppContext();
    return (
        <div className="m-page-breadcrumbs">
            <label>
                <Link to="/" className="v-secondary-color">
                    List all
                </Link>
                {" / "}
                <Link to={entryRoute} className="v-secondary-color">
                    {entryLabel}
                </Link>{" "}
                {step ? "/" : ""} {step}
            </label>
        </div>
    );
};

export default PageBreadcrumbs;
