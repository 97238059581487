import React from "react";
// import StepTracking from "../Analytics/StepTracking";
import Basket from "../Basket/Basket";
import useCustomerMemberships from "../Context/CustomerMembershipsContext";
import Membership from "../MembershipRenewal/Membership";
import BreadCrumbs from "../Util/Breadcrumbs";
import NotificationBar from "../Util/NotificationBar";
import PageHeader from "../Util/PageHeader";

const MembershipsRenewal = () => {
    const { renewableCustomerMemberships, hasRenewableCustomerMemberships } =
        useCustomerMemberships();

    return (
        <>
            <PageHeader title="Memberships Renewal" />
            <div className="fw">
                <div className="pw">
                    <BreadCrumbs step={0} />
                    <div className="clearfix" />
                    <div className="content fw">
                        <div className="two-thirds-container">
                            <div>
                                <h3>Memberships to renew</h3>
                                {hasRenewableCustomerMemberships ? (
                                    renewableCustomerMemberships?.map(
                                        (customerMembership) => (
                                            <Membership
                                                key={
                                                    customerMembership
                                                        .membership.id
                                                }
                                                membership={
                                                    customerMembership.membership
                                                }
                                                customerMembership={
                                                    customerMembership
                                                }
                                            />
                                        )
                                    )
                                ) : (
                                    <p>No memberships to renew</p>
                                )}
                                <div className="clearfix" />
                            </div>
                        </div>
                        <aside
                            id="basket-scroll"
                            className="sidebar third-container right"
                        >
                            <Basket
                                isCheckout={false}
                                shouldShowMembershipWarning={false}
                            />
                        </aside>
                    </div>
                    <footer />
                </div>
            </div>
            <NotificationBar duration={7500} />
            {/* <StepTracking stepNumber="1" stepName="Choose your Membership" /> */}
        </>
    );
};
export default MembershipsRenewal;
