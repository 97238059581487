import React from "react";
import { withRouter } from "react-router-dom";
// import StepTracking from "../Analytics/StepTracking";
import Basket from "../Basket/Basket";
import { useAppContext } from "../Context/AppContext";
import MembershipList from "../MembershipPurchase/MembershipList";
import BreadCrumbs from "../Util/Breadcrumbs";
import NotificationBar from "../Util/NotificationBar";
import PageHeader from "../Util/PageHeader";

const MembershipsSelection = () => {
    const { setPageTitle, setEntryLabel, setEntryRoute } = useAppContext();

    setEntryLabel("Select your memberships");
    setEntryRoute(`/memberships`);
    setPageTitle("Buy memberships");

    return (
        <>
            <PageHeader />
            <div className="fw">
                <div className="pw">
                    <BreadCrumbs step={0} />
                    <div className="clearfix" />
                    <div className="content fw">
                        <div className="two-thirds-container">
                            <MembershipList />
                        </div>
                        <aside
                            id="basket-scroll"
                            className="sidebar third-container right"
                        >
                            <Basket
                                isCheckout={false}
                                shouldShowMembershipWarning={false}
                            />
                        </aside>
                    </div>
                    <footer />
                </div>
            </div>
            <NotificationBar duration={7500} />
            {/* <StepTracking stepNumber="1" stepName="Choose your Membership" /> */}
        </>
    );
};

export default withRouter(MembershipsSelection);
