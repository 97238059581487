import { groupBy, orderBy } from "lodash";
import React, { useEffect } from "react";
import EqualHeight from "storm-equal-height";
import { IBooking } from "../../generated/dataInterfaces";
import PackageBookingCard from "./PackageBookingCard";

const PackagedBookings = ({ bookings }: IPackagedBookingsProps) => {
    useEffect(() => {
        EqualHeight.init(".m-booking-list");
    });

    const groups = groupBy(
        orderBy(bookings, ["activityStartsAt"], ["asc"]),
        "packageReference"
    );

    return (
        <div className="m-activity-date">
            <div className="m-booking-list">
                {Object.keys(groups).map((key) => (
                    <PackageBookingCard key={key} bookings={groups[key]} />
                ))}
            </div>
            <div className="clearfix" />
        </div>
    );
};

export default PackagedBookings;

interface IPackagedBookingsProps {
    bookings: IBooking[];
}
