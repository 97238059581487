import { useMutation } from "@apollo/client";
import React, { useCallback, useEffect, useRef } from "react";
import { Redirect, useParams } from "react-router-dom";
import RESET_PASSWORD_MUTATION from "../../../../data/User/ResetPasswordMutation";
import { useAppContext } from "../../../Context/AppContext";
import { useAuthenticated, useLogin } from "../../../Context/AuthContext";
import { useForm } from "../../../Context/FormContext";
import Button, { BUTTON_COLOR } from "../../../Util/Button";
import InputField, { InputFieldType } from "../../../Util/InputField";

const ResetPasswordForm = () => {
    const { securityToken }: { securityToken?: string } = useParams();
    const passwordInputRef = useRef<HTMLInputElement | null>(null);
    const passwordConfirmInputRef = useRef<HTMLInputElement | null>(null);
    const { organisationId, entryRoute } = useAppContext();
    const { setIsAuthenticated, isAuthenticated } = useAuthenticated();
    const { loginCustomer } = useLogin();
    const { setCheck, setReady, ready } = useForm();
    const [resetPasswordMutation] = useMutation(RESET_PASSWORD_MUTATION);

    const resetPasswordMutationExtended = useCallback(async () => {
        try {
            const { data, errors } = await resetPasswordMutation({
                variables: {
                    securityStamp: securityToken,
                    newPassword: passwordInputRef.current
                        ? passwordInputRef?.current?.value
                        : null,
                    organisationId
                }
            });

            if (data && data["resetPassword"]) {
                // success
                // gather email and password
                const email = data["resetPassword"].email;
                // create a token by logging in again
                // TODO: possibly streamline this via the server side
                const loggedIn = await loginCustomer(
                    email,
                    passwordInputRef?.current?.value ?? ""
                );
                setIsAuthenticated(loggedIn);
            }

            if (errors && errors.length > 0) {
                console.error(errors.map((error) => error.message).join(" "));
            }
        } catch (e) {
            throw new Error(e);
        }
    }, [
        loginCustomer,
        resetPasswordMutation,
        organisationId,
        securityToken,
        setIsAuthenticated
    ]);

    function handleResetClick() {
        // check all fields are valid
        setCheck(true);
        setReady(false);
    }

    useEffect(() => {
        void (async () => {
            // check that the fields are all filled in
            if (ready) {
                await resetPasswordMutationExtended();
            }
        })();
    }, [ready, resetPasswordMutationExtended]);

    if (isAuthenticated) {
        return <Redirect to={entryRoute} />;
    }

    return (
        <>
            <InputField
                label="Password"
                type={InputFieldType.PASSWORD}
                placeholder="Please enter your new password"
                reference={passwordInputRef}
                required={true}
            />
            <InputField
                label="Password Confirmation"
                type={InputFieldType.PASSWORD}
                placeholder="Please reenter your password"
                reference={passwordConfirmInputRef}
                required={true}
                partnered={passwordInputRef}
            />
            <Button
                title="Submit"
                handleClick={handleResetClick}
                color={BUTTON_COLOR.PRIMARY}
            />
        </>
    );
};

export default ResetPasswordForm;
