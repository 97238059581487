import { gql } from "@apollo/client";

export const RESOURCES = gql`
    query resourcesByOrg(
        $orgId: ID!
        $resourceStub: String
        $facilityStub: String
        $resourceGroupStub: String
        $membershipId: String
    ) {
        resourcesByOrg(
            input: {
                orgId: $orgId
                resourceStub: $resourceStub
                facilityStub: $facilityStub
                resourceGroupStub: $resourceGroupStub
                membershipId: $membershipId
            }
        ) {
            id
            name
            description
            activities {
                id
                name
                keyInformation
                description
                imageUrl
                needHelpUrl
                ordering
            }
        }

        membershipGroupByResourceGroupStub(
            input: { orgId: $orgId, resourceGroupStub: $resourceGroupStub }
        ) {
            id
            name
            stub
            isAvailable
        }

        membershipsByOrg(input: { orgId: $orgId }) {
            id
        }
    }
`;

export const GET_RESOURCE_ACTIVITIES = gql`
    query resource($resourceId: ID!) {
        resource(input: { resourceId: $resourceId }) {
            id
            activities {
                id
                name
                keyInformation
                ticketDescription
                description
                imageUrl
                needHelpUrl
                dates
                ordering
            }
        }
    }
`;
