import { gql } from "@apollo/client";

const GET_MEMBERSHIP_GROUPS = gql`
    query membershipGroupsByOrg($orgId: ID!, $membershipGroupStub: String) {
        membershipGroupsByOrg(
            input: { orgId: $orgId, membershipGroupStub: $membershipGroupStub }
        ) {
            id
            name
            memberships(where: { isDeleted_not: true }) {
                id
                name
                description
                price
                ordering
            }
            ordering
        }
    }
`;

export default GET_MEMBERSHIP_GROUPS;
