import moment from "moment";
import React from "react";
import {
    IBasketExtra,
    ICustomerMembership,
    IRemoveFromBasketInput
} from "../../generated/dataInterfaces";
import useBasket from "../Context/BasketContext/useBasket";
import BasketItemExtra from "./BasketItemExtra";

const BasketActivityItem = ({
    id,
    date,
    activityTitle,
    quantity,
    currency,
    ticketDescription,
    extras,
    price,
    discount,
    membershipDiscount,
    numberOfAppliedCustomerMemberships,
    applicableCustomerMemberships,
    isItemRemovable,
    shouldShowMembershipWarning
}: IBasketActivityItemProps) => {
    const { basketId, removeItemFromBasket } = useBasket();
    if (!basketId) {
        return null;
    }
    const handleRemoveClick = async () => {
        const removeItemData: IRemoveFromBasketInput = {
            basketId,
            basketItemId: id
        };
        await removeItemFromBasket({ variables: { value: removeItemData } });
    };

    const dateNice = moment.utc(date);
    const totalDiscount =
        (discount ?? 0) * (quantity - numberOfAppliedCustomerMemberships);

    return (
        <div className="basket-item a-box v-box-secondary">
            <time dateTime={dateNice.format("YYYY-MM-DD")}>
                {dateNice.format("dddd, MMMM Do YYYY")}
            </time>
            <p>
                <strong>{activityTitle}</strong>
            </p>
            <div className="fw item-line">
                <p className="left-container">
                    <span>{quantity} x</span>
                    {ticketDescription} at {dateNice.format("HH:mm")}
                </p>
                <p className="right-container">
                    <span className={isItemRemovable ? "icon" : "no-icon"}>
                        {currency}
                        {price.toFixed(2)}
                        {isItemRemovable ? (
                            <i
                                onClick={handleRemoveClick}
                                className="far fa-trash-alt color-ui-warning"
                            />
                        ) : null}
                    </span>
                </p>
            </div>
            {totalDiscount && totalDiscount > 0 ? (
                <p className="discount">
                    Discount
                    <span className={isItemRemovable ? "icon" : "no-icon"}>
                        -£
                        {totalDiscount.toFixed(2)}
                    </span>
                </p>
            ) : null}
            {membershipDiscount ? (
                <p className="discount">
                    Memberships discount
                    <span className={isItemRemovable ? "icon" : "no-icon"}>
                        -£{membershipDiscount.toFixed(2)}
                    </span>
                </p>
            ) : null}
            {shouldShowMembershipWarning &&
            !membershipDiscount &&
            applicableCustomerMemberships &&
            applicableCustomerMemberships.length > 0 ? (
                <p className="discount">
                    You will be able to apply your memberships at the next stage
                </p>
            ) : null}

            {extras?.map((extraData, i) => {
                return (
                    <BasketItemExtra
                        key={i}
                        id={extraData.id}
                        extraId={extraData.extraId}
                        extra={extraData.extra}
                        description={extraData.description}
                        pricePerItem={extraData.pricePerItem}
                        discountPerItem={extraData.discountPerItem}
                        quantity={extraData.quantity}
                        isItemRemovable={isItemRemovable}
                    />
                );
            })}

            <div className="clearfix" />
        </div>
    );
};

export default BasketActivityItem;

interface IBasketActivityItemProps {
    id: string;
    date: string;
    activityTitle: string;
    quantity: number;
    currency: string;
    ticketDescription: string;
    price: number;
    extras: IBasketExtra[] | null;
    discount?: number;
    isItemRemovable: boolean;
    membershipDiscount: number;
    applicableCustomerMemberships: ICustomerMembership[] | null;
    numberOfAppliedCustomerMemberships: number;
    shouldShowMembershipWarning: boolean;
}
