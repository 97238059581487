import { orderBy } from "lodash";
import moment from "moment";
import React from "react";
import { IBooking, IBookingStatus } from "../../generated/dataInterfaces";

function PackageBookingCard({ bookings }: IPackageBookingCardProps) {
    const orderedBookings = orderBy(bookings, ["activityStartsAt"], ["asc"]);
    const firstBooking = orderedBookings[0];

    const isCancelled = firstBooking.bookingStatus === IBookingStatus.Cancelled;

    return (
        <div
            className={`m-activity-box half-container a-box ${
                isCancelled ? "cancelled" : ""
            }`}
        >
            <div>
                <header>
                    <p className="lead">
                        {firstBooking.activityQuantity} x{" "}
                        {firstBooking.activityInstance.activity.name}
                        <br />
                        {isCancelled ? <> (CANCELLED)</> : null}
                    </p>
                </header>
                <div className="content">
                    <label>
                        <span>when: </span>

                        <ul className="date-list">
                            {orderedBookings.map((booking, i) => {
                                // ❗UTC needed for activityStartsAt
                                const dateNice: moment.Moment = moment(
                                    booking.activityStartsAt
                                ).utc();
                                return (
                                    <li key={i}>
                                        {dateNice.format("MMMM Do YYYY")} at{" "}
                                        {dateNice.format("h.mm a")}
                                    </li>
                                );
                            })}
                        </ul>
                    </label>
                    {firstBooking.bookingNames ? (
                        <label>
                            <span>Name(s)/Details:</span>{" "}
                            {firstBooking.bookingNames.join(", ")}
                        </label>
                    ) : null}
                    <label>
                        <span>booking reference: </span>
                        {firstBooking.packageReference}
                    </label>
                </div>

                <footer></footer>
                <div className="clearfix" />
            </div>
        </div>
    );
}

export default PackageBookingCard;

export interface IPackageBookingCardProps {
    bookings: IBooking[];
}
