import { exists } from "@les-ormes/lib";
import { uniq } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useActivityContext } from "../ActivityContext";

const HourAndMinuteSelect = () => {
    const { activityTimeData, setSelectedActivityInstanceId } =
        useActivityContext();

    const [selectedHour, setSelectedHour] = useState<string>("default");
    const [selectedMinute, setSelectedMinute] = useState<string>("default");

    const tryToSelectActivityInstanceId = (hour: string, minute: string) => {
        const timeOfDay = `${hour}:${minute}`;

        const matchingActivity = activityTimeData?.find(
            (activityTime) => activityTime.timeOfDay === timeOfDay
        );

        if (matchingActivity) {
            setSelectedActivityInstanceId(
                matchingActivity.activityDetails.activityInstanceId
            );
        } else {
            setSelectedActivityInstanceId(null);
        }
    };

    const hours = uniq(
        activityTimeData
            ?.map((activityTime) => {
                return activityTime.activityDetails.quantityAvailable > 0
                    ? moment(activityTime.timeOfDay, "HH:mm").format("HH")
                    : null;
            })
            ?.filter(exists)
    );

    const minutes = activityTimeData
        ?.filter(
            (activityTime) =>
                activityTime.activityDetails.quantityAvailable > 0 &&
                selectedHour ===
                    moment(activityTime.timeOfDay, "HH:mm").format("HH")
        )
        .map((activityTime) =>
            moment(activityTime.timeOfDay, "HH:mm").format("mm")
        );

    return (
        <>
            <div className="m-activity-time-selector fw ">
                <div className="col-4">
                    <select
                        onChange={(event) => {
                            setSelectedHour(event.target.value);
                            setSelectedMinute("default");

                            tryToSelectActivityInstanceId(
                                event.target.value,
                                "default"
                            );
                        }}
                        value={selectedHour}
                    >
                        <option value="default">Hour</option>
                        {hours.map((hour, index) => (
                            <option value={hour} key={index}>
                                {hour}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-4">
                    <select
                        onChange={(event) => {
                            setSelectedMinute(event.target.value);

                            tryToSelectActivityInstanceId(
                                selectedHour,
                                event.target.value
                            );
                        }}
                        value={selectedMinute}
                    >
                        <option value="default">Minute</option>
                        {minutes?.map((minute, index) => (
                            <option value={minute} key={index}>
                                {minute}
                            </option>
                        )) ?? null}
                    </select>
                </div>
            </div>
        </>
    );
};

export default HourAndMinuteSelect;
