import React from "react";
import { useRouteMatch } from "react-router-dom";
import useBasket from "../Context/BasketContext/useBasket";
import useCustomerMemberships from "../Context/CustomerMembershipsContext";
import ScrollToTop from "../Util/ScrollToTop";
import BasketCoupons from "./BasketCoupons";
import BasketFooter from "./BasketFooter";
import BasketItem from "./BasketItem";
import Timer from "./Timer";
import ViewBasketButtonMobile from "./ViewBasketButtonMobile";

const generateBasketFooterLink = (
    areThereBasketActivities: boolean,
    areBookingNamesRequired: boolean,
    hasCustomerMemberships: boolean,
    path: string
) => {
    switch (true) {
        case path === "/booking-details":
            return "/check-out";
        case areThereBasketActivities &&
            hasCustomerMemberships &&
            path !== "/apply-memberships":
            return "/apply-memberships";
        case areBookingNamesRequired && path !== "/booking-details":
            return "/booking-details";
        default:
            return null;
    }
};

const Basket = ({ isCheckout, shouldShowMembershipWarning }: IBasketProps) => {
    const match = useRouteMatch();

    const {
        basketData,
        areThereBasketItems,
        areThereBasketActivities,
        areThereBasketMemberships,
        areBookingNamesRequired
    } = useBasket();
    const { hasCustomerMemberships } = useCustomerMemberships();

    const basketFooterLink = generateBasketFooterLink(
        areThereBasketActivities,
        areBookingNamesRequired,
        hasCustomerMemberships,
        match.path
    );

    return (
        <>
            <ViewBasketButtonMobile shouldShow={areThereBasketItems} />
            <div id="basket" className="m-activity-booking-cart fw">
                <div className="booking">
                    <header className="a-box v-box-secondary">
                        <h4>Your Basket</h4>
                    </header>
                    <div className="clearfix" />
                    {basketData && basketData.items ? (
                        <>
                            {basketData.items.map((item) => (
                                <BasketItem
                                    key={item.id}
                                    basketItem={item}
                                    isItemRemovable={!isCheckout}
                                    shouldShowMembershipWarning={
                                        shouldShowMembershipWarning
                                    }
                                />
                            ))}

                            <BasketCoupons
                                coupons={basketData?.coupons ?? null}
                            />
                        </>
                    ) : null}
                    <div className="clearfix" />
                    {areThereBasketMemberships && areThereBasketActivities ? (
                        <div className="basket-warning-item a-box v-box-secondary ">
                            <p>
                                You will not be able to use your memberships
                                until they have been activated by uploading a
                                photo. Once you&apos;ve completed checkout we
                                will send you instructions.
                            </p>
                            <div className="clearfix" />
                        </div>
                    ) : null}
                    <BasketFooter
                        basketTotal={basketData ? basketData.total : 0}
                        isBasketEmpty={!areThereBasketItems}
                        isCheckout={isCheckout}
                        overrideButtonLink={basketFooterLink}
                    />
                    <div className="clearfix" />
                </div>
                {areThereBasketItems ? <Timer /> : null}
                <ScrollToTop display={true} />
            </div>
        </>
    );
};

interface IBasketProps {
    isCheckout: boolean;
    overrideButtonLink?: string;
    shouldShowMembershipWarning: boolean;
}

export default Basket;
