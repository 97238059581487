import React, { useState } from "react";
import { FormContextProvider } from "../../Context/FormContext";
import CredentialForm from "../../CredentialForm";
import { ICredential } from "../../../generated/dataInterfaces";

const ToggleBox = ({ toggle, data }: IToggleBox) => {
    const [isToggle, setIsToggle] = useState(toggle);

    const handleClick = () => {
        setIsToggle((isToggle) => !isToggle);
    };

    if (!data) {
        return null;
    }

    return (
        <div className={`m-toggle-box fw ${data.isDisabled ? "disabled" : ""}`}>
            <header
                onClick={handleClick}
                className={`a-box ${isToggle ? "on" : "off"}`}
            >
                <h4>{data.firstName + " " + data.lastName}</h4>
                {data.isPrimaryCredential ? (
                    <label className="left">primary</label>
                ) : null}
                <div className="clearfix" />
            </header>
            <div className={`toggle-selector fw ${isToggle ? "on" : "off"}`}>
                <div className="main a-box v-box-secondary">
                    <div className="section fw">
                        <FormContextProvider>
                            <CredentialForm data={data} />
                        </FormContextProvider>
                    </div>
                    <div className="clearfix" />
                </div>
            </div>
        </div>
    );
};

export default ToggleBox;

interface IToggleBox {
    toggle?: boolean;
    data?: ICredential;
}
