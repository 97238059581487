import { gql } from "@apollo/client";

const CHECK_IN_INFO = gql`
    query checkInInfo($bookingReference: String!) {
        checkInInfo(bookingReference: $bookingReference) {
            isCheckInAvailable
            preCheckInInstruction
            postCheckInInstruction
            bookingReference
            selfCheckInOpensAt
            selfCheckInClosesAt
            isAlreadyCheckedIn
            activityStartsAt
            activityName
            resourceName
        }
    }
`;

export default CHECK_IN_INFO;
