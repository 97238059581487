export default `
    id
    isPrimaryCredential
    firstName
    lastName
    email
    password {
        hash
    }
    mobileNumber
    createdAt
    isDisabled
    isLockedOut
    doesReceiveEmails
    doesReceiveSms
    customer {
        id
        name
        postcode
        credentials {
            id
            isPrimaryCredential
            firstName
            lastName
            email
            password {
                hash
            }
            mobileNumber
            createdAt
            isDisabled
            isLockedOut
        }
        bookings {
            activityInstance {
                activity {
                    name
                    description
                    resource{
                        name
                    }
                }
            }
            id
            activityQuantity
            reference
            packageReference
            bookingNames
            activityStartsAt
            bookingStatus
            selfCheckInOpensAt
            selfCheckInClosesAt
            checkedInAt
            customerMemberships{
                id
                name
            }
        }
        memberships (where: {isDisabled: false}) {
            id
            name
            hasBeenActivated
            completedAt
            expiresAt
            renewalAvailableAt
            canPhotoBeChanged
            photoUploadedAt
            photo{
                base64
            }
            membership {
                id
                name
                canBeCrossAuthorised
            }
        }
        membershipsSharedWithMe (where: {sharedCustomerMembership:{isDisabled_not:true}} ) {
            id
            isConfirmed
            membershipOwner{
              id
              name
            }
             sharedCustomerMembership{
                id
                name
                hasBeenActivated
                completedAt
                expiresAt
                renewalAvailableAt
                photoUploadedAt
                membership {
                    id
                    name
                    canBeCrossAuthorised
                }
                photo{
                    base64
                }
                customer{
                    name
                }
            }
        }
    }
`;

// photos should not be loaded, with the credential
