import moment from "moment";
import React, { useEffect } from "react";
import Html from "../../Util/Html";
import RadioButton from "../../Util/RadioGroup/RadioButton";
import { useActivityContext } from "../ActivityContext";
import HourAndMinuteSelect from "./HourAndMinuteSelect";

/**
 *  @name           TimeSelector
 *  @description    Once the Date has been selected the options which are currently available
 *                  are displayed
 *  @props          null
 */
const TimeSelector = () => {
    const {
        selectedDate,
        activityTimeData,
        selectedActivityInstanceId,
        setSelectedActivityInstanceId
    } = useActivityContext();

    const selectedActivityInstance =
        activityTimeData && activityTimeData.length === 1 // pre-select date if only one
            ? activityTimeData[0]
            : activityTimeData
            ? activityTimeData.find(
                  (atd) =>
                      atd.activityDetails.activityInstanceId ===
                      selectedActivityInstanceId
              )
            : null;

    useEffect(
        function preSelectActivityInstance() {
            if (
                selectedActivityInstance &&
                selectedActivityInstance.activityDetails.quantityAvailable > 0
            ) {
                setSelectedActivityInstanceId(
                    selectedActivityInstance.activityDetails.activityInstanceId
                );
            }
        },
        [selectedActivityInstance, setSelectedActivityInstanceId]
    );

    return (
        <div className="m-activity-time-selector section fw ">
            <label>
                <strong>Select a start time</strong>
            </label>
            {selectedDate && activityTimeData ? (
                activityTimeData.length < 20 ? (
                    activityTimeData.map((instance) => {
                        const time = moment(instance.timeOfDay, "HH:mm").format(
                            "h:mma"
                        );
                        return (
                            <RadioButton
                                key={
                                    instance.activityDetails.activityInstanceId
                                }
                                id={instance.activityDetails.activityInstanceId}
                                value={time}
                                label={time}
                                disabled={
                                    !(instance.activityDetails
                                        .quantityAvailable > 0
                                        ? true
                                        : false)
                                }
                                selectedButtonId={selectedActivityInstanceId}
                                setSelectedButtonId={
                                    setSelectedActivityInstanceId
                                }
                                dataTestId="activity-time-button"
                            />
                        );
                    })
                ) : (
                    <HourAndMinuteSelect />
                )
            ) : (
                <p className="fade">
                    Please select a date to view available times
                </p>
            )}
            {selectedActivityInstance &&
            selectedActivityInstance.activityDetails &&
            selectedActivityInstance.activityDetails.packageDescription ? (
                <div className="fw m-package-description">
                    <Html
                        content={
                            selectedActivityInstance.activityDetails
                                .packageDescription
                        }
                    />
                </div>
            ) : null}
        </div>
    );
};

export default TimeSelector;
