import React from "react";

const ResourceListLoading = () => {
    return (
        <>
            {[0, 1].map((j) => (
                <div
                    key={`m-resource-list-${j}`}
                    className="m-resource-list fw loading"
                >
                    <h3>&nbsp;</h3>
                    <section>&nbsp;</section>

                    {[0, 1, 2].map((i) => (
                        <div
                            key={`m-activity-booking-${j}-${i}`}
                            className="m-activity-booking fw"
                        >
                            <header className="a-box">
                                <aside />
                                <h4>&nbsp;</h4>
                                <label>&nbsp;</label>
                                <div className="clearfix" />
                            </header>
                        </div>
                    ))}
                </div>
            ))}
        </>
    );
};

export default ResourceListLoading;
