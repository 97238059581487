import React from "react";

const PageBillboard = () => {
    const pageBillboardImageUrl = "../../../../images/page-billboard.jpg";

    return (
        <div className="o-page-billboard-container fw">
            <div className="pw">
                <img
                    src={`${pageBillboardImageUrl}`}
                    alt="Page Billboard Image"
                />
            </div>
        </div>
    );
};

export default PageBillboard;
