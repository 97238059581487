import { groupBy } from "lodash";
import React from "react";
import { IBooking } from "../../../generated/dataInterfaces";
import PackageBookingConfirmation from "./PackageBookingConfirmation";

interface IPropsPackageBookings {
    bookings: IBooking[];
}

const PackageBookings = ({ bookings }: IPropsPackageBookings) => {
    const groups = groupBy(bookings, "packageReference");

    return (
        <>
            {Object.keys(groups).map((key) => (
                <PackageBookingConfirmation key={key} bookings={groups[key]} />
            ))}
        </>
    );
};

export default PackageBookings;
