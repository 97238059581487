import { gql } from "@apollo/client";

const REMOVE_MEMBERSHIP_SHARE_MUTATION = gql`
    mutation removeMembershipShare($customerMembershipShareId: ID!) {
        removeMembershipShare(
            input: { customerMembershipShareId: $customerMembershipShareId }
        )
    }
`;

export default REMOVE_MEMBERSHIP_SHARE_MUTATION;
