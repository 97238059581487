import { useQuery } from "@apollo/client";
import { exists } from "@les-ormes/lib";
import { produce } from "immer";
import React, { useEffect, useState } from "react";
import GET_MEMBERSHIPS from "../../data/Membership/MembershipList";
import { IBasket, IBasketMembership } from "../../generated/dataInterfaces";
import { useAppContext } from "../Context/AppContext";
import useBasket from "../Context/BasketContext/useBasket";
import { IMembership } from "./interfaces";
import Membership from "./Membership";

const MembershipList = () => {
    const { organisationId } = useAppContext();
    const { basketData } = useBasket();

    const [memberships, setMemberships] = useState<IMembership[]>([]);

    const { loading, error, data } = useQuery<IMembershipData>(
        GET_MEMBERSHIPS,
        {
            variables: {
                orgId: organisationId
            },
            skip: !organisationId
        }
    );

    useEffect(() => {
        if (basketData) {
            updateMembershipsWithBasketData(
                basketData,
                memberships,
                setMemberships
            );
        }
    }, [basketData, memberships]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        console.log(`Error! ${error.message}`);
        return null;
    }

    if (!loading && data && data.membershipsByOrg && memberships.length === 0) {
        const memberships = data.membershipsByOrg;
        if (basketData) {
            updateMembershipsWithBasketData(
                basketData,
                memberships,
                setMemberships
            );
        } else {
            setMemberships(memberships);
        }
    }

    return memberships && memberships.length > 0 ? (
        <>
            <div>
                <h3>Memberships</h3>
                {memberships.map((membership) => (
                    <Membership key={membership.id} membership={membership} />
                ))}
                <div className="clearfix" />
            </div>
        </>
    ) : (
        <p>Memberships coming soon</p>
    );
};

interface IMembershipData {
    membershipsByOrg: IMembership[];
}

function updateMembershipsWithBasketData(
    basketData: IBasket,
    memberships: IMembership[],
    setMemberships: (value: React.SetStateAction<IMembership[]>) => void
) {
    if (basketData.items) {
        const { items } = basketData;
        const basketMembershipItems = items
            .filter((item) => item.basketMembership)
            .map((item) => item.basketMembership)
            .filter(exists);
        const hydratedMemberships =
            hydrateMembershipGroupsWithMembershipQuantities(
                memberships,
                basketMembershipItems
            );

        setMemberships(hydratedMemberships);
    }
}

function hydrateMembershipGroupsWithMembershipQuantities(
    memberships: IMembership[],
    basketMemberships: IBasketMembership[]
): IMembership[] {
    if (basketMemberships.length === 0) {
        return setMembershipQuantitiesToZero(memberships);
    }
    return produce(memberships, (membershipsDraft) => {
        basketMemberships.forEach((basketMembership) => {
            const matchingMembership = membershipsDraft.find(
                (membership) => membership.id === basketMembership.membershipId
            );

            if (matchingMembership) {
                matchingMembership.quantity = basketMembership.quantity;
            }
        });
    });
}

function setMembershipQuantitiesToZero(
    memberships: IMembership[]
): IMembership[] {
    return produce(memberships, (membershipsDraft) => {
        if (membershipsDraft) {
            membershipsDraft.forEach((membershipDraft) => {
                membershipDraft.quantity = 0;
            });
        }
    });
}

export default MembershipList;
