import { ApolloClient } from "@apollo/client";
import ReactGA from "react-ga";
import { NormalizedCacheObject } from "apollo-cache-inmemory";
import React, { createContext, useContext, useEffect, useState } from "react";
import useStateWithLocalStorage from "../../Util/useStateWithLocalStorage";
import generateApolloClient from "./generateApolloClient";

const AppContext = createContext<IAppContext | null>(null);

export const AppContextProvider = ({
    children
}: {
    children: React.ReactNode;
}) => {
    const [organisationId, setOrganisationId] = useState<string | null>(null);
    const [customerId, setCustomerId] = useState<string | null>(null);
    const [credentialId, setCredentialId] = useState<string | null>(null);
    const [transactionData, setTransactionData] = useState<any>(null);
    const [pageTitle, setPageTitle] = useState<string | null>(null);
    const [isAccountPage, setIsAccountPage] = useState(false);
    const [entryRoute, setEntryRoute] = useStateWithLocalStorage(
        "entryRoute",
        "/account/profile"
    );
    const [entryLabel, setEntryLabel] = useStateWithLocalStorage(
        "entryLabel",
        "Choose your options"
    );
    const [basketId, setBasketId] = useStateWithLocalStorage<string | null>(
        "basketId",
        null
    );

    const [token, setToken] = useStateWithLocalStorage<string | null>(
        "token",
        null
    );
    const [redirectTo, setRedirectTo] = useStateWithLocalStorage<string | null>(
        "redirectTo",
        "/account/profile"
    );
    const [gaTrackerNames, setGaTrackerNames] = useState<ReactGA.TrackerNames>(
        []
    );
    const [gaTrackerArray, setGaTrackerArray] = useState<ReactGA.Tracker[]>([]);

    const addTrackers = () => {
        const trackerIds: any[] = [
            process.env.GA_TRACKING_ID,
            process.env.GA_TRACKING_2_ID
        ];

        let trackers: ReactGA.Tracker[] = [];
        let trackerNames: ReactGA.TrackerNames = [];

        trackerIds.forEach((trackerId: string, index: number) => {
            const trackerName = `tracker${index}`;

            trackers = [
                ...trackers,
                {
                    trackingId: trackerId,
                    gaOptions: {
                        name: trackerName,
                        allowLinker: true
                    }
                }
            ];
            trackerNames = [...trackerNames, trackerName];
        });

        setGaTrackerArray(trackers);
        setGaTrackerNames(trackerNames);
    };

    const [apolloClient, setApolloClient] =
        useState<ApolloClient<NormalizedCacheObject> | null>(
            generateApolloClient(null)
        );

    useEffect(() => {
        addTrackers();
    }, []);

    useEffect(() => {
        if (token) {
            const apolloClient = generateApolloClient(token);
            setApolloClient(apolloClient);
        }
    }, [token]);

    // useEffect(() => {
    //     setRedirectTo(entryRoute);
    // }, [entryRoute]);

    const appContext: IAppContext = {
        apolloClient,
        organisationId,
        setOrganisationId,
        customerId,
        setCustomerId,
        credentialId,
        setCredentialId,
        transactionData,
        setTransactionData,
        pageTitle,
        setPageTitle,
        redirectTo,
        setRedirectTo,
        isAccountPage,
        setIsAccountPage,
        token,
        setToken,
        entryRoute,
        setEntryRoute,
        entryLabel,
        setEntryLabel,
        basketId,
        setBasketId,
        gaTrackerArray,
        gaTrackerNames
    };

    return (
        <AppContext.Provider value={appContext}>{children}</AppContext.Provider>
    );
};

export interface IAppContext {
    apolloClient: ApolloClient<NormalizedCacheObject> | null;
    basketId: string | null;
    setBasketId: (value: string | null) => void;
    organisationId: string | null;
    setOrganisationId: (value: string | null) => void;
    customerId: string | null;
    setCustomerId: (value: string | null) => void;
    credentialId: string | null;
    setCredentialId: (value: string | null) => void;
    transactionData: any;
    setTransactionData: (value: any) => void;
    pageTitle: string | null;
    setPageTitle: (value: string | null) => void;
    token: string | null;
    setToken: (value: string | null) => void;
    isAccountPage: boolean;
    setIsAccountPage: (value: boolean) => void;
    redirectTo: string | null;
    setRedirectTo: (value: string) => void;
    entryRoute: string;
    setEntryRoute: (value: string) => void;
    entryLabel: string;
    setEntryLabel: (value: string) => void;
    gaTrackerArray: ReactGA.Tracker[];
    gaTrackerNames: ReactGA.TrackerNames;
}

export default AppContext;

export const useAppContext = () => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error(
            "useAppContext must be used within a AppContextProvider"
        );
    }
    return context;
};

export const useAppRedirect = () => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error(
            "useAppRedirect must be used within a AppContextProvider"
        );
    }
    const { redirectTo, setRedirectTo } = context;
    return { redirectTo, setRedirectTo };
};
