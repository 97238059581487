import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { IBooking, IBookingStatus } from "../../generated/dataInterfaces";

const ActivityBookingCard = ({ booking }: IActivityBookingCard) => {
    const isCancelled = booking.bookingStatus === IBookingStatus.Cancelled;

    const isCheckedIn = Boolean(booking.checkedInAt);

    return (
        <div
            key={123}
            className={`m-activity-box half-container a-box ${
                isCancelled ? "cancelled" : ""
            }`}
            data-testid="booking-card"
        >
            <div>
                <header>
                    <p className="lead">
                        {booking.activityQuantity} x{" "}
                        {booking.activityInstance.activity.name}
                        <br />
                        {booking.activityInstance.activity.resource.name}
                        {isCancelled ? <> (CANCELLED)</> : null}
                    </p>
                </header>
                <div className="content">
                    <label>
                        <span>when: </span>

                        {
                            // ❗UTC needed for activityStartsAt
                            moment(booking.activityStartsAt)
                                .utc()
                                .format("h:mm a")
                        }
                    </label>
                    {booking.bookingNames && booking.bookingNames.length > 0 ? (
                        <label>
                            <span>Name(s)/Details:</span>{" "}
                            {booking.bookingNames.join(", ")}
                        </label>
                    ) : null}

                    <label>
                        <span>booking reference: </span>
                        {booking.reference}
                    </label>

                    {booking.customerMemberships &&
                    booking.customerMemberships.length > 0 ? (
                        <label>
                            <span>memberships:</span>
                            <ul>
                                {booking.customerMemberships.map((c) => (
                                    <li key={c.id}>{c.name}</li>
                                ))}
                            </ul>
                        </label>
                    ) : null}
                </div>

                <div className="clearfix" />
            </div>
            {!isCheckedIn &&
            // ❗ Passing true will change the time zone without changing the current time.
            // It is needed because the selfCheckInOpensAt is set to the exact utc time ( which during BST is +1)
            moment().utc(true).isSameOrAfter(booking.selfCheckInOpensAt) &&
            moment().utc(true).isSameOrBefore(booking.selfCheckInClosesAt) ? (
                <Link
                    className="m-btn"
                    data-testid="checkin-button"
                    to={`/check-in/${booking.reference}`}
                >
                    Check in now
                </Link>
            ) : null}
            {isCheckedIn ? (
                <span data-testid="checked-in-text">You’re checked in</span>
            ) : null}
        </div>
    );
};

export default ActivityBookingCard;

export interface IActivityBookingCard {
    booking: IBooking;
}
