import React from "react";
import { useAuthenticated } from "../../Context/AuthContext";
import AuthenticatedUser from "./AuthenticatedUser";
import RegisterNewCustomer from "./RegisterNewCustomer";
import { FormContextProvider } from "../../Context/FormContext";
import NotificationBar from "../../Util/NotificationBar";
//import StepTracking from "../../Analytics/StepTracking";

const Register = () => {
    const { isAuthenticated } = useAuthenticated();

    return isAuthenticated ? (
        <AuthenticatedUser />
    ) : (
        <>
            <FormContextProvider>
                <RegisterNewCustomer />
            </FormContextProvider>
            <NotificationBar duration={7500} />
            {/* <StepTracking stepNumber="2" stepName="Registration" /> */}
        </>
    );
};

export default Register;
