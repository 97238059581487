import React from "react";
import { useAppContext } from "../../../Context/AppContext";
import { FormContextProvider } from "../../../Context/FormContext";
import NotificationBar from "../../../Util/NotificationBar";
import PageHeader from "../../../Util/PageHeader";
import ResetPasswordForm from "./ResetPasswordForm";

const ResetPassword = () => {
    const { setPageTitle } = useAppContext();
    setPageTitle("Set your password");
    return (
        <FormContextProvider>
            <div className="fw">
                <PageHeader />
                <div className="pw">
                    <div className="clearfix" />
                    <div className="content fw">
                        <div className="two-thirds-container">
                            <div className="m-login a-box v-box-secondary">
                                <header>
                                    <h3>Change or set your password</h3>
                                </header>
                                <div className="clearfix" />
                                <div className="password-reset-form">
                                    <ResetPasswordForm />
                                    <div className="clearfix" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <NotificationBar duration={5000} />
            </div>
        </FormContextProvider>
    );
};

export default ResetPassword;
