import React from "react";
import AccountSidebarNavigation, {
    AccountSidebarNavigationIcon
} from "./AccountSidebarNavigation";
import { useAccountContext } from "../../Context/AccountContext";

const AccountSidebar = () => {
    const { credential, fullName } = useAccountContext();
    if (!credential) {
        return null;
    }

    return (
        <div className="third-container left">
            <div className="three-quarters-container">
                <div className="m-account-sidebar a-box v-box-secondary">
                    <header>
                        <p className="lead">
                            <strong>{fullName}</strong>
                        </p>
                        {credential.isPrimaryCredential ? (
                            <p className="v-secondary-color">Premium Member</p>
                        ) : null}
                    </header>
                    <div className="clearfix" />
                    <div className="content">
                        <AccountSidebarNavigation
                            title="Profile"
                            icon={AccountSidebarNavigationIcon.PROFILE}
                        />
                        <AccountSidebarNavigation
                            title="My activities"
                            route="activities"
                            icon={AccountSidebarNavigationIcon.ACTIVITIES}
                        />
                        <AccountSidebarNavigation
                            title="Memberships"
                            route="memberships"
                            icon={AccountSidebarNavigationIcon.STAYS}
                        />

                        <AccountSidebarNavigation
                            title="Users"
                            route="credentials"
                            icon={AccountSidebarNavigationIcon.CREDENTIALS}
                        />

                        <AccountSidebarNavigation
                            title="Log Out"
                            icon={AccountSidebarNavigationIcon.LOGOUT}
                        />
                    </div>
                    <div className="clearfix" />
                </div>
            </div>
        </div>
    );
};

export default AccountSidebar;
