import React from "react";
import { ICoupon } from "../../generated/dataInterfaces";
import useBasket from "../Context/BasketContext/useBasket";

const BasketCouponItem = ({ coupon }: IBasketCouponItem) => {
    const { basketId, removeCouponFromBasket } = useBasket();
    if (!basketId) {
        return null;
    }
    const handleRemoveClick = async () => {
        await removeCouponFromBasket({
            variables: { couponId: coupon.id, basketId }
        });
    };

    return (
        <div className="coupon-item">
            <p data-testid="basket-coupon-code-item">
                {coupon.couponCode}

                <i
                    onClick={handleRemoveClick}
                    className="far fa-trash-alt color-ui-warning"
                />
            </p>
            <div className="clearfix" />
        </div>
    );
};

export default BasketCouponItem;

interface IBasketCouponItem {
    coupon: ICoupon;
}
