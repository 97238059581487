import React, { useState } from "react";
import { Redirect, Link } from "react-router-dom";

const AutoLogout = () => {
    const hostname = window.location.hostname;
    const logoutPath = "/account/logout";
    const [countDown, setCountDown] = useState<number>(5);

    setTimeout(() => {
        if (countDown > 0) {
            setCountDown(countDown - 1);
        }
    }, 1000);

    if (hostname === process.env["AUTO_LOGOUT_HOSTNAME"]) {
        if (countDown > 0) {
            return (
                <>
                    <h3 style={{ paddingTop: 20 }}>
                        You will be logged out in {countDown} seconds.
                    </h3>
                    <p>
                        Click
                        <Link to={logoutPath}> here</Link> to log out now.
                    </p>
                </>
            );
        } else {
            return <Redirect to={logoutPath} />;
        }
    }

    return null;
};

export default AutoLogout;
