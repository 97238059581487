import React from "react";
import { NavLink } from "react-router-dom";

const AccountSidebarNavigation = ({
    title,
    icon,
    route
}: IAccountSidebarNavigationProps) => {
    const clickRoute = (route ? route : title).toLowerCase().replace(/ /g, "");
    return (
        <NavLink
            activeClassName="active"
            className="navigation-item"
            to={`/account/${clickRoute}`}
        >
            <div>
                <p className={icon}>{title}</p>
                <div className="clearfix" />
            </div>
        </NavLink>
    );
};

export default AccountSidebarNavigation;

export interface IAccountSidebarNavigationProps {
    title: string;
    icon: AccountSidebarNavigationIcon;
    route?: string;
    handleClick?: () => void;
}
export enum AccountSidebarNavigationIcon {
    DASHBOARD = "dashboard",
    PROFILE = "profile",
    STAYS = "stays",
    ACTIVITIES = "activities",
    PAYMENT = "paymentdetails",
    LOGOUT = "logout",
    CREDENTIALS = "credentials"
}
