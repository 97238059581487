import { useMutation, useQuery } from "@apollo/client";
import decodeJwt from "jwt-decode";
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import GET_BASKET from "../../data/Basket/GetBasket";
import GET_ORGANISATION_ID from "../../data/Organisation/GetOrganisation";
import LOGIN_CUSTOMER from "../../data/User/LoginCustomer";
import { useAppContext } from "./AppContext";

const AuthContext = createContext<IAuthContext | null>(null);

function AuthContextProvider({ children }: { children: React.ReactNode }) {
    const {
        token,
        setToken,
        setCredentialId,
        organisationId,
        setOrganisationId,
        setCustomerId,
        basketId
    } = useAppContext();

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    const { data } = useQuery(GET_ORGANISATION_ID, {
        skip: !!organisationId
    });
    if (data && data["getOrganisationId"]) {
        setOrganisationId(data["getOrganisationId"]);
    }

    useEffect(() => {
        if (token) {
            const decodedToken: {
                credentialId: string;
                organisationId: string;
                customerId: string;
            } = decodeJwt(token);

            if (
                decodedToken &&
                decodedToken.credentialId &&
                decodedToken.organisationId &&
                decodedToken.customerId
            ) {
                setCredentialId(decodedToken.credentialId);
                setOrganisationId(decodedToken.organisationId);
                setCustomerId(decodedToken.customerId);
                setIsAuthenticated(true);
            }
        } else {
            setIsAuthenticated(false);
            setCredentialId(null);
            setCustomerId(null);
        }
    }, [token, setCredentialId, setCustomerId, setOrganisationId]);

    const [loginCustomer] = useMutation(LOGIN_CUSTOMER, {
        refetchQueries: [
            {
                query: GET_BASKET,
                variables: {
                    basketId
                }
            }
        ]
    });
    const loginCustomerExtended = useCallback(
        async (email: string, password: string) => {
            // do checks here to make sure credentials are correct
            try {
                const { data, errors } = await loginCustomer({
                    variables: {
                        value: {
                            organisationId,
                            username: email,
                            password
                        }
                    }
                });

                if (data && data["loginCustomer"]) {
                    setToken(data["loginCustomer"].token);
                    setIsAuthenticated(true);
                }

                if (errors && errors.length > 0) {
                    setToken(null);
                    setIsAuthenticated(false);
                    return false;
                }

                return true;
            } catch (e) {
                console.log(e);
                return false;
            }
        },
        [loginCustomer, organisationId, setToken]
    );

    const values = useMemo(() => {
        return {
            isAuthenticated,
            setIsAuthenticated,
            loginCustomer: loginCustomerExtended
        };
    }, [isAuthenticated, setIsAuthenticated, loginCustomerExtended]);

    return (
        <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
    );
}

interface IAuthContext {
    isAuthenticated: boolean;
    setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
    loginCustomer: (email: string, password: string) => Promise<boolean>;
}

function useAuthenticated() {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error(
            "Authenticated must be used within a AuthContextProvider"
        );
    }
    const { isAuthenticated, setIsAuthenticated } = context;
    return { isAuthenticated, setIsAuthenticated };
}

function useLogin() {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("Login must be used within a AuthContextProvider");
    }
    const { loginCustomer } = context;
    return { loginCustomer };
}

export { AuthContextProvider, useAuthenticated, useLogin };
