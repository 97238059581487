import React from "react";
import { Link } from "react-router-dom";

const Step = ({ last, active, title, link, current }: IStep) => {
    const activeLinkColor = active ? "v-secondary-color" : "";

    return (
        <div className={current ? `step current` : `step`}>
            {active ? <i className="tick fal fa-check" /> : null}
            <p className={`lead ${activeLinkColor}`}>
                {link ? (
                    <Link to={link} className="">
                        {title}
                    </Link>
                ) : (
                    title
                )}
            </p>
            {!last ? (
                <p className="lead">
                    <i className={`fal fa-chevron-right ${activeLinkColor}`} />
                </p>
            ) : null}
        </div>
    );
};

export default Step;

interface IStep {
    last: boolean;
    active: boolean;
    current: boolean;
    title: string;
    link: string | null;
}
