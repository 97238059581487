import React, { useEffect, useState } from "react";
import { useAccountContext } from "../../Context/AccountContext";
import { useAppContext } from "../../Context/AppContext";
import { FormContextProvider } from "../../Context/FormContext";
import { useNotification } from "../../Context/NotificationContext";
import ManagePaymentCards from "../../PaymentCards/Management";
import { InputFieldType } from "../../Util/InputField";
import UpdateField from "../../Util/InputField/UpdateField";
import { NOTIFICATION_BAR_MESSAGE } from "../../Util/NotificationBar";

const Profile = () => {
    const { setPageTitle } = useAppContext();
    const {
        setNotificationColor,
        setNotificationMessage,
        setNotificationSubMessage,
        setShowNotification
    } = useNotification();

    const { credential, customer, updateMyCustomer } = useAccountContext();

    const [accountName, setAccountName] = useState<string>("");
    const [accountPostCode, setAccountPostCode] = useState<string>("");
    const [previousData, setPreviousData] = useState<IProfileFormData>();
    const [locked, setLocked] = useState<boolean>(true);

    setPageTitle("Profile");

    const onHandleEditClick = async () => {
        // if the customer has edited their details then we need to save them
        if (locked) {
            const pData: IProfileFormData = {
                accountName,
                postCode: accountPostCode
            };

            setPreviousData(pData);
        } else {
            // mutation here
            const successful = await updateMyCustomer({
                variables: {
                    name: accountName,
                    postcode: accountPostCode
                }
            });

            // set the states
            if (successful) {
                setAccountName(accountName);
                setAccountPostCode(accountPostCode);

                // unlock all fields
                setNotificationColor(NOTIFICATION_BAR_MESSAGE.SUCCESS);
                setNotificationMessage("Successfully saved data");
                setNotificationSubMessage("");
                setShowNotification(true);
            }
        }

        // change button title
        setLocked((e) => !e);
    };

    const onHandleCancelClick = () => {
        // save data to what it was previously
        setAccountName(previousData?.accountName ?? "");
        setAccountPostCode(previousData?.postCode ?? "");

        // change button title
        setLocked((e) => !e);

        return false;
    };

    useEffect(() => {
        if (customer) {
            setAccountName(customer.name);
            setAccountPostCode(customer.postcode);
        }
    }, [customer]);

    if (!customer) {
        return null;
    }

    return (
        <>
            <FormContextProvider>
                <div className="m-customer-details">
                    <div className="clearfix" />
                    <div className="m-edit-customer-form">
                        <p>
                            These are your customer account details. To edit,
                            click or tap the <strong>Update My Account</strong>{" "}
                            button below.
                        </p>
                        {/* NAME */}
                        <div className="m-form-field">
                            <label>
                                <strong>customer account name</strong>
                            </label>
                            <input
                                type={InputFieldType.TEXT}
                                placeholder="Please enter your account name"
                                value={accountName}
                                disabled={locked}
                                onChange={(e) => {
                                    setAccountName(e.target.value);
                                }}
                            />
                        </div>
                        <div className="clearfix" />
                        {/* END NAME */}
                        {/* POST CODE */}
                        <div className="m-form-field">
                            <label>
                                <strong>Post code</strong>
                            </label>
                            <input
                                type={InputFieldType.TEXT}
                                placeholder="Please enter your post code"
                                value={accountPostCode.toUpperCase()}
                                disabled={locked}
                                onChange={(e) => {
                                    setAccountPostCode(e.target.value);
                                }}
                            />
                        </div>
                        <div className="clearfix" />
                        {/* END POST CODE */}
                    </div>
                    <div className="clearfix" />
                </div>
                <div className="clearfix" />
                {credential?.isPrimaryCredential ? (
                    <footer className="m-edit-form">
                        <UpdateField
                            isHidden={false}
                            lockedMode={locked}
                            handleSaveClick={onHandleEditClick}
                            handleCancelClick={onHandleCancelClick}
                            editTitle="Update my account"
                            saveTitle="Save changes"
                        />
                    </footer>
                ) : null}
            </FormContextProvider>
            <div className="clearfix" />
            {credential?.isPrimaryCredential ? (
                <div className="m-customer-details">
                    <header>
                        <h3>Saved payment cards</h3>
                    </header>
                    <div className="clearfix" />
                    <div className="content">
                        <ManagePaymentCards customerId={customer.id} />
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default Profile;

interface IProfileFormData {
    accountName: string;
    postCode: string;
}
