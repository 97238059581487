import { filter, map, sortBy } from "lodash";
import React, { useEffect } from "react";
import ActivityBookingCard from "../Util/ActivityBookingCard";
import { IBookingWithPrettyDate } from "./IBookingWithPrettyDate";
import EqualHeight from "storm-equal-height";

const ActivityBookingByDay = ({
    day,
    bookings
}: IActivityBookingByDayProps) => {
    const todaysBookingsOrderByStartAt = sortBy(
        filter(bookings, (b) => b.prettyDate === day),
        (b) => b.activityStartsAt as Date
    );

    useEffect(() => {
        EqualHeight.init(".m-booking-list");
    });

    return (
        <div className="m-activity-date">
            <header>
                <p className="lead">{day}</p>
            </header>
            <div className="m-booking-list">
                {map(todaysBookingsOrderByStartAt, (booking, i) => {
                    return <ActivityBookingCard key={i} booking={booking} />;
                })}
            </div>
            <div className="clearfix" />
        </div>
    );
};

export default ActivityBookingByDay;

interface IActivityBookingByDayProps {
    day: string;
    bookings: IBookingWithPrettyDate[];
}
