import React from "react";
import { Link } from "react-router-dom";
import useBasket from "../Context/BasketContext/useBasket";

interface IBasketFooterProps {
    basketTotal: number;
    isCheckout: boolean;
    isBasketEmpty: boolean;
    overrideButtonLink: string | null;
}

const BasketFooter = ({
    basketTotal,
    isBasketEmpty,
    isCheckout,
    overrideButtonLink
}: IBasketFooterProps) => {
    const { areBookingNamesRequired, areBookingNamesValid } = useBasket();

    // if  !overrideButtonLink the link will be to "/check-out"
    // stop to checkout if booking names are required and not valid
    const shouldDisableCheckoutButton =
        isBasketEmpty ||
        (overrideButtonLink === "/check-out" &&
            areBookingNamesRequired &&
            !areBookingNamesValid);

    return (
        <footer className="fw a-box v-box-secondary">
            {isBasketEmpty ? (
                <div className="no-sale">
                    <p>Your basket is currently empty</p>
                    <button className="m-btn fw" disabled>
                        Checkout
                    </button>
                </div>
            ) : (
                <div className="sale">
                    <p className="fw">
                        <strong>
                            Total payment required
                            <span>£{basketTotal.toFixed(2)}</span>
                        </strong>
                    </p>
                    {!isCheckout ? (
                        <Link
                            to={
                                overrideButtonLink
                                    ? overrideButtonLink
                                    : "/check-out"
                            }
                        >
                            <button
                                disabled={shouldDisableCheckoutButton}
                                className="m-btn fw"
                                data-testid="checkout-button"
                            >
                                Checkout
                            </button>
                        </Link>
                    ) : null}
                </div>
            )}
        </footer>
    );
};

export default BasketFooter;
